import { useRecoilCallback } from 'recoil';
import {
  currentSubscriptionActivitiesSearchParamAtom,
  currentSubscriptionCommentsSearchParamAtom,
  currentSubscriptionTempAddressesSearchParamAtom,
  subscriptionRefreshRequestIDAtoms,
  subscriptionSearchRequestIDAtom,
} from '../';
import {
  DEFAULT_ACTIVITY_COUNT,
  DEFAULT_ADDRESS_COUNT,
  DEFAULT_COMMENT_COUNT,
} from '../../js/constants/subs';

// *********************************************** //
//           Subscription List Page Hooks          //
// *********************************************** //

export const useRefreshSubscriptionList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh subscription list
        set(subscriptionSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshSubscription = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        // refresh subscription
        set(subscriptionRefreshRequestIDAtoms(id), requestID => requestID + 1);

        // refresh Activity log
        set(currentSubscriptionActivitiesSearchParamAtom, prevParam => {
          return {
            limit: prevParam.limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

// *********************************************** //
//           Subscription Detail Page Hooks        //
// *********************************************** //

export const useRefreshCurrentSubscriptionActivities = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ACTIVITY_COUNT) => {
        set(currentSubscriptionActivitiesSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useRefreshCurrentSubscriptionComments = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_COMMENT_COUNT) => {
        set(currentSubscriptionCommentsSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

// *********************************************** //
//            Subscription Detail Page Hooks       //
// *********************************************** //

export const useRefreshCurrentSubscriptionTempAddresses = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ADDRESS_COUNT) => {
        set(currentSubscriptionTempAddressesSearchParamAtom, prevParam => {
          return {
            limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};
