import React, { useRef } from 'react';
import { Row } from 'react-bootstrap';
import ReactSelect, {
  StylesConfig,
  createFilter,
  components,
  ValueContainerProps,
} from 'react-select';
import { FilterGroupNameFlag } from 'src/components/flags/EpSubsFlags';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import style from './EpSubsSavedFilterSelector.module.scss';
import { DEBUGGING } from 'src/config';

export interface IMultipleSelectProps {
  onSelect: (option: TSimpleFilterSelectOption | null) => void;
  selectedOptionValue: string;
  allOptions: TSimpleFilterSelectOption[] | null;
}

export type TSimpleFilterSelectOption = {
  label: string;
  value: string;
  groupName?: string;
  groupField?: string;
};

type IsMulti = false;
type TValueContainerChildren = { props: { data: TSimpleFilterSelectOption } }[];

export const EpSubsSimpleFilterSelector = ({
  onSelect,
  selectedOptionValue,
  allOptions,
}: IMultipleSelectProps): JSX.Element => {
  const selectRef = useRef<any>(null);

  const customStyles: StylesConfig<TSimpleFilterSelectOption, IsMulti> = {
    option: provided => ({
      ...provided,
      wordWrap: 'break-word',
    }),
  };

  // Used to customize the layout of selected options
  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<TSimpleFilterSelectOption, IsMulti>) => (
    <components.ValueContainer {...props}>
      {/* {console.log('ValueContainer: ', children)} */}
      <Row className={`ml-1 ${style.select_value}`}>
        {FilterGroupNameFlag(
          !!children && !!(children as TValueContainerChildren)[0]?.props?.data
            ? (children as TValueContainerChildren)[0].props.data.groupName ?? ''
            : null,
          { margin: '0.3rem 0rem' },
        )}
        <p className="ml-1 font-weight-bold">{children}</p>
      </Row>
    </components.ValueContainer>
  );

  if (!allOptions || allOptions.length === 0) return <ReactSelect options={[]} />;
  const selectedOption = allOptions.find(obj => {
    return obj.value === selectedOptionValue;
  });
  // //***** Not Sure what is the purpose of this section of code: *****//
  // let selectDefaultLabel: TSimpleFilterSelectOption | undefined | null = options.find(
  //   option => option.label === noFilter.label,
  // );
  // if (selectDefaultLabel === undefined) {
  //   // if undefined
  //   selectDefaultLabel = null;
  // }
  // console.log(selectDefaultLabel);
  // //***** End: Not Sure what is the purpose of this section of code. *****//

  const handleChange = (option: TSimpleFilterSelectOption | null) => {
    onSelect(option);
    DEBUGGING && console.log('Simple Filter Selected Options: ', option);
  };

  return (
    <ReactSelect
      isMulti={false}
      isClearable={false}
      options={allOptions}
      // for typeahead filter, only search 'label' field, don't search 'value' field
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: option => `${option.label}`,
      })}
      value={
        selectedOption && {
          ...selectedOption,
          label: toInitialUpperCase(selectedOption.label, false),
        }
      }
      // Used to avoid redundant option highlight
      // Ref: https://github.com/JedWatson/react-select/issues/1825
      getOptionValue={option => toInitialUpperCase(option.label, false)}
      onChange={option => handleChange(option)}
      isDisabled={false}
      ref={ref => (selectRef.current = ref)}
      components={{ ValueContainer }}
      styles={customStyles}
      // onBlur={() => {
      //   renderProps.onBlur();
      //   // if (onBlur) onBlur();
      // }}
    />
  );
};
