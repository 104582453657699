import { useRecoilCallback } from 'recoil';
import {
  pauseScheduledTask,
  resumeScheduledTask,
  changeScheduledTaskInterval,
  enableTimeMask,
} from '../../services';
import { scheduledTaskListRequestIDAtom } from './systemAdminAtoms';
import { putNoty } from '../../utilities/epSubsNoty';

export const usePauseScheduledTask = (): ((taskId: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (taskId: number) => {
        try {
          const updateSuccess = await pauseScheduledTask(taskId);
          if (updateSuccess) {
            const message = `Successfully paused scheduled task.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh scheduled tasks
        set(scheduledTaskListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useResumeScheduledTask = (): ((taskId: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (taskId: number) => {
        try {
          const updateSuccess = await resumeScheduledTask(taskId);
          if (updateSuccess) {
            const message = `Successfully resumed scheduled task.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh scheduled tasks
        set(scheduledTaskListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useChangeScheduledTaskInterval = (): ((
  taskId: number,
  interval: string,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (taskId: number, interval: string) => {
        try {
          const updateSuccess = await changeScheduledTaskInterval(taskId, interval);
          if (updateSuccess) {
            const message = `Successfully updated scheduled task interval.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh scheduled tasks
        set(scheduledTaskListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useTimeMask = (): ((
  taskId: number,
  maskInputValue: string,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (taskId: number, maskInputValue: string) => {
        try {
          const maskSuccess = await enableTimeMask(taskId, maskInputValue);
          if (maskSuccess.data) {
            putNoty({ type: 'success', text: maskSuccess.message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh scheduled tasks
        set(scheduledTaskListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};
