import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { ELocale, IEpSubsDBCustomer_addressValue } from '../../models';
import {
  ESFormSimpleDropdownField,
  TUseFormSetValue,
} from 'src/components/formComponents';
import { EpSubsAddressFormSubSection } from '../addresses/EpSubsAddressFormSubSection';
import { INewAddressFormData } from '../../models';
import { getAddressConcat } from 'src/utilities/stringUtility';
import { ICompositeSubscriptionFormData } from '../subscriptions/EpSubsSubscriptionForm';

export enum subscriptionPlansSummaryType {
  None,
  Shippable,
  Digital,
}

type Props = {
  defaultAddressData: INewAddressFormData;
  namePath: string;
  addressListData: IEpSubsDBCustomer_addressValue[] | undefined;
  showCreateNewAddress: boolean;
  validationStatus?: boolean;
  isShipping?: boolean;
  register: any;
  errors: any;
  control: any;
  setValue: any;
  currentValues: ICompositeSubscriptionFormData;
  customerLocale: ELocale;
};

/**
 * @param isShipping  if isShipping is true show shipping section, otherwise show billing section
 * @param addressData if the customer has any address will show existing address can use
 * @param shipping_to_billing if the shipping and billing are same
 * @param showCreateNewAddress if it is true show create new address
 * @example
 * Situation Cases that need unit test:
 * 1. shipping part:
 *  isShipping
 *  1.If isShipping is true, show shipping part otherwise show billing part
 *    1.If the customer has no existing address, show the shipping address form without toggle button
 *    2.If the customer has address, show the existing address option with toggle button
 *
 * 2. billing part:
 *  not isShipping && not shipping_to_billing
 *  1. if user did not check the shipping to billing, showed billing address
 *   1. if the customer has no existing address, will not show the billing address form
 *   2. if the customer has the existing address, will show the address options form
 *  2. shipping to billing -> show nothing
 */
const EpSubsAddressFormSection = ({
  addressListData,
  defaultAddressData,
  showCreateNewAddress,
  namePath,
  isShipping = true,
  register,
  errors,
  control,
  setValue,
  currentValues,
  customerLocale,
}: Props): JSX.Element => {
  const [showCreateNewAddressSection, setShowCreateNewAddressSection] =
    useState(showCreateNewAddress);
  return (
    <>
      {(isShipping || !currentValues.shipping_to_billing) && (
        <>
          {(showCreateNewAddressSection ||
            (!isShipping && (!addressListData || addressListData.length == 0))) && (
            <EpSubsAddressFormSubSection
              defaultAddressData={defaultAddressData}
              namePath={namePath}
              errors={isShipping ? errors.shipping ?? {} : errors.billing ?? {}}
              register={register}
              control={control}
              setValue={setValue as TUseFormSetValue<any>}
              watch={
                isShipping
                  ? (currentValues.shipping as INewAddressFormData)
                  : (currentValues.billing as INewAddressFormData)
              }
              customerLocale={customerLocale}
            />
          )}

          {addressListData && addressListData.length > 0 && !showCreateNewAddressSection && (
            <div style={{ paddingLeft: '130px' }}>
              <Form.Row>
                <Col md={{ span: 8, offset: 2 }}>
                  <ESFormSimpleDropdownField
                    label="Saved Addresses"
                    controlId={isShipping ? 'shippingAddress' : `billingAddress`}
                    name={isShipping ? 'shipping_address_id' : 'billing_address_id'}
                    placeholder="Select An Address"
                    required
                    register={register({
                      required: 'Please select an address!',
                    })}
                    control={control}
                    options={addressListData.map(address => {
                      return {
                        value: String(address.id),
                        label: getAddressConcat(address),
                      };
                    })}
                    variant="side"
                  />
                </Col>
              </Form.Row>
              <Row className="mt-3 mb-3">
                <Col md={{ span: 8, offset: 2 }}>
                  <Col className="d-flex align-items-center">
                    <p className="mb-0 mr-3">OR:</p>
                    <Button
                      id="Ep_added_new_address"
                      onClick={() => setShowCreateNewAddressSection(true)}
                    >
                      Add New Address
                    </Button>
                  </Col>
                </Col>
              </Row>
            </div>
          )}

          {addressListData && addressListData.length > 0 && showCreateNewAddressSection && (
            <Row className="mt-3 mb-3">
              <Col md={{ span: 8, offset: 2 }}>
                <Col className="d-flex align-items-center">
                  <p className="mb-0 mr-3">OR:</p>
                  <Button
                    onClick={() => {
                      setShowCreateNewAddressSection(false);
                    }}
                  >
                    Use Existing Address
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default EpSubsAddressFormSection;
