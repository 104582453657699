import React from 'react';
import EpSubsMainContainer from 'src/containers/EpSubsMainContainer';

//----------------------------------------------------------------------
//[TInput begin]
//[TInput end]

//[TOutput begin]
type TOutput = JSX.Element;
//[TOutput end]
//----------------------------------------------------------------------

export default function MainContainer(): TOutput {
  return <EpSubsMainContainer />;
}
