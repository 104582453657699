import {
  epSubsPlanActionTypes,
  FETCH_PLAN_DATA_SUCCESS,
  SAVE_LATEST_PLAN_SEARCH_PARAM,
} from './epSubsPlanTypes';
import { PlansState, RootState } from '../rootState';
import { IEpSubsCompositeDBPlanValue } from '../../models/';
import { DEBUGGING } from 'src/config';

const epSubsPlanReducer = (
  state: RootState['plans'] = {
    planList: new Map<number, IEpSubsCompositeDBPlanValue>(),
    latestLocation: { search: '' },
  },
  action: epSubsPlanActionTypes,
): PlansState => {
  switch (action.type) {
    case FETCH_PLAN_DATA_SUCCESS:
      DEBUGGING && console.log('FETCH_PLAN_DATA_SUCCESS for id = ', action.id, state);
      const newPlanList = new Map(state.planList);
      return {
        ...state,
        planList: newPlanList.set(action.id, action.planData),
      };
    case SAVE_LATEST_PLAN_SEARCH_PARAM:
      DEBUGGING && console.log('SAVE_LATEST_PLAN_SEARCH_PARAM: ', action.loc);
      return {
        ...state,
        latestLocation: action.loc,
      };
    default:
      return state;
  }
};

export default epSubsPlanReducer;
