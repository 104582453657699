export enum ELocale {
  // BCP-47 Code (Reference: https://appmakers.dev/bcp-47-language-codes-list/)
  en_US = 'en-US',
  en_AU = 'en-AU',
  ja_JP = 'ja-JP',
  de_DE = 'de-DE',
  en_UK = 'en-UK',
  es_US = 'es-US',
  es_MX = 'es-MX',
  cs_CZ = 'cs-CZ',
  sk_SK = 'sk-SK',
  fr_FR = 'fr-FR',
  pt_BR = 'pt-BR',
}
