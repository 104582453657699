import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBTransactionValue,
  TEpSubsDBTransactionValueKeys,
  TEpSubsDBResponseOk,
  IEpSubsCompositeDBTransactionValue,
  IEpSubsDBTransaction_commentValue,
  IEpSubsDBTransaction_activityValue,
} from '../models';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';
export const genTransactionListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBTransactionValueKeys>> = [],
  order = 'DESC',
  simpleQuery = '',
  filter = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBTransactionValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBTransactionValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/transactions/search?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `perPage=${perPage}`,
          `page=${pageIdx - 1}`,
          `status=${filter}`,
          `order=${order}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const getAllTransactions = async (): Promise<
  IEpSubsCompositeDBTransactionValue[]
> => {
  return axios.get(`${hostUrl}/transactions`).then(response => {
    return response.data.data ?? [];
  });
};

export const genTransactionData = async (
  id: number,
): Promise<IEpSubsCompositeDBTransactionValue> => {
  return axios.get(`${hostUrl}/transactions/${id}`).then(response => {
    return response.data;
  });
};

export const genTransactions = async (
  id: number,
  source: 'subscriptions' | 'customers',
): Promise<TEpSubsDBResponseOk<IEpSubsDBTransactionValue>> => {
  return axios.get(`${hostUrl}/${source}/${id}/transactions`).then(response => {
    return response.data;
  });
};

// wait for future decision
export const genExportTransactionList = async (
  sortingItems: Array<TSortingItem<TEpSubsDBTransactionValueKeys>> = [],
  simpleQuery = '',
): Promise<string> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBTransactionValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/transactions/export?` +
        [`key=${simpleQuery}`, `sort=${sortSQLStr}`].join('&'),
    )
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `TRANSACTIONS-${unixtimeWithoutTime(Date.now())}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const genTransactionActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBTransaction_activityValue[]> => {
  return axios
    .get(`${hostUrl}/transactions/${id}/actlogs?limit=${limit}`)
    .then(response => {
      return response.data.data;
    });
};

export const genTransactionActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBTransaction_activityValue> => {
  return axios.get(`${hostUrl}/transactions/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genTransactionCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBTransaction_commentValue[]> => {
  return axios
    .get(`${hostUrl}/transactions/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreateTransactionComment = async (
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/transactions/comments`, data).then(response => {
    removeAllUploadedImage();
    return response.data.data;
  });
};

export const genRemoveTransaction = async (
  invoice_id: number,
  transaction_id?: number,
): Promise<string | undefined> => {
  if (transaction_id !== undefined) {
    return axios
      .post(`${hostUrl}/invoices/${invoice_id}/remove-payment/${transaction_id}`)
      .then(response => {
        return response.data.data;
      });
  }
};
