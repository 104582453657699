import braintree from 'braintree-web';
import paypal from 'paypal-checkout-components';
import React, { BaseSyntheticEvent, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// import { Button } from 'react-bootstrap';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { DEBUGGING } from 'src/config';
import { languageOptions } from 'src/models/i18n';
import useScript from 'src/utilities/useScript';

export interface payPalCheckbox {
  make_primary: boolean;
  checkboxApplyAllSubs: boolean;
}

export const BraintreeWebPaypalCheckout = ({
  options,
  onSubmit,
}: {
  options: { authorization: string };
  onSubmit: (
    data: paypal.AuthorizationResponse,
    checkboxPayPal: boolean,
    checkboxApplyAllSubs: boolean,
    event?: BaseSyntheticEvent,
  ) => Promise<void>;
}): JSX.Element => {
  const { register, watch } = useForm<payPalCheckbox>({
    mode: 'all',
  });
  const [isLoading, setIsLoading] = useState(false);
  // State for two step submit of Paypal payment method
  // const [paymentInfo, setPaymentInfo] = useState<paypal.AuthorizationResponse>();
  // const [buttonShow, setButtonShow] = useState(false);
  const paypalCheckout = () => {
    braintree.client
      .create({
        authorization: options.authorization,
      })
      .then(function (clientInstance) {
        DEBUGGING && console.log(clientInstance, 'clientInstance');
        return braintree.paypalCheckout.create({
          client: clientInstance,
        });
      })
      .then(function (paypalCheckoutInstance) {
        global.paypal.Button.render(
          {
            env: 'sandbox' as paypal.Environment,
            payment: function () {
              return paypalCheckoutInstance.createPayment({
                flow: 'vault' as paypal.FlowType,
                intent: 'capture' as paypal.Intent,
              });
            },
            style: {
              size: 'large',
              color: 'blue',
            } as paypal.ButtonStyle,

            onAuthorize: async (
              data: paypal.AuthorizationData,
            ): Promise<paypal.AuthorizationResponse> => {
              setIsLoading(true);
              const tokenPayload = await paypalCheckoutInstance.tokenizePayment(data);
              DEBUGGING && console.log(tokenPayload.nonce, 'payload nonce');
              // Action for two step submit of Paypal payment method
              // setPaymentInfo(tokenPayload);
              // setButtonShow(true);
              await onSubmit(
                tokenPayload,
                watch().make_primary ?? true,
                watch().checkboxApplyAllSubs ?? true,
              );
              setIsLoading(false);
              return tokenPayload;
            },
          },
          '#paypal-button',
        );
      })
      .catch(function (err) {
        console.error('Error!', err);
      });
  };
  useScript({
    scriptUrl: `https://www.paypalobjects.com/api/checkout.js`,
    scriptId: 'paypal_checkout',
    callback: paypalCheckout,
  });

  return (
    <>
      <div>
        <Row style={{ marginLeft: '50px', marginTop: '20px' }}>
          <Form.Check
            type="checkbox"
            name="make_primary"
            ref={register()}
            defaultChecked={true}
          />
          <p>{languageOptions().portal.default.make_default}</p>
        </Row>
        <Row style={{ marginLeft: '50px' }}>
          <Form.Check
            type="checkbox"
            name="apply_to_all_subs"
            ref={register()}
            defaultChecked={true}
          />
          <p>{languageOptions().portal.default.apply_all}</p>
        </Row>
        <div
          id="paypal-button"
          style={{ padding: '10px', textAlign: 'center', marginTop: '20px' }}
        />
      </div>
      <EpSubsSpinnerModal isLoading={isLoading} />
    </>
  );
};
