import { useRecoilCallback } from 'recoil';
import {
  notificationDetailEnabledAtomFamily,
  notificationDetailRequestIDAtomFamily,
  rowNotificationEventsRequestIDAtom,
} from '..';

export const useRefreshNotificationEvents = (): (() => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(rowNotificationEventsRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

/**
 * This hook will enable the EventCategory list page in Settings
 * to render the refreshed Notification Detail data from genNotificationData service.
 * The design of the this mechanism is to
 * - 1 Avoid calling all the notification detail data apis for each notifications
 * - 2 Enable the EventCategory list page in Settings to depend on the data from notification detail data api
 */
export const useEnableNotificationDetailData = (): ((
  notificationId: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (notificationId: number) => {
        set(notificationDetailEnabledAtomFamily(notificationId), true);
      },
    [],
  );
};

export const useRefreshNotificationDetailData = (): ((
  notificationId: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (notificationId: number) => {
        set(
          notificationDetailRequestIDAtomFamily(notificationId),
          requestID => requestID + 1,
        );
      },
    [],
  );
};
