import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import {
  DB_DATA_PARSE_ERROR,
  UNDEFINED_NULL_DATA_HANDLING,
  handleDefaultExceptions,
} from './epSubsServiceErrorHandleUtil';
import { putNoty } from 'src/utilities/epSubsNoty';
import { AUTH_TOKEN } from '../js/constants/subs';
import { TFilterData } from 'src/models';
import { LOCALE } from 'src/config';

const NO_ERROR = false;
export const EMPTY_EXPORT_ERROR = 'Empty result, nothing exported!';

// *********************************************** //
//                   Sorting Utils                 //
// *********************************************** //
export type TAscendingType = 'ASC' | 'DESC';
export type TSortingItem<TKeyEnum> = {
  key: TKeyEnum;
  ascending?: TAscendingType | null;
};

export function getSortSQLStrFromSortingItems<TKeyEnum>(
  sortingItems: Array<TSortingItem<TKeyEnum>>,
): string {
  if (sortingItems === ([] as any)) {
    return '';
  }
  const sortSQLStr = sortingItems
    .map(sortingItem => [sortingItem.key, sortingItem.ascending ?? 'DESC'].join(' '))
    .join(', ');

  return sortSQLStr;
}

export function getSortSQLStrFromFilterSearchSort(
  filterSearchSort: TFilterData['sort_field'],
): string {
  if (filterSearchSort === undefined) {
    return '';
  }
  const spitedFieldPath = filterSearchSort.field.split('.');
  const sortSQLStr = [
    spitedFieldPath.length > 1 ? spitedFieldPath[1] : spitedFieldPath[0],
    filterSearchSort.asc ? 'ASC' : 'DESC',
  ].join(' ');

  return sortSQLStr;
}

// *********************************************** //
//         Axios Error Interception Utils          //
// *********************************************** //

const checkMethodType = (targetMethods: Method[], currentMethod?: Method) =>
  currentMethod
    ? targetMethods.includes(String(currentMethod).toUpperCase() as Method)
    : false;

export function configAxios() {
  // config axios interceptors
  axios.interceptors.request.use((req: AxiosRequestConfig) => {
    // `req` is the Axios request config, so you can modify the `headers`.
    req.headers['Accept-Language'] = LOCALE;
    if (localStorage.getItem(AUTH_TOKEN) !== null) {
      req.headers['Authorization'] = `Bearer ${localStorage.getItem(AUTH_TOKEN)}`;
    }
    return req;
  });

  axios.interceptors.response.use(
    response => {
      // The logic depends on the backend message design
      const requestUrl: string = response.config.url ?? '';

      let errorCheckForOKResponse: boolean =
        isStatusStrOK(response) && !response.data.data;
      if (
        requestUrl.match(/.+\/subscriptions\/export\?.+/) ||
        requestUrl.match(/.+\/customers\/export\?.+/) ||
        requestUrl.match(/.+\/plans\/export\?.+/) ||
        requestUrl.match(/.+\/addons\/export\?.+/) ||
        requestUrl.match(/.+\/coupons\/export\?.+/)
      ) {
        errorCheckForOKResponse = !response.data && response.data.status !== 'failed';
      } else if (requestUrl.match(/.+\/subscriptions\/.+\/preview/)) {
        errorCheckForOKResponse = !response.data && response.data.summaries;
      } else if (
        // Requests with ok in response only:
        (requestUrl.match(/.+\/subscriptions\/.+/) &&
          checkMethodType(['PATCH'], response.config.method)) ||
        requestUrl.match(/.+\/subscriptions\/.+\/change-start-date/) ||
        requestUrl.match(/.+\/subscriptions\/.+\/change-next-billing-date/) ||
        requestUrl.match(/.+\/subscriptions\/.+\/change-cancel-date/) ||
        requestUrl.match(/.+\/subscriptions\/.+\/add-address/) ||
        requestUrl.match(/.+\/elastic\/remove-filter\/.+/)
      ) {
        errorCheckForOKResponse = isStatusStrOK(response) && NO_ERROR;
      }

      let schemaChangeCheck = NO_ERROR;
      if (
        requestUrl.match(/.+\/customers\/search\?.+/) ||
        requestUrl.match(/.+\/invoices\/search\?.+/) ||
        requestUrl.match(/.+\/plans\/search\?.+/) ||
        requestUrl.match(/.+\/active-plans/) ||
        requestUrl.match(/.+\/addons\/search\?.+/) ||
        requestUrl.match(/.+\/active-addons/) ||
        requestUrl.match(/.+\/coupons\/search\?.+/) ||
        requestUrl.match(/.+\/active-coupons/) ||
        requestUrl.match(/.+\/subscriptions\/search\?.+/) ||
        requestUrl.match(/.+\/transactions\/search\?.+/) ||
        requestUrl.match(/.+\/credit-notes\/search\?.+/)
      ) {
        schemaChangeCheck = response.data.total !== undefined;
      }

      let nullDataHandlingMethod = 'throw';
      if (
        requestUrl.match(/.+\/ref\/reason-code\/.+/) ||
        requestUrl.match(/.+\/ref\/sub-channels/) ||
        requestUrl.match(/.+\/ref\/gateways/) ||
        // -- Customer/Subscription Detail Page List Tab
        requestUrl.match(/.+\/.+\/.+\/credit-notes/) ||
        requestUrl.match(/.+\/.+\/.+\/promotional-credits/) ||
        requestUrl.match(/.+\/.+\/.+\/invoices/) ||
        requestUrl.match(/.+\/.+\/.+\/transactions/) ||
        requestUrl.match(/.+\/.+\/.+\/emails/) ||
        requestUrl.match(/.+\/customers\/.+\/address/) ||
        requestUrl.match(/.+\/customers\/.+\/actlogs\?limit=.+/) ||
        // requestUrl.match(/.+\/customers\/actlogs\/.+/) ||
        requestUrl.match(/.+\/customers\/.+\/comments/) ||
        requestUrl.match(/.+\/customers\/.+\/payment-methods/) ||
        requestUrl.match(/.+\/plans\/.+\/actlogs\?limit=.+/) ||
        // requestUrl.match(/.+\/plans\/actlogs\/.+/) ||
        requestUrl.match(/.+\/plans\/.+\/comments/) ||
        requestUrl.match(/.+\/addons\/.+\/actlogs\?limit=.+/) ||
        // requestUrl.match(/.+\/addons\/actlogs\/.+/) ||
        requestUrl.match(/.+\/addons\/.+\/comments/) ||
        requestUrl.match(/.+\/coupons\/.+\/actlogs\?limit=.+/) ||
        // requestUrl.match(/.+\/coupons\/actlogs\/.+/) ||
        requestUrl.match(/.+\/coupons\/.+\/comments/) ||
        requestUrl.match(/.+\/coupons\/list\/active/) ||
        requestUrl.match(/.+\/transactions\/.+\/actlogs\?limit=.+/) ||
        // requestUrl.match(/.+\/transactions\/actlogs\/.+/) ||
        requestUrl.match(/.+\/transactions\/.+\/comments/) ||
        requestUrl.match(/.+\/subscriptions\/.+\/actlogs\?limit=.+/) ||
        // requestUrl.match(/.+\/subscriptions\/actlogs\/.+/) ||
        requestUrl.match(/.+\/subscriptions\/.+\/comments/) ||
        requestUrl.match(/.+\/invoices\/.+\/actlogs\?limit=.+/) ||
        // requestUrl.match(/.+\/invoices\/actlogs\/.+/) ||
        requestUrl.match(/.+\/invoices\/.+\/comments/) ||
        // -- Saved Filter List in Supported Search List --
        requestUrl.match(/.+\/customers\/filters/) ||
        requestUrl.match(/.+\/subscriptions\/filters/) ||
        requestUrl.match(/.+\/invoices\/filters/) ||
        // -- Allow Empty result for Search List Page --
        // requestUrl.match(/.+\/products\/search.*/) ||
        (requestUrl.match(/.+\/products/) &&
          checkMethodType(['GET'], response.config.method)) ||
        requestUrl.match(/.+\/plans\/search.*/) ||
        // requestUrl.match(/.+\/offers\/search.*/) ||
        (requestUrl.match(/.+\/offers/) &&
          checkMethodType(['GET'], response.config.method)) ||
        requestUrl.match(/.+\/addons\/search.*/) ||
        requestUrl.match(/.+\/coupons\/search.*/) ||
        requestUrl.match(/.+\/customers\/search.*/) ||
        requestUrl.match(/.+\/subscriptions\/search.*/) ||
        requestUrl.match(/.+\/invoices\/search.*/) ||
        requestUrl.match(/.+\/transactions\/search.*/) ||
        requestUrl.match(/.+\/logs\/emails\/search.*/) ||
        requestUrl.match(/.+\/users\/search/) ||
        // -- Customized Filter Search Result --
        requestUrl.match(/.+\/customers\/filtered-search.*/) ||
        requestUrl.match(/.+\/subscriptions\/filtered-search.*/) ||
        requestUrl.match(/.+\/invoices\/filtered-search.*/) ||
        // -- Saved Filter Search Result --
        requestUrl.match(/.+\/customers\/filtered-search\/.+/) ||
        requestUrl.match(/.+\/subscriptions\/filtered-search\/.+/) ||
        requestUrl.match(/.+\/invoices\/filtered-search\/.+/) ||
        requestUrl.match(/.+\/error\/.+/)
      ) {
        nullDataHandlingMethod = 'empty_array';
      }
      try {
        if (response.status >= 400) {
          throw new Error(response.data?.message ?? response.data);
        }
        if (errorCheckForOKResponse || response.data === null) {
          if (schemaChangeCheck) {
            // If payload type check failed, Data Parse Error
            throw new Error(DB_DATA_PARSE_ERROR);
          }
          switch (nullDataHandlingMethod) {
            case 'throw':
              // If data is not available in response, DB Error:
              if (response.data.status == 'ok' && response.data.status !== undefined) {
              } else {
                throw '';
              }
              break;
            case 'empty_array':
              // return ([] as unknown) as TPayload;
              if (response.data === null) response.data = [];
              else response.data.data = [];
              break;
            default:
              throw new Error(UNDEFINED_NULL_DATA_HANDLING);
          }
        }
        // Handle the case that response.data.data should be [] but provided null by BE
        // This case should be handled by BE
        if (
          response.data &&
          response.data.data === null &&
          nullDataHandlingMethod === 'empty_array'
        ) {
          response.data.data = [];
        }
      } catch (error) {
        putNoty({ type: 'error', text: (error as Error).message });
        // re-throw error
        throw error;
      }

      return response;
    },
    async function (error) {
      try {
        handleDefaultExceptions(error);
      } catch (error) {
        const err = error as Error;
        if (err.message === 'Unauthorized') {
          putNoty({
            type: 'error',
            text: 'You do not have an authorization on this server',
          });
          localStorage.removeItem(AUTH_TOKEN);
          window.location.href = '/';
        } else {
          putNoty({ type: 'error', text: err.message });
          throw err;
        }
      }
    },
  );
}

const isStatusStrOK = (response: AxiosResponse<any>): boolean => {
  return response.data?.status === '' || response.data?.status === 'ok';
};
