import axios from 'axios';
import {
  GATEWAY_TYPE,
  IEpSubsDBGateway_accountApiValue,
  IEpSubsDBGateway_accountPost,
  IEpSubsDBPayment_method_typeValue,
  TEpSubsDBGateway_accountValueKeys,
  TEpSubsDBResponseOk,
} from 'src/models';
import { gatewaysOnPortalType } from 'src/recoil/gateway/gatewayStates.js';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import { hostUrl } from '../config.js';
import { getSortSQLStrFromSortingItems, TSortingItem } from './epSubsServiceUtils';

export const genGatewayListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBGateway_accountValueKeys>> = [],
  simpleQuery = '',
  // status = '',
  // productType = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBGateway_accountApiValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBGateway_accountValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/gateways?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          // `status=${status}`,
          // `product_type=${productType}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const getAllGatewayList = async (): Promise<gatewaysOnPortalType[]> => {
  return axios.get(`${hostUrl}/gateways/list`).then(response => {
    return response.data.data ?? [];
  });
};

export const getAllGateways = async (): Promise<IEpSubsDBGateway_accountApiValue[]> => {
  return axios.get(`${hostUrl}/gateways`).then(response => {
    return response.data.data ?? [];
  });
};

export const genGatewayData = async (
  id: number,
): Promise<IEpSubsDBGateway_accountApiValue> => {
  return axios.get(`${hostUrl}/gateways/${id}`).then(response => {
    return response.data;
  });
};

export const genCreateGateway = async (
  data: IEpSubsDBGateway_accountPost,
): Promise<string> => {
  return axios.post(`${hostUrl}/gateways`, data).then(response => {
    return response.data.data;
  });
};

export const genEditGateway = async (
  id: number,
  data: IEpSubsDBGateway_accountPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/gateways/${id}`, data).then(() => {
    return true;
  });
};

export const genDeleteGateway = async (id: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/gateways/${id}`).then(() => {
    return true;
  });
};

export const genSetDefaultGateway = async (id: number): Promise<boolean> => {
  return axios.put(`${hostUrl}/gateways/${id}/set-default`).then(() => {
    return true;
  });
};

export const genActivateGateway = async (
  id: number,
  status: number,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/gateways/${id}/activate/${status}`).then(() => {
    return true;
  });
};

export const getGatewayKeyFromApiValue = (
  apiGatewayStr: string,
): keyof typeof GATEWAY_TYPE =>
  toInitialUpperCase(apiGatewayStr) as keyof typeof GATEWAY_TYPE;

export const getGatewaysPaymentMethodTypes = (): Promise<
  IEpSubsDBPayment_method_typeValue[]
> => {
  return axios.get(`${hostUrl}/payment-method-types`).then(response => response.data);
};
