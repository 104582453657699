import { useRecoilCallback, useRecoilValue } from 'recoil';
import axios from 'axios';
import { genLogin, IEpSubsLoginPost } from '../../services/epSubsUserService';
import { DB_RESPONSE_OK, DB_RESPONSE_FAILED } from '../../models/epSubsDBGenTypes';
import { AUTH_TOKEN } from '../../js/constants/subs';
import { jwtTokenAtom, hasPermissionSelectors, userInfoRequestAtom } from './userStates';
import { Permission } from 'src/models';
import { transactionSearchRequestIDAtom } from '../transaction/transactionListPageStates';
import { DEBUGGING } from 'src/config';

// login: call backend api, update jwtTokenAtom, set Axios header
export const useLogin = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async (data: IEpSubsLoginPost) => {
        try {
          const jwtToken = await genLogin(data);
          DEBUGGING && console.log(`useLogin jwtToken success`, jwtToken);

          // Saves a bearer token inside Axios default header Authorization:
          axios.defaults.headers.Authorization = `Bearer ${jwtToken}`;

          localStorage.setItem(AUTH_TOKEN, jwtToken);

          set(jwtTokenAtom, jwtToken);

          return {
            status: DB_RESPONSE_OK,
          };
        } catch (error) {
          DEBUGGING && console.log(`useLogin jwtToken error`, error);
          set(jwtTokenAtom, '');
          localStorage.removeItem(AUTH_TOKEN);
          return {
            status: DB_RESPONSE_FAILED,
            error: (error as Error).message,
          };
        }
      },
    [],
  );
};

// logout: remove jwtTokenAtom, remove token form Axios header
export const useLogout = () => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(jwtTokenAtom, '');
        localStorage.removeItem(AUTH_TOKEN);
      },
    [],
  );
};

export const useRefreshUserList = (): (() => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(transactionSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

// Check if user has allowedPermissions
export const useHasPermission = (allowedPermissions: Permission[]) => {
  return useRecoilValue(hasPermissionSelectors(allowedPermissions));
};

export const useRefreshUserInfo = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(userInfoRequestAtom, requestID => requestID + 1);
      },
    [],
  );
};
