import { all, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { watchArchivePlan, watchFetchPlan } from '../store/plan/epSubsPlanActions';
import { watchFetchCustomer } from '../store/customer/epSubsCustomerActions';
import {
  // watchArchiveSubscription,
  watchFetchSubscription,
} from '../store/subscription/epSubsSubscriptionActions';

export default function* rootSaga(): SagaIterator {
  yield all([
    call(watchFetchPlan),
    call(watchArchivePlan),
    call(watchFetchCustomer),
    // call(watchArchiveCustomer)
    call(watchFetchSubscription),
    // call(watchArchiveSubscription),
  ]);
}
