import React, { ReactText, useEffect, useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import style from './Pagination.module.scss';
import { getParamsFromSearch } from '..';
import { useLocation } from 'react-router-dom';

export type TPaginationVariation = 'full' | 'right';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */

const range = (from: number, to: number, step = 1): number[] => {
  let i = from;
  const range2 = [];

  while (i <= to) {
    range2.push(i);
    i += step;
  }

  return range2;
};

export type TPageData = {
  currentPage: number;
  pageLimit?: number;
  totalPages?: number;
  totalRecords?: number;
};

export const offsetToPageId = (offset: number, perPage: number): number => {
  return offset / perPage + 1;
};

type Props = {
  variant?: TPaginationVariation;
  totalRecords: number;
  pageLimit?: number;
  pageNeighbors?: number;
  onPageChanged?: any;
};

function Pagination<TSortKeys>(props: Props): JSX.Element {
  const variant = props.variant ?? 'full';
  const pageLimit = props.pageLimit ?? 10;
  const totalRecords = typeof props.totalRecords === 'number' ? props.totalRecords : 0;
  // pageNeighbors can be: 0, 1 or 2
  const pageNeighbors = Math.max(0, Math.min(props.pageNeighbors ?? 0, 2));
  const totalPages = Math.ceil(totalRecords / pageLimit);

  // Handle Current Page to be synced with location
  const location = useLocation();
  const page = getParamsFromSearch<TSortKeys>(location.search).page;
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [value, setValue] = useState(page);
  useEffect(() => {
    const page = getParamsFromSearch<TSortKeys>(location.search).page;
    setCurrentPage(page);
    setValue(page);
  }, [location.search]);

  // console.log(
  //   'Start Pagination: ',
  //   variant,
  //   pageLimit,
  //   totalRecords,
  //   totalPages,
  //   'page: ',
  //   page,
  //   'currentPage: ',
  //   currentPage,
  //   'value: ',
  //   value,
  //   'location: ',
  //   location,
  // );

  const gotoPage = ({ currentPage, pageLimit = props.pageLimit }: TPageData) => {
    const { onPageChanged = (f: any) => f } = props;
    const gotoPage = Math.max(1, Math.min(currentPage, totalPages));

    const paginationData = {
      currentPage: gotoPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    setCurrentPage(gotoPage);
    setValue(gotoPage);
    onPageChanged(paginationData);
  };

  const handleClick =
    (page: React.ReactText) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      gotoPage({ currentPage: Number.parseInt(page.toString(), 10) });
    };

  const handleMoveLeft = (event: React.MouseEvent) => {
    event.preventDefault();
    gotoPage({ currentPage: currentPage - pageNeighbors * 2 - 1 });
  };

  const handleMoveRight = (event: React.MouseEvent) => {
    event.preventDefault();
    gotoPage({ currentPage: currentPage + pageNeighbors * 2 + 1 });
  };

  // fetch page number by input by kate
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handlePerPageChange = (event: any) => {
    const pageLimit = parseInt(event.target.value);
    const newTotalPages = Math.ceil(totalRecords / pageLimit);
    const newCurrentPage =
      newTotalPages < totalPages ? Math.min(newTotalPages, currentPage) : currentPage;
    gotoPage({ currentPage: newCurrentPage, pageLimit });
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbors to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbors
   */

  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbors * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);

      let pages: ReactText[] = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  // if (!totalRecords || totalPages === 1) {
  //   return null;
  // }

  const pages = fetchPageNumbers();

  const totalRecordsModule = (
    <Row style={{ paddingTop: '8px' }}>
      <span className="totalRecords">Total Records: {totalRecords}</span>
    </Row>
  );

  const leftButton = (
    <button
      type="button"
      className={`${style.my_page_link} ${style.arrow_button}`}
      aria-label="Previous"
      onClick={handleMoveLeft}
    >
      <span aria-hidden="true">&laquo;</span>
      <span className="sr-only">Previous</span>
    </button>
  );

  const rightButton = (
    <button
      type="button"
      className={`${style.my_page_link} ${style.arrow_button}`}
      aria-label="Next"
      onClick={handleMoveRight}
    >
      <span aria-hidden="true">&raquo;</span>
      <span className="sr-only">Next</span>
    </button>
  );

  const pagesModule = (
    <Row>
      <span>
        <ul className={`${style.my_pagination}`} style={{ padding: '0px' }}>
          {pages.map((page, index) => {
            switch (page) {
              case LEFT_PAGE:
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index} className={style.my_page_item}>
                    {leftButton}
                  </li>
                );

              case RIGHT_PAGE:
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index} className={style.my_page_item}>
                    {rightButton}
                  </li>
                );

              default:
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index} className={`${style.my_page_item}`}>
                    <button
                      type="button"
                      className={`${style.my_page_link}  ${
                        currentPage === page ? style.active : ''
                      }`}
                      onClick={handleClick(page)}
                    >
                      {page}
                    </button>
                  </li>
                );
            }
          })}
        </ul>
      </span>
      <span style={{ paddingTop: '4px' }}>
        <form className={`${style.my_pagination}`}>
          <input
            type="text"
            id={style.page_number}
            value={value}
            onChange={handleChange}
          />
          <button
            type="button"
            className={style.my_page_link}
            id={style.jump_to}
            name="Jump"
            onClick={handleClick(value)}
          >
            Jump to
          </button>
        </form>
      </span>
    </Row>
  );

  const simplePagesModule = (
    <nav aria-label="Pagination" style={{ display: 'flex' }}>
      <ul
        className={`${style.my_pagination} ${style.pagination_at_right} d-flex`}
        style={{ paddingLeft: '10px' }}
      >
        <div className={style.simple_pagination_text}>{`Showing ${
          1 + (currentPage - 1) * pageLimit
        } - ${Math.min(currentPage * pageLimit, totalRecords)} of ${totalRecords}`}</div>
        <div className={style.simple_pagination_button}>
          {currentPage > 1 && leftButton}
        </div>
        <div className={style.simple_pagination_button}>
          {currentPage < totalPages && rightButton}
        </div>
      </ul>
    </nav>
  );

  // const { register, control } = useForm<ICommentFormData>({
  //   mode: 'all',
  // });

  const perPageOptionModule = (
    <Row style={{ paddingTop: '2px' }}>
      <form autoComplete={'off'} className="my_auto">
        <Form.Label className="mr-3">Records per page</Form.Label>
        {/* <ESFormDropdownInput
          name={'page'}
          register={register}
          control={control}
          placeholder={''}
          options={PAGES_OPTIONS}
          onChange={handlePerPageChange}
          defaultValue={pageLimit.toString()}
        /> */}
        <Form.Control
          as="select"
          custom
          style={{ width: 'unset' }}
          onChange={handlePerPageChange}
          value={pageLimit}
        >
          {[3, 5, 10, 15, 20, 25].map(perPage => (
            <option key={perPage} value={perPage}>
              {perPage}
            </option>
          ))}
        </Form.Control>
      </form>
    </Row>
  );

  let paginationBody;
  switch (variant) {
    case 'full':
      paginationBody = (
        <Container fluid>
          <Row>
            <Col md={4}>{totalRecordsModule}</Col>
            <Col md={6}>{pagesModule}</Col>
            <Col md={2}>{perPageOptionModule}</Col>
          </Row>
        </Container>
      );
      break;
    case 'right':
      paginationBody = simplePagesModule;
      break;
  }

  return paginationBody;
}

export default Pagination;
