import { useRecoilCallback } from 'recoil';
import { paymentListRequestIDAtom } from '..';

export const useRefreshPaymentList = (): (() => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(paymentListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};
