import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBCouponValue,
  TEpSubsDBCouponValueKeys,
  TEpSubsDBResponseOk,
  IEpSubsDBCoupon_activityValue,
  IEpSubsDBCoupon_commentValue,
  IEpSubsCompositeDBCouponValue,
  IEpSubsCompositeDBCouponPost,
} from '../models';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';

export const genCouponListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBCouponValueKeys>> = [],
  simpleQuery = '',
  filter = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBCouponValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBCouponValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/coupons/search?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          `status=${filter}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const genActiveCouponListData = async (): Promise<IEpSubsDBCouponValue[]> => {
  return axios.get(`${hostUrl}/coupons/list/active`).then(response => {
    return response.data;
  });
};

export const genArchivedCouponListData = async (): Promise<
  TEpSubsDBResponseOk<IEpSubsDBCouponValue>
> => {
  return axios.get(`${hostUrl}/coupons/list/archived`).then(response => {
    return response.data;
  });
};

export const genExpiredCouponListData = async (): Promise<
  TEpSubsDBResponseOk<IEpSubsDBCouponValue>
> => {
  return axios.get(`${hostUrl}/coupons/list/expired`).then(response => {
    return response.data;
  });
};

export const genExportCouponList = async (
  sortingItems: Array<TSortingItem<TEpSubsDBCouponValueKeys>> = [],
  simpleQuery = '',
  filter = '',
): Promise<string> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBCouponValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/coupons/export?` +
        [`key=${simpleQuery}`, `sort=${sortSQLStr}`, `status=${filter}`].join('&'),
    )
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `${filter.toUpperCase()}-COUPONS-${unixtimeWithoutTime(
        Date.now(),
      )}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const genCouponData = async (
  id: number,
): Promise<IEpSubsCompositeDBCouponValue> => {
  return axios.get(`${hostUrl}/coupons/${id}`).then(response => {
    return response.data;
  });
};

export const genCouponActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBCoupon_activityValue[]> => {
  return axios.get(`${hostUrl}/coupons/${id}/actlogs?limit=${limit}`).then(response => {
    return response.data.data;
  });
};

export const genCouponActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBCoupon_activityValue> => {
  return axios.get(`${hostUrl}/coupons/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genCouponCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBCoupon_commentValue[]> => {
  return axios
    .get(`${hostUrl}/coupons/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreateCouponComment = async (
  id: number,
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/coupons/${id}/comments`, data).then(response => {
    removeAllUploadedImage();
    return response.data;
  });
};

export const genCreateCoupon = async (
  data: IEpSubsCompositeDBCouponPost,
): Promise<string> => {
  return axios.post(`${hostUrl}/coupons`, data).then(response => {
    return response.data.data;
  });
};

export const genCloneCoupon = async (id: number): Promise<string> => {
  return axios.post(`${hostUrl}/coupons/${id}/clone`).then(response => {
    return response.data.data; // newCouponID
  });
};

export const genEditCoupon = async (
  id: number,
  data: IEpSubsCompositeDBCouponPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/coupons/${id}`, data).then(() => {
    return true;
  });
};

export const genArchiveCoupon = async (id: number, archive = true): Promise<boolean> => {
  const archiveParaStr = !archive ? 'archive=false' : '';
  return axios.post(`${hostUrl}/coupons/${id}/archive?${archiveParaStr}`).then(() => {
    return true;
  });
};

export const genPlanMatchedCoupons = async (
  planId: number,
): Promise<IEpSubsDBCouponValue[]> => {
  return axios.get(`${hostUrl}/coupons/plan-match?id=${planId}`).then(response => {
    return response.data;
  });
};

export const genAddonMatchedCoupons = async (
  addon_id: string,
): Promise<IEpSubsDBCouponValue[]> => {
  return axios
    .get(`${hostUrl}/coupons/addon-match?addon_id=${addon_id}`)
    .then(response => {
      return response.data;
    });
};

export const genCouponAllListData = async (): Promise<IEpSubsDBCouponValue[]> => {
  return axios.get(`${hostUrl}/coupons/search?`).then(response => {
    return response.data;
  });
};
