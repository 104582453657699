import { getParamsFromSearch } from 'src/components/tables/EpFullFeaturedTable';
import { ESimpleSearchFields, TFilterSource } from 'src/js/constants/subs';
import {
  TEpSubsDBAddonValueKeys,
  TEpSubsDBCouponValueKeys,
  TEpSubsDBCustomerValueKeys,
  TEpSubsDBInvoiceValueKeys,
  TEpSubsDBPlanValueKeys,
  TEpSubsDBSubscriptionValueKeys,
} from 'src/models';
import {
  genExportAddonList,
  genExportCouponList,
  // genExportCustomerList,
  genExportFilteredSearchData,
  // genExportInvoiceList,
  genExportPlanList,
  genExportSavedFilteredSearchData,
  // genExportSubscriptionList,
} from 'src/services';
import * as H from 'history';
import { putNoty } from 'src/utilities/epSubsNoty';
import { getSimpleFilterData } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import {
  PLAN_STATUS_FILTER_GROUP_FIELD,
  PRODUCT_FILTER_GROUP_FIELD,
} from 'src/containers/plans/EpSubsPlanNav';
import { ADDON_STATUS_FILTER_GROUP_FIELD } from 'src/containers/addons/EpSubsAddonNav';
import { COUPON_STATUS_FILTER_GROUP_FIELD } from 'src/containers/coupons/EpSubsCouponNav';
import { isFilterEnabledSearchList } from 'src/components/navBars/EpSubsListNav/EpSubsListNav';

const FILTER_KEYS_WITH_OBJECT_PREFIX = [
  'id',
  'name',
  'status',
  'plan_id',
  'created_at',
  'updated_at',
  'amount',
  // Invoice Only Fields
  'paid_at',
  'voided_at',
];

export const addPrefixForFilterField = (field: string, source: TFilterSource): string => {
  if (FILTER_KEYS_WITH_OBJECT_PREFIX.includes(field)) {
    return `${source}.${field}`;
  }
  return field;
};

export const onExportFilterSearchResultClick =
  ({
    setIsLoading,
    source,
    location,
  }: {
    setIsLoading: (isLoading: boolean) => void;
    source: Exclude<TFilterSource, 'emailLog'>;
    location: H.Location;
  }) =>
  async () => {
    const routeParams = getParamsFromSearch<
      | TEpSubsDBCustomerValueKeys
      | TEpSubsDBSubscriptionValueKeys
      | TEpSubsDBInvoiceValueKeys
      | TEpSubsDBPlanValueKeys
    >(location.search);
    const { order, sort, simpleQueryFieldKey, filterId, useSavedFilter } = routeParams;
    const simpleQuery = decodeURIComponent(routeParams.simpleQuery);
    const filter = decodeURIComponent(routeParams.filter);
    const filterEnabled = isFilterEnabledSearchList(source);
    try {
      setIsLoading(true);
      let downloadedFileName: string;
      let simpleFilterData: { [key: string]: string[] };
      const elasticSortField = {
        sort_field:
          sort !== ''
            ? {
                field: sort,
                // Descending is the default value if not provided
                asc: order === 'ASC' ? true : false,
              }
            : undefined,
      };
      if (filterEnabled) {
        if (!useSavedFilter || filterId == 0) {
          const filterData = JSON.parse(filter);
          downloadedFileName = await genExportFilteredSearchData(source, {
            ...elasticSortField,
            filters: filterData,
            keyword: simpleQuery ?? '',
            element: simpleQueryFieldKey ?? ESimpleSearchFields.ALL,
          });
          setIsLoading(false);
        } else {
          downloadedFileName = await genExportSavedFilteredSearchData({
            source,
            filterId,
            filterMetaData: elasticSortField,
            keyword: simpleQuery ?? '',
            element: simpleQueryFieldKey || ESimpleSearchFields.ALL,
          });
          setIsLoading(false);
        }
      } else {
        switch (source) {
          // case 'customer':
          //   downloadedFileName = await genExportCustomerList(
          //     elasticSortField,
          //     simpleQuery,
          //     simpleQueryFieldKey !== '' ? simpleQueryFieldKey : undefined,
          //   );
          //   break;
          // case 'subscription':
          //   downloadedFileName = await genExportSubscriptionList(
          //     elasticSortField,
          //     simpleQuery,
          //     simpleQueryFieldKey !== '' ? simpleQueryFieldKey : undefined,
          //   );
          //   break;
          // case 'invoice':
          //   downloadedFileName = await genExportInvoiceList(
          //     elasticSortField.sort_field
          //       ? [
          //           {
          //             key: elasticSortField.sort_field.field as TEpSubsDBInvoiceValueKeys,
          //             ascending: elasticSortField.sort_field.asc ? 'ASC' : 'DESC',
          //           },
          //         ]
          //       : [],
          //     simpleQuery,
          //   );
          //   break;
          case 'plan':
            simpleFilterData = getSimpleFilterData(filter);
            downloadedFileName = await genExportPlanList(
              elasticSortField.sort_field
                ? [
                    {
                      key: elasticSortField.sort_field.field as TEpSubsDBPlanValueKeys,
                      ascending: elasticSortField.sort_field.asc ? 'ASC' : 'DESC',
                    },
                  ]
                : [],
              simpleQuery,
              simpleFilterData[PLAN_STATUS_FILTER_GROUP_FIELD][0],
              simpleFilterData[PRODUCT_FILTER_GROUP_FIELD][0],
            );
            setIsLoading(false);
            break;
          case 'addon':
            simpleFilterData = getSimpleFilterData(filter);
            downloadedFileName = await genExportAddonList(
              elasticSortField.sort_field
                ? [
                    {
                      key: elasticSortField.sort_field.field as TEpSubsDBAddonValueKeys,
                      ascending: elasticSortField.sort_field.asc ? 'ASC' : 'DESC',
                    },
                  ]
                : [],
              simpleQuery,
              simpleFilterData[ADDON_STATUS_FILTER_GROUP_FIELD][0],
            );
            setIsLoading(false);
            break;
          case 'coupon':
            simpleFilterData = getSimpleFilterData(filter);
            downloadedFileName = await genExportCouponList(
              elasticSortField.sort_field
                ? [
                    {
                      key: elasticSortField.sort_field.field as TEpSubsDBCouponValueKeys,
                      ascending: elasticSortField.sort_field.asc ? 'ASC' : 'DESC',
                    },
                  ]
                : [],
              simpleQuery,
              simpleFilterData[COUPON_STATUS_FILTER_GROUP_FIELD][0],
            );
            setIsLoading(false);
            break;
          default:
            throw new Error(`Unexpected export call for ${source}!`);
        }
      }
      const message = `${downloadedFileName}`;
      putNoty({ type: 'success', text: message });
    } catch (e) {
      putNoty({ type: 'error', text: (e as Error).message });
      setIsLoading(false);
    }
  };
