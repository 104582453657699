import * as React from 'react';

import Table from 'react-bootstrap/Table';
import style from './index.module.scss';
import Image from 'react-bootstrap/Image';
import sortGrey from '../../../assets/sortGrey.png';
import { TToolTip } from 'src/components/toolTip/EpToolTip';
import { Spinner } from 'react-bootstrap';
import { simpleToolTip } from 'src/components/simpleTooltip/simpleTooltip';

export const NO_ERROR_IN_TABLE = '';
export const UNKNOWN_TABLE_INPUT_ERROR = 'Unknown Table Input Error!';
export interface ListState<TRowSchema> {
  loading: boolean;
  err: string;
  data: Array<TRowSchema>;
  total: number;
}

// export type TRowSchemaKeys<TRowSchema> = keyof TRowSchema;

export function getInitialListState<TRowSchema>(
  initData: Array<TRowSchema>,
): ListState<TRowSchema> {
  const item: ListState<TRowSchema> = {
    data: initData,
    total: 0,
    err: NO_ERROR_IN_TABLE,
    loading: false,
  };
  return item;
}

type TRowSchemaKeysString<TRowSchemaKeys> = TRowSchemaKeys extends string
  ? TRowSchemaKeys
  : string;

type Props<TRowSchema, TRowSchemaKeys> = {
  listState: ListState<TRowSchema>;
  _key: keyof TRowSchema | ((record?: TRowSchema) => TKeyParsingResult);
  showHeader?: boolean;
  enableColumnSort?: boolean;
  onSort?: (
    e: React.MouseEvent,
    source: TRowSchemaKeysString<TRowSchemaKeys> | '',
  ) => void;
  children?: any;
  simple?: boolean;
  myList?: TRowSchema[];
};

/**
 * @param _key The field name to be used as the key of each row
 * or a function to compute a key from a data record row
 */
function EpSimpleTable<TRowSchema, TRowSchemaKeys>({
  listState,
  _key,
  showHeader = true,
  enableColumnSort = true,
  onSort,
  myList,
  children,
  simple = true,
}: Props<TRowSchema, TRowSchemaKeys>): JSX.Element {
  const elements = React.Children.toArray(children);
  const tableModule = (
    <Table
      hover
      responsive={true}
      style={{
        minHeight: '14rem',
        overflowX: 'hidden',
        overflowY: 'hidden',
      }}
    >
      {showHeader && (
        <thead>
          <tr
            key="thead"
            className={style.thead_label}
            // Override className style of cursor if sort not enabled
            style={!enableColumnSort ? { cursor: 'default' } : {}}
          >
            {elements.map((child: any) => {
              return (
                <th
                  style={{ verticalAlign: 'top' }}
                  key={`${child.props.label}Label`}
                  onClick={
                    child.props.label
                      ? e =>
                          enableColumnSort &&
                          onSort &&
                          onSort(
                            e,
                            (child.props.sort &&
                              handleKeyOverload(child.props.sort).keyName) ??
                              handleKeyOverload(child.props.source).keyName,
                          )
                      : undefined
                  }
                >
                  <div>
                    {simpleToolTip(child.props.label, child.props.tooltip)}
                    {child.props.label &&
                      child.props.source &&
                      enableColumnSort &&
                      onSort && (
                        <Image
                          src={sortGrey}
                          style={{ marginLeft: '0.5rem' }}
                          width="10"
                          alt=""
                        />
                      )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
      )}
      {simple ? (
        <tbody>
          {listState.data?.length > 0 ? (
            listState.data?.map(record => {
              return Row(record, elements, _key);
            })
          ) : (
            <tr>
              <p>No Records Found.</p>
            </tr>
          )}
        </tbody>
      ) : (
        <>
          <tbody>
            {myList?.map(record => {
              return Row(record, elements, _key);
            })}
          </tbody>
        </>
      )}
    </Table>
  );

  return (
    <div>
      {listState.loading ?? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: '50px', height: '50px' }}
          />
        </div>
      )}
      {listState.err ?? <div>{listState.err}</div>}
      <div className="panel">{tableModule}</div>
    </div>
  );
}

export default EpSimpleTable;

function Row<TRowSchema>(
  data: TRowSchema,
  elements: any,
  _key: keyof TRowSchema | ((record?: TRowSchema) => TKeyParsingResult),
) {
  const key = handleKeyOverload<TRowSchema>(_key, data).keyValue;
  return (
    <tr key={key}>
      {elements.map((child: any) => {
        return React.cloneElement(child, { ...child, record: data });
      })}
    </tr>
  );
}

type ColProps<TRowSchema> = {
  key: string;
  source: keyof TRowSchema | ((record?: TRowSchema) => TKeyParsingResult);
  label: string;
  sort?: keyof TRowSchema | ((record?: TRowSchema) => TKeyParsingResult);
  render?: (value: any, record: TRowSchema) => any;
  className?: string;
  style?: { [key: string]: any };
  record?: TRowSchema;
  tooltip?: TToolTip;
};

export function Col<TRowSchema>(props: ColProps<TRowSchema>): JSX.Element {
  const { key, className, style = {}, record, render, source } = props;
  const data = handleKeyOverload<TRowSchema>(source, record).keyValue;
  let content;
  if (data != null) {
    if (render && record) {
      content = render(data, record);
    } else {
      content = data;
    }
  } else {
    content = <></>;
  }

  return (
    <td key={key} className={className} style={style}>
      {content}
    </td>
  );
}

export type TKeyParsingResult = {
  keyName: string;
  keyValue: any;
};

// There should be better ways to handle overload, this is just a basic working way
// To allow passing either a string key or a function to get key to indicate the data and the key name
function handleKeyOverload<TRowSchema>(
  key: keyof TRowSchema | ((record?: TRowSchema) => TKeyParsingResult),
  record?: TRowSchema,
): TKeyParsingResult {
  let keyValue, keyName;
  if (typeof key === 'string') {
    // If key is a string, for simple cases when TRowSchema is a basic value object
    keyValue = record ? record[key as keyof TRowSchema] : null;
    keyName = key;
    return { keyValue, keyName };
  }
  // If key is a function, needed for more complicated TRowSchema structures than a basic value object
  keyValue = (key as (record?: TRowSchema) => TKeyParsingResult)(record).keyValue;
  keyName = (key as (record?: TRowSchema) => TKeyParsingResult)(record).keyName;
  return { keyValue, keyName };
}
