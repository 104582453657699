import React, { BaseSyntheticEvent, useRef, useState } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { genResetPassword } from '../services';
import EpSubsHorizontalTabNav from 'src/components/tabs/EpSubsHorizontalTabNav';
import { notEmpty } from '../utilities/commonUtils';
import { EpLinkText } from '../components/links/EpLinkText';
import EpSubsConfirmModal from '../components/modals/EpSubsConfirmModal';
import { IEpSubsProfile } from '../models';
import { useRefreshEmailLogList } from 'src/recoil';

type Props = {
  modalShow: boolean;
  profile: IEpSubsProfile;
  onClose: () => void;
  onChangePasswordSubmit: (
    formData: IChangePasswordForm,
    e: React.BaseSyntheticEvent,
  ) => void;
  onChangeNameSubmit: (formData: IChangeNameForm, e: React.BaseSyntheticEvent) => void;
};

type ChangePasswordFormProps = {
  onChangePasswordSubmit: (
    formData: IChangePasswordForm,
    e: React.BaseSyntheticEvent,
  ) => void;
  email: string;
};

type ChangeNameFormProps = {
  onChangeNameSubmit: (formData: IChangeNameForm, e: React.BaseSyntheticEvent) => void;
  initialName: IChangeNameForm;
};

export interface IChangePasswordForm {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface IChangeNameForm {
  first_name: string;
  last_name: string;
}

const ChangePasswordForm = ({
  onChangePasswordSubmit,
  email,
}: ChangePasswordFormProps) => {
  const refreshEmailLogList = useRefreshEmailLogList();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState<boolean>(false);
  const [showResetPasswordResModal, setShowResetPasswordResModal] =
    useState<boolean>(false);
  const [confirmModalText, setConfirmModalText] = useState({
    headerText: '',
    contentText: '',
  });

  const { register, handleSubmit, errors, watch, reset } = useForm<IChangePasswordForm>({
    mode: 'onBlur',
  });
  const newPassword = useRef({});
  newPassword.current = watch('new_password', '');

  const handleResetPassword = async (email: string) => {
    try {
      await genResetPassword(email);
      refreshEmailLogList();
      setShowResetPasswordModal(false);

      //show success modal
      setConfirmModalText({
        headerText: 'Reset Password Requested',
        contentText:
          'If you have an active account in our system, then we have sent you an email to reset your password. Please check your inbox.',
      });
      setShowResetPasswordResModal(true);
    } catch (e) {
      setShowResetPasswordModal(false);
      // show failed modal
      setConfirmModalText({
        headerText: 'Reset Password Failed',
        contentText: 'Unable to send reset password to this email, try again later.',
      });
      setShowResetPasswordResModal(true);
    }
  };

  return (
    <div>
      <Form
        onSubmit={handleSubmit((data, e) =>
          onChangePasswordSubmit(data, e as BaseSyntheticEvent),
        )}
        style={{ marginTop: '30px' }}
      >
        <Form.Group as={Row} className="mb-3" controlId="old_password">
          <Col md={4} className="text-right">
            <Form.Label>Current Password</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Control
              type="password"
              name="old_password"
              ref={register({
                required: 'Current password is required',
              })}
            />
            {errors.old_password && (
              <div className={'text-danger'}>{errors.old_password.message}</div>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="new_password">
          <Col md={4} className="text-right">
            <Form.Label>New Password</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Control
              type="password"
              name="new_password"
              ref={register({
                required: 'New password is required',
              })}
            />
            {errors.new_password && (
              <div className={'text-danger'}>{errors.new_password.message}</div>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="new_password_confirmation">
          <Col md={4} className="text-right">
            <Form.Label>Repeat New Password</Form.Label>
          </Col>
          <Col md={6}>
            <Form.Control
              type="password"
              name="new_password_confirmation"
              ref={register({
                validate: value =>
                  value === newPassword.current || 'The new passwords do not match',
              })}
            />
            {errors.new_password_confirmation && (
              <div className={'text-danger'}>
                {errors.new_password_confirmation.message}
              </div>
            )}
          </Col>
        </Form.Group>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button type="submit">Submit</Button>
          <Button
            type="button"
            variant="light"
            className="mx-3"
            onClick={() => {
              reset();
            }}
          >
            Clear
          </Button>
        </div>
      </Form>
      <EpLinkText className="mt-2" onClick={() => setShowResetPasswordModal(true)}>
        Forgot Your Password?
      </EpLinkText>
      <EpSubsConfirmModal
        headerName={'Reset password'}
        contextContent={'Are you sure you want to reset your password?'}
        modalShow={showResetPasswordModal}
        onClose={() => setShowResetPasswordModal(false)}
        onConfirm={() => handleResetPassword(email)}
      />
      <EpSubsConfirmModal
        headerName={confirmModalText.headerText}
        contextContent={confirmModalText.contentText}
        showSecondaryButton={false}
        modalShow={showResetPasswordResModal}
        confirmText="OK"
        onClose={() => setShowResetPasswordResModal(false)}
        onConfirm={() => setShowResetPasswordResModal(false)}
      />
    </div>
  );
};

const ChangeNameForm = ({ onChangeNameSubmit, initialName }: ChangeNameFormProps) => {
  const [name, setName] = useState<IChangeNameForm>(initialName);
  const { register, handleSubmit, errors, reset } = useForm<IChangeNameForm>({
    mode: 'onBlur',
  });

  return (
    <Form
      onSubmit={handleSubmit((data, e) => {
        setName({
          first_name: data.first_name,
          last_name: data.last_name,
        });
        onChangeNameSubmit(data, e as BaseSyntheticEvent);
      })}
      style={{ marginTop: '30px' }}
    >
      <Form.Group as={Row} className="mb-3" controlId="first_name">
        <Col md={4} className="text-right">
          <Form.Label>First Name</Form.Label>
        </Col>
        <Col md={6}>
          <Form.Control
            type="string"
            name="first_name"
            defaultValue={name.first_name}
            ref={register({
              required: 'First name is required',
            })}
          />
          {errors.first_name && (
            <div className={'text-danger'}>{errors.first_name.message}</div>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="last_name">
        <Col md={4} className="text-right">
          <Form.Label>Last Name</Form.Label>
        </Col>
        <Col md={6}>
          <Form.Control
            type="string"
            name="last_name"
            defaultValue={name.last_name}
            ref={register({
              required: 'Last name is required',
            })}
          />
          {errors.last_name && (
            <div className={'text-danger'}>{errors.last_name.message}</div>
          )}
        </Col>
      </Form.Group>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button type="submit">Submit</Button>
        <Button type="button" variant="light" className="mx-3" onClick={() => reset()}>
          Reset
        </Button>
      </div>
    </Form>
  );
};

const EpSubsProfileModal = ({
  modalShow,
  profile,
  onClose,
  onChangePasswordSubmit,
  onChangeNameSubmit,
}: Props): JSX.Element => {
  const tabNavProps = [
    {
      value: 'name',
      label: 'Edit name',
      component: (
        <ChangeNameForm
          key={`${profile.first_name}${profile.last_name}`}
          onChangeNameSubmit={onChangeNameSubmit}
          initialName={{
            first_name: profile.first_name,
            last_name: profile.last_name,
          }}
        />
      ),
    },
    {
      value: 'password',
      label: 'Edit password',
      component: (
        <ChangePasswordForm
          onChangePasswordSubmit={onChangePasswordSubmit}
          email={profile.email}
        />
      ),
    },
  ].filter(notEmpty);
  return (
    <Modal
      show={modalShow}
      onHide={() => {
        onClose();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ margin: '0 20px', minHeight: '400px' }}>
        <EpSubsHorizontalTabNav<'name' | 'password'>
          tabs={tabNavProps}
          defaultKey={'name'}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EpSubsProfileModal;
