// User Permission
export enum Permission {
  'full_access' = 'full_access',
  //Customer Permission
  'cust.view' = 'cust.view',
  'cust.create' = 'cust.create',
  'cust.edit' = 'cust.edit',
  'cust.export' = 'cust.export',
  'cust.view_comment' = 'cust.view_comment',
  'cust.view_log' = 'cust.view_log',
  'cust.add_comment' = 'cust.add_comment',
  'cust.del_comment' = 'cust.del_comment',
  'cust.manage_payment' = 'cust.manage_payment',
  //Subscription Permission
  'sub.view' = 'sub.view',
  'sub.create' = 'sub.create',
  'sub.edit' = 'sub.edit',
  'sub.cancel' = 'sub.cancel',
  'sub.pause' = 'sub.pause',
  'sub.resume' = 'sub.resume',
  'sub.reactivate' = 'sub.reactivate',
  'sub.export' = 'sub.export',
  'sub.view_comment' = 'sub.view_comment',
  'sub.view_log' = 'sub.view_log',
  'sub.add_comment' = 'sub.add_comment',
  'sub.del_comment' = 'sub.del_comment',
  //Invoice Permission
  'inv.view' = 'inv.view',
  'inv.create' = 'inv.create',
  'inv.edit' = 'inv.edit',
  'inv.payment' = 'inv.payment',
  'inv.refund' = 'inv.refund',
  'inv.writeoff' = 'inv.writeoff',
  'inv.void' = 'inv.void',
  'inv.export' = 'inv.export',
  'inv.email' = 'inv.email',
  'inv.view_comment' = 'inv.view_comment',
  'inv.view_log' = 'inv.view_log',
  'inv.add_comment' = 'inv.add_comment',
  'inv.del_comment' = 'inv.del_comment',
  //Product Permission
  'product.view' = 'product.view',
  'product.create' = 'product.create',
  'product.edit' = 'product.edit',
  'product.delete' = 'product.delete',
  //Plan Permission
  'plan.view' = 'plan.view',
  'plan.create' = 'plan.create',
  'plan.edit' = 'plan.edit',
  'plan.archive' = 'plan.archive',
  'plan.export' = 'plan.export',
  'plan.view_comment' = 'plan.view_comment',
  'plan.add_comment' = 'plan.add_comment',
  'plan.del_comment' = 'plan.del_comment',
  'plan.view_log' = 'plan.view_log',
  //Offer Permission
  'offer.view' = 'offer.view',
  'offer.create' = 'offer.create',
  'offer.edit' = 'offer.edit',
  'offer.delete' = 'offer.delete',
  'offer.view_log' = 'offer.view_log',
  //Addon Permission
  'addon.view' = 'addon.view',
  'addon.create' = 'addon.create',
  'addon.edit' = 'addon.edit',
  'addon.archive' = 'addon.archive',
  'addon.export' = 'addon.export',
  'addon.add_comment' = 'addon.add_comment',
  'addon.del_comment' = 'addon.del_comment',
  'addon.view_comment' = 'addon.view_comment',
  'addon.view_log' = 'addon.view_log',
  //Coupon Permission
  'coupon.view' = 'coupon.view',
  'coupon.create' = 'coupon.create',
  'coupon.edit' = 'coupon.edit',
  'coupon.archive' = 'coupon.archive',
  'coupon.export' = 'coupon.export',
  'coupon.add_comment' = 'coupon.add_comment',
  'coupon.del_comment' = 'coupon.del_comment',
  'coupon.view_comment' = 'coupon.view_comment',
  'coupon.view_log' = 'coupon.view_log',
  //Credit notes Permission
  'cn.view' = 'cn.view',
  'cn.create' = 'cn.create',
  'cn.refund' = 'cn.refund',
  'cn.void' = 'cn.void',
  'cn.delete' = 'cn.delete',
  'cn.view_comment' = 'cn.view_comment',
  'cn.add_comment' = 'cn.add_comment',
  'cn.del_comment' = 'cn.del_comment',
  'cn.view_log' = 'cn.view_log',
  'cn.remove_alloc' = 'cn.remove_alloc',
  //Promo Credit Permission
  'promo_credit.edit' = 'promo_credit.edit',
  //Transaction Permission
  'tran.view' = 'tran.view',
  'tran.view_comment' = 'tran.view_comment',
  'tran.add_comment' = 'tran.add_comment',
  'tran.del_comment' = 'tran.del_comment',
  'tran.view_log' = 'tran.view_log',
  //Email Permission
  'email.view' = 'email.view',
  //Delivery Permission
  'delivery.export' = 'delivery.export',
  //Admin Permission
  'admin.setting' = 'admin.setting',
  'admin.logger' = 'admin.logger',
  'admin.job' = 'admin.job',
  'admin.apikey' = 'admin.apikey',
  'admin.integration' = 'admin.integration',
  'admin.role' = 'admin.role',
  'admin.user' = 'admin.user',
  'admin.scheduled_tasks' = 'admin.scheduled_tasks',
  //Notification
  'notification.view' = 'notification.view',
  'notification.manage' = 'notification.manage',
  //gift card
  'gc.view' = 'gc.view',
  'gc.edit' = 'gc.edit',
  //Gateway Permission
  'gateway.view' = 'gateway.view',
  'gateway.edit' = ' gateway.edit',
  'gateway.create' = 'gateway.create',
  'gateway.delete' = 'gateway.delete',
  //Bulk Operation Permission
  'bulkop.view' = 'bulkop.view',
  'bulkop.create' = 'bulkop.create',
  'bulkop.delete' = 'bulkop.delete',
  'admin.bulkop' = 'admin.bulkop',
  //Web Hook Permission
  'wh.view' = 'wh.view',
  'wh.edit' = 'wh.edit',
  //tags Permission
  'tag.view' = 'tag.view',
  'tag.edit' = 'tag.edit',
  //fulfillment Permission
  'ff.view' = 'ff.view',
  'chart.view' = 'chart.view',
  //plugin
  'plugin.view' = 'plugin.view',
  'plugin.edit' = 'plugin.edit',
}

export const UiPermission = {
  //Customer Permission
  'cust.view': [Permission.full_access, Permission['cust.view']],
  'cust.create': [Permission.full_access, Permission['cust.create']],
  'cust.edit': [Permission.full_access, Permission['cust.edit']], // Here to start
  'cust.export': [Permission.full_access, Permission['cust.export']],
  'cust.view_comment': [Permission.full_access, Permission['cust.view_comment']],
  'cust.view_log': [Permission.full_access, Permission['cust.view_log']],
  'cust.add_comment': [Permission.full_access, Permission['cust.add_comment']],
  'cust.del_comment': [Permission.full_access, Permission['cust.del_comment']],
  'cust.manage_payment': [Permission.full_access, Permission['cust.manage_payment']],
  //Subscription Permissions
  'sub.view': [
    Permission.full_access,
    Permission['sub.view'],
    Permission['gateway.view'],
  ],
  'sub.create': [
    Permission.full_access,
    Permission['sub.create'],
    Permission['gateway.view'],
    Permission['offer.view'],
  ],
  'sub.edit': [
    Permission.full_access,
    Permission['sub.edit'],
    Permission['gateway.view'],
    Permission['offer.view'],
  ],
  'sub.cancel': [Permission.full_access, Permission['sub.cancel']],
  'sub.pause': [Permission.full_access, Permission['sub.pause']],
  'sub.resume': [Permission.full_access, Permission['sub.resume']],
  'sub.reactivate': [Permission.full_access, Permission['sub.reactivate']],
  'sub.export': [Permission.full_access, Permission['sub.export']],
  'sub.view_comment': [Permission.full_access, Permission['sub.view_comment']],
  'sub.view_log': [Permission.full_access, Permission['sub.view_log']],
  'sub.add_comment': [Permission.full_access, Permission['sub.add_comment']],
  'sub.del_comment': [Permission.full_access, Permission['sub.del_comment']],
  //Invoice Permission xxx xxx
  'inv.view': [Permission.full_access, Permission['inv.view']],
  'inv.create': [Permission.full_access, Permission['inv.create']],
  'inv.edit': [Permission.full_access, Permission['inv.edit']],
  'inv.payment': [Permission.full_access, Permission['inv.payment']],
  'inv.remove_payment': [Permission.full_access, Permission['inv.refund']],
  'inv.refund': [Permission.full_access, Permission['inv.refund']],
  'inv.writeoff': [Permission.full_access, Permission['inv.writeoff']],
  'inv.void': [Permission.full_access, Permission['inv.void']],
  'inv.export': [Permission.full_access, Permission['inv.export']],
  'inv.email': [Permission.full_access, Permission['inv.email']],
  'inv.view_comment': [Permission.full_access, Permission['inv.view_comment']],
  'inv.view_log': [Permission.full_access, Permission['inv.view_log']],
  'inv.add_comment': [Permission.full_access, Permission['inv.add_comment']],
  'inv.del_comment': [Permission.full_access, Permission['inv.del_comment']],
  //Product Permission xxx xxx
  'product.view': [
    Permission.full_access,
    Permission['product.view'],
    Permission['gateway.view'],
  ],
  'product.create': [
    Permission.full_access,
    Permission['product.create'],
    Permission['gateway.view'],
  ],
  'product.edit': [
    Permission.full_access,
    Permission['product.edit'],
    Permission['gateway.view'],
  ],
  'product.delete': [Permission.full_access, Permission['product.delete']],
  //Plan Permission
  'plan.view': [Permission.full_access, Permission['plan.view']],
  'plan.create': [Permission.full_access, Permission['plan.create']],
  'plan.edit': [Permission.full_access, Permission['plan.edit']],
  'plan.archive': [Permission.full_access, Permission['plan.archive']],
  'plan.export': [Permission.full_access, Permission['plan.export']],
  'plan.view_comment': [Permission.full_access, Permission['plan.view_comment']],
  'plan.add_comment': [Permission.full_access, Permission['plan.add_comment']],
  'plan.del_comment': [Permission.full_access, Permission['plan.del_comment']],
  'plan.view_log': [Permission.full_access, Permission['plan.view_log']],
  //Offer Permission
  'offer.view': [Permission.full_access, Permission['offer.view']],
  'offer.create': [Permission.full_access, Permission['offer.create']],
  'offer.edit': [Permission.full_access, Permission['offer.edit']],
  'offer.delete': [Permission.full_access, Permission['offer.delete']],
  //Addon Permission
  'addon.view': [Permission.full_access, Permission['addon.view']],
  'addon.create': [Permission.full_access, Permission['addon.create']],
  'addon.edit': [Permission.full_access, Permission['addon.edit']],
  'addon.archive': [Permission.full_access, Permission['addon.archive']],
  'addon.export': [Permission.full_access, Permission['addon.export']],
  'addon.add_comment': [Permission.full_access, Permission['addon.add_comment']],
  'addon.del_comment': [Permission.full_access, Permission['addon.del_comment']],
  'addon.view_comment': [Permission.full_access, Permission['addon.view_comment']],
  'addon.view_log': [Permission.full_access, Permission['addon.view_log']],
  //Coupon Permission
  'coupon.view': [Permission.full_access, Permission['coupon.view']],
  'coupon.create': [Permission.full_access, Permission['coupon.create']],
  'coupon.edit': [Permission.full_access, Permission['coupon.edit']],
  'coupon.archive': [Permission.full_access, Permission['coupon.archive']],
  'coupon.export': [Permission.full_access, Permission['coupon.export']],
  'coupon.add_comment': [Permission.full_access, Permission['coupon.add_comment']],
  'coupon.del_comment': [Permission.full_access, Permission['coupon.del_comment']],
  'coupon.view_log': [Permission.full_access, Permission['coupon.view_log']],
  'coupon.view_comment': [Permission.full_access, Permission['coupon.view_comment']],
  //Credit notes Permission
  'cn.view': [Permission.full_access, Permission['cn.view']],
  'cn.create': [Permission.full_access, Permission['cn.create']],
  'cn.remove_alloc': [Permission.full_access, Permission['cn.remove_alloc']],
  'cn.refund': [Permission.full_access, Permission['cn.refund']],
  'cn.void': [Permission.full_access, Permission['cn.void']],
  'cn.delete': [Permission.full_access, Permission['cn.delete']],
  'cn.view_comment': [Permission.full_access, Permission['cn.view_comment']],
  'cn.add_comment': [Permission.full_access, Permission['cn.add_comment']],
  'cn.del_comment': [Permission.full_access, Permission['cn.del_comment']],
  'cn.view_log': [Permission.full_access, Permission['cn.view_log']],
  //Promo Credit Permission
  'promo_credit.edit': [Permission.full_access, Permission['promo_credit.edit']],
  //Transaction Permission
  'tran.view': [Permission.full_access, Permission['tran.view']],
  'tran.view_comment': [Permission.full_access, Permission['tran.view_comment']],
  'tran.add_comment': [Permission.full_access, Permission['tran.add_comment']],
  'tran.del_comment': [Permission.full_access, Permission['tran.del_comment']],
  'tran.view_log': [Permission.full_access, Permission['tran.view_log']],
  //Email Permission
  'email.view': [Permission.full_access, Permission['email.view']],
  //Delivery Permission
  'delivery.export': [Permission.full_access, Permission['delivery.export']],
  //Admin Permission
  'admin.setting': [Permission['admin.setting']],
  'admin.logger': [Permission['admin.logger']],
  'admin.job': [Permission['admin.job']],
  'admin.apikey': [Permission['admin.apikey']],
  'admin.integration': [Permission['admin.integration']],
  'admin.role': [Permission['admin.role']],
  'admin.user': [Permission['admin.user']],
  'admin.scheduled_tasks': [Permission['admin.scheduled_tasks']],
  'notification.view': [Permission.full_access, Permission['notification.view']],
  'notification.manage': [Permission.full_access, Permission['notification.manage']],
  //Gift Card Permission - Not Implemented in FE yet
  'gc.view': [Permission.full_access, Permission['gc.view']],
  'gc.edit': [Permission.full_access, Permission['gc.edit']],
  //Gateway Permission
  'gateway.view': [Permission.full_access, Permission['gateway.view']],
  'gateway.create': [Permission.full_access, Permission['gateway.create']],
  'gateway.edit': [Permission.full_access, Permission['gateway.edit']],
  'gateway.delete': [Permission.full_access, Permission['gateway.delete']],
  //Bulk Operation Permission
  'bulkop.view': [Permission.full_access, Permission['bulkop.view']],
  'bulkop.create': [Permission.full_access, Permission['bulkop.create']],
  'admin.bulkop': [Permission.full_access, Permission['admin.bulkop']],
  'bulkop.delete': [Permission.full_access, Permission['bulkop.delete']],
  //Web Hook Permission
  'wh.view': [Permission.full_access, Permission['wh.view']],
  'wh.edit': [Permission.full_access, Permission['wh.edit']],
  //offer
  'offer.view_log': [Permission.full_access, Permission['offer.view_log']],
  //tages
  'tag.view': [Permission.full_access, Permission['tag.view']],
  'tag.edit': [Permission.full_access, Permission['tag.edit']],
  //fulfillment
  'ff.view': [Permission.full_access, Permission['ff.view']],
  'chart.view': [Permission.full_access, Permission['chart.view']],
  //plugin
  'plugin.view': [Permission.full_access, Permission['plugin.view']],
  'plugin.edit': [Permission.full_access, Permission['plugin.edit']],
};
