import React, { BaseSyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { resetUserPassword } from '../../services/epSubsUserService';
import style from './EpSubsLogin.module.scss';
import { putNoty } from 'src/utilities/epSubsNoty';
import { DEBUGGING } from 'src/config';

type Props = {
  resetToken: string;
  email: string;
};

export interface IEpSubsResetPasswordPost {
  email: string;
  token: string;
  password: string;
}

const EpSubsResetPassword = ({ resetToken, email }: Props): JSX.Element => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();

  function validateForm() {
    return password.length > 0;
  }

  const handleSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match, try again.');
      return;
    }

    try {
      await resetUserPassword({
        email: email,
        token: resetToken,
        password: password,
      });

      const successMsg = 'Password set successfully!';
      putNoty({ type: 'success', text: successMsg });

      // go to homepage
      history.push('/');
    } catch (e) {
      DEBUGGING && console.log('reset password error: ', e);
      setErrorMessage('Unable to reset password');
    }
  };

  return (
    <div className="Login">
      <h1 className={style.login_header}>Reset Your Password</h1>
      <Form noValidate onSubmit={handleSubmit} id={style.login_form} autoComplete={'off'}>
        <Form.Group controlId="password">
          <Form.Label>Please set your account password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
          />
          <Form.Control
            type="password"
            className="mt-3"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value);
              setErrorMessage('');
            }}
          />
        </Form.Group>
        <Button block type="submit" disabled={!validateForm()}>
          Submit
        </Button>
        {errorMessage && <div className={style.error}>{errorMessage}</div>}
      </Form>
    </div>
  );
};

export default EpSubsResetPassword;
