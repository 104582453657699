import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DEBUGGING } from 'src/config';

export const DebugRouter = ({ children }: { children: any }) => {
  const { location } = useHistory();
  if (process.env.NODE_ENV === 'development') {
    DEBUGGING &&
      console.log(
        `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
          location.state,
        )}`,
      );
  }

  return children;
};

export const NoMatch = (): JSX.Element => {
  const location = useLocation();

  return (
    <div style={{ padding: '12px' }}>
      Cannot find <code>{location.pathname}</code> or you do not have access to it.
    </div>
  );
};
