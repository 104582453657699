import { useRecoilCallback } from 'recoil';
import {
  currentAddonActivitiesSearchParamAtom,
  currentAddonCommentsSearchParamAtom,
  addonRefreshRequestIDAtoms,
  addonSearchRequestIDAtom,
} from '..';
import { genArchiveAddon } from '../../services/epSubsAddonService';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';
import { putNoty } from '../../utilities/epSubsNoty';
import { BaseSyntheticEvent } from 'react';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//                Addon List Page Hooks             //
// *********************************************** //

export const useRefreshAddonList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh addon list
        set(addonSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshAddon = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        // refresh addon
        set(addonRefreshRequestIDAtoms(id), requestID => requestID + 1);

        // refresh Activity log
        set(currentAddonActivitiesSearchParamAtom, prevParam => {
          return {
            limit: prevParam.limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useArchiveAddon = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async (
        event: BaseSyntheticEvent,
        id: number,
        archive: boolean,
        setButtonDisable: any,
      ) => {
        setButtonDisable(true);
        try {
          const archiveSuccess = await genArchiveAddon(id, archive);
          DEBUGGING && console.log('archiveSuccess', archiveSuccess);
          if (archiveSuccess) {
            const message = `Successfully ${
              archive ? 'archived' : 'activated'
            } addon with id ${id}.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log(`error`, error);
        }

        // refresh addon
        setTimeout(() => {
          set(addonRefreshRequestIDAtoms(id), requestID => requestID + 1);
          set(currentAddonActivitiesSearchParamAtom, prevParam => {
            return {
              limit: prevParam.limit,
              requestId: prevParam.requestId + 1, // for refresh query
            };
          });
          setButtonDisable(false);
        }, 1000);
        // refresh Activity log
      },
    [],
  );
};

// *********************************************** //
//                Addon Detail Page Hooks           //
// *********************************************** //

export const useRefreshCurrentAddonActivities = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ACTIVITY_COUNT) => {
        set(currentAddonActivitiesSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useRefreshCurrentAddonComments = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_COMMENT_COUNT) => {
        set(currentAddonCommentsSearchParamAtom, prevParam => {
          return {
            limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};
