import { useRecoilCallback } from 'recoil';
import {
  createApikey,
  deleteApikey,
  deactivateApikey,
  reactivateApikey,
} from '../../services';
import { apikeyListRequestIDAtom } from './apikeyAtoms';
import { IEpSubsDBApikeyPost } from '../../models';
import { putNoty } from '../../utilities/epSubsNoty';

export const useCreateApikey = (): ((data: IEpSubsDBApikeyPost) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (data: IEpSubsDBApikeyPost) => {
        try {
          const updateSuccess = await createApikey(data);
          if (updateSuccess) {
            const message = `Successfully created API Key.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh API Key list
        set(apikeyListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useDeleteApikey = (): ((apikeyId: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (apikeyId: number) => {
        try {
          const updateSuccess = await deleteApikey(apikeyId);
          if (updateSuccess) {
            const message = `Successfully deleted API Key.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh API Key list
        set(apikeyListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useDeactivateApikey = (): ((apikeyId: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (apikeyId: number) => {
        try {
          const updateSuccess = await deactivateApikey(apikeyId);
          if (updateSuccess) {
            const message = `Successfully deactivated scheduled task.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh API Key list
        set(apikeyListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useReactivateApikey = (): ((apikeyId: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (apikeyId: number) => {
        try {
          const updateSuccess = await reactivateApikey(apikeyId);
          if (updateSuccess) {
            const message = `Successfully reactivated scheduled task.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh API Key list
        set(apikeyListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};
