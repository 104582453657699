import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { FILTER_SOURCE, TRANSACTION_STATUS } from 'src/js/constants/subs';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import EpSubsListNav from '../../components/navBars/EpSubsListNav/EpSubsListNav';

const TRANSACTION_STATUS_FILTER_GROUP_NAME = 'transaction_status';
export const TRANSACTION_STATUS_FILTER_GROUP_FIELD = 'status';
const noFilter = {
  label: 'All Status',
  value: '',
  groupField: TRANSACTION_STATUS_FILTER_GROUP_FIELD,
};

export const DEFAULT_TRANSACTION_SEARCH_OPTION = {
  [TRANSACTION_STATUS_FILTER_GROUP_FIELD]: [''],
};

const EpSubsTransactionNav = (routerProps: RouteComponentProps): JSX.Element => {
  const history = useHistory();

  return (
    <EpSubsListNav
      key="transaction"
      {...{
        routerProps,
        createBtnText: '',
        onCreateBtnClick: undefined,
        history,
        source: FILTER_SOURCE.TRANSACTION,
        allSimplyFilterOptions: {
          [TRANSACTION_STATUS_FILTER_GROUP_FIELD]: [noFilter].concat(
            Object.values(TRANSACTION_STATUS).map(status => {
              return {
                label: toInitialUpperCase(status),
                value: status,
                groupName: TRANSACTION_STATUS_FILTER_GROUP_NAME,
                groupField: TRANSACTION_STATUS_FILTER_GROUP_FIELD,
              };
            }),
          ),
        },
        defaultSimplyFilterOption: DEFAULT_TRANSACTION_SEARCH_OPTION,
      }}
    />
  );
};

export default EpSubsTransactionNav;
