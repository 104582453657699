export const DB_CONNECTION_ERROR = 'Cannot get data! Are you connected to the database?';
export const UNDEFINED_NULL_DATA_HANDLING =
  'Please specify how would you like to handle null data in your API call.';
export const DB_DATA_PARSE_ERROR =
  'Cannot parse DB data! Please check if there is a recent schema change.';
export const NETWORK_ERROR = 'Network Error';
export const NO_ERROR_RETURNED = 'No error returned from backend.';
export const BACKEND_SERVICE_ERROR =
  'Unable to get data, check whether you are connected to backend service or the API requested is ready.';

export type NULL_DATA_HANDLING_METHOD = 'throw' | 'empty_array';
// *********************************************** //
//             Error Handling Utils                //
// *********************************************** //
export type TErrorHandleParam<TPayload> = {
  errorCheckForOKResponse: boolean;
  schemaChangeCheck?: boolean;
  successPayload: TPayload;
  errorMessage?: string;
  nullDataHandlingMethod?: NULL_DATA_HANDLING_METHOD;
};

// The logic depends on the backend message design
// export function handleBackendResponseErrors<TPayload>(
//   props: TErrorHandleParam<TPayload>,
// ): TPayload {
//   const {
//     errorCheckForOKResponse,
//     schemaChangeCheck = false,
//     successPayload,
//     errorMessage,
//     nullDataHandlingMethod = 'throw',
//   } = props;
//   if (errorCheckForOKResponse) {
//     if (schemaChangeCheck) {
//       // If payload type check failed, Data Parse Error
//       throw new Error(DB_DATA_PARSE_ERROR);
//     }
//     switch (nullDataHandlingMethod) {
//       case 'throw':
//         // If data is not available in response, DB Error:
//         throw new Error(DB_CONNECTION_ERROR);
//       case 'empty_array':
//         return ([] as unknown) as TPayload;
//       default:
//         throw new Error(UNDEFINED_NULL_DATA_HANDLING);
//     }
//   } else if (errorMessage) {
//     // If error is available in response, return the Error from Backend:
//     throw new Error(errorMessage);
//   }
//   // Success response
//   return successPayload;
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handleDefaultExceptions(e: any): void {
  if (e.response || (e.message && e.message !== NETWORK_ERROR)) {
    // Throw the message from handleBackendResponseErrors function
    throw new Error(e.response?.data.error ?? e.response?.data.message ?? e.message);
  }
  // Failed to connect Backend Error:
  else {
    throw new Error(BACKEND_SERVICE_ERROR);
  }
}
