import {
  epSubsSubscriptionActionTypes,
  FETCH_SUBSCRIPTION_DATA_SUCCESS,
  SAVE_LATEST_SUBSCRIPTION_SEARCH_PARAM,
} from './epSubsSubscriptionTypes';
import { SubscriptionsState, RootState } from '../rootState';
import { IEpSubsCompositeDBSubscriptionValue } from '../../models/';
import { DEBUGGING } from 'src/config';

const epSubsSubscriptionReducer = (
  state: RootState['subscriptions'] = {
    subscriptionList: new Map<number, IEpSubsCompositeDBSubscriptionValue>(),
    latestLocation: { search: '' },
  },
  action: epSubsSubscriptionActionTypes,
): SubscriptionsState => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_DATA_SUCCESS:
      DEBUGGING &&
        console.log('FETCH_SUBSCRIPTION_DATA_SUCCESS for id = ', action.id, state);
      const newSubscriptionList = new Map(state.subscriptionList);
      return {
        ...state,
        subscriptionList: newSubscriptionList.set(action.id, action.subscriptionData),
      };
    case SAVE_LATEST_SUBSCRIPTION_SEARCH_PARAM:
      DEBUGGING && console.log('SAVE_LATEST_SUBSCRIPTION_SEARCH_PARAM: ', action.loc);
      return {
        ...state,
        latestLocation: action.loc,
      };
    default:
      return state;
  }
};

export default epSubsSubscriptionReducer;
