import axios from 'axios';
import {
  IEpSubsChangeNamePut,
  IEpSubsChangePasswordPut,
  IEpSubsProfile,
  IEpSubsUserResetPasswordValue,
  IEpSubsUserRoles,
  IEpSubsUserRolesKeys,
  TEpSubsDBResponseOk,
} from 'src/models/index.js';
import { hostUrl } from '../config.js';
import { IEpSubsUserInviteValue, IEpSubsDBRoleValue } from '../models';
import { IEpSubsAcceptInvitePost } from '../containers/login/EpSubsInviteAccept';
import { IUserRoleFormData } from 'src/components/modals/EpSubsUserRoleModal.js';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { IEpSubsResetPasswordPost } from 'src/containers/login/EpSubsResetPassword.js';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';

export interface IEpSubsLoginPost {
  user: string;
  password: string;
}

export const genLogin = async (data: IEpSubsLoginPost): Promise<string> => {
  return axios.post(`${hostUrl}/auth/gettoken`, data).then(response => {
    return response.data.data;
  });
};

export const genAcceptInvite = async (
  data: IEpSubsAcceptInvitePost,
): Promise<IEpSubsUserInviteValue> => {
  return axios.post(`${hostUrl}/users/accept`, data).then(response => {
    return response.data;
  });
};

export const getAllActiveUsers = async (): Promise<IEpSubsUserRoles[]> => {
  return axios.get(`${hostUrl}/users/search`).then(response => {
    return response.data.data.filter((el: IEpSubsUserRoles) => el.active == 1);
  });
};

export const getAllPendingUsers = async (): Promise<IEpSubsUserRoles[]> => {
  return axios.get(`${hostUrl}/users/search?pending_invite=1`).then(response => {
    return response.data.data;
  });
};

export const getResendInvitation = async (userId: number): Promise<number> => {
  return axios.get(`${hostUrl}/users/resend/${userId}`).then(response => {
    return response.data.data;
  });
};

export const genResetPassword = async (
  email: string,
): Promise<IEpSubsUserResetPasswordValue> => {
  const data = {
    email: email,
  };
  return axios.post(`${hostUrl}/users/reset-password`, data).then(response => {
    return response.data;
  });
};

export const getResetToken = async (email: string, token: string): Promise<number> => {
  return axios
    .get(`${hostUrl}/users/reset-password?email=${email}&token=${token}`)
    .then(response => {
      return response.data.data;
    });
};

export const resetUserPassword = async (
  data: IEpSubsResetPasswordPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/users/reset-password`, data).then(response => {
    return response.data.status;
  });
};

export const genUserRoles = async (): Promise<IEpSubsDBRoleValue[]> => {
  return axios.get(`${hostUrl}/admin/roles`).then(response => {
    return response.data;
  });
};

export const revokeUserInvite = async (userId: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/users/${userId}`).then(response => {
    return response.data.status;
  });
};

export const changeUserRoles = async (data: IUserRoleFormData): Promise<boolean> => {
  return axios.put(`${hostUrl}/users/${data.id}/roles`, data).then(response => {
    return response.data.status;
  });
};

export const inviteUser = async (data: IUserRoleFormData): Promise<boolean> => {
  return axios.post(`${hostUrl}/users/invite`, data).then(response => {
    return response.data.status;
  });
};

export const switchUserStatus = async (userId: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/users/${userId}/toggle`).then(response => {
    return response.data;
  });
};

export const genUserListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<IEpSubsUserRolesKeys>> = [],
  simpleQuery = '',
  filter = '',
): Promise<TEpSubsDBResponseOk<IEpSubsUserRoles>> => {
  const sortSQLStr = getSortSQLStrFromSortingItems<IEpSubsUserRolesKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/users/search?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          `status=${filter}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const genExportUserList = async (
  sortingItems: Array<TSortingItem<IEpSubsUserRolesKeys>> = [],
  simpleQuery = '',
  filter = '',
): Promise<string> => {
  const sortSQLStr = getSortSQLStrFromSortingItems<IEpSubsUserRolesKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/users/export?` +
        [`key=${simpleQuery}`, `sort=${sortSQLStr}`, `status=${filter}`].join('&'),
    )
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `${filter.toUpperCase()}-USERS-${unixtimeWithoutTime(
        Date.now(),
      )}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const changeUserPassword = async (
  data: IEpSubsChangePasswordPut,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/users/me/password`, data).then(response => {
    return response.data.status;
  });
};

export const changeName = async (data: IEpSubsChangeNamePut): Promise<boolean> => {
  return axios.put(`${hostUrl}/users/me/profile`, data).then(response => {
    return response.data.status;
  });
};

export const getProfile = async (): Promise<IEpSubsProfile> => {
  return axios.get(`${hostUrl}/users/me`).then(response => {
    return response.data;
  });
};
