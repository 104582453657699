import { selector, selectorFamily } from 'recoil';
import { getRoleList, getRolePermissions, getPermissionList } from '../../services';
import { roleListRequestIDAtom, roleRefreshRequestIDAtoms } from './roleAtoms';
import { IEpSubsDBRoleValue, IEpSubsDBPermissionValue } from '../../models';

export interface RoleWithPermissions extends IEpSubsDBRoleValue {
  permissions: IEpSubsDBPermissionValue[];
}

/**
 * An selector that stores Role list
 */
export const roleListSelector = selector<IEpSubsDBRoleValue[]>({
  key: 'roleListSelector',
  get: async ({ get }) => {
    get(roleListRequestIDAtom);
    const roleList = await getRoleList();
    return roleList;
  },
});

/**
 * An selectorFamily that stores role details(permissions) for each role
 */
export const rolePermissionsSelectors = selectorFamily<
  IEpSubsDBPermissionValue[],
  number
>({
  key: 'rolePermissionsSelectors',
  get:
    roleId =>
    async ({ get }) => {
      get(roleRefreshRequestIDAtoms(roleId)); // Add request ID as a dependency
      const rolePermissions = await getRolePermissions(roleId);
      return rolePermissions;
    },
});

/**
 * An selector that stores Permission list
 */
export const permissionListSelector = selector<IEpSubsDBPermissionValue[]>({
  key: 'permissionListSelector',
  get: async () => {
    const permissionList = await getPermissionList();
    return permissionList;
  },
});
