import React from 'react';
import { useHistory } from 'react-router';
import local_style from './EpLinkText.module.scss';

type Props = {
  text?: string;
  to?: string;
  onClick?: () => void;
  className?: string;
  style?: { [key: string]: string };
} & { children?: JSX.Element | string | number };

/**
 * EpLinkText is customized trying to fix the bug of NavLink or Link but failed: (Fixed by MenuLink)
 * However, it is still nice to have to make it more flexible and to centralize styling
 * @param text: Either children or text will be rendered. Children will overwrite text.
 * @param to: The url to be pushed to router
 * @param onClick: The extra effect needed to be triggered on click
 */
export const EpLinkText = ({
  to,
  onClick,
  text,
  children,
  className = '',
  style,
}: Props) => {
  const history = useHistory();
  return (
    <div
      className={`${className} ${local_style.action_text}`}
      style={style}
      onClick={() => {
        to && history.push(to);
        onClick && onClick();
      }}
    >
      {children ?? text ?? 'Link'}
    </div>
  );
};
