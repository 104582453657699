import { Modal } from 'react-bootstrap';
import React, { BaseSyntheticEvent } from 'react';
import { IAddressFormData } from 'src/components/addresses/EpSubsAddressFormSubSection';
import EpSubsAddressForm from './EpSubsAddressForm';
import { ELocale, INewAddressFormData } from '../../models';
import { getCountryCode } from 'src/models/i18n';
import { DEBUGGING, LOCALE } from 'src/config';

type Props = {
  modalShow: boolean;
  formTitle?: string;
  addressData?: INewAddressFormData;
  onSubmit: (
    formData: INewAddressFormData,
    event?: BaseSyntheticEvent,
  ) => Promise<boolean>;
  onCancel: () => void;
  customerLocale: ELocale;
  hasTempAddress?: boolean;
};

export const EpSubsEditAddressModal = ({
  modalShow,
  formTitle = 'Update Address',
  addressData,
  onSubmit,
  onCancel,
  customerLocale,
  hasTempAddress = false,
}: Props): JSX.Element => {
  // const dispatch = useDispatch();
  DEBUGGING && console.log('address data for temp:', addressData);
  // Initial Value for Editable Fields
  const prevEditableAddressData: IAddressFormData = {
    first_name: !!addressData ? addressData.first_name : '',
    last_name: !!addressData ? addressData.last_name : '',
    company: !!addressData ? addressData.company : '',
    address1: !!addressData ? addressData.address1 : '',
    address2: !!addressData ? addressData.address2 : '',
    city: !!addressData ? addressData.city : '',
    zip: !!addressData ? addressData.zip : '',
    state_code: !!addressData ? addressData.state_code : '',
    country: !!addressData ? addressData.country : getCountryCode(LOCALE as ELocale),
    validation_status: !!addressData ? !!addressData.validation_status : false,
    start_at: !!addressData ? addressData.start_at : '',
    end_at: !!addressData ? addressData.end_at : '',
  };

  const childProps = {
    formTitle,
    onSubmit,
    onCancel,
    enableSaveAndCreateNew: true,
    defaultAddressData: prevEditableAddressData,
    customerLocale,
    isTempAddress: hasTempAddress,
  };
  return (
    <>
      <Modal show={modalShow} onHide={onCancel} size="lg" centered>
        <Modal.Body id="contained-modal-title-vcenter">
          <EpSubsAddressForm {...childProps} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EpSubsEditAddressModal;
