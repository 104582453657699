import { atom } from 'recoil';

/**
 * An atom that stores the fresh Request ID for scheduled task list
 */
export const scheduledTaskListRequestIDAtom = atom<number>({
  key: 'scheduledTaskListRequestIDAtom',
  default: 0,
});

/**
 * An atom that stores the fresh Request ID for Logger list
 */
export const loggerListRequestIDAtom = atom<number>({
  key: 'loggerListRequestIDAtom',
  default: 0,
});
