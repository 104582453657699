import React from 'react';
import { languageOptions } from 'src/models/i18n';
import styled from 'styled-components';
import Button from './Button';

const StyledModal = styled.div`
  position: fixed;
  z-index: 899;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: left;

  .modal-sheet {
    position: relative;
    top: 40%;
    // -webkit-transform: translateY(-50%);
    // -moz-transform: translateY(-50%);
    // -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 504px;
    max-width: 80%;
    padding: 64px 48px 32px 56px;
    margin: auto;
    text-align: left;
    background-color: #ffffff;
    // @media screen and (min-width: $device-mobileL) {
    //   -webkit-transform: translateY(-75%);
    //   -moz-transform: translateY(-75%);
    //   -ms-transform: translateY(-75%);
    // }
    // @media screen and (min-width: $device-laptop) {
    //   -webkit-transform: translateY(-50%);
    //   -moz-transform: translateY(-50%);
    //   -ms-transform: translateY(-50%);
    // }
    .modal-footer-section {
      display: flex;
      justify-content: flex-end;
      margin-top: 32px;
      button {
        margin-left: 16px;
      }
    }
  }
`;

type Props = {
  cancelText: string;
  okText?: string;
  onOk: () => void;
  onCancel: () => void;
  visible?: boolean;
  children: JSX.Element;
};

const Modal = ({
  visible = false,
  cancelText,
  okText = `${languageOptions().portal.primaryCard.confirm}`,
  onCancel,
  onOk,
  children,
}: Props) => {
  if (!visible) return <></>;

  return (
    <StyledModal style={{ display: 'block' }}>
      <div className="modal-sheet">
        {children}
        <div className="modal-footer-section">
          {cancelText && (
            <Button btnType="secondary" onClick={onCancel}>
              {cancelText}
            </Button>
          )}
          <Button btnType="primary" onClick={onOk}>
            {okText}
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};

export default Modal;
