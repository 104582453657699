import { atom, selector } from 'recoil';
import { IEpSubsDBRoleValue } from 'src/models/_db_generated_/epSubsDBTypes';
import { genUserRoles } from 'src/services/epSubsUserService';

// *********************************************** //
//                User Roles States                //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for user roles
 */
export const userRolesAtom = atom<IEpSubsDBRoleValue[]>({
  key: 'userRolesAtom',
  default: [],
});

/**
 * An selector that stores the basic data for user roles based on current roles
 */
export const userRolesSelector = selector<IEpSubsDBRoleValue[]>({
  key: 'userRolesSelector',
  get: async ({ get }) => {
    if (get(userRolesAtom).length == 0) {
      return await genUserRoles();
    } else {
      return get(userRolesAtom);
    }
  },
});

export const userRolesSelectedAtomUserRoles = atom<number[]>({
  key: 'userRolesSelectedAtomUserRoles',
  default: [],
});
