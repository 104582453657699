import { atom } from 'recoil';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';

import { selector, selectorFamily } from 'recoil';
import {
  IEpSubsDBCredit_note_commentValue,
  IEpSubsCompositeDBCredit_noteValue,
  IEpSubsDBReason_codeValue,
  IEpSubsDBCredit_note_activityValue,
  UiPermission,
} from '../../models';
import {
  genCreditNoteActivityData,
  genCreditNoteActivityDetail,
  genCreditNoteCommentData,
  genReasonCodes,
} from '../../services';
import { creditNoteSelectors } from './creditNoteListPageStates';
import { CURRENT_ID_ATOM_DEFAULT_VALUE, hasPermissionSelectors } from '..';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//         Current CreditNote Detail States           //
// *********************************************** //

/**
 * An atom that stores the currently selected creditNote id
 */
export const currentCreditNoteIdAtom = atom<number | null>({
  key: 'currentCreditNoteIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected creditNote details
 */
export const currentCreditNoteSelector = selector<
  IEpSubsCompositeDBCredit_noteValue | undefined
>({
  key: 'currentCreditNoteSelector',
  get: ({ get }) => {
    const id = get(currentCreditNoteIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(creditNoteSelectors(id));
    }
  },
});

// *********************************************** //
//        Current CreditNote Comments States          //
// *********************************************** //

/**
 * An atom that stores the currently selected creditNote Comments query parameters
 */
export const currentCreditNoteCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentCreditNoteCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected creditNote Comments
 */
export const currentCreditNoteCommentsSelector = selector<
  IEpSubsDBCredit_note_commentValue[]
>({
  key: 'currentCreditNoteCommentsSelector',
  get: async ({ get }) => {
    const id = get(currentCreditNoteIdAtom);
    const params = get(currentCreditNoteCommentsSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['cn.view_comment']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genCreditNoteCommentData(id, params.limit);
    } else {
      return [];
    }
  },
});

// *********************************************** //
//      Current CreditNote Allocations States          //
// *********************************************** //

/**
 * An atom that stores the currently selected creditNote Allocations query parameters
 */
export const currentCreditNoteActivitySearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentCreditNoteActivitySearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected creditNote Allocations
 */
export const currentCreditNoteActivitySelector = selector<
  IEpSubsDBCredit_note_activityValue[]
>({
  key: 'currentCreditNoteActivitySelector',
  get: async ({ get }) => {
    const id = get(currentCreditNoteIdAtom);
    const params = get(currentCreditNoteActivitySearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['cn.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genCreditNoteActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores CreditNote Activity Detail for each activity
 */
export const creditNoteActivityDetailSelectors = selectorFamily<
  IEpSubsDBCredit_note_activityValue,
  number
>({
  key: 'creditNoteActivityDetailSelectors',
  get: actLogId => async () => {
    const creditNoteActivityData = await genCreditNoteActivityDetail(actLogId);
    DEBUGGING &&
      console.log(
        'recoil, creditNoteSelectors creditNoteActivityData:',
        creditNoteActivityData,
      );
    return creditNoteActivityData;
  },
});

// *********************************************** //
//        Credit Note Reason Code States           //
// *********************************************** //
export const creditNoteReasonCodeSelector = selector<IEpSubsDBReason_codeValue[]>({
  key: 'creditNoteReasonCodeSelector',
  get: async () => {
    return await genReasonCodes('create_credit_note');
  },
});
