import {
  atom,
  atomFamily,
  GetRecoilValue,
  RecoilValueReadOnly,
  useRecoilCallback,
  waitForAll,
} from 'recoil';
import {
  DEFAULT_ACTIVITY_COUNT,
  DEFAULT_ADDRESS_COUNT,
  DEFAULT_COMMENT_COUNT,
} from '../../js/constants/subs';

import { selector, selectorFamily } from 'recoil';
import {
  IEpSubsDBSubscription_activityValue,
  IEpSubsDBSubscription_commentValue,
  IEpSubsCompositeDBSubscriptionValue,
  IEpSubsDBAddonValue,
  IEpSubsDBCouponValue,
  UiPermission,
  IEpSubsDBSubscription_addressValue,
  IEpSubsTagsListValue,
} from '../../models/';
import {
  genAddonMatchedCoupons,
  genMatchedAddonByPlan,
  genPlanDataByStringId,
  genPlanMatchedCoupons,
  genSubscriptionActivityData,
  genSubscriptionActivityDetail,
  genSubscriptionCommentData,
  genTempAddressForSubscription,
  getSubscriptionTags,
} from '../../services/';
import { subscriptionSelectors } from './subscriptionListPageStates';
import {
  activeInvoiceCouponListStateSelector,
  addonSearchRequestIDAtom,
  couponSearchRequestIDAtom,
  CURRENT_ID_ATOM_DEFAULT_VALUE,
  hasPermissionSelectors,
} from '..';
import { subscriptionPlansSummaryType } from 'src/components/addresses/EpSubsAddressFormSection';
import { DEBUGGING } from 'src/config';
import { arrayToMap, getMinItemFromList, notEmpty } from 'src/utilities/commonUtils';
import { generateUUID } from 'src/utilities/stringUtility';

// *********************************************** //
//     Current Subscription Detail States          //
// *********************************************** //

/**
 * An atom that stores the currently selected subscription id
 */
export const currentSubscriptionIdAtom = atom<number | null>({
  key: 'currentSubscriptionIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected subscription details
 */
export const currentSubscriptionSelector = selector<
  IEpSubsCompositeDBSubscriptionValue | undefined
>({
  key: 'currentSubscriptionSelector',
  get: ({ get }) => {
    const id = get(currentSubscriptionIdAtom);
    DEBUGGING && console.log('currentSubscriptionIdAtom: ', id);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(subscriptionSelectors(id));
    }
  },
});

// *********************************************** //
//      Current Subscription Comments States       //
// *********************************************** //

/**
 * An atom that stores the currently selected subscription Comments query parameters
 */
export const currentSubscriptionCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentSubscriptionCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected subscription Comments
 */
export const currentSubscriptionCommentsSelector = selector<
  IEpSubsDBSubscription_commentValue[]
>({
  key: 'currentSubscriptionCommentsSelector',
  get: async ({ get }) => {
    const id = get(currentSubscriptionIdAtom);
    const params = get(currentSubscriptionCommentsSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['sub.view_comment']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genSubscriptionCommentData(id, params.limit);
    } else {
      return [];
    }
  },
});

// *********************************************** //
//      Current Subscription Activities States     //
// *********************************************** //

/**
 * An atom that stores the currently selected subscription Activities query parameters
 */
export const currentSubscriptionActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentSubscriptionActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected subscription Activities
 */
export const currentSubscriptionActivitiesSelector = selector<
  IEpSubsDBSubscription_activityValue[]
>({
  key: 'currentSubscriptionActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentSubscriptionIdAtom);
    const params = get(currentSubscriptionActivitiesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['sub.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genSubscriptionActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores Subscription Activity Detail for each activity
 */
export const subscriptionActivityDetailSelectors = selectorFamily<
  IEpSubsDBSubscription_activityValue,
  number
>({
  key: 'subscriptionActivityDetailSelectors',
  get: actLogId => async () => {
    const subscriptionActivityData = await genSubscriptionActivityDetail(actLogId);
    return subscriptionActivityData;
  },
});

// *********************************************** //
//   Create/Add/Change Subscription Page States    //
// *********************************************** //
export const selectedSubscriptionPlansSummaryTypeAtoms = atomFamily<
  subscriptionPlansSummaryType[],
  string
>({
  key: 'selectedSubscriptionPlansSummaryTypeAtom',
  default: [] as subscriptionPlansSummaryType[],
});

// Addons
export const selectedSubscriptionPlanNameMatchedAddonsSelectors = selectorFamily<
  IEpSubsDBAddonValue[],
  string
>({
  key: 'selectedSubscriptionPlanNameMatchedAddonsSelectors',
  get:
    (planId: string) =>
    async ({ get }) => {
      get(addonSearchRequestIDAtom);
      DEBUGGING &&
        console.log(
          'selectedSubscriptionPlanNameMatchedAddonsSelectors check: ',
          `planId: ${planId}`,
        );
      if (planId !== '') {
        return await genMatchedAddonByPlan(planId);
      }
      return [];
    },
});

// Coupons
export const selectedSubscriptionPlanNameMatchedCouponsSelectors = selectorFamily<
  IEpSubsDBCouponValue[],
  string
>({
  key: 'selectedSubscriptionPlanNameMatchedCouponsSelectors',
  get:
    (planId: string) =>
    async ({ get }) => {
      get(couponSearchRequestIDAtom);
      const planData = await genPlanDataByStringId(planId);
      if (planId != '') {
        return await genPlanMatchedCoupons(planData.id);
      }
      return [];
    },
});

export const selectedSubscriptionAddonNameMatchedCouponsSelectors = selectorFamily<
  IEpSubsDBCouponValue[],
  string
>({
  key: 'selectedSubscriptionAddonNameMatchedCouponsSelectors',
  get:
    (addonId: string) =>
    async ({ get }) => {
      get(couponSearchRequestIDAtom);
      if (addonId != '') {
        return await genAddonMatchedCoupons(addonId);
      }
      return [];
    },
});

export const selectedSubscriptionPlanNameMatchedCouponsMapSelectors = selectorFamily<
  Map<string, IEpSubsDBCouponValue>,
  string
>({
  key: 'selectedSubscriptionPlanNameMatchedCouponsMapSelectors',
  get:
    (planId: string) =>
    async ({ get }) => {
      if (planId != '') {
        return arrayToMap(
          get(selectedSubscriptionPlanNameMatchedCouponsSelectors(planId)),
          coupon => coupon.coupon_id,
        );
      }
      return new Map();
    },
});

export const selectedSubscriptionAddonNameMatchedCouponsMapSelectors = selectorFamily<
  Map<string, IEpSubsDBCouponValue>,
  string
>({
  key: 'selectedSubscriptionAddonNameMatchedCouponsMapSelectors',
  get:
    (addonId: string) =>
    async ({ get }) => {
      if (addonId != '') {
        return arrayToMap(
          get(selectedSubscriptionAddonNameMatchedCouponsSelectors(addonId)),
          coupon => coupon.coupon_id,
        );
      }
      return new Map();
    },
});

// *********************************************** //
//       Coupon Combination States/Utils           //
// *********************************************** //
interface IMatchedCouponIdsSetItem {
  type: 'plan' | 'addon';
  stringId: string;
  couponIdSets: Set<string>;
}

const selectedSubscriptionPlanNameMatchedCouponIdsSetItemSelectors = selectorFamily<
  IMatchedCouponIdsSetItem,
  string
>({
  key: 'selectedSubscriptionPlanNameMatchedCouponIdsSetItemSelector',
  get:
    (planId: string) =>
    async ({ get }) => {
      return {
        type: 'plan',
        stringId: planId,
        couponIdSets: new Set(
          get(selectedSubscriptionPlanNameMatchedCouponsSelectors(planId)).map(
            coupon => coupon.coupon_id,
          ),
        ),
      };
    },
});

const selectedSubscriptionAddonNameMatchedCouponIdsSetItemSelectors = selectorFamily<
  IMatchedCouponIdsSetItem,
  string
>({
  key: 'selectedSubscriptionAddonNameMatchedCouponIdsSetItemSelector',
  get:
    (addonId: string) =>
    async ({ get }) => {
      return {
        type: 'addon',
        stringId: addonId,
        couponIdSets: new Set(
          get(selectedSubscriptionAddonNameMatchedCouponsSelectors(addonId)).map(
            coupon => coupon.coupon_id,
          ),
        ),
      };
    },
});

type TMatchedCouponListSelector = (
  id: string,
) => RecoilValueReadOnly<IEpSubsDBCouponValue[]>;
const getDataFromMatchedCouponIdsSetItem = (
  couponIdSetItem: IMatchedCouponIdsSetItem,
): TMatchedCouponListSelector => {
  let couponDataSelector: TMatchedCouponListSelector;
  if (couponIdSetItem.type === 'plan') {
    couponDataSelector = selectedSubscriptionPlanNameMatchedCouponsSelectors;
  } else {
    couponDataSelector = selectedSubscriptionAddonNameMatchedCouponsSelectors;
  }
  return couponDataSelector;
};

type TMatchedCouponMapSelector = (
  id: string,
) => RecoilValueReadOnly<Map<string, IEpSubsDBCouponValue>>;
const getDataMapFromMatchedCouponIdsSetItem = (
  couponIdSetItem: IMatchedCouponIdsSetItem,
): TMatchedCouponMapSelector => {
  let couponDataMapSelector: TMatchedCouponMapSelector;
  if (couponIdSetItem.type === 'plan') {
    couponDataMapSelector = selectedSubscriptionPlanNameMatchedCouponsMapSelectors;
  } else {
    couponDataMapSelector = selectedSubscriptionAddonNameMatchedCouponsMapSelectors;
  }
  return couponDataMapSelector;
};

export const selectedSubscriptionAvailableCouponsSelectors = selectorFamily<
  IEpSubsDBCouponValue[],
  {
    planIds?: string[];
    addonIds?: string[];
  }
>({
  key: 'selectedSubscriptionAvailableCouponsSelectors',
  get:
    ({ planIds = [], addonIds = [] }) =>
    async ({ get }) => {
      // Step 0: Pull all match couponId lists
      const matchedCouponIdsSetItems = get(
        waitForAll([
          ...planIds
            .filter(planId => planId != '')
            .map(planId =>
              selectedSubscriptionPlanNameMatchedCouponIdsSetItemSelectors(planId),
            ),
          ...addonIds
            .filter(addonId => addonId != '')
            .map(addonId =>
              selectedSubscriptionAddonNameMatchedCouponIdsSetItemSelectors(addonId),
            ),
        ]),
      );

      // Case 1: no planId or addonId is given
      if (matchedCouponIdsSetItems.length === 0) return [];

      // Case 2: only one planId or addonId is given
      if (matchedCouponIdsSetItems.length === 1) {
        return get(
          getDataFromMatchedCouponIdsSetItem(matchedCouponIdsSetItems[0])(
            matchedCouponIdsSetItems[0].stringId,
          ),
        );
      }

      // Case 3: More than one planId or addonId are given, need to find the common set of item Coupons

      // // ---Logic to get Intersections among coupons---
      // const itemCouponLists = getIntersectionAmongCouponLists(
      //   get,
      //   matchedCouponIdsSetItems,
      // );

      // ---Logic to get Union among coupons---
      const itemCouponLists = getUnionAmongCouponLists(get, matchedCouponIdsSetItems);

      const invoiceCouponData = get(activeInvoiceCouponListStateSelector);
      return [...invoiceCouponData, ...itemCouponLists];
    },
});

/**
 * @param get get in Selector
 * @returns Intersection of coupons in matchedCouponIdsSetItems
 * Not used for now, might need in the future
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getIntersectionAmongCouponLists = (
  get: GetRecoilValue,
  matchedCouponIdsSetItems: IMatchedCouponIdsSetItem[],
): IEpSubsDBCouponValue[] => {
  // Step 1: Get the shortest couponId list to scan to be more efficient
  const baseCouponIdsSetItem = getMinItemFromList(
    matchedCouponIdsSetItems,
    couponIdsSet => couponIdsSet.couponIdSets.size,
  ) as IMatchedCouponIdsSetItem;

  // Step 2: Find the coupons shared by all couponLists
  const availableCouponIds = Array.from(baseCouponIdsSetItem.couponIdSets).filter(
    couponId => {
      for (const couponIdSetItem of matchedCouponIdsSetItems) {
        if (!couponIdSetItem.couponIdSets.has(couponId)) {
          return false;
        }
      }
      return true;
    },
  );

  if (availableCouponIds.length === 0) return [];
  // Step 3: Get the coupon data from the corresponding dataList of the baseCouponIdsSetItem
  const availableCouponSet = new Set(availableCouponIds);
  return get(
    getDataFromMatchedCouponIdsSetItem(baseCouponIdsSetItem)(
      baseCouponIdsSetItem.stringId,
    ),
  ).filter(couponData => availableCouponSet.has(couponData.coupon_id));
};

/**
 * @param get get in Selector
 * @returns Union of coupons in matchedCouponIdsSetItems
 */
const getUnionAmongCouponLists = (
  get: GetRecoilValue,
  matchedCouponIdsSetItems: IMatchedCouponIdsSetItem[],
): IEpSubsDBCouponValue[] => {
  // Step 1: Get the origin of coupon to minimize the data read
  const couponIdToSourceMap = new Map<string, number[]>();
  matchedCouponIdsSetItems.forEach((matchedCouponIdsSetItem, index) => {
    for (const couponId of matchedCouponIdsSetItem.couponIdSets) {
      const couponSources = couponIdToSourceMap.get(couponId);
      if (!couponSources) {
        couponIdToSourceMap.set(couponId, [index]);
        continue;
      }
      couponIdToSourceMap.set(couponId, [...couponSources, index]);
    }
  });

  // Step 2: Get the coupon data lists by sourceItem
  const couponDataByItem = matchedCouponIdsSetItems.map(item =>
    get(getDataMapFromMatchedCouponIdsSetItem(item)(item.stringId)),
  );

  // Step 3: Get the coupon data list from sourceItem using origin
  return Array.from(couponIdToSourceMap, ([couponId, sourceIndices]) =>
    couponDataByItem[sourceIndices[0]].get(couponId),
  ).filter(notEmpty);
};

// *********************************************** //
//  Current Subscription Temp Addresses States     //
// *********************************************** //

/**
 * An atom that stores the currently selected subscription Addresses query parameters
 */
export const currentSubscriptionTempAddressesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentSubscriptionTempAddressesSearchParamAtom',
  default: {
    limit: DEFAULT_ADDRESS_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently subscription Addresses
 */
export const currentSubscriptionTempAddressesSelector = selector<
  IEpSubsDBSubscription_addressValue[] | undefined
>({
  key: 'currentSubscriptionTempAddressesSelector',
  get: async ({ get }) => {
    const id = get(currentSubscriptionIdAtom);
    get(currentSubscriptionTempAddressesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['sub.view']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genTempAddressForSubscription(id);
    }
  },
});

// *********************************************** //
//          Subscription Tags Selector                //
// *********************************************** //

export const subscriptionTagsRequestIDAtom = atom<number>({
  key: 'subscriptionTagsRequestIDAtom',
  default: 0,
});

export const subscriptionTagsSelector = selector<IEpSubsTagsListValue[]>({
  key: 'subscriptionTagsSelector',
  get: async () => {
    const tagsList = await getSubscriptionTags();
    return tagsList;
  },
});

export const useRefreshSubscriptionTags = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(subscriptionTagsRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const subscriptionUUIDSelector = selector<string>({
  key: 'subscriptionUUIDSelector',
  get: async () => {
    const uuid = await generateUUID();
    return uuid;
  },
});
