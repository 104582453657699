import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { DEBUGGING, hostUrl } from '../../config.js';
import { putNoty } from 'src/utilities/epSubsNoty';
import { Spinner } from 'react-bootstrap';

type TFinishStripeProc =
  | 'add-pm-confirm-vault'
  | 'update-pm-confirm-vault'
  | 'create-sub-confirm-vault'
  | 'create-sub-confirm-charge'
  | 'adhoc-charge-confirm-vault'
  | 'adhoc-charge-confirm-charge'
  | 'charge-invoice-confirm-charge';

const buildUrl = (method: string, query: string) => {
  return encodeURI(`${hostUrl}${method}?${query}`);
};

const EpSubsStripe3ds = (): JSX.Element => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const proc = params.get('proc') as TFinishStripeProc | string;
  DEBUGGING && console.log('render', { search, params, proc });

  const callBe = async (vault_return_url: string) => {
    const urlParams = new URLSearchParams(search);
    const src = urlParams.get('src') as string;
    const decodeSrc = decodeURIComponent(src);
    await axios
      .get(vault_return_url)
      .then(response => {
        //second authentication
        if (response.data.code == 302) {
          window.location.replace(JSON.parse(response.data.data).url);
        }
        //payment id
        if (typeof response.data.data == 'number') {
          const message = '3DS authentication is done';
          putNoty({ type: 'success', text: `${message}` });
          if (decodeSrc) {
            window.location.replace(`${decodeSrc}`);
          }
        }
        //payment update
        if (response.data.data == true) {
          const message = '3DS authentication is done';
          putNoty({ type: 'success', text: `${message}` });
          if (decodeSrc) {
            window.location.replace(`${decodeSrc}`);
          }
        }
        //subscription id
        if (response.data.data.id) {
          window.location.replace(`/subscriptions/${response.data.data.id}/detail`);
          const message = '3DS authentication adds subscription successfully';
          putNoty({ type: 'success', text: `${message}` });
        }
      })
      .catch(error => {
        DEBUGGING && console.log('error content:', error);
        putNoty({ type: 'error', text: `${error.message}` });
        window.location.replace(`${decodeSrc}`);
      });
  };

  useEffect(() => {
    const updatedParams = params;
    let vault_return_url: string;
    // console.log('2.0 customerLoadable.state:', customerLoadable.state);
    switch (proc) {
      case 'add-pm-confirm-vault':
        vault_return_url = buildUrl(
          '/customers/finish-add-payment-methods',
          updatedParams.toString(),
        );
        break;
      case 'update-pm-confirm-vault':
        vault_return_url = buildUrl(
          '/customers/finish-update-payment-methods',
          updatedParams.toString(),
        );
        break;
      case 'create-sub-confirm-vault':
        vault_return_url = buildUrl(
          '/subscriptions/confirm-pm',
          updatedParams.toString(),
        );
        break;
      case 'create-sub-confirm-charge':
        vault_return_url = buildUrl('/subscriptions/finish', updatedParams.toString());
        break;
      case 'adhoc-charge-confirm-vault':
        vault_return_url = buildUrl('/invoices/otp-confirm-pm', updatedParams.toString());
        break;
      case 'adhoc-charge-confirm-charge':
        vault_return_url = buildUrl('/invoices/otp-finish', updatedParams.toString());
        break;
      case 'charge-invoice-confirm-charge':
        vault_return_url = buildUrl('/invoices/confirm-charge', updatedParams.toString());
        break;
      default:
        return;
    }
    callBe(vault_return_url);
  }, [params]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20%',
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        style={{ width: '50px', height: '50px' }}
      />
    </div>
  );
};
export default EpSubsStripe3ds;
