import {
  DEBUGGING,
  hostUrl,
  isLocalHost,
  paypalLiveUrl,
  paypalTestUrl,
} from 'src/config';

export const genPaypalBillingAgreementToken = async (
  return_url: string,
  cancel_url: string,
  amount_int: number,
  plan_description: string,
  shipping: boolean,
  user_token: string,
): Promise<string | boolean> => {
  return fetch(
    `${hostUrl}/paypal_express/set_express_checkout?return_url=${return_url}&cancel_url=${cancel_url}&amount=${amount_int}&description=${plan_description}&noshipping=${shipping}&user_token=${user_token}`,
    {
      method: 'post',
    },
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      const paypalExpressToken = data.data;
      DEBUGGING && console.log('url link:', paypalTestUrl + paypalExpressToken);
      if (paypalExpressToken) {
        location.replace(
          `${
            isLocalHost == 'live'
              ? paypalLiveUrl + paypalExpressToken
              : paypalTestUrl + paypalExpressToken
          }`,
        );
      }
      return paypalExpressToken;
    });
};

export function genPaypalBillingAgreementID(token: string, user_token: string) {
  return fetch(
    `${hostUrl}/paypal_express/create_billing_agreement?token=${token}&user_token=${user_token}`,
    {
      method: 'post',
    },
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      const BAID = JSON.parse(JSON.stringify(data));
      return BAID.data;
    });
}

export function genPaypalExpressCheckoutDetail(token: string, user_token: string) {
  return fetch(
    `${hostUrl}/paypal_express/get_express_checkout_detail?token=${token}&user_token=${user_token}`,
    {
      method: 'get',
    },
  )
    .then(function (response) {
      return response.json();
    })
    .then(data => {
      const paypalLoad = JSON.parse(JSON.stringify(data));
      return paypalLoad.data;
    });
}
