import { atom, atomFamily } from 'recoil';

/**
 * An atom that stores the fresh Request ID for settings groups list
 */
export const settingsGroupListRequestIDAtom = atom<number>({
  key: 'settingsGroupListRequestIDAtom',
  default: 0,
});

/**
 * An atomFamily that stores the refresh Request ID for each settings group
 */
export const settingsGroupRequestIDAtoms = atomFamily<number, string>({
  key: 'settingsGroupRequestIDAtoms',
  default: 0,
});
