import React from 'react';
import styled from 'styled-components';
import { Click, Hover_Primary, Primary_Blue, Secondary_Blue, White } from './colors';

const StyledButton = styled.div`
  .portal-btn {
    font-family: inherit;
    font-size: 12px;
    text-align: center;
    vertical-align: top;
    font-weight: 400;
    line-height: 16px;
    text-transform: none;
    border-radius: 6px;
    width: auto;
    border: 4px solid transparent;
    padding: 4px 8px;
    min-width: 90px;
    height: 32px;
    &:focus {
      border: 4px solid ${Click};
    }
  }
  .portal-btn-primary {
    background-color: ${Primary_Blue};
    color: ${White};
    &:hover:enabled {
      background-color: ${Hover_Primary};
    }
  }
  .portal-btn-secondary {
    color: ${Secondary_Blue};
    padding: 0;
    min-width: 0;
  }
`;

type Props = {
  onClick: () => void;
  btnType: string;
  children?: JSX.Element | string;
};

const Button = ({ onClick, btnType = 'primary', children, ...props }: Props) => {
  return (
    <StyledButton>
      {btnType === 'primary' && (
        <button
          onClick={onClick}
          type="button"
          className="portal-btn portal-btn-primary"
          {...props}
        >
          {children}
        </button>
      )}
      {btnType === 'secondary' && (
        <button
          onClick={onClick}
          type="button"
          className="btn portal-btn btn-link portal-btn-secondary"
          {...props}
        >
          {children}
        </button>
      )}
    </StyledButton>
  );
};

export default Button;
