import React from 'react';
import style from '../simpleTooltip/simpleTooltip.module.scss';

export const simpleToolTip = (
  child?: JSX.Element,
  tooltipContent?: string,
): JSX.Element => {
  return (
    <div className={style.tooltip}>
      {child && child}
      {tooltipContent && <span className={style.tooltiptext}>{tooltipContent}</span>}
    </div>
  );
};
