// import style from './EpSubsVerticalTabNav.module.scss';

import React, { BaseSyntheticEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

interface tabObject<TTabKey> {
  value: TTabKey | string;
  label: string;
  component?: JSX.Element;
  icon?: JSX.Element;
}

type Props<TTabKey> = {
  tabs: tabObject<TTabKey>[];
  defaultKey: TTabKey;
  enableHashtagInUrl?: boolean;
  onSelect?: (key: TTabKey | string, event?: BaseSyntheticEvent) => void;
  controlId?: string;
  disabled?: boolean;
};

function EpSubsHorizontalTabNav<TTabKey extends string>({
  tabs,
  defaultKey,
  enableHashtagInUrl = false,
  onSelect,
  controlId,
  disabled,
}: Props<TTabKey>): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const hash = location.hash.substring(1);

  const [key, setKey] = useState<TTabKey | string>(
    enableHashtagInUrl && hash ? hash : defaultKey,
  );
  const handleSelect = (key: TTabKey | string, event?: BaseSyntheticEvent) => {
    setKey(key);
    enableHashtagInUrl && history.push(`#${key}`);
    onSelect && onSelect(key, event);
  };

  return (
    // <div className={`d-flex flex-row-reverse ${style.tab_container}`}>
    <Tabs
      id={controlId ?? 'vertical-tab-nav'}
      activeKey={key}
      onSelect={(key: string | null, event?: BaseSyntheticEvent) => {
        key ? handleSelect(key, event) : '';
      }}
    >
      {tabs.map(tab => {
        const label = (
          <div id={`tab_${tab.label}`} style={{ fontWeight: 'bold' }}>
            {tab.icon ?? <></>} {tab.label}
          </div>
        );
        return (
          <Tab
            key={tab.value}
            eventKey={tab.value}
            title={label}
            disabled={disabled ?? false}
          >
            {tab.component ?? <></>}
          </Tab>
        );
      })}
    </Tabs>
    // </div>
  );
}

export default EpSubsHorizontalTabNav;
