import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBAdminBulkOperationValue,
  IEpSubsDBAdminBulkPost,
  IEpSubsDBBulkOperationLogValue,
  IEpSubsDBBulkOperationPostValue,
  IEpSubsDBBulkOperationValue,
  TEpSubsDBAdminBulkValueKeys,
  TEpSubsDBResponseOk,
} from '../models';
import { getSortSQLStrFromSortingItems, TSortingItem } from './epSubsServiceUtils';

export const getBulkOperationList = async (): Promise<IEpSubsDBBulkOperationValue[]> => {
  return axios.get(`${hostUrl}/bulkop`).then(response => {
    return response.data.data ?? [];
  });
};

export const getBulkOperationLogList = async (): Promise<
  IEpSubsDBBulkOperationLogValue[]
> => {
  return axios.get(`${hostUrl}/bulkop/log`).then(response => {
    return response.data.data ?? [];
  });
};

export const createBulkOperationLog = async (
  data: IEpSubsDBBulkOperationPostValue,
): Promise<IEpSubsDBBulkOperationLogValue[]> => {
  const uploadData = {
    bulk_op_id: data.BulkOpId,
    file: data.File,
  };
  return axios.post(`${hostUrl}/bulkop/log`, uploadData).then(response => {
    return response.data;
  });
};

export const getBulkOperationLogID = async (
  id: number,
): Promise<IEpSubsDBBulkOperationLogValue> => {
  return axios.get(`${hostUrl}/bulkop/log/${id}`).then(response => {
    return response.data.data;
  });
};

export const deleteBulkOperationLogID = async (id: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/bulkop/log/${id}`).then(response => {
    return response.data.message;
  });
};

export const uploadBulkOperationFile = async (data: FormData): Promise<string> => {
  return axios.post(`${hostUrl}/bulkop/upload`, data).then(response => {
    return response.data.data;
  });
};

// *********************************************** //
//                AdminBulk Api Service            //
// *********************************************** //

export const genAdminBulkListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBAdminBulkValueKeys>> = [],
  simpleQuery = '',
  // status = '',
  // productType = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBAdminBulkOperationValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBAdminBulkValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/admin/bulkop?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const getAllAdminBulkList = async (): Promise<
  IEpSubsDBAdminBulkOperationValue[]
> => {
  return axios.get(`${hostUrl}/admin/bulkop`).then(response => {
    return response.data.data ?? [];
  });
};
export const genCreateAdminBulk = async (
  data: IEpSubsDBAdminBulkPost,
): Promise<number> => {
  return axios.post(`${hostUrl}/admin/bulkop`, data).then(response => {
    return response.data.data;
  });
};

export const genAdminBulkData = async (
  id: number,
): Promise<IEpSubsDBAdminBulkOperationValue> => {
  return axios.get(`${hostUrl}/admin/bulkop/${id}`).then(response => {
    return response.data;
  });
};

export const genEditAdminBulk = async (
  id: number,
  data: IEpSubsDBAdminBulkPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/admin/bulkop/${id}`, data).then(() => {
    return true;
  });
};

export const genDeleteAdminBulk = async (id: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/admin/bulkop/${id}`).then(() => {
    return true;
  });
};
