import * as React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  allOfferDataMapSelector,
  allPlanDataMapSelector,
  allProductDataMapSelector,
  allSubsChannelsDataMapSelector,
  filterUiParameterSelector,
  useLoadOptionalRecoilSelector,
  userSelector,
} from 'src/recoil';
import { useState, useLayoutEffect, BaseSyntheticEvent, useEffect } from 'react';
import {
  ArrayField,
  Control,
  // Controller,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import style from './EpSubsSavedFilterSelector.module.scss';

import {
  IEpSubsCompositeDBPlanValue,
  IEpSubsDBOfferValue,
  IEpSubsDBProductValue,
  IEpSubsDBSubscription_channelValue,
  IEpSubsTagsListValue,
  TEpSubsDBElastic_ui_paramsApiValue,
  TFilterData,
  TFilterEntry,
} from 'src/models';
import {
  ESFormDateInput,
  ESFormDropdownInput,
  ESFormTextInput,
  TUseFormSetValue,
  utcToUnixTimestamp,
} from 'src/components/formComponents';
import {
  TFlatDropdownItem,
  TGroupDropdownItem,
  EDropdownItemType,
} from 'src/components/dropdownSelect/ESGeneralDropdownInput';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import { EpSubsMultipleSelect } from 'src/components/dropdownSelect/EpSubsMultipleSelect';
import {
  convertMoneyFromStorageToUi,
  convertMoneyFromUiToStorage,
} from 'src/components/money';
import {
  getMoneyChangeUnitStr,
  otherCountryLocalTimeZoneAsString,
} from 'src/models/i18n';
import { getPlanSelectionLabel } from 'src/components/plans/EpSubsPlanSelect/EpSubsPlanSelect';
import { compareFilterJson } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { TFilterSelectOption } from './EpSubsSavedFilterSelector';
import { DEBUGGING } from 'src/config';
import { EpIcon } from 'src/assets';
import { getProductTypeInGroupTitle } from 'src/containers/products/EpSubsProductUtils';
import { genAllCountryRuleData } from 'src/services';
import {
  customerTagsMapSelector,
  invoiceTagsMapSelector,
  subscriptionTagsMapSelector,
} from 'src/recoil/tags/tagsState';

const PlanKeyRegExp = /.*plan_id/;
const ProductKeyRegExp = /product_id/;
const OfferKeyRegExp = /offer_id/;
const SubsChannelKeyRegExp = /sub_channels/;

const isPlanKeyField = (field?: string | null): boolean =>
  field !== null && field !== undefined && !!field.match(PlanKeyRegExp);
const isProductKeyField = (field?: string | null): boolean =>
  field !== null && field !== undefined && !!field.match(ProductKeyRegExp);
const isOfferKeyField = (field?: string | null): boolean =>
  field !== null && field !== undefined && !!field.match(OfferKeyRegExp);

export const EpSubsFilterCard = ({
  // source,
  idMatchedSavedFilterOption,
  onSubmit,
  defaultFilterData,
  isEditedFilter,
  isAppliedFilter,
  isSavedFilter,
  onCheckingEditedFilter,
  onEditingFilter,
  onCreatingOrEditingFilter,
  // Side effect after apply filter other than submit form, may not needed
  onUpdatingFilter,
  onRemovingFilter,
  onClearFilter,
  selectedFilterOption,
}: {
  // source: TFilterSource;
  idMatchedSavedFilterOption: TFilterSelectOption | null;
  onSubmit: (
    formData: Pick<TFilterData, 'filters'>,
    event?: BaseSyntheticEvent,
  ) => boolean;
  isEditedFilter: boolean;
  isAppliedFilter: boolean;
  isSavedFilter: boolean;
  defaultFilterData: TFilterEntry[];
  onCheckingEditedFilter: (isEdited: boolean) => void;
  onEditingFilter: () => void;
  onCreatingOrEditingFilter: (
    filterData: TFilterEntry[],
    isCreatingNew?: boolean,
  ) => void;
  onUpdatingFilter: (filterData: TFilterEntry[]) => void;
  onRemovingFilter: (filterIdToRemove: number) => void;
  onClearFilter: () => void;
  selectedFilterOption: TFilterSelectOption | null;
}): JSX.Element => {
  DEBUGGING &&
    console.log(
      'Monitor Filter Card States: ',
      `isEditedFilter: ${isEditedFilter}; `,
      `isAppliedFilter: ${isAppliedFilter}; `,
      `isSavedFilter: ${isSavedFilter}; `,
    );
  //get user info to set filter to private
  const userInfo = useRecoilValue(userSelector);
  // *********************************************** //
  //                Define States and Hooks          //
  // *********************************************** //
  // Basic Data States//
  const [isLoading, setIsLoading] = useState(true);
  // const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const [filterUiParameter, setFilterUiParameter] = useState<
    TEpSubsDBElastic_ui_paramsApiValue[]
  >([]);
  const [allPlanDataMap, setAllPlanDataMap] = useState<
    Map<string, IEpSubsCompositeDBPlanValue>
  >(new Map());
  const [allProductDataMap, setAllProductDataMap] = useState<
    Map<string, IEpSubsDBProductValue>
  >(new Map());
  const [allOfferDataMap, setAllOfferDataMap] = useState<
    Map<string, IEpSubsDBOfferValue>
  >(new Map());
  const [allSubsChannelsDataMap, setAllSubsChannelsDataMap] = useState<
    Map<string, IEpSubsDBSubscription_channelValue>
  >(new Map());

  // Need Waiting for API to add an get all plan list data
  const filterUiParametersLoadable = useRecoilValueLoadable(filterUiParameterSelector);
  const allPlanListStateLoadable = useRecoilValueLoadable(allPlanDataMapSelector);
  const allProductListStateLoadable = useRecoilValueLoadable(allProductDataMapSelector);
  const allOfferListStateLoadable = useRecoilValueLoadable(allOfferDataMapSelector);
  const allSubsChannelsListStateLoadable = useRecoilValueLoadable(
    allSubsChannelsDataMapSelector,
  );

  const customerTagsMap = useLoadOptionalRecoilSelector(customerTagsMapSelector);
  const invoiceTagsMap = useLoadOptionalRecoilSelector(invoiceTagsMapSelector);
  const subscriptionTagsMap = useLoadOptionalRecoilSelector(subscriptionTagsMapSelector);

  // *********************************************** //
  //                 Define Effects                  //
  // *********************************************** //
  useLayoutEffect(() => {
    switch (filterUiParametersLoadable.state) {
      case 'loading':
        // only show loading once, do not show loading UI when refresh the list
        break;
      case 'hasValue':
        setIsLoading(false);
        setFilterUiParameter(filterUiParametersLoadable.contents);
        break;
      case 'hasError':
        setIsLoading(false);
    }
  }, [filterUiParametersLoadable.contents]);

  useLayoutEffect(() => {
    if (allPlanListStateLoadable.state === 'hasValue') {
      setAllPlanDataMap(allPlanListStateLoadable.contents);
    }
  }, [allPlanListStateLoadable.contents]);

  useLayoutEffect(() => {
    if (allProductListStateLoadable.state === 'hasValue') {
      setAllProductDataMap(allProductListStateLoadable.contents);
    }
  }, [allProductListStateLoadable.contents]);

  useLayoutEffect(() => {
    if (allOfferListStateLoadable.state === 'hasValue') {
      setAllOfferDataMap(allOfferListStateLoadable.contents);
    }
  }, [allOfferListStateLoadable.contents]);

  useLayoutEffect(() => {
    if (allSubsChannelsListStateLoadable.state === 'hasValue') {
      setAllSubsChannelsDataMap(allSubsChannelsListStateLoadable.contents);
    }
  }, [allSubsChannelsListStateLoadable.contents]);

  const defaultFilterValue = [...defaultFilterData];

  console.log('checking default value for the filter:', defaultFilterValue);
  const namePath = 'filters';
  const { register, control, handleSubmit, setValue, watch, errors } = useForm<{
    filters: TFilterEntry[];
  }>({
    defaultValues: {
      [namePath]: defaultFilterValue,
    },
  });
  const { fields, append, remove } = useFieldArray<TFilterEntry>({
    control: control,
    name: namePath,
  });
  const currentFilterValue = watch().filters;
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...currentFilterValue[index],
    };
  });
  DEBUGGING && console.log('currentFilterValue: ', currentFilterValue);
  console.log('defaultFilterValue:', defaultFilterData);

  useEffect(() => {
    DEBUGGING &&
      console.log(
        'Check unexpected JSON change: ',
        idMatchedSavedFilterOption?.filterJson &&
          JSON.parse(idMatchedSavedFilterOption?.filterJson),
        controlledFields.map(filterEntry => {
          return {
            field: filterEntry.field,
            operator: filterEntry.operator,
            value: filterEntry.value,
            value2: filterEntry.value2,
            values_set: filterEntry.values_set,
          };
        }),
      );
    if (
      // Might need to add a condition to make sure saved filter is not affected
      compareFilterJson(
        idMatchedSavedFilterOption?.filterJson ?? '',
        JSON.stringify(
          controlledFields.map(filterEntry => {
            return {
              field: filterEntry.field,
              operator: filterEntry.operator,
              value: filterEntry.value,
              value2: filterEntry.value2,
              values_set: filterEntry.values_set,
            };
          }),
        ),
        false,
      )
    ) {
      onCheckingEditedFilter(false);
    } else {
      onCheckingEditedFilter(true);
    }
  }, [controlledFields, defaultFilterData, idMatchedSavedFilterOption?.filterJson]);
  DEBUGGING && console.log('filter data:', JSON.stringify(watch().filters));
  const filterJsonData = JSON.stringify(watch().filters);
  useEffect(() => {
    localStorage.setItem('filterData', filterJsonData);
  }, [filterJsonData]);

  DEBUGGING && console.log('new filter record:', localStorage.getItem('filterData'));
  return (
    <form
      autoComplete={'off'}
      onSubmit={handleSubmit((formData, event) => {
        DEBUGGING && console.log('filter form data:', formData);
        onSubmit(formData, event);
      })}
    >
      {isLoading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <ul style={{ margin: '0px', padding: '0px' }}>
          <Row key={'clear_filter'}>
            <Button
              id="clear_filter"
              variant="blank"
              onClick={() => {
                onClearFilter();
              }}
              className={`pt-2 ${style.operation_button}`}
            >
              <EpIcon name="rubber" style={{ marginLeft: '0' }} />
              Clear Filter
            </Button>
          </Row>
          {controlledFields.map((item, index) => {
            // Prepare variables for Filter Row Components
            const currentFiledParameter = getCurrentFieldParameters(
              filterUiParameter,
              currentFilterValue[index]?.field,
            );
            return (
              <Row key={item.id}>
                <Button
                  id="search_minus"
                  type="button"
                  variant="blank"
                  className={`${style.minus_button}`}
                  style={{ margin: '8px' }}
                  onClick={() => {
                    remove(index);
                    onEditingFilter && onEditingFilter();
                  }}
                  disabled={controlledFields.length <= 1}
                >
                  <p>-</p>
                </Button>
                <Col md={3} className={`${style.filter_dropdown}`}>
                  <ESFormDropdownInput
                    required
                    name={`${namePath}.${index}.field`}
                    register={register}
                    controlId="Ep_field"
                    control={control}
                    options={filterUiParameter.map(item => getFilterFieldOption(item))}
                    defaultValue={item.field ?? filterUiParameter[0]?.field ?? undefined}
                    placeholder={'Please select a filter field'}
                    onChange={(fieldValue: string | undefined) => {
                      const defaultOperator = getDefaultOperatorValue(
                        fieldValue ?? '',
                        filterUiParameter,
                      );
                      setValue(`${namePath}.${index}.operator`, defaultOperator);
                      onEditingFilter();
                    }}
                    displayOptionGroup
                  />
                </Col>
                <Col md={2} className={`${style.filter_dropdown}`}>
                  <ESFormDropdownInput
                    required
                    name={`${namePath}.${index}.operator`}
                    register={register}
                    controlId="Ep_operator"
                    control={control}
                    options={
                      !!filterUiParameter && !!currentFilterValue[index]?.field
                        ? getFilterOperatorLists(
                            filterUiParameter,
                            currentFilterValue[index].field,
                          )
                        : []
                    }
                    onChange={() => onEditingFilter()}
                    defaultValue={
                      item.operator ??
                      // getValues(`${namePath}.${index}.operator`) ??
                      (!!currentFiledParameter
                        ? currentFiledParameter.all_operators[0]
                        : undefined)
                    }
                    placeholder={'Operator'}
                  />
                </Col>
                <Col md={3}>
                  <EpSubsSearchFilterValueFields
                    currentFiledParameter={currentFiledParameter}
                    currentFieldItem={item}
                    namePath={namePath}
                    control={control}
                    controlId="Ep_filter_value"
                    register={register}
                    index={index}
                    setValue={setValue}
                    errors={!!errors.filters ? errors.filters[index] : undefined}
                    allPlanDataMap={allPlanDataMap}
                    allProductDataMap={allProductDataMap}
                    allOfferDataMap={allOfferDataMap}
                    allSubsChannelsDataMap={allSubsChannelsDataMap}
                    customerTagsMap={customerTagsMap}
                    invoiceTagsMap={invoiceTagsMap}
                    subscriptionTagsMap={subscriptionTagsMap}
                    onChange={() => onEditingFilter()}
                  />
                </Col>
              </Row>
            );
          })}
        </ul>
      )}
      <Row>
        <Button
          id="search_plus"
          type="button"
          className={`mx-2 ${style.plus_button}`}
          variant="blank"
          onClick={() => {
            append(
              {
                field: filterUiParameter[0].field,
                operator: getDefaultOperatorValue(
                  filterUiParameter[0].field,
                  filterUiParameter,
                ),
              },
              true,
            );
            onEditingFilter && onEditingFilter();
          }}
        >
          <p>+</p>
        </Button>
        <Button
          className={`${style.form_button}`}
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
          variant="primary"
          type="submit"
          id="apply_filter"
        >
          Apply Filter
        </Button>
        {!isEditedFilter &&
          idMatchedSavedFilterOption &&
          parseInt(idMatchedSavedFilterOption.value) > 1 &&
          checkingIfUserMatched(
            selectedFilterOption?.created_by as string,
            userInfo.email as string,
            selectedFilterOption?.accessLevel as number,
          ) && (
            <Button
              variant="blank"
              onClick={() => {
                onCreatingOrEditingFilter(currentFilterValue);
              }}
              className={`pt-2 ml-3 ${style.operation_button}`}
            >
              <EpIcon name="rename" style={{ marginLeft: '0' }} />
              Rename
            </Button>
          )}
        {!isEditedFilter &&
          idMatchedSavedFilterOption &&
          parseInt(idMatchedSavedFilterOption.value) > 1 &&
          checkingIfUserMatched(
            selectedFilterOption?.created_by as string,
            userInfo.email as string,
            selectedFilterOption?.accessLevel as number,
          ) && (
            <Button
              variant="blank"
              onClick={() => {
                onRemovingFilter(parseInt(idMatchedSavedFilterOption.value));
              }}
              className={`pt-2 ml-3 ${style.operation_button}`}
            >
              <EpIcon name="trashBin" style={{ marginLeft: '0' }} />
              Remove Filter
            </Button>
          )}
        {isEditedFilter &&
          isAppliedFilter &&
          !isSavedFilter &&
          checkingIfUserMatched(
            selectedFilterOption?.created_by as string,
            userInfo.email as string,
            selectedFilterOption?.accessLevel as number,
          ) && (
            <Button
              variant="blank"
              onClick={() => {
                if (
                  idMatchedSavedFilterOption &&
                  parseInt(idMatchedSavedFilterOption.value) > 1
                ) {
                  onUpdatingFilter(currentFilterValue);
                } else {
                  onCreatingOrEditingFilter(currentFilterValue, true);
                }
              }}
              className={`pt-2 ml-3 ${style.operation_button}`}
            >
              <EpIcon name="save" style={{ marginLeft: '0' }} />
              Save
            </Button>
          )}
        {isEditedFilter &&
          isAppliedFilter &&
          !isSavedFilter &&
          idMatchedSavedFilterOption &&
          parseInt(idMatchedSavedFilterOption.value) > 1 &&
          checkingIfUserMatched(
            selectedFilterOption?.created_by as string,
            userInfo.email as string,
            selectedFilterOption?.accessLevel as number,
          ) && (
            <Button
              variant="blank"
              onClick={() => {
                onCreatingOrEditingFilter(currentFilterValue);
              }}
              className={`pt-2 ml-3 ${style.operation_button}`}
            >
              <EpIcon name="saveAs" style={{ marginLeft: '0' }} />
              Save and Rename
            </Button>
          )}
        {isEditedFilter &&
          isAppliedFilter &&
          !isSavedFilter &&
          idMatchedSavedFilterOption &&
          parseInt(idMatchedSavedFilterOption.value) > 1 &&
          checkingIfUserMatched(
            selectedFilterOption?.created_by as string,
            userInfo.email as string,
            selectedFilterOption?.accessLevel as number,
          ) && (
            <Button
              variant="blank"
              onClick={() => {
                onCreatingOrEditingFilter(currentFilterValue, true);
              }}
              className={`pt-2 ml-3 ${style.operation_button}`}
            >
              <EpIcon name="saveAsNew" style={{ marginLeft: '0' }} />
              Save As New
            </Button>
          )}
      </Row>
    </form>
  );
};

export const EpSubsSearchFilterValueFields = ({
  currentFiledParameter,
  currentFieldItem,
  namePath,
  control,
  controlId,
  register,
  index,
  setValue,
  errors,
  allPlanDataMap,
  allProductDataMap,
  allOfferDataMap,
  allSubsChannelsDataMap,
  customerTagsMap,
  invoiceTagsMap,
  subscriptionTagsMap,
  onChange,
}: {
  currentFiledParameter: TEpSubsDBElastic_ui_paramsApiValue | null;
  currentFieldItem: Partial<ArrayField<TFilterEntry, 'id'>>;
  namePath: string;
  control: Control<{
    filters: TFilterEntry[];
  }>;
  controlId: string;
  register: any;
  index: number;
  setValue: TUseFormSetValue<any>;
  errors?: DeepMap<TFilterEntry, FieldError>;
  allPlanDataMap: Map<string, IEpSubsCompositeDBPlanValue>;
  allProductDataMap: Map<string, IEpSubsDBProductValue>;
  allOfferDataMap: Map<string, IEpSubsDBOfferValue>;
  allSubsChannelsDataMap: Map<string, IEpSubsDBSubscription_channelValue>;
  customerTagsMap: Map<string, IEpSubsTagsListValue> | null;
  invoiceTagsMap: Map<string, IEpSubsTagsListValue> | null;
  subscriptionTagsMap: Map<string, IEpSubsTagsListValue> | null;
  onChange?: () => void;
}): JSX.Element => {
  // Do not return any input field when currentFiledParameter is not ready.
  if (currentFiledParameter === null) return <></>;
  DEBUGGING &&
    console.log(
      'Check Field Array Item: ',
      currentFieldItem,
      currentFiledParameter,
      currentFiledParameter.all_values?.map(value => getFilterKeyValueOption(value)),
    );
  const [allCountryOptions, setAllCountryOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >();

  useEffect(() => {
    const getAllCountryCode = async () => {
      const allCountryData = await genAllCountryRuleData();
      const countryOptions = Object.keys(allCountryData).map(key => {
        const country = allCountryData[key as any];
        return {
          value: `${country.code}`,
          label: String(country.name),
        };
      });
      console.log('checking the country options:', countryOptions);
      setAllCountryOptions(countryOptions);
    };
    getAllCountryCode();
  }, []);

  console.log('checking current filed items:', currentFieldItem.values_set);

  // Return input field by different operators:
  switch (currentFiledParameter.data_type) {
    case 'date':
      switch (currentFieldItem.operator) {
        // One Parameter: Calendar
        case 'is after':
        case 'is before':
          return (
            <Row>
              <Col className={`mx-2 ${style.calendar}`}>
                <ESFormDateInput
                  name={`${namePath}.${index}.value`}
                  register={register}
                  control={control}
                  controlId={controlId}
                  setValue={setValue as TUseFormSetValue<any>}
                  defaultDate={Number(currentFieldItem.value)}
                  placeholderText={toInitialUpperCase(
                    currentFieldItem.operator.split(' ')[1],
                  )}
                  onChange={val => {
                    val &&
                      setValue(
                        `${namePath}.${index}.value`,
                        utcToUnixTimestamp(
                          otherCountryLocalTimeZoneAsString(new Date(val * 1000)),
                        ),
                      );
                  }}
                />
              </Col>
            </Row>
          );
        // Two Parameter: Calendar
        case 'between':
          return (
            <Row>
              <Col className={`mx-2 ${style.calendar}`}>
                <ESFormDateInput
                  name={`${namePath}.${index}.value`}
                  register={register}
                  control={control}
                  controlId={controlId}
                  setValue={setValue as TUseFormSetValue<any>}
                  placeholderText={'Start Date'}
                  defaultDate={Number(currentFieldItem.value)}
                  onChange={val => {
                    val &&
                      setValue(
                        `${namePath}.${index}.value`,
                        utcToUnixTimestamp(
                          otherCountryLocalTimeZoneAsString(new Date(val * 1000)),
                        ),
                      );
                  }}
                />
              </Col>
              <Col className={`${style.calendar}`}>
                <ESFormDateInput
                  name={`${namePath}.${index}.value2`}
                  register={register}
                  control={control}
                  controlId={controlId}
                  setValue={setValue as TUseFormSetValue<any>}
                  placeholderText={'End Date'}
                  defaultDate={Number(currentFieldItem.value)}
                  onChange={val => {
                    val &&
                      setValue(
                        `${namePath}.${index}.value2`,
                        utcToUnixTimestamp(
                          otherCountryLocalTimeZoneAsString(new Date(val * 1000)),
                        ),
                      );
                  }}
                />
              </Col>
            </Row>
          );
        // No Parameter
        case 'is present':
        case 'is not present':
        default:
          return <></>;
      }
    case 'keyword':
      switch (currentFieldItem.operator) {
        // One Parameter: Single Value Dropdown
        case 'is':
        case 'is not':
          return (
            <Row>
              <Col className={`mx-2 ${style.calendar}`}>
                <ESFormDropdownInput
                  required
                  name={`${namePath}.${index}.value`}
                  controlId={controlId}
                  register={register}
                  control={control}
                  options={
                    (currentFiledParameter.field !== 'country'
                      ? currentFiledParameter.all_values?.map(value =>
                          getFilterKeyValueOption(
                            value,
                            currentFieldItem.field,
                            allPlanDataMap,
                            allProductDataMap,
                            allOfferDataMap,
                            allSubsChannelsDataMap,
                            customerTagsMap,
                            invoiceTagsMap,
                            subscriptionTagsMap,
                          ),
                        )
                      : allCountryOptions?.map(val =>
                          getFilterKeyValueOption(
                            val.label,
                            currentFieldItem.field,
                            allPlanDataMap,
                            allProductDataMap,
                            allOfferDataMap,
                            allSubsChannelsDataMap,
                            customerTagsMap,
                            invoiceTagsMap,
                            subscriptionTagsMap,
                            val.value,
                            val.label,
                          ),
                        )) ?? []
                  }
                  defaultValue={
                    String(currentFieldItem.value).toLowerCase() ??
                    (!!currentFiledParameter.all_values
                      ? currentFiledParameter.all_values[0]
                      : undefined)
                  }
                  placeholder={''}
                  displayOptionGroup={isPlanKeyField(currentFieldItem.field)}
                  onChange={onChange}
                />
              </Col>
            </Row>
          );
        // One Parameter: Multi Value Dropdown
        case 'includes':
        case 'does not include':
          return (
            <Row>
              <Col className={`mr-2 ${style.multi_select_dropdown}`}>
                <EpSubsMultipleSelect
                  required
                  name={`${namePath}.${index}.values_set`}
                  selectId={'Ep_values_set'}
                  control={control}
                  register={register}
                  setValue={setValue}
                  allOptions={
                    (currentFiledParameter.field !== 'country'
                      ? currentFiledParameter.all_values?.map(value =>
                          getFilterKeyValueOption(
                            value,
                            currentFieldItem.field,
                            allPlanDataMap,
                            allProductDataMap,
                            allOfferDataMap,
                            allSubsChannelsDataMap,
                            customerTagsMap,
                            invoiceTagsMap,
                            subscriptionTagsMap,
                          ),
                        )
                      : allCountryOptions?.map(val =>
                          getFilterKeyValueOption(
                            val.label,
                            currentFieldItem.field,
                            allPlanDataMap,
                            allProductDataMap,
                            allOfferDataMap,
                            allSubsChannelsDataMap,
                            customerTagsMap,
                            invoiceTagsMap,
                            subscriptionTagsMap,
                            val.value,
                            val.label,
                          ),
                        )) ?? []
                  }
                  defaultValue={
                    currentFieldItem.values_set?.map(keyValue =>
                      keyValue.toLowerCase(),
                    ) ?? []
                  }
                  onChange={onChange}
                  displayOptionGroup={shouldDisplayOptionGroupForFilterKey(
                    currentFieldItem.field,
                  )}
                />
              </Col>
            </Row>
          );
        default:
          return <></>;
      }
    case 'text':
      switch (currentFieldItem.operator) {
        // One Parameter: String Input
        case 'contains':
        case 'does not contain':
        case 'starts with':
        case 'equals':
        case 'not equal to':
          const isTextEqualOperators = ['equals', 'not equal to'].includes(
            currentFieldItem.operator,
          );
          return (
            <Col className={`${style.text_input}`}>
              <ESFormTextInput
                placeholder={''}
                name={`${namePath}.${index}.value`}
                required={!isTextEqualOperators}
                register={register}
                defaultValue={
                  currentFieldItem.value != null ? String(currentFieldItem.value) : ''
                }
                errorField={errors?.value}
                // control={control}
                // validate={validate}
                onChange={onChange}
              />
            </Col>
          );
        // No Parameter
        case 'is not present':
        case 'is present':
        default:
          return <></>;
      }
    case 'integer':
    case 'long':
      switch (currentFieldItem.operator) {
        // One Parameter: Number Input
        case 'equals':
        case 'less than':
        case 'less than or equal':
        case 'greater than':
        case 'greater than or equal':
          let step: string;
          let min: string;
          let returnedValueConversion: (input: string) => number;
          if (
            currentFieldItem.field?.match(/.*credits.*/) ||
            currentFieldItem.field?.match(/.*amount.*/) ||
            currentFieldItem.field?.match(/.*total.*/) ||
            currentFieldItem.field?.match(/^price$/)
          ) {
            // Money Number Case
            step = getMoneyChangeUnitStr();
            min = '0';
            returnedValueConversion = valueInDollar =>
              convertMoneyFromUiToStorage(valueInDollar);
          } else {
            // Id Integer Number Case
            step = '1';
            min = '1';
            returnedValueConversion = value => parseInt(value);
          }
          return (
            <Row>
              <Col className={`${style.number_input}`}>
                <Form.Control
                  required
                  placeholder=""
                  name={`${namePath}.${index}.value`}
                  as="input"
                  type="number"
                  min={min}
                  step={step}
                  ref={register({
                    required: 'value is required',
                  })}
                  defaultValue={
                    currentFieldItem.field?.match(/.*credits.*/) ||
                    currentFieldItem.field?.match(/.*amount.*/) ||
                    currentFieldItem.field?.match(/.*total.*/) ||
                    currentFieldItem.field?.match(/^price$/)
                      ? convertMoneyFromStorageToUi(String(currentFieldItem.value))
                      : currentFieldItem.value
                  }
                  onChange={() => onChange}
                  {...register(`${namePath}.${index}.value`, {
                    setValueAs: returnedValueConversion,
                  })}
                />
                {errors?.value && errors?.value.message}
              </Col>
            </Row>
          );
        // No Parameter
        case 'is not present':
        case 'is present':
        default:
          return <></>;
      }
    case 'boolean':
      switch (currentFieldItem.operator) {
        // One Parameter: Boolean Dropdown
        case 'is not':
        case 'is':
          return (
            <Row>
              <Col className={`mx-2 ${style.filter_dropdown}`}>
                <ESFormDropdownInput
                  required
                  name={`${namePath}.${index}.value`}
                  controlId={`formGridArray${index}Value`}
                  register={register}
                  control={control}
                  options={['true', 'false'].map(value => getFilterKeyValueOption(value))}
                  defaultValue={String(currentFieldItem.value) ?? 'true'}
                  placeholder={''}
                  onChange={onChange}
                />
              </Col>
            </Row>
          );
        default:
          return <></>;
      }
    default:
      return <></>;
  }
};

// *********************************************** //
//      Prepare Filter Option List for Dropdown    //
// *********************************************** //
const getFilterFieldOption = (
  filterItem: TEpSubsDBElastic_ui_paramsApiValue,
): TGroupDropdownItem<string> => {
  return {
    type: EDropdownItemType.GROUPED,
    value: filterItem.field,
    label: filterItem.description,
    groupName: filterItem.group_name,
  };
};

const getFilterOperatorOption = (operator: string): TFlatDropdownItem<string> => {
  return {
    value: operator,
    label: toInitialUpperCase(operator),
  };
};

const shouldDisplayOptionGroupForFilterKey = (field?: string): boolean =>
  isPlanKeyField(field) || isProductKeyField(field) || isOfferKeyField(field);

const getFilterKeyValueOption = (
  value: string,
  field?: string,
  allPlanDataMap?: Map<string, IEpSubsCompositeDBPlanValue>,
  allProductDataMap?: Map<string, IEpSubsDBProductValue>,
  allOfferDataMap?: Map<string, IEpSubsDBOfferValue>,
  allSubsChannelsDataMap?: Map<string, IEpSubsDBSubscription_channelValue>,
  customerTagsMap?: Map<string, IEpSubsTagsListValue> | null,
  invoiceTagsMap?: Map<string, IEpSubsTagsListValue> | null,
  subscriptionTagsMap?: Map<string, IEpSubsTagsListValue> | null,
  countryValue?: string,
  countryLabel?: string,
): TFlatDropdownItem<string> | TGroupDropdownItem<string> => {
  if (field == undefined) {
    return {
      type: EDropdownItemType.FLAT,
      value: value.toLowerCase(),
      label: toInitialUpperCase(value) + '(Field Missing)',
    };
  }
  console.log('checking filed:', field);
  switch (field) {
    case field.match(PlanKeyRegExp)?.input:
      const planData = allPlanDataMap?.get(value);
      const planProductData = planData?.product;
      return {
        type: EDropdownItemType.GROUPED,
        value: value.toLowerCase(),
        label: !!planData ? getPlanSelectionLabel(planData, planProductData) : value,
        groupName: !planData
          ? 'Unknown Plans'
          : !planProductData
          ? 'Unknown Product'
          : planProductData.name,
      };
    case field.match(ProductKeyRegExp)?.input:
      const productData = allProductDataMap?.get(value);
      const productType = getProductTypeInGroupTitle(productData);
      return {
        type: EDropdownItemType.GROUPED,
        value: value.toLowerCase(),
        label: !!productData ? productData.name : value,
        groupName: productType,
      };
    case field.match(OfferKeyRegExp)?.input:
      const offerData = allOfferDataMap?.get(value);
      return {
        type: EDropdownItemType.GROUPED,
        value: value.toLowerCase(),
        label: !!offerData ? offerData.name : value,
        groupName: '',
      };
    case field.match(SubsChannelKeyRegExp)?.input:
      const subsChannelData = allSubsChannelsDataMap?.get(value);
      return {
        type: EDropdownItemType.FLAT,
        value: value.toLowerCase(),
        label: !!subsChannelData ? subsChannelData.name : value,
      };
    case 'country':
      return {
        type: EDropdownItemType.FLAT,
        value: countryValue?.toLowerCase() ?? value.toLowerCase(),
        label: toInitialUpperCase(countryLabel ?? value),
      };
    case 'customer.tags':
      return {
        type: EDropdownItemType.FLAT,
        value: value,
        label: `${customerTagsMap?.get(value)?.name ?? 'None'}`,
      };
    case 'subscription.tags':
      return {
        type: EDropdownItemType.FLAT,
        value: value.toLowerCase(),
        label: `${subscriptionTagsMap?.get(value)?.name ?? 'None'}`,
      };
    case 'invoice.tags':
      return {
        type: EDropdownItemType.FLAT,
        value: value.toLowerCase(),
        label: `${invoiceTagsMap?.get(value)?.name ?? 'None'}`,
      };
    case 'customer.state_code':
      return {
        type: EDropdownItemType.FLAT,
        value: value.toLowerCase(),
        label: value.toUpperCase(),
      };
    default:
      return {
        type: EDropdownItemType.FLAT,
        value: value.toLowerCase(),
        label: toInitialUpperCase(value),
      };
  }
};
// *********************************************** //
//            Get Filter Option Variables          //
// *********************************************** //
const getCurrentFieldParameters = (
  filterUiParameter: TEpSubsDBElastic_ui_paramsApiValue[],
  currentField: string,
): TEpSubsDBElastic_ui_paramsApiValue | null =>
  filterUiParameter.find(item => item.field === currentField) ?? null;

const getFilterOperatorLists = (
  filterUiParameter: TEpSubsDBElastic_ui_paramsApiValue[],
  currentField: string,
): TFlatDropdownItem<string>[] => {
  const currentFieldParameters = getCurrentFieldParameters(
    filterUiParameter,
    currentField,
  );
  if (!currentFieldParameters) return [];
  return currentFieldParameters.all_operators.map(operator =>
    getFilterOperatorOption(operator),
  );
};

// *********************************************** //
//        Filter Default Value/Option Utils        //
// *********************************************** //
const getDefaultOperatorValue = (
  fieldValue: string,
  filterUiParameter: TEpSubsDBElastic_ui_paramsApiValue[],
): string => {
  const selectedFieldParameter = getCurrentFieldParameters(
    filterUiParameter,
    fieldValue ?? '',
  );
  switch (selectedFieldParameter?.data_type) {
    case 'date':
      return 'is after';
    case 'keyword':
      return 'includes';
    case 'text':
      return 'contains';
    case 'integer':
    case 'long':
      return 'greater than';
    case 'boolean':
      return 'is';
    default:
      return selectedFieldParameter?.all_operators[0] ?? '';
  }
};

// *********************************************** //
//        checking same user with filter          //
// *********************************************** //

export const checkingIfUserMatched = (
  filterUser: string,
  loginUser: string,
  access_level: number,
) => {
  switch (access_level) {
    case 1:
      return true;
    case 0:
      if (filterUser === loginUser) {
        return true;
      } else return false;
  }
};
