import { EpLinkText } from '../../components/links/EpLinkText';
import EpSubsResetPwModal from './EpSubsResetPwModal';
import React, { useState } from 'react';
import { genResetPassword } from '../../services';
import EpSubsConfirmModal from '../../components/modals/EpSubsConfirmModal';
import { DEBUGGING } from 'src/config';

const EpSubsForgotPassword = ({ ...props }): JSX.Element => {
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmModalText, setConfirmModalText] = useState({
    headerText: '',
    contentText: '',
  });
  const handleResetPassword = async (email: string, e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    try {
      const res = await genResetPassword(email);
      DEBUGGING && console.log(res);
      setShowResetPassword(false);

      //show success modal
      setConfirmModalText({
        headerText: 'Reset Password Requested',
        contentText:
          'If you have an active account in our system, then we have sent you an email to reset your password. Please check your inbox.',
      });
      setShowConfirmModal(true);
    } catch (e) {
      setShowResetPassword(false);
      // show failed modal
      setConfirmModalText({
        headerText: 'Reset Password Failed',
        contentText: 'Unable to send reset password to this email, try again later.',
      });
      setShowConfirmModal(true);
    }
  };

  return (
    <div {...props}>
      <EpLinkText className="mt-2" onClick={() => setShowResetPassword(true)}>
        Forgot Your Password?
      </EpLinkText>
      <EpSubsResetPwModal
        modalShow={showResetPassword}
        onClose={() => setShowResetPassword(false)}
        onSubmit={handleResetPassword}
      />
      <EpSubsConfirmModal
        headerName={confirmModalText.headerText}
        contextContent={confirmModalText.contentText}
        modalShow={showConfirmModal}
        confirmText="OK"
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default EpSubsForgotPassword;
