import React, { BaseSyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Modal, Form, Button } from 'react-bootstrap';
import { ESFormLabel, ESFormEmailInput } from '../formComponents';
import style from './EpSubsUserRoleModal.module.scss';
import { EpSubsUserRoleMultipleSelect } from '../dropdownSelect/EpSubsUserRoleMultipleSelect';
import { userRolesSelector } from 'src/recoil/userRoles/userRolesStates';
export interface IUserRoleFormData {
  email: string | null;
  roles: string;
  rolesList?: number[];
  id?: number;
}

type Props = {
  headerName: string;
  showChecked: boolean;
  modalShow: boolean;
  onClose: () => void;
  onSubmit: (data: IUserRoleFormData, event: BaseSyntheticEvent) => Promise<boolean>;
  children?: JSX.Element;
  isInvite?: boolean;
};

const EpSubsUserRoleModal = ({
  headerName,
  // showChecked = false,
  modalShow,
  onClose,
  onSubmit,
  children,
  isInvite = true,
}: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    // watch,
    control,
    setValue,
    // getValues,
    errors,
    trigger,
  } = useForm<IUserRoleFormData>({
    // mode: 'all',
    defaultValues: {
      rolesList: [],
    },
  });

  return (
    <Modal
      show={modalShow}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{headerName}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={handleSubmit((data, e) => onSubmit(data, e as BaseSyntheticEvent))}
        autoComplete={'off'}
      >
        <Modal.Body className={style.modal_body}>
          {isInvite && (
            <Form.Group controlId="formGridEmailName">
              <Form.Row>
                <Col>
                  <p>{`You can either let your team members access everything in this site, or assign specific roles to them.`}</p>
                  <ESFormLabel fieldName="Email" />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={8}>
                  <ESFormEmailInput
                    defaultEmail={null}
                    namePath={''}
                    register={register}
                    error={errors.email}
                    required={true}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Row>
              <Col>
                <ESFormLabel fieldName="Assign user roles" />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={8}>
                <div>
                  <EpSubsUserRoleMultipleSelect
                    namePath="rolesList"
                    required={true}
                    selector={userRolesSelector}
                    isClearable
                    setValue={setValue}
                    control={control}
                    onBlur={() => {
                      trigger('rolesList');
                    }}
                  />
                </div>
              </Col>
            </Form.Row>
          </Form.Group>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            {isInvite ? 'Invite' : 'Update'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EpSubsUserRoleModal;
