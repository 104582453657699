import { TLanguageResource } from '../texts';

export const en_US: TLanguageResource = {
  portal: {
    logo: {
      logo: 'RADIANT LIFE',
      title: 'Payment Methods',
      subTitle: 'Primary account will be charged by default.',
    },
    payments: {
      header: 'ending in',
      primaryContent: 'This is your primary payment method',
      backupContent: 'This is your backup payment method',
    },
    update: {
      header: 'Update Payment Method',
      cardNumber: 'Card Number',
      cvv: 'CVV',
      expirationDate: 'Expiration Date',
      postalCode: 'Postcode',
      confirm: 'Confirm',
    },
    add: {
      header: 'Add New Payment Method',
      subTitle1: 'Stripe',
      subTitle2: 'Paypal Account',
      subTitle3: 'Paypal Express',
      subTitle4: 'Sepa Debit',
      cardNumber: 'Card Number',
      cvv: 'CVV',
      expirationDate: 'Expiration Date',
      postalCode: 'Postcode',
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email Address',
      iban: 'IBAN',
      confirm: 'Confirm',
    },
    buttons: {
      add: 'Add New Payment Method',
      edit: 'Edit',
      remove: 'Remove',
      primary: 'Set as Primary',
    },
    isPrimary: {
      name: 'Primary',
    },
    primaryCard: {
      header: 'Set as primary',
      content:
        'Are you sure you want to set this payment method as your primary payment?',
      cancel: 'Cancel',
      confirm: 'OK',
    },
    removeCard: {
      header: 'Remove',
      content: 'Are you sure you want to remove this payment method?',
      cancel: 'Cancel',
      confirm: 'Ok',
    },
    paypalCheckout: {
      name: 'PayPal Checkout',
    },
    create: {
      success: 'new payment method added successfully',
      failure: 'Failed to create payment.',
    },
    edit: {
      success: 'update payment method successfully',
      failure: 'Failed to update payment.',
    },
    delete: {
      success: 'Delete payment successfully.',
      failure: 'Failed to delete payment. ',
    },
    error: {
      braintree: {
        failure: 'Failed to initialize Braintree.',
        failurePayment: 'Failed to get payment methods from Braintree',
      },
      stripe: {
        failureInitialize: 'Failed to initialize Stripe.',
      },
      paypal: {
        failure: 'Unexpected Braintree paypalCheckout Instance payload ',
      },
      token: {
        failure: 'Tokenization failed server side. Is the card valid? ',
        somethingWrong: 'Something bad happened!',
      },
      postalCode: {
        frontLength: 'There should be no more than',
        endLength: 'characters in postcode',
        failure: 'Invalid postcode.',
      },
      gateway: {
        failure: 'Failed to choose a gateway option.',
      },
      paymentMethod: {
        failure: 'Failed to get payment intent',
      },
      link: {
        failure: 'Your link is not valid.',
      },
      unknown: 'Unknown Gateway',
    },
    netWork: 'Network error occurred when tokenizing.',
    reminder: {
      someField: 'Some fields are invalid.',
      allField: 'All fields are empty! Please fill out the form.',
      contact: 'Please contact our customer service to regenerate it for you.',
    },
    sepa: {
      header: 'Select a Country',
      error:
        'Outside Germany, please use the payment method "Credit Card" for your transaction.',
    },
    default: {
      make_default: 'Set as primary payment method',
      apply_all: 'Apply to all subscriptions',
    },
    link: 'Visit The Epoch Times',
  },
};
