import { put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga/macro';
import { SagaIterator } from '@redux-saga/core';
import { IEpSubsCompositeDBSubscriptionValue } from '../../models/';
import {
  epSubsSubscriptionActionTypes,
  FETCH_SUBSCRIPTION_DATA,
  FETCH_SUBSCRIPTION_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_DATA_FAILURE,
  IFetchSubscriptionDataAction,
  // ARCHIVE_SUBSCRIPTION,
  // IArchiveSubscriptionAction,
  // ARCHIVE_SUBSCRIPTION_FAILURE,
} from './epSubsSubscriptionTypes';
import {
  // genArchiveSubscription,
  genSubscriptionData,
  // genSampleSubscriptionData
} from '../../services/epSubsSubscriptionService';
import { RootState, TCompositeSubscriptionDataMap } from '../rootState';
import { DEBUGGING } from 'src/config';
// import { putNoty } from '../../utilities/epSubsNoty';

// *********************************************** //
//         watchFetchSubscription Saga             //
// *********************************************** //

export function fetchSubscriptionDataSuccess(
  id: number,
  subscriptionData: IEpSubsCompositeDBSubscriptionValue,
): epSubsSubscriptionActionTypes {
  return {
    type: FETCH_SUBSCRIPTION_DATA_SUCCESS,
    id,
    subscriptionData,
  };
}

export const getSubscriptionList = (state: RootState): TCompositeSubscriptionDataMap =>
  state.subscriptions.subscriptionList;

function* getSubscriptionAsync(action: IFetchSubscriptionDataAction): SagaIterator {
  const subscriptionList = yield* select(getSubscriptionList);
  const subscriptionData = subscriptionList.get(action.id);
  DEBUGGING &&
    console.log(
      'Saga: FetchSubscriptionById: ',
      subscriptionData,
      action.id,
      action.refresh,
      subscriptionList,
    );
  if (subscriptionData === null || subscriptionData === undefined || action.refresh) {
    try {
      DEBUGGING &&
        console.log(
          'Saga: ReFetchSubscriptionById: ',
          action.id,
          action.refresh,
          subscriptionList,
        );
      // *** Use genSampleSubscriptionData for offline test, use genSubscriptionData for db server *** //
      const subscriptionData = yield* call(genSubscriptionData, action.id);
      if (subscriptionData !== null) {
        yield put(fetchSubscriptionDataSuccess(action.id, subscriptionData));
      }
    } catch (error) {
      // Dispatch a fetch failure action. What to do?
      yield put({ type: FETCH_SUBSCRIPTION_DATA_FAILURE, error });
    }
  }
}

export function* watchFetchSubscription(): SagaIterator {
  // Need to use takeEvery instead of takeLatest, otherwise the fetch process
  // are always interrupted by later fetch action and causing an unnecessary delay
  yield takeEvery(FETCH_SUBSCRIPTION_DATA, getSubscriptionAsync);
}

// // *********************************************** //
// //          watchSetCurrentSubscription Saga               //
// // *********************************************** //

// export function setCurrentSubscriptionSuccess(id: number): epSubsSubscriptionActionTypes {
//   return {
//     type: SET_CURRENT_SUBSCRIPTION_SUCCESS,
//     id
//   };
// }

// function* setCurrentSubscriptionAsync(action: ISetCurrentSubscriptionAction): SagaIterator {
//   try{
//     yield* call(getSubscriptionAsync, {type: FETCH_SUBSCRIPTION_DATA, id: action.id})
//     yield put(setCurrentSubscriptionSuccess(action.id))
//   } catch(error){
//     // Dispatch a set failure action. What to do? Retry?
//     yield put({ type: SET_CURRENT_SUBSCRIPTION_FAILURE, error })
//   }
// }

// export function* watchSetCurrentSubscription(): SagaIterator {
//   yield takeLatest(SET_CURRENT_SUBSCRIPTION, setCurrentSubscriptionAsync);
// }

// // *********************************************** //
// //          watchArchiveSubscription Saga          //
// // *********************************************** //

// function* archiveSubscriptionAsync(action: IArchiveSubscriptionAction): SagaIterator {
//   try {
//     const archiveSuccess = yield* call(genArchiveSubscription, action.id, action.archive);
//     if (archiveSuccess) {
//       // Update the subscription detail in redux store
//       yield put({ type: FETCH_SUBSCRIPTION_DATA, id: action.id, refresh: true });
//       const message = `Successfully ${
//         action.archive ? 'archived' : 'activated'
//       } subscription with id ${action.id}.`;
//       yield* call(putNoty, { type: 'success', text: message });
//     }
//   } catch (error) {
//     // Dispatch a archive failure action. What to do? Retry?
//     yield put({ type: ARCHIVE_SUBSCRIPTION_FAILURE, error });
//     yield* call(putNoty, { type: 'error', text: (error as Error).message });
//   }
// }

// export function* watchArchiveSubscription(): SagaIterator {
//   yield takeEvery(ARCHIVE_SUBSCRIPTION, archiveSubscriptionAsync);
// }
