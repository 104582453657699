import { LOCALE, TIMEZONE } from 'src/config';
import { ELocale } from '.';

/**
 * @param separator default to be ' '
 * @param locale Default to be instance wide LOCALE
 * @returns "MMM DD" for en-US and "DD MMM" for en_AU
 */
export const getMonthDayFormat = (
  separator = ' ',
  endSymbol = '',
  locale = LOCALE as ELocale,
): string => {
  switch (locale) {
    case ELocale.en_AU:
      if (separator.includes('.')) return ['DD', 'MMM'].join(' ');
      return ['DD', 'MMM'].join(separator) + endSymbol;
    case ELocale.de_DE:
    case ELocale.fr_FR:
    case ELocale.cs_CZ:
    case ELocale.sk_SK:
      return ['DD', 'MMM'].join('.');
    case ELocale.ja_JP:
      return ['MMM', 'DD'].join(' ') + endSymbol;
    case ELocale.en_US:
    case ELocale.en_UK:
    case ELocale.es_MX:
    case ELocale.es_US:
    case ELocale.pt_BR:
    default:
      return ['MMM', 'DD'].join(separator) + endSymbol;
  }
};

export const convertToLocalTime = (time: string, notShowTime?: boolean) => {
  const moment = require('moment-timezone');
  const dateString = moment(time)
    .tz(TIMEZONE)
    .format(`${getMonthDayFormat('.', ',')} YYYY ${notShowTime ? '' : 'HH:mm'}`);
  return dateString;
};

export const localTimeZone = (date: Date, hasTime?: boolean) => {
  const moment = require('moment-timezone');
  const dateWithoutZone = moment
    .tz(date, TIMEZONE)
    .format(hasTime ? 'YYYY-MM-DDTHH:mm:ss.SSS' : 'YYYY-MM-DDT00:00:00.SSS');
  const localZone = moment(dateWithoutZone).format('Z');
  const dateWithLocalZone = [dateWithoutZone, localZone].join('');
  return new Date(dateWithLocalZone);
};

export const otherCountryLocalTimeZone = (date: Date) => {
  const moment = require('moment-timezone');
  const dateWithoutZone = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
  const otherZone = moment.tz(date, TIMEZONE).format('Z');
  const dateWithOtherZone = [dateWithoutZone, otherZone].join('');
  return new Date(dateWithOtherZone);
};

export const otherCountryLocalTimeZoneAsString = (date: Date): string => {
  const moment = require('moment-timezone');
  const dateWithoutZone = moment(date).format('YYYY-MM-DDT00:00:00.SSS');
  const otherZone = moment.tz(date, TIMEZONE).format('Z');
  const dateWithOtherZone = [dateWithoutZone, otherZone].join('');
  return dateWithOtherZone;
};

type logoSize = {
  width: string;
  height: string;
};
export const logoDimension = (locale: string = LOCALE): logoSize => {
  switch (locale) {
    case ELocale.en_AU:
      return { width: 'auto', height: '150px' };
    case ELocale.ja_JP:
    case ELocale.de_DE:
    case ELocale.en_US:
    case ELocale.en_UK:
    case ELocale.cs_CZ:
    case ELocale.es_MX:
    case ELocale.es_US:
    case ELocale.sk_SK:
    case ELocale.pt_BR:
    case ELocale.fr_FR:
    default:
      return { width: '180px', height: 'auto' };
  }
};
