import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { HIDE_CREATE_NEW_BUTTON_IN_LIST } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import {
  FILTER_SOURCE,
  PLAN_STATUS,
  // SUBSCRIPTION_PLAN_TYPE,
} from 'src/js/constants/subs';
import { IEpSubsDBProductValue, UiPermission } from 'src/models';
import { allProductListSelector, useHasPermission } from 'src/recoil';
import EpSubsListNav from '../../components/navBars/EpSubsListNav/EpSubsListNav';
import { toInitialUpperCase } from 'src/utilities/stringUtility';

const PLAN_STATUS_FILTER_GROUP_NAME = 'plan_status';
export const PLAN_STATUS_FILTER_GROUP_FIELD = 'status';
const PRODUCT_FILTER_GROUP_NAME = 'product_id';
export const PRODUCT_FILTER_GROUP_FIELD = 'product_id';

const noPlanStatusFilter = {
  label: 'All Plan Status',
  value: '',
  groupField: PLAN_STATUS_FILTER_GROUP_FIELD,
};
const noProductTypeFilter = {
  label: 'All Products',
  value: '',
  groupField: PRODUCT_FILTER_GROUP_FIELD,
};
export const DEFAULT_PLAN_SEARCH_OPTION = {
  [PLAN_STATUS_FILTER_GROUP_FIELD]: [PLAN_STATUS.ACTIVE],
  [PRODUCT_FILTER_GROUP_FIELD]: [noProductTypeFilter.value],
};

const EpSubsPlanNav = (routerProps: RouteComponentProps): JSX.Element => {
  const history = useHistory();
  const allProductListLoadable = useRecoilValueLoadable(allProductListSelector);
  const [allProductList, setAllProductList] = useState<IEpSubsDBProductValue[]>();

  useEffect(() => {
    if (allProductListLoadable.state === 'hasValue') {
      setAllProductList(allProductListLoadable.contents.data);
    }
  }, [allProductListLoadable.contents]);

  const createBtnText = '+ Create New Plan';

  const onCreateBtnClick = useHasPermission(UiPermission['plan.create'])
    ? () => {
        history.push('/plans/create-plan');
      }
    : HIDE_CREATE_NEW_BUTTON_IN_LIST;

  return !allProductList ? (
    <></>
  ) : (
    <EpSubsListNav
      key="plan"
      {...{
        routerProps,
        createBtnText,
        onCreateBtnClick,
        history,
        source: FILTER_SOURCE.PLAN,
        allSimplyFilterOptions: {
          [PLAN_STATUS_FILTER_GROUP_FIELD]: [noPlanStatusFilter].concat(
            Object.values(PLAN_STATUS).map(status => {
              return {
                label: toInitialUpperCase(status),
                value: status,
                groupName: PLAN_STATUS_FILTER_GROUP_NAME,
                groupField: PLAN_STATUS_FILTER_GROUP_FIELD,
              };
            }),
          ),
          [PRODUCT_FILTER_GROUP_FIELD]: [noProductTypeFilter].concat(
            allProductList.map(productDta => {
              return {
                label: productDta.name,
                value: String(productDta.id),
                groupName: PRODUCT_FILTER_GROUP_NAME,
                groupField: PRODUCT_FILTER_GROUP_FIELD,
              };
            }),
          ),
        },
        defaultSimplyFilterOption: DEFAULT_PLAN_SEARCH_OPTION,
      }}
    />
  );
};

export default EpSubsPlanNav;
