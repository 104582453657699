import React, { BaseSyntheticEvent } from 'react';
import { FieldName, SetFieldValue, useForm } from 'react-hook-form';
import { Form, Button, Row, Col } from 'react-bootstrap';
import style from '../customers/EpSubsCustomerForm.module.scss';
import {
  ESFormDateInput,
  EpSubsAddressFormSubSection,
  TUseFormSetValue,
  addDays,
  dateToUnixtime,
} from '../formComponents';
import { ELocale, INewAddressFormData } from '../../models';
import { localTimeZone } from 'src/models/i18n';
import { DEBUGGING } from 'src/config';

type Props = {
  formTitle: string;
  onSubmit: (data: INewAddressFormData, event?: BaseSyntheticEvent) => Promise<boolean>;
  onCancel: () => void;
  enableSaveAndCreateNew?: boolean;
  defaultAddressData: INewAddressFormData;
  customerLocale: ELocale;
  isTempAddress?: boolean;
};

export const EpSubsAddressForm = (props: Props): JSX.Element => {
  const { register, control, handleSubmit, setValue, errors, watch, trigger } =
    useForm<INewAddressFormData>({
      mode: 'all',
      reValidateMode: 'onBlur',
      defaultValues: props.defaultAddressData,
    });
  DEBUGGING && console.log('default form data:', props.defaultAddressData);
  const { onSubmit, onCancel, defaultAddressData, formTitle = 'Add Address' } = props;
  return (
    <Form className={style.form} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
      <h5 className="subheader">{formTitle}</h5>
      <EpSubsAddressFormSubSection
        defaultAddressData={defaultAddressData}
        errors={errors}
        register={register}
        control={control}
        watch={watch()}
        setValue={setValue as TUseFormSetValue<any>}
        validationStatus={defaultAddressData.validation_status}
        customerLocale={props.customerLocale}
      />
      {props.isTempAddress && (
        <Row>
          <Col
            md={4}
            style={{
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: '18px',
              paddingTop: '5px',
            }}
          >
            Start Time*
          </Col>
          <Col md={4}>
            <ESFormDateInput
              required={true}
              controlId={'start_at'}
              name="start_at"
              register={register}
              control={control}
              minDate={localTimeZone(new Date())}
              showTimeSelect={true}
              defaultDate={dateToUnixtime(
                new Date(defaultAddressData.start_at as string),
              )}
              setValue={
                ((
                  name: FieldName<INewAddressFormData>,
                  value: SetFieldValue<INewAddressFormData>,
                  config,
                ) =>
                  setValue(name, value, {
                    ...config,
                    shouldValidate: true,
                  })) as TUseFormSetValue<INewAddressFormData>
              }
              onBlur={() => {
                trigger(['start_at', 'end_at']);
              }}
            />
            {errors.start_at && (
              <div className={'text-danger'}>{errors.start_at.message}</div>
            )}
          </Col>
        </Row>
      )}
      {props.isTempAddress && (
        <Row>
          <Col
            md={4}
            style={{
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: '18px',
              paddingTop: '20px',
            }}
          >
            End Time*
          </Col>
          <Col
            md={4}
            style={{
              paddingTop: '20px',
              paddingBottom: '10px',
            }}
          >
            <ESFormDateInput
              controlId={'end_at'}
              name="end_at"
              register={register}
              control={control}
              minDate={addDays(localTimeZone(new Date()), 1)}
              showTimeSelect={true}
              defaultDate={dateToUnixtime(new Date(defaultAddressData.end_at as string))}
              setValue={
                ((
                  name: FieldName<INewAddressFormData>,
                  value: SetFieldValue<INewAddressFormData>,
                  config,
                ) =>
                  setValue(name, value, {
                    ...config,
                    shouldValidate: true,
                  })) as TUseFormSetValue<INewAddressFormData>
              }
              onBlur={() => {
                trigger(['start_at', 'end_at']);
              }}
            />
          </Col>
        </Row>
      )}

      <h5 className="subheader"></h5>
      <div style={{ textAlign: 'right' }}>
        <Button
          className={`${style.form_button}`}
          variant="primary"
          type="submit"
          id="address_form_save"
        >
          Save
        </Button>
        <Button
          className={`${style.form_button}`}
          onClick={() => onCancel()}
          variant="secondary"
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default EpSubsAddressForm;
