import { Stripe } from '@stripe/stripe-js';
import React, { BaseSyntheticEvent, useMemo, useState } from 'react';
import { Nav, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { EpIcon, IconFactory } from 'src/assets';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm';
import { GATEWAYS } from 'src/models';
import { languageOptions } from 'src/models/i18n';
import { useLoadOptionalRecoilSelector } from 'src/recoil';
import { defaultBraintreeGatewaySelector } from 'src/recoil/gateway/gatewayStates';
import { EpSubsPayPalExpressCheckout } from '../payPalExpress/EpSubsPayPalExpressCheckout';
import { TTokenizeCallback } from '../subscriptions/subscriptionOperations/EpSubsCreateSubscription';
import { EpSubsPaymentBrainTreeCardForm } from './components/paymentForms/EpSubsPaymentBrainTreeCardForm';
import { EpSubsPaymentPortalPaypalForm } from './components/paymentForms/EpSubsPaymentPortalPaypalForm';
import { EpSubsPaymentStripeCardForm } from './components/paymentForms/EpSubsPaymentStripeCardForm';
import { GatewayListDataType } from './EpSubsUpdatePaymentWrapper';
import * as stripeJs from '@stripe/stripe-js';

export type TPortalPaymentMethod = 'creditCard' | 'payPalAccount' | 'payPalExpress';
const highlightTabStyle = {
  borderBottom: '1px solid #007bff',
  paddingBottom: '8px',
  marginBottom: '0px',
};
interface ParamType {
  token: string;
}

type Props = {
  defaultGatewayAccountId: number;
  onSubmit: (
    gateway: number,
    data: TNewPaymentFormData,
    setIsLoading: (isLoading: boolean) => void,
    event?: BaseSyntheticEvent<any>,
  ) => Promise<boolean>;
  onBraintreeInstance: (tokenize: TTokenizeCallback) => void;
  onStripeInstance: (stripe: Stripe, stripeElements?: stripeJs.StripeElements) => void;
  onClosePaypalForm?: () => void;
  allowedPaymentMethods: TPortalPaymentMethod[];
  productGatewayList?: GatewayListDataType[];
  // defaultPaymentData?: TNewPaymentFormData;
};

type TPaymentMethodConfig = {
  iconName: keyof typeof IconFactory;
  label: string;
};

const PAYMENT_METHOD_CONFIG: Record<TPortalPaymentMethod, TPaymentMethodConfig> = {
  creditCard: {
    iconName: 'stripe',
    label: `${languageOptions().portal.add.subTitle1}`,
  },
  payPalAccount: {
    iconName: 'paypal',
    label: `${languageOptions().portal.add.subTitle2}`,
  },
  payPalExpress: {
    iconName: 'paypal',
    label: `${languageOptions().portal.add.subTitle3}`,
  },
};

export const EpSubsPortalAddPaymentForm = ({
  defaultGatewayAccountId,
  onSubmit,
  onBraintreeInstance,
  onStripeInstance,
  onClosePaypalForm,
  allowedPaymentMethods,
  productGatewayList,
}: // defaultPaymentData,
Props): JSX.Element => {
  const [methodKey, setMethodKey] = useState<TPortalPaymentMethod>(
    allowedPaymentMethods[0],
  );
  const token = useParams<ParamType>().token;
  const defaultBraintreeGateway = useLoadOptionalRecoilSelector(
    defaultBraintreeGatewaySelector,
    undefined,
  );

  // we only show the first stripe of product gateway list in portal page, because the users will confused when ask them to choose the different gateway.
  // getStripeID = [2, 32]
  const getBraintreeID = [] as any;
  const gatewayPaymentTypeHasStripe = productGatewayList?.map(val => {
    if (
      val.type == GATEWAYS.Stripe &&
      val.payment_method_types.some(val => val.code == 'card')
    ) {
      return true;
    }
    if (
      val.type == GATEWAYS.Braintree &&
      val.payment_method_types.some(val => val.code == 'card')
    ) {
      getBraintreeID.push(val.id);
    }
  });

  const creditCardForm = (() => {
    switch (gatewayPaymentTypeHasStripe?.includes(true)) {
      case false:
        return (
          <EpSubsPaymentBrainTreeCardForm
            {...{
              onSubmit,
              onBraintreeInstance,
              gatewayAccountId: getBraintreeID[0] ?? defaultGatewayAccountId,
            }}
          />
        );
      case true:
        return (
          <EpSubsPaymentStripeCardForm
            {...{
              onSubmit,
              onStripeInstance,
              gatewayAccountId: defaultGatewayAccountId,
            }}
          />
        );
      default:
        return (
          <EpSubsPaymentStripeCardForm
            {...{
              onSubmit,
              onStripeInstance,
              gatewayAccountId: defaultGatewayAccountId,
            }}
          />
        );
    }
  })();
  //payPalAccount is Braintree paypal checkout which is still available to use to create and paypal account
  const payPalAccountForm = (() => {
    if (defaultBraintreeGateway === undefined)
      return <Spinner animation="border" variant="primary" />;
    if (defaultBraintreeGateway === null) return <></>;

    return (
      <EpSubsPaymentPortalPaypalForm
        {...{ token, onClosePaypalForm, gatewayAccountId: defaultBraintreeGateway.id }}
      />
    );
  })();

  const payPalExpressForm = (() => {
    return <EpSubsPayPalExpressCheckout />;
  })();

  const paymentInputForm = useMemo(() => {
    switch (methodKey) {
      case 'creditCard':
        return creditCardForm;
      case 'payPalAccount':
        return payPalAccountForm;
      case 'payPalExpress':
        return payPalExpressForm;
    }
  }, [methodKey, creditCardForm, payPalAccountForm, payPalExpressForm]);

  return (
    <>
      <Nav
        variant="tabs"
        style={{
          fontSize: '15px',
          display: 'center',
          justifyContent: 'flex-center',
          fontWeight: 'bold',
        }}
      >
        {allowedPaymentMethods.length >= 1 &&
          allowedPaymentMethods.map((allowedMethodKey: TPortalPaymentMethod) => (
            <Nav.Item key={allowedMethodKey} style={{ width: '33%' }} className="my-auto">
              <Nav.Link
                onClick={() => setMethodKey(allowedMethodKey)}
                style={{
                  ...(methodKey === allowedMethodKey ? highlightTabStyle : ''),
                  borderTop: 'none',
                  padding: '0px',
                }}
              >
                <Row className="justify-content-center m-auto">
                  <EpIcon
                    className="my-auto"
                    name={PAYMENT_METHOD_CONFIG[allowedMethodKey]?.iconName}
                    style={{ height: '2rem', verticalAlign: 'middle' }}
                  />
                  <p
                    className="my-auto"
                    style={{
                      color: methodKey === allowedMethodKey ? '#007bff' : 'black',
                    }}
                  >
                    {PAYMENT_METHOD_CONFIG[allowedMethodKey]?.label}
                  </p>
                </Row>
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
      <div>{paymentInputForm}</div>
    </>
  );
};
