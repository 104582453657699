import React from 'react';
import {
  PLAN_STATUS,
  TAddonStatus,
  TCouponStatus,
  TEmailLogStatus,
  TInvoiceStatus,
  EOfferStatus,
  TPlanStatus,
  TSubscriptionStatus,
} from 'src/js/constants/subs';
import { toInitialUpperCase, toStringKey } from 'src/utilities/stringUtility';
import { ESubscriptionChangeApiAmendType, NotificationType } from 'src/models';
import { getLocalDisplayTime } from '../formComponents';
import cssStyle from './EpSubsFlags.module.scss';
import { convertMoneySignDependLocale } from 'src/models/i18n';

type TAllowedStyleParameters =
  | 'margin'
  | 'marginLeft'
  | 'marginRight'
  | 'marginBottom'
  | 'marginTop'
  | 'fontSize'
  | 'padding'
  | string;
type TStyle = { [key in TAllowedStyleParameters]?: string };
type TFlagType = 'solid' | 'blank';
type TFlagColor =
  | 'red'
  | 'green'
  | 'blue'
  | 'light_blue'
  | 'grey'
  | 'light_grey'
  | 'orange'
  | 'black'
  | 'golden_rod'
  | 'barn_red'
  | 'purple'
  | 'bright_cyan'
  | 'yellow';
type IBasicFlagProps = {
  label: string;
  color: TFlagColor;
  type?: TFlagType;
  style?: TStyle;
};

export function BasicFlag({
  label,
  color,
  type = 'solid',
  style,
}: IBasicFlagProps): JSX.Element {
  return (
    <div
      id={`Ep_flag_${toStringKey(label)}`}
      className={`${cssStyle[`flag_${type}`]} ${cssStyle[`flag_${color}`]}`}
      style={style}
    >
      {label}
    </div>
  );
}

export const CustomerPinFlag = ({
  pin,
  showLabel = false,
}: {
  pin: string;
  showLabel?: boolean;
}): JSX.Element => {
  return (
    <BasicFlag
      label={`${showLabel ? 'PIN: ' : ''}${pin}`}
      color="orange"
      type="blank"
      style={{ maxWidth: '8rem', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
    />
  );
};

export const SubscriptionStatusFlag = (
  status: TSubscriptionStatus,
  style?: TStyle,
): JSX.Element => {
  switch (status) {
    case 'Active':
    case 'active':
      return <BasicFlag label={`Active`} color="green" type="solid" style={style} />;
    case 'Cancelled':
    case 'cancelled':
      return <BasicFlag label={`Cancelled`} color="red" type="solid" style={style} />;
    case 'Paused':
    case 'paused':
      return <BasicFlag label={`Paused`} color="orange" type="solid" style={style} />;
    case 'Non Renewing':
    case 'non_renewing':
      return <BasicFlag label={`Non Renewing`} color="grey" type="solid" style={style} />;
    case 'OnHold':
    case 'on_hold':
      return (
        <BasicFlag label={`On Hold`} color="bright_cyan" type="solid" style={style} />
      );
    case 'Future':
    case 'future':
      return <BasicFlag label={`Future`} color="blue" type="solid" style={style} />;
    default:
      return <></>;
  }
};

/**
 * Used in Customer List Page to give a summary its subscription Status
 */
export const SubscriptionStatusStatFlag = (
  status: TSubscriptionStatus | null,
  count: number,
): JSX.Element => {
  switch (status) {
    case 'Active':
    case 'active':
      return (
        <BasicFlag
          label={`${count} Active`}
          color="green"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'Cancelled':
    case 'cancelled':
      return (
        <BasicFlag
          label={`${count} Cancelled`}
          color="red"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'Paused':
    case 'paused':
      return (
        <BasicFlag
          label={`${count} Paused`}
          color="orange"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'Non Renewing':
    case 'non_renewing':
      return (
        <BasicFlag
          label={`${count} Non Renewing`}
          color="grey"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'OnHold':
    case 'on_hold':
      return (
        <BasicFlag
          label={`${count} On Hold`}
          color="bright_cyan"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'Future':
    case 'future':
      return (
        <BasicFlag
          label={`${count} Future`}
          color="blue"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    default:
      return (
        <BasicFlag
          label="None"
          color="black"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
  }
};

export const SubscriptionPreviewAmendTypeFlag = (
  status: ESubscriptionChangeApiAmendType,
  style: TStyle = { fontSize: '10px', padding: '0.15rem' },
): JSX.Element => {
  switch (status) {
    case ESubscriptionChangeApiAmendType.NO_CHANGE:
      return (
        <BasicFlag label={`No Change`} color="light_grey" type="blank" style={style} />
      );
    case ESubscriptionChangeApiAmendType.ADDED:
      return <BasicFlag label={`Added`} color="green" type="blank" style={style} />;
    case ESubscriptionChangeApiAmendType.DELETED:
      return <BasicFlag label={`Deleted`} color="red" type="blank" style={style} />;
    case ESubscriptionChangeApiAmendType.CHANGED:
      return (
        <BasicFlag label={`Changed`} color="light_grey" type="blank" style={style} />
      );
    case ESubscriptionChangeApiAmendType.CHANGED_FROM:
      return <BasicFlag label={`Old`} color="red" type="blank" style={style} />;
    case ESubscriptionChangeApiAmendType.CHANGED_TO:
      return <BasicFlag label={`New`} color="green" type="blank" style={style} />;
    default:
      return <></>;
  }
};

export const SubscriptionPauseDetailFlag = (resumeDate?: string | null): JSX.Element => {
  if (!!resumeDate) {
    const resumeTimeLabel = `Until ${getLocalDisplayTime(new Date(resumeDate))}`;
    return (
      <BasicFlag
        label={resumeTimeLabel}
        color="green"
        type="blank"
        style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
      />
    );
  }
  return (
    <BasicFlag
      label={`Until Manual Resumption`}
      color="orange"
      type="blank"
      style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
    />
  );
};

export const PlanStatusFlag = (status: TPlanStatus, style?: TStyle): JSX.Element => {
  switch (status) {
    case PLAN_STATUS.ACTIVE:
      return <BasicFlag label="ACTIVE" color="green" type="blank" style={style} />;
    case PLAN_STATUS.ARCHIVED:
      return <BasicFlag label="ARCHIVED" color="red" type="blank" style={style} />;
    default:
      return <></>;
  }
};

export const OfferStatusFlag = (status: EOfferStatus, style?: TStyle): JSX.Element => {
  switch (status) {
    case EOfferStatus.ACTIVE:
      return <BasicFlag label="ACTIVE" color="green" type="blank" style={style} />;
    case EOfferStatus.INACTIVE:
      return <BasicFlag label="INACTIVE" color="grey" type="blank" style={style} />;
    case EOfferStatus.ARCHIVED:
      return <BasicFlag label="ARCHIVED" color="red" type="blank" style={style} />;
    default:
      return <></>;
  }
};

export const PlanTrialFlag = (isTrial: boolean, style?: TStyle): JSX.Element => {
  switch (isTrial) {
    case true:
      return <BasicFlag label="TRIAL" color="purple" type="solid" style={style} />;
    default:
      return <></>;
  }
};

export const AddonStatusFlag = (status: TAddonStatus, style?: TStyle): JSX.Element => {
  switch (status) {
    case 'active':
      return <BasicFlag label="ACTIVE" color="green" type="blank" style={style} />;
    case 'archived':
      return <BasicFlag label="ARCHIVED" color="red" type="blank" style={style} />;
    default:
      return <></>;
  }
};

export const CouponStatusFlag = (status: TCouponStatus, style?: TStyle): JSX.Element => {
  switch (status) {
    case 'active':
      return <BasicFlag label="ACTIVE" color="green" type="blank" style={style} />;
    case 'archived':
      return <BasicFlag label="ARCHIVED" color="red" type="blank" style={style} />;
    case 'expired':
      return <BasicFlag label="EXPIRED" color="grey" type="blank" style={style} />;
    default:
      return <></>;
  }
};

export const InvoiceStatusFlag = (
  status: TInvoiceStatus,
  style?: TStyle,
): JSX.Element => {
  switch (status) {
    case 'paid':
      return <BasicFlag label="PAID" color="green" type="solid" style={style} />;
    case 'not_paid':
      return <BasicFlag label="NOT PAID" color="red" type="solid" style={style} />;
    case 'payment_due':
      return (
        <BasicFlag label="PAYMENT DUE" color="barn_red" type="solid" style={style} />
      );
    case 'voided':
      return <BasicFlag label="VOIDED" color="grey" type="solid" style={style} />;
    case 'pending':
      return <BasicFlag label="PENDING" color="orange" type="solid" style={style} />;
    default:
      return <></>;
  }
};

export type TInvoiceAmountStatus = 'adjusted' | 'credit';
export const InvoiceAmountFlag = ({
  status,
  moneyInCents,
}: {
  status: TInvoiceAmountStatus;
  moneyInCents: number;
}): JSX.Element => {
  switch (status) {
    case 'adjusted':
      return (
        <BasicFlag
          label={`Adjusted ${convertMoneySignDependLocale(moneyInCents)}`}
          color="red"
          type="blank"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', fontSize: '11px' }}
        />
      );
    case 'credit':
      return (
        <BasicFlag
          label={`Credit Issued ${convertMoneySignDependLocale(moneyInCents)}`}
          color="red"
          type="blank"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', fontSize: '11px' }}
        />
      );
    default:
      return <></>;
  }
};

export type TCreditNoteStatus = 'refund_due' | 'refunded' | 'adjusted' | 'voided';
export const CreditNoteStatusFlag = (status: TCreditNoteStatus): JSX.Element => {
  switch (status) {
    case 'refunded':
      return (
        <BasicFlag
          label="REFUNDED"
          color="grey"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'adjusted':
      return (
        <BasicFlag
          label="ADJUSTED"
          color="grey"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'refund_due':
      return (
        <BasicFlag
          label="REFUND DUE"
          color="orange"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'voided':
      return (
        <BasicFlag
          label="VOIDED"
          color="black"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    default:
      return <></>;
  }
};

export type TTransactionStatus =
  | 'success'
  | 'failure'
  | 'in_progress'
  | 'voided'
  | 'disputed'
  | 'dispute_won'
  | 'dispute_lost';
export const TransactionStatusFlag = (status: TTransactionStatus): JSX.Element => {
  switch (status) {
    case 'success':
      return (
        <BasicFlag
          label="SUCCESS"
          color="green"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'in_progress':
      return (
        <BasicFlag
          label="IN PROGRESS"
          color="orange"
          type="solid"
          style={{ height: 'auto', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'voided':
      return (
        <BasicFlag
          label="VOIDED"
          color="grey"
          type="solid"
          style={{ height: 'auto', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'disputed':
      return (
        <BasicFlag
          label="DISPUTED"
          color="light_blue"
          type="solid"
          style={{ height: 'auto', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'dispute_won':
      return (
        <BasicFlag
          label="DISPUTE WON"
          color="green"
          type="solid"
          style={{ height: 'auto', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'dispute_lost':
      return (
        <BasicFlag
          label="DISPUTE LOST"
          color="red"
          type="solid"
          style={{ height: 'auto', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'failure':
      return (
        <BasicFlag
          label="FAILURE"
          color="red"
          type="solid"
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    default:
      return <></>;
  }
};

export const AllocationStatusFlag = (status: string): JSX.Element => {
  return (
    <BasicFlag
      label={status}
      color="orange"
      type="solid"
      style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
    />
  );
};

export const EmailLogStatusFlag = (status: TEmailLogStatus): JSX.Element => {
  switch (status) {
    case 'sent':
      return (
        <BasicFlag
          label="Sent"
          color="green"
          type="solid"
          style={{ width: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'opened':
      return (
        <BasicFlag
          label="Opened"
          color="green"
          type="solid"
          style={{ width: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'bounced':
      return (
        <BasicFlag
          label="Bounced"
          color="red"
          type="solid"
          style={{ width: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'dropped':
      return (
        <BasicFlag
          label="Dropped"
          color="grey"
          type="solid"
          style={{ width: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case 'clicked':
      return (
        <BasicFlag
          label="Clicked"
          color="golden_rod"
          type="solid"
          style={{ width: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    default:
      return <></>;
  }
};

export const FilterGroupNameFlag = (
  groupName: string | null | undefined,
  style?: TStyle,
): JSX.Element => {
  return !!groupName ? (
    <BasicFlag
      label={toInitialUpperCase(groupName)}
      color="light_blue"
      type="solid"
      style={style}
    />
  ) : (
    <></>
  );
};

type TBasicSmallFlagSizeType = 'small' | 'normal';
const BasicSmallFlag = ({
  label,
  type = 'blank',
  color,
  style,
  size = 'small',
}: IBasicFlagProps & { size?: TBasicSmallFlagSizeType }): JSX.Element => {
  let sizeStyle: TStyle;
  switch (size) {
    case 'small':
      sizeStyle = {
        padding: '0 8px',
        fontWeight: '400',
        fontSize: '8px',
        height: '16px',
        lineHeight: '16px',
      };
      break;
    case 'normal':
      sizeStyle = {
        padding: '0 8px',
        fontWeight: '400',
        fontSize: '10px',
        height: '22px',
        lineHeight: '22px',
      };
  }
  return (
    <BasicFlag
      label={label}
      type={type}
      color={color}
      style={{
        borderRadius: '32px',
        backgroundColor: 'inherit',
        minWidth: '1rem',
        ...sizeStyle,
        ...style,
      }}
    />
  );
};

export const NotificationDraftStatusFlag = (
  isDraft: boolean,
  size: TBasicSmallFlagSizeType = 'small',
): JSX.Element => {
  return isDraft ? (
    <BasicSmallFlag
      label="Draft"
      type="blank"
      color="orange"
      size={size}
      style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
    />
  ) : (
    <></>
  );
};

export const NotificationEnableStatusFlag = (
  enabled: boolean,
  size: TBasicSmallFlagSizeType = 'small',
): JSX.Element => {
  switch (enabled) {
    case true:
      return (
        <BasicSmallFlag
          label="Enabled"
          type="blank"
          color="blue"
          size={size}
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case false:
      return (
        <BasicSmallFlag
          label="Disabled"
          type="blank"
          color="grey"
          size={size}
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
  }
};

export const PaymentMethodStatusFlag = (isActive: boolean): JSX.Element => {
  return isActive ? (
    <BasicFlag
      label="In Use"
      type="solid"
      color="green"
      style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
    />
  ) : (
    <></>
  );
};

export const NotificationTypeFlag = (
  type: NotificationType,
  size: TBasicSmallFlagSizeType = 'small',
): JSX.Element => {
  switch (type) {
    case NotificationType.email:
      return (
        <BasicSmallFlag
          label="Email"
          color="blue"
          type="blank"
          size={size}
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    case NotificationType.sms:
      return (
        <BasicSmallFlag
          label="SMS"
          color="green"
          type="blank"
          size={size}
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        />
      );
    default:
      return <></>;
  }
};

export const GatewayStatusFlag = (status: boolean, style?: TStyle): JSX.Element => {
  switch (status) {
    case true:
      return <BasicFlag label="ACTIVE" color="green" type="blank" style={style} />;
    case false:
      return <BasicFlag label="INACTIVE" color="grey" type="blank" style={style} />;
    default:
      return <></>;
  }
};

export const GatewayDefaultFlag = (is_default: boolean, style?: TStyle): JSX.Element => {
  switch (is_default) {
    case true:
      return <BasicFlag label="DEFAULT" color="blue" type="solid" style={style} />;
    default:
      return <></>;
  }
};

export const DeliveryMethodDefaultFlag = (
  is_default: boolean,
  style?: TStyle,
): JSX.Element => {
  switch (is_default) {
    case true:
      return <BasicFlag label="DEFAULT" color="blue" type="solid" style={style} />;
    default:
      return <BasicFlag label="NOT DEFAULT" color="grey" type="solid" style={style} />;
  }
};

export const DeliveryStatusFlag = (status: string, style?: TStyle): JSX.Element => {
  switch (status) {
    case 'active':
      return <BasicFlag label="ACTIVE" color="blue" type="solid" style={style} />;
    default:
      return <BasicFlag label="INACTIVE" color="grey" type="solid" style={style} />;
  }
};

export const FulfillmentCenterStatusFlag = (
  status: boolean,
  style?: TStyle,
): JSX.Element => {
  switch (status) {
    case true:
      return <BasicFlag label="ACTIVE" color="green" type="solid" style={style} />;
    case false:
      return <BasicFlag label="INACTIVE" color="grey" type="solid" style={style} />;
    default:
      return <></>;
  }
};

export const GiftCardStatusFlag = (status: string, style?: TStyle): JSX.Element => {
  switch (status) {
    case 'claimed':
      return <BasicFlag label="CLAIMED" color="blue" type="solid" style={style} />;
    case 'pending':
      return <BasicFlag label="PENDING" color="orange" type="solid" style={style} />;
    case 'new':
      return <BasicFlag label="AVAILABLE" color="green" type="solid" style={style} />;
    case 'voided':
      return <BasicFlag label="VOIDED" color="grey" type="solid" style={style} />;
    default:
      return <></>;
  }
};

export const WebhookStatusFlag = (status: boolean, style?: TStyle): JSX.Element => {
  switch (status) {
    case true:
      return <BasicFlag label="ACTIVE" color="green" type="blank" style={style} />;
    case false:
      return <BasicFlag label="INACTIVE" color="grey" type="blank" style={style} />;
    default:
      return <></>;
  }
};

export const PluginStatusFlag = (
  status?: boolean | null,
  style?: TStyle,
): JSX.Element => {
  switch (status) {
    case true:
      return <BasicFlag label="ENABLED" color="green" type="blank" style={style} />;
    case false:
      return <BasicFlag label="DISABLED" color="grey" type="blank" style={style} />;
    default:
      return <BasicFlag label="DISABLED" color="grey" type="blank" style={style} />;
  }
};
export const SubscriptionOffersStatusFlag = (
  status: string,
  style?: TStyle,
): JSX.Element => {
  switch (status) {
    case 'active':
      return <BasicFlag label="ACTIVE" color="green" type="blank" style={style} />;
    case 'archived':
      return <BasicFlag label="ARCHIVED" color="red" type="blank" style={style} />;
    case 'pending':
      return <BasicFlag label="PENDING" color="orange" type="solid" style={style} />;
    default:
      return <></>;
  }
};
