import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import { IEpSubsCompositeDBCustomerValue } from '../../models/';

export const FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'FETCH_CUSTOMER_DATA_SUCCESS';
export const FETCH_CUSTOMER_DATA_FAILURE = 'FETCH_CUSTOMER_DATA_FAILURE';
export const SAVE_CUSTOMER_DATA_SUCCESS = 'SAVE_CUSTOMER_DATA_SUCCESS';
export const SAVE_LATEST_CUSTOMER_SEARCH_PARAM = 'SAVE_LATEST_CUSTOMER_SEARCH_PARAM';
// export const ARCHIVE_CUSTOMER = 'ARCHIVE_CUSTOMER';
// export const ARCHIVE_CUSTOMER_FAILURE = 'ARCHIVE_CUSTOMER_FAILURE';

export type IFetchCustomerDataAction = {
  type: typeof FETCH_CUSTOMER_DATA;
  id: number;
  refresh?: boolean;
};

export type IFetchCustomerDataSuccessAction = {
  type: typeof FETCH_CUSTOMER_DATA_SUCCESS;
  id: number;
  customerData: IEpSubsCompositeDBCustomerValue;
};

export type ISaveLatestCustomerSearchParamAction = {
  type: typeof SAVE_LATEST_CUSTOMER_SEARCH_PARAM;
  loc: TLocationRecord;
};

// export type IArchiveCustomerAction = {
//   type: typeof ARCHIVE_CUSTOMER;
//   id: number;
//   archive?: boolean;
// };

export type epSubsCustomerActionTypes =
  | IFetchCustomerDataAction
  | IFetchCustomerDataSuccessAction
  | ISaveLatestCustomerSearchParamAction;
