import { atom, atomFamily } from 'recoil';

/**
 * An atom that stores the fresh Request ID for Role list
 */
export const roleListRequestIDAtom = atom<number>({
  key: 'roleListRequestIDAtom',
  default: 0,
});

/**
 * An atomFamily that stores the refresh Request ID for each role
 */
export const roleRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'roleRefreshRequestIDAtoms',
  default: 0,
});
