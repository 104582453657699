import axios from 'axios';
import { TEpSubsDBResponseOk } from 'src/models/epSubsDBGenTypes.js';
import { hostUrl } from '../config.js';
import { getSortSQLStrFromSortingItems, TSortingItem } from './epSubsServiceUtils';
import {
  IEpSubsDBPluginPost,
  IEpSubsDBPluginValue,
  TEpSubsDBPluginValueKeys,
} from 'src/models/index.js';

// *********************************************** //
//                Plugin Api Service            //
// *********************************************** //

export const genPluginListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBPluginValueKeys>> = [],
  simpleQuery?: string,
): Promise<TEpSubsDBResponseOk<IEpSubsDBPluginValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBPluginValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/plugin?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
        ].join('&'),
    )
    .then(response => {
      return {
        status: 'ok',
        total: response.data.length,
        data: response.data,
      };
    });
};

export const getAllPluginList = async (): Promise<IEpSubsDBPluginValue[]> => {
  return axios.get(`${hostUrl}/plugin`).then(response => {
    return response.data ?? [];
  });
};
export const genCreatePlugin = async (data: IEpSubsDBPluginPost): Promise<number> => {
  return axios.post(`${hostUrl}/plugin`, data).then(response => {
    return response.data.data;
  });
};

export const genPluginData = async (id: number): Promise<IEpSubsDBPluginValue> => {
  return axios.get(`${hostUrl}/plugin/${id}`).then(response => {
    return response.data;
  });
};

export const genEditPlugin = async (
  id: number,
  data: IEpSubsDBPluginPost,
): Promise<boolean> => {
  return axios.patch(`${hostUrl}/plugin/${id}`, data).then(() => {
    return true;
  });
};

type toggleType = {
  description?: string | null;
  config?: string | null;
  enabled: boolean;
};
export const genTogglePlugin = async (id: number, data: toggleType): Promise<boolean> => {
  return axios.patch(`${hostUrl}/plugin/${id}`, data).then(() => {
    return true;
  });
};

export const genPluginNameOption = async (): Promise<string[]> => {
  return axios.get(`${hostUrl}/plugin/configurable-name`).then(response => {
    return response.data;
  });
};
