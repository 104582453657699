import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import style from './EpSubsFormComponents.module.scss';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { TUseFormSetValue } from './EpSubsFormComponents';
import { extractInitialUpperCase } from 'src/utilities/stringUtility';
import * as Popper from 'popper.js';
import 'react-datepicker/dist/react-datepicker.css';
import { DEBUGGING, TIMEZONE } from 'src/config';
import { LOCALE } from 'src/config';
import { ELocale } from 'src/models';
import { localTimeZone, otherCountryLocalTimeZone } from 'src/models/i18n';
import { EpIcon } from 'src/assets';
// const START_OF_SUBS = '2018-01-01';
// const END_OF_EPOCH = '2100-02-28';
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30;
export const MONTHS_IN_QUARTER = 3;
export const DAYS_IN_QUARTER = MONTHS_IN_QUARTER * DAYS_IN_MONTH;
export const QUARTERS_IN_HALF = 2;
export const DAYS_IN_HALF = QUARTERS_IN_HALF * DAYS_IN_QUARTER;
export const HALVES_IN_YEAR = 2;
export const DAYS_IN_YEAR = HALVES_IN_YEAR * DAYS_IN_HALF;
export const SEC_IN_DAY = 3600 * 24;
export const SEC_IN_MIN = 60;
export const MILLISECOND_IN_SEC = 1000;
export const MILLISECOND_IN_Min = 60000;

type Props = {
  register: any;
  control: Control;
  controlId?: string;
  setValue: TUseFormSetValue<any>;
  namePath?: string;
  name: string;
  placeholderText?: string;
  // Unixtime without milliseconds
  defaultDate?: number;
  // currentValues?: any;
  minDate?: Date;
  maxDate?: Date;
  excludeDates?: Date[];
  onChange?: (value: number | null) => void;
  required?: boolean;
  validate?: () => boolean;
  // Can be used to trigger validation
  onBlur?: () => void;
  disabled?: boolean;
  showTimeSelect?: boolean;
  validateMessage?: string;
  popperPlacement?: Popper.Placement;
  filterPassedTime?: (date: Date) => boolean;
};

export const ESFormDateInput = ({
  register,
  control,
  controlId,
  setValue,
  namePath = '',
  name,
  defaultDate,
  minDate,
  maxDate,
  excludeDates,
  onChange,
  required,
  validate,
  onBlur,
  disabled = false,
  showTimeSelect,
  popperPlacement,
  validateMessage,
  filterPassedTime,
}: Props): JSX.Element => {
  const inputRef = useRef<ReactDatePicker | null>(null);
  // Read the default value from Parent
  let date: Date | undefined;
  let setDate: Dispatch<SetStateAction<Date | undefined>>;
  if (!!defaultDate) {
    [date, setDate] = useState<Date | undefined>(unixtimeToDate(defaultDate));
  } else {
    [date, setDate] = useState<Date | undefined>();
  }

  let rules = {};
  if (required) {
    rules = { required: 'Date is required' };
  }
  if (validate) {
    rules = { ...rules, validate };
  }
  DEBUGGING &&
    console.log(
      `ESFormDateInput value check: ${namePath}${name}:`,
      `\n check  date: ${date}`,
      `\n defaultDate: ${defaultDate && unixtimeToDate(defaultDate)}`,
      `\n   min  date: ${minDate}`,
      `\n   max  date: ${maxDate}`,
      control,
    );
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  return (
    // ******************* Boiler Plate for single component Test only: ******************* //
    // <form onSubmit={handleSubmit(onSubmit)}>
    // ******************* Boiler Plate for single component Test only: ******************* //
    <>
      <Controller
        name={`${namePath}${name}`}
        control={control}
        ref={register()}
        rules={rules}
        // Make the time set shift to current time of a day instead of midnight
        defaultValue={defaultDate}
        onFocus={() => {
          if (inputRef.current !== null) inputRef.current.setFocus();
        }}
        render={(props: ControllerRenderProps<Record<string, any>>) => {
          return (
            <div className={style.form_date_picker} style={{ display: 'flex' }}>
              <ReactDatePicker
                id={controlId}
                {...props}
                ref={ref => (inputRef.current = ref)}
                className={`input p-1`}
                wrapperClassName="w-100"
                placeholderText={'Select a date'}
                showYearDropdown
                minDate={minDate}
                maxDate={maxDate}
                excludeDates={excludeDates}
                // The value in the Date format
                selected={date && localTimeZone(date, showTimeSelect)}
                value={date && getPaddingForDate(date, showTimeSelect)}
                showDisabledMonthNavigation
                onChange={(date: Date) => {
                  const newDateValue = showTimeSelect
                    ? dateToUnixtime(otherCountryLocalTimeZone(date))
                    : getSameTimeForGivenDate(otherCountryLocalTimeZone(date));
                  const valueToSubmit = newDateValue > 0 ? newDateValue : null;
                  DEBUGGING &&
                    console.log(
                      'value to submit:',
                      '\nunix number to submit',
                      valueToSubmit,
                      '\ndate from calendar',
                      date,
                      '\ndate local time zone',
                      localTimeZone(date),
                      '\ndate other country local time zone',
                      otherCountryLocalTimeZone(date),
                    );
                  setValue(`${namePath}${name}`, valueToSubmit);
                  setDate(date);
                  // This can be used to update a local state defined in the container calling this
                  onChange && onChange(valueToSubmit);
                  setOpenCalendar(false);
                }}
                showTimeSelect={showTimeSelect}
                timeIntervals={1}
                onBlur={() => {
                  props.onBlur();
                  // Onblur to trigger form validation
                  if (onBlur) onBlur();
                  setOpenCalendar(false);
                }}
                disabled={disabled}
                open={openCalendar}
                // error validation will be triggered after select a date
                onSelect={() => {
                  if (onBlur) onBlur();
                  setOpenCalendar(false);
                }}
                popperPlacement={popperPlacement}
                calendarClassName={style.flex_row_calendar}
                filterTime={filterPassedTime}
                dateFormat="MM/dd/yyyy h:mm aa"
                isClearable
                onKeyDown={e => {
                  e.preventDefault();
                }}
              />
              <EpIcon
                name="calendar"
                style={{ margin: '0px', marginTop: '5px' }}
                role="button"
                onClick={() => setOpenCalendar(true)}
              />
            </div>
          );
        }}
      />
      {validate && validate() === false ? (
        <div className={style.error}>{validateMessage}</div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export const getPaddingForDate = (date: Date, showTimeSelect = false) => {
  //May 04 2023 06:00:00 GMT-0400 (Eastern Daylight Time)
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear() +
    (showTimeSelect ? ' ' + date.toLocaleTimeString(LOCALE as ELocale) : '')
  );
};

export const getNewPaddingForDate = (date: Date) => {
  return (
    date.toLocaleString(`${LOCALE as ELocale}`, { month: 'short' }) +
    '.' +
    date.getDate() +
    '.' +
    date.getFullYear()
  );
};

// *********************************************** //
//         Utilities To Get Same Time              //
// *********************************************** //

const getCurrentTime = (): number => {
  const timeInSec = Math.round(new Date().getTime() / MILLISECOND_IN_SEC);
  return timeInSec - getDayStartTimeFromUnixtimeInSec(timeInSec);
};

//Convert the current local time with UTC time
export const getDateWithUTCStartTime = (date: Date): Date => {
  return new Date(
    [
      `${date.toLocaleString('en-US', {
        month: 'long',
        timeZone: 'UTC',
      })} ${date.getUTCDate()}, ${date.getUTCFullYear()}`,
      // getTimeZoneStr(date),
    ].join(' '),
  );
};

export const formUTCTimeByYearMonthDay = (date: Date): Date => {
  return new Date(date.getTime() - date.getTimezoneOffset() * MILLISECOND_IN_Min);
};

export const getDayStartTimeFromUnixtimeInSec = (dateInUnixtimeInSec: number): number => {
  const offsetTimeInSec =
    new Date(dateInUnixtimeInSec * MILLISECOND_IN_SEC).getTimezoneOffset() * SEC_IN_MIN;
  const normalizedTimeInSec = dateInUnixtimeInSec - offsetTimeInSec;
  const normalizedDayStartTimeInSec =
    Math.floor(normalizedTimeInSec / SEC_IN_DAY) * SEC_IN_DAY;
  const localTimeDayStartTimeInSec = normalizedDayStartTimeInSec + offsetTimeInSec;
  return localTimeDayStartTimeInSec;
};

export const getSameTimeFromGivenUnixtimeInSec = (dateInUnixtimeInSec: number): number =>
  dateInUnixtimeInSec + getCurrentTime();

export const getDayStartTime = (date: Date): number => {
  const timeInSec = Math.round(Date.parse(date.toString())) / MILLISECOND_IN_SEC;
  return timeInSec;
};

export const getSameTimeForGivenDate = (date: Date): number =>
  getDayStartTime(date) + getCurrentTime();

// *********************************************** //
//         Utilities For Time Conversion           //
// *********************************************** //

export const unixtimeToDate = (time: number): Date => new Date(time * MILLISECOND_IN_SEC);

export const dateToUnixtime = (date: Date): number => {
  return Math.round(date.getTime() / MILLISECOND_IN_SEC);
};

export const utcToUnixTimestamp = (time: string): number => {
  return Math.floor(new Date(`${time}`).getTime() / 1000);
};

export const toUnixTimeWithoutTime = (unixTimeWithTime: number): number => {
  return Math.floor(unixTimeWithTime / 86400) * 86400;
};

export const parseUnixTime = (datetimeStr: string): number =>
  dateToUnixtime(new Date(datetimeStr));

export const getQuarter = (date: Date): number => {
  const monthValue = date.getMonth();
  return Math.round(monthValue / MONTHS_IN_QUARTER) + 1;
};

export const getHalf = (date: Date): number => {
  const monthValue = date.getMonth();
  return Math.round(monthValue / (MONTHS_IN_QUARTER * QUARTERS_IN_HALF)) + 1;
};

export const unixtimeToDateWithoutTime = (time: number): string =>
  new Date(time * MILLISECOND_IN_SEC).toISOString().split('T')[0];

export const unixtimeWithoutTime = (time: number): string =>
  new Date(time).toISOString().split('T')[0];

// *********************************************** //
//         Utilities For Time Operation            //
// *********************************************** //

export const addDays = (date: Date, days: number): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);

export const addMonths = (date: Date, months: number): Date =>
  new Date(date.getFullYear(), date.getMonth() + months, date.getDate());

export const addYears = (date: Date, years: number): Date =>
  new Date(date.getFullYear() + years, date.getMonth(), date.getDate());

export const diffInDays = (laterDate: Date, earlierDate: Date): number => {
  const timeDiff = laterDate.getTime() - earlierDate.getTime();
  const daysDiff = Math.round(timeDiff / (SEC_IN_DAY * MILLISECOND_IN_SEC));
  return daysDiff;
};

export const isDateInTheSameDay = (dateA: Date, dateB: Date): boolean => {
  return getDayStartTime(dateA) === getDayStartTime(dateB);
};

export const isUnixTimeInTheSameDay = (unixTimeA: number, unixTimeB: number): boolean => {
  return (
    getDayStartTimeFromUnixtimeInSec(unixTimeA) ===
    getDayStartTimeFromUnixtimeInSec(unixTimeB)
  );
};

export const isDataRecordInRange = (
  dateToCheck: Date,
  startDate: Date,
  endDate: Date,
): boolean => {
  return dateToCheck <= endDate && dateToCheck >= startDate;
};

// *********************************************** //
//         Utilities For Time Format               //
// *********************************************** //
export const getTimeZoneStr = (date: Date, useShortName = true): string => {
  const matchTimeZone = date.toString().match(/\(([A-Za-z\s].*)\)/);
  const timeZone = matchTimeZone ? matchTimeZone[1] : '';
  return useShortName ? extractInitialUpperCase(timeZone) : timeZone;
};

/**
 * @returns MMM-dd-YYYY HH:MM ZZZ
 * (e.g.: Oct-31-2021 14:45)
 */
export const getLocalDisplayTime = (date: Date): string =>
  [
    `${date.toLocaleString(LOCALE as ELocale, {
      month: 'short',
      timeZone: TIMEZONE,
    })}/${date.getDate()}/${date.getFullYear()}`,
    `${date.getHours().toLocaleString(LOCALE as ELocale, {
      style: 'decimal',
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${date.getMinutes().toLocaleString(LOCALE as ELocale, {
      style: 'decimal',
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`,
  ].join(' ');

export const getChartDateStr = (
  date: Date,
  mode: 'display' | 'key' | 'compatible_key' = 'display',
): string => {
  switch (mode) {
    case 'display':
      return [
        `${date.toLocaleString(LOCALE as ELocale, {
          month: 'short',
          timeZone: TIMEZONE,
        })}.${date.getDate()}.${date.getFullYear()}`,
      ].join(' ');
    case 'key':
      return [
        `${date.toLocaleString(LOCALE as ELocale, {
          month: 'short',
          timeZone: TIMEZONE,
        })}.${date.getUTCDate()}.${date.getUTCFullYear()}`,
        // getTimeZoneStr(date),
      ].join(' ');
    case 'compatible_key':
      // In order to support Safari, Firefox etc.
      // https://stackoverflow.com/questions/3257460/new-date-is-working-in-chrome-but-not-firefox
      const compatible_key = `${date.toLocaleString('en-US', {
        month: 'long',
        timeZone: TIMEZONE,
      })} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
      DEBUGGING &&
        console.log('Datepicker getChartDateStr chart_compatible_key', compatible_key);
      return compatible_key;
  }
};

export const getChatDateNum = (date: string): string => {
  const arrayDate = date.split('.');
  return arrayDate[0] + ',' + arrayDate[1] + ',' + arrayDate[2];
};

export const getChartDate = (date: Date): Date =>
  new Date(getChartDateStr(date, 'compatible_key'));

// export const getChartDate = (date: Date): Date => new Date(getChartDateStr(date, 'key'));

export const getChartMonthStr = (
  date: Date,
  mode: 'display' | 'key' | 'compatible_key' = 'display',
): string => {
  switch (mode) {
    case 'display':
      return [
        `${date.toLocaleString(LOCALE as ELocale, {
          month: 'short',
          timeZone: TIMEZONE,
        })}.${date.getFullYear()}`,
      ].join(' ');
    case 'key':
      return `${date.toLocaleString(LOCALE as ELocale, {
        month: 'short',
        timeZone: TIMEZONE,
      })}.${date.getFullYear()}`;
    case 'compatible_key':
      // In order to support Safari, Firefox etc.
      // https://stackoverflow.com/questions/3257460/new-date-is-working-in-chrome-but-not-firefox
      return `${date.toLocaleString(LOCALE as ELocale, {
        month: 'long',
        timeZone: TIMEZONE,
      })}, ${date.getFullYear()}`;
  }
};

export const getChartQuarterStr = (date: Date): string =>
  `${date.getFullYear()}.Q${getQuarter(date)}`;

export const getChartHalfStr = (date: Date): string =>
  `${date.getFullYear()}.H${getHalf(date)}`;

export const getChartYearStr = (date: Date): string => `${date.getFullYear()}`;
