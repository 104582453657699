import {
  epSubsCustomerActionTypes,
  FETCH_CUSTOMER_DATA_SUCCESS,
  SAVE_LATEST_CUSTOMER_SEARCH_PARAM,
} from './epSubsCustomerTypes';
import { CustomersState, RootState } from '../rootState';
import { IEpSubsCompositeDBCustomerValueInList } from '../../models/';
import { DEBUGGING } from 'src/config';

const epSubsCustomerReducer = (
  state: RootState['customers'] = {
    customerList: new Map<number, IEpSubsCompositeDBCustomerValueInList>(),
    latestLocation: { search: '' },
  },
  action: epSubsCustomerActionTypes,
): CustomersState => {
  switch (action.type) {
    case FETCH_CUSTOMER_DATA_SUCCESS:
      DEBUGGING && console.log('FETCH_CUSTOMER_DATA_SUCCESS for id = ', action.id, state);
      const newCustomerList = new Map(state.customerList);
      return {
        ...state,
        customerList: newCustomerList.set(action.id, action.customerData),
      };
    case SAVE_LATEST_CUSTOMER_SEARCH_PARAM:
      DEBUGGING && console.log('SAVE_LATEST_CUSTOMER_SEARCH_PARAM: ', action.loc);
      return {
        ...state,
        latestLocation: action.loc,
      };
    default:
      return state;
  }
};

export default epSubsCustomerReducer;
