import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from 'src/components/tables/EpFullFeaturedTable';
import * as H from 'history';
import { offsetToPageId } from 'src/components/tables/EpFullFeaturedTable/Pagination/Pagination';
import {
  IEpSubsDBWebhookValue,
  IEpSubsWebhookLogValue,
  TEpSubsDBWebhookTypeValue,
  TEpSubsDBWebhookValueKeys,
} from 'src/models';
import { arrayToMap } from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import { CURRENT_ID_ATOM_DEFAULT_VALUE } from '../recoilConstants';
import {
  genWebhookData,
  genWebhookListData,
  getAllWebhookList,
  getWebhookLogs,
  getWebhookTypes,
} from 'src/services/epSubsWebhooksService';
import { DEBUGGING } from 'src/config';

/**
 * An atom that stores the fresh Request ID for webhook list
 */
export const webhookListRequestIDAtom = atom<number>({
  key: 'webhookListRequestIDAtom',
  default: 0,
});

/**
 * An atomFamily that stores the refresh Request ID for each webhook
 */
export const webhookRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'webhookRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An atomFamily that stores the all logs data for each webhook
 */
export const webhookLogsRequestIDAtoms = atomFamily<number, number>({
  key: 'webhookLogsRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores webhook details for each webhook
 */
export const webhookSelectors = selectorFamily<IEpSubsDBWebhookValue, number>({
  key: 'webhookSelectors',
  get:
    webhookId =>
    async ({ get }) => {
      get(webhookRefreshRequestIDAtoms(webhookId)); // Add request ID as a dependency
      const webhookData = await genWebhookData(webhookId);
      DEBUGGING && console.log('recoil, webhookSelectors webhookData:', webhookData);
      return webhookData;
    },
});

/**
 * An selector that stores webhook list
 */
export const webhookSelector = selector<IEpSubsDBWebhookValue[]>({
  key: 'webhookListSelector',
  get: async ({ get }) => {
    get(webhookListRequestIDAtom);
    const webhookList = await getAllWebhookList();
    return webhookList;
  },
});

/**
 * An selector that stores a map from webhook.id to webhook Object
 */
export const webhookMapSelector = selector<Map<number, IEpSubsDBWebhookValue>>({
  key: 'webhookMapSelector',
  get: async ({ get }) => {
    const webhookList = get(webhookSelector);
    return arrayToMap(webhookList, webhook => webhook.id);
  },
});

/**
 * An selector that stores the default webhook
 */
export const defaultWebhookSelector = selector<IEpSubsDBWebhookValue>({
  key: 'defaultWebhookSelector',
  get: async ({ get }) => {
    const webhookList = get(webhookSelector);
    if (webhookList.length === 0) {
      const message = 'No default webhook set! Please set on backend.';
      putNoty({ type: 'error', text: message });
      throw new Error(message);
    }
    return webhookList[0];
  },
});

// *********************************************** //
//             webhook List Page States            //
// *********************************************** //
export const DEFAULT_WEBHOOK_SEARCH_PARAMETER = '';

/**
 * An atom that stores the latest webhook search param
 */
export const latestWebhookSearchParamAtom = atom<TLocationRecord>({
  key: 'latestWebhookSearchParamAtom',
  default: { search: DEFAULT_WEBHOOK_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for webhook list
 */
export const webhookSearchRequestIDAtom = atom<number>({
  key: 'webhookSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for webhook list
 */
export const webhookListSelector = selector<ListState<IEpSubsDBWebhookValue>>({
  key: 'webhookIdListSelector',
  get: async ({ get }) => {
    const requestID = get(webhookSearchRequestIDAtom); // Add request ID as a dependency
    const webhookSearchParam = get(latestWebhookSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, webhookBasicListSelector webhookSearchParam',
        webhookSearchParam,
        'requestID',
        requestID,
      );

    // fetch webhooks
    if (webhookSearchParam.ready && webhookSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBWebhookValueKeys>(
        webhookSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const offset = (page - 1) * perPage;
      try {
        const response = await genWebhookListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'id', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
        );

        DEBUGGING && console.log('recoil, webhookBasicListSelector response', response);
        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

// *********************************************** //
//           webhook Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected webhook id
 */
export const currentWebhookIdAtom = atom<number | null>({
  key: 'currentWebhookIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected webhook details
 */
export const currentWebhookSelector = selector<IEpSubsDBWebhookValue | undefined>({
  key: 'currentWebhookSelector',
  get: ({ get }) => {
    const id = get(currentWebhookIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(webhookSelectors(id));
    }
  },
});

/**
 * An selector that stores All Webhook Types
 */
export const allWebhookEventTypesSelector = selector<TEpSubsDBWebhookTypeValue[]>({
  key: 'allWebhookTypesSelector',
  get: async ({ get }) => {
    get(webhookListRequestIDAtom);
    const allWebhookEventTypes = await getWebhookTypes();
    return allWebhookEventTypes;
  },
});

/**
 * An selectorFamily that stores webhook details for each webhook
 */
export type webhookLogType = {
  webhookId: number;
  limit: number;
};
export const webhookLogsSelectors = selectorFamily<
  IEpSubsWebhookLogValue[],
  webhookLogType
>({
  key: 'webhookLogsSelectors',
  get:
    (data: webhookLogType) =>
    async ({}) => {
      const webhookLogsData = await getWebhookLogs(data.webhookId, data.limit);
      DEBUGGING && console.log('recoil, webhook logs data:', webhookLogsData);
      return webhookLogsData;
    },
});
