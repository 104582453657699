// import React from 'react';
import * as H from 'history';

export type TPeriodEnum = 'Day' | 'Week' | 'Month' | 'Year';

export const onEditSubscriptionBtnClick = (history: H.History, id: number): void => {
  history.push(`/subscriptions/edit-subscription/${id}`);
};

export const onCreateBtnClick = (history: H.History): void => {
  history.push('/subscriptions/create-subscription');
};

export const onCreateGiftBtnClick = (history: H.History): void => {
  history.push('/subscriptions/create-gift-subscription');
};

export const SUBSCRIPTION_CHANNEL_SPLITTER = ', ';
export const SUBSCRIPTION_DETAIL_REFRESH_WAITING_TIME = 200;
// interface IArchiveButtonProps {
//   id: number;
//   archive: boolean;
//   dispatch: React.Dispatch<any>;
// }

// export const onArchiveBtnClick = ({
//   id,
//   archive,
//   dispatch,
// }: IArchiveButtonProps): void => {
//   dispatch({ type: ARCHIVE_SUBSCRIPTION, id, archive });
// };

// export const archiveBtnText = (status: TSubscriptionStatus | string): string => {
//   return status === 'Active' ? 'Archive Subscription' : 'Unarchive Subscription';
// };
