import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBSubscriptionValue,
  TEpSubsDBSubscriptionValueKeys,
  TEpSubsDBResponseOk,
  IEpSubsDBSubscription_activityValue,
  IEpSubsDBSubscription_commentValue,
  IEpSubsCompositeDBSubscriptionValue,
  IEpSubsCompositeDBSubscriptionPost,
  IEpSubsDBSubscription_channelValue,
  IEpSubsSubscriptionPlanChangePreview,
  IEpSubsSubscriptionRenewPreview,
  TFilterData,
  IEpSubsCompositeDBSubscriptionApiPost,
  IEpSubsPlanChangePostData,
  IEpSubsCreateSubscriptionPreview,
  IEpSubsDBSubscription_addressValue,
  INewTempAddressFormData,
  IEpSubsReactivePostData,
  IEpSubsTagsListValue,
} from '../models';
import { EMPTY_EXPORT_ERROR, TSortingItem } from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { IPauseSubFormData } from '../containers/subscriptions/subscriptionDetail/subscriptionActions/EpSubsPauseSubModal';
import { ICancelSubFormData } from 'src/containers/subscriptions/subscriptionDetail/subscriptionActions/EpSubsCancelSubModal';
import { IResumeSubFormData } from '../containers/subscriptions/subscriptionDetail/subscriptionActions/EpSubsResumeSubModal';
import {
  IChangeNextBillingFormDataPost,
  ISubChangeDeliveryMethodFormData,
} from 'src/containers/subscriptions/subscriptionDetail/EpSubsSubscriptionDetailWrapper';
import { IChangeCancelDateFormData } from 'src/containers/subscriptions/subscriptionDetail/subscriptionActions/EpSubsChangeCancelDateForm';
import { IChangeStartDateSubFormData } from 'src/containers/subscriptions/subscriptionDetail/subscriptionActions/EpSubsChangeStartDateForm';
import { ESimpleSearchFields } from 'src/js/constants/subs';
import { INewAddressFormData } from '../models';
import { putNoty } from '../../src/utilities/epSubsNoty';
import { AddCouponFormData } from 'src/components/coupon/EpSubsAddCouponInDetailPage.tsx/EpSubsAddCouponInDetailPage.js';
import { ISubPauseDeliveryMethodFormData } from 'src/containers/subscriptions/subscriptionDetail/subscriptionActions/EpSubsPauseDeliveryMethodModal.js';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';
import { ITagsFormData } from 'src/components/modals/EpSubsTagModal.js';
import { IRenewSubFormData } from 'src/containers/subscriptions/subscriptionDetail/subscriptionActions/EpSubsBillFutureRenewSubscriptionModal.js';

export const genSubscriptionListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: TSortingItem<TEpSubsDBSubscriptionValueKeys> | null = null,
  simpleQuery = '',
  simpleQueryFieldKey = ESimpleSearchFields.ALL,
): Promise<TEpSubsDBResponseOk<IEpSubsDBSubscriptionValue>> => {
  return axios
    .post(`${hostUrl}/subscriptions/search`, {
      keyword: simpleQuery,
      element: simpleQueryFieldKey,
      sort_field: !!sortingItems
        ? {
            field: sortingItems?.key,
            // Descending is the default value if not provided
            asc: sortingItems?.ascending === 'ASC' ? true : false,
          }
        : null,
      page_index: pageIdx - 1,
      per_page: perPage,
    })
    .then(response => {
      return response.data;
    });
};

export const genExportSubscriptionList = async (
  filterMetaData: Pick<TFilterData, 'sort_field'>,
  simpleQuery = '',
  simpleQueryFieldKey: ESimpleSearchFields = ESimpleSearchFields.ALL,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/subscriptions/export-detail`, {
      keyword: simpleQuery,
      element: simpleQueryFieldKey,
      sort_field: filterMetaData.sort_field,
    })
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `SUBSCRIPTIONS-${unixtimeWithoutTime(Date.now())}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const genSubscriptionData = async (
  id: number,
): Promise<IEpSubsCompositeDBSubscriptionValue> => {
  return axios.get(`${hostUrl}/subscriptions/${id}`).then(response => {
    return response.data;
  });
};

export const genSubscriptionChannelData = async (): Promise<
  IEpSubsDBSubscription_channelValue[]
> => {
  return axios.get(`${hostUrl}/ref/sub-channels`).then(response => {
    return response.data;
  });
};

//WAITING FOR API TO BE CREATED
/*
export const genSubscriptionShippingData = async (
  id: number,
): Promise<IEpSubsDBSubscriptionValue> => {
  return axios
    .get(`${hostUrl}/subscriptions/shipping/${id}`)
    .then(response => {
      const payload = handleBackendResponseErrors<IEpSubsDBSubscriptionValue>({
        errorCheckForOKResponse: response.data.status === 'ok' && !response.data.data,
        successPayload: response.data.data,
        errorMessage: response.data.error,
      });
      return payload;
    })
    .catch(err => {
      throw handleDefaultExceptions(err);
    });
};
*/

export const genSubscriptionActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBSubscription_activityValue[]> => {
  return axios
    .get(`${hostUrl}/subscriptions/${id}/actlogs?limit=${limit}`)
    .then(response => {
      return response.data.data;
    });
};

export const genSubscriptionActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBSubscription_activityValue> => {
  return axios.get(`${hostUrl}/subscriptions/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genSubscriptionCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBSubscription_commentValue[]> => {
  return axios
    .get(`${hostUrl}/subscriptions/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreateSubscriptionComment = async (
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/subscriptions/comments/add`, data).then(response => {
    removeAllUploadedImage();
    return response.data.data;
  });
};

// export const genSampleSubscriptionData = async (id: string): Promise<IEpSubsDBSubscriptionValue> => {
//   const subscriptionData = sampleData.data.filter(record => String(record.id) === id)[0];
//   return subscriptionData;
// };

export const genCreateSubscription = async (
  data: IEpSubsCompositeDBSubscriptionApiPost,
  randomId: string,
): Promise<number | { url?: string; returnUrl?: string }> => {
  return axios
    .post<
      | { code: string; message: string; data: string }
      | { code: undefined; data: { id: number } }
    >(`${hostUrl}/subscriptions`, data, {
      headers: {
        'Idempotency-Key': randomId,
      },
    })
    .then(response => {
      if (response.data.code != undefined) {
        const message = `${response.data.message}`;
        putNoty({ type: 'error', text: message });
        const { url, return_url } = JSON.parse(response.data.data);
        return { url, returnUrl: return_url };
      } else {
        const newSubscriptionId = response.data.data.id;
        return newSubscriptionId;
      }
    });
};

export const genCreateSubscriptionPreview = async (
  data: IEpSubsCompositeDBSubscriptionApiPost,
): Promise<IEpSubsCreateSubscriptionPreview> => {
  return axios.post(`${hostUrl}/subscriptions/preview`, data).then(response => {
    return response.data;
  });
};

export const genAddSubscription = async (
  data: IEpSubsCompositeDBSubscriptionApiPost,
): Promise<number> => {
  return axios.post(`${hostUrl}/subscriptions/add`, data).then(response => {
    const newSubscriptionId = response.data.data.id;
    return newSubscriptionId;
  });
};

export const genSubscriptionPreviewPlanChange = async (
  id: number,
  data: IEpSubsPlanChangePostData,
): Promise<IEpSubsSubscriptionPlanChangePreview> => {
  return axios
    .patch<IEpSubsSubscriptionPlanChangePreview>(
      `${hostUrl}/subscriptions/${id}/preview`,
      data,
    )
    .then(response => response.data);
};

export const genSubscriptionPlanChange = async (
  id: number,
  randomId: string,
  data: IEpSubsPlanChangePostData,
): Promise<boolean> => {
  return axios
    .patch(`${hostUrl}/subscriptions/${id}`, data, {
      headers: {
        'Idempotency-Key': randomId,
      },
    })
    .then(() => true);
};

export const genSubscriptionChangeNextBilling = async (
  id: number,
  data: IChangeNextBillingFormDataPost,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/change-next-billing-date`, data)
    .then(() => {
      return true;
    });
};

export const genSubscriptionChangeCancelDate = async (
  id: number,
  data: IChangeCancelDateFormData,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/change-cancel-date`, data)
    .then(() => {
      return true;
    });
};

export const genEditSubscription = async (
  data: IEpSubsCompositeDBSubscriptionPost,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/subscriptions/update`, data).then(() => {
    return true;
  });
};

export const genPauseSubscription = async (
  id: number,
  data: IPauseSubFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/subscriptions/${id}/pause`, data).then(response => {
    return response.data;
  });
};

export const genChangeSubscriptionDeliveryMethod = async (
  id: number,
  data: ISubChangeDeliveryMethodFormData,
): Promise<boolean> => {
  return axios.patch(`${hostUrl}/subscriptions/${id}/delivery`, data).then(response => {
    return response.data;
  });
};

export const genPauseSubscriptionDeliveryMethod = async (
  id: number,
  data: ISubPauseDeliveryMethodFormData,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/delivery/pause`, data)
    .then(response => {
      return response.data;
    });
};

export const genResumeSubscription = async (
  id: number,
  data: IResumeSubFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/subscriptions/${id}/resume`, data).then(response => {
    return response.data;
  });
};

export const genCancelSubscription = async (
  id: number,
  data: ICancelSubFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/subscriptions/${id}/cancel`, data).then(response => {
    return response.data;
  });
};

export const genReactivateSubscriptionPreview = async (
  id: number,
  data: IEpSubsReactivePostData,
): Promise<IEpSubsSubscriptionPlanChangePreview> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/reactivate/preview`, data)
    .then(response => {
      return response.data;
    });
};

export const genReactivateSubscription = async (
  id: number,
  randomId: string,
  data: IEpSubsReactivePostData,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/reactivate`, data, {
      headers: {
        'Idempotency-Key': randomId,
      },
    })
    .then(response => {
      return response.data;
    });
};

export const genRenewSubscriptionPreview = async (
  id: number,
  data: IRenewSubFormData,
): Promise<IEpSubsSubscriptionRenewPreview> => {
  return axios
    .get(
      `${hostUrl}/subscriptions/${id}/bill-future-invoice-preview?cycles=${data.renewal_cycle_number}`,
    )
    .then(response => {
      return response.data.data;
    });
};

export const genRenewSubscription = async (
  id: number,
  data: IRenewSubFormData,
): Promise<number> => {
  return axios
    .post(
      `${hostUrl}/subscriptions/${id}/bill-future-renewal?cycles=${data.renewal_cycle_number}`,
    )
    .then(response => {
      return response.data.data;
    });
};

export const genChangeSubscriptionBillingCycles = async (
  id: number,
  data: number,
): Promise<number> => {
  return axios
    .patch(`${hostUrl}/subscriptions/${id}/remaining-billing-cycles`, {
      remaining_billing_cycles: data,
    })
    .then(response => {
      return response.data.data;
    });
};

export const genSetStartDateSubscription = async (
  id: number,
  randomId: string,
  data: IChangeStartDateSubFormData,
): Promise<number> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/change-start-date`, data, {
      headers: {
        'Idempotency-Key': randomId,
      },
    })
    .then(response => {
      return response.data.data;
    });
};

export const genRemoveCancellationSubscription = async (id: number): Promise<number> => {
  return axios.post(`${hostUrl}/subscriptions/${id}/removecancel`).then(response => {
    return response.data.data;
  });
};

export const genRemoveScheduledChangesSubscription = async (
  id: number,
): Promise<{ data: boolean } | { code: number; message: string; data: boolean }> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/remove-scheduled-change`)
    .then(response => {
      return response.data;
    });
};

export const genInsistRemoveAllScheduledChangesSubscription = async (
  id: number,
): Promise<number> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/remove-scheduled-change?confirmed=1`)
    .then(response => {
      return response.data.data;
    });
};

export const genRemoveScheduledPauseSubscription = async (
  id: number,
): Promise<{ data: boolean } | { code: number; message: string; data: boolean }> => {
  return axios.post(`${hostUrl}/subscriptions/${id}/removepause`).then(response => {
    return response.data;
  });
};

export const genRemoveScheduledSubscriptionDeliveryPause = async (
  id: number,
): Promise<number> => {
  return axios.get(`${hostUrl}/subscriptions/${id}/delivery/unpause`).then(response => {
    return response.data.data;
  });
};

export const genInsistRemoveScheduledPausedSubscription = async (
  id: number,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/removepause?confirmed=1`)
    .then(response => {
      return response.data.data;
    });
};

export const genAddCouponInDetailPage = async (
  id: number,
  formData: AddCouponFormData,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/add-coupons`, {
      coupon_id: formData.coupon_id,
      start_dt: formData.start_dt,
      end_dt: formData.end_dt,
    })
    .then(response => {
      return response.statusText;
    });
};

export const genAddPhaseCouponInDetailPage = async (
  id: number,
  formData: AddCouponFormData,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/add-coupons`, {
      coupon_id: formData.coupon_id,
      start_dt: formData.start_dt,
      end_dt: formData.end_dt,
    })
    .then(response => {
      return response.statusText;
    });
};

export const genRemoveCouponInDetailPage = async (
  subId: number,
  sub_coupon_id: number,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/subscriptions/${subId}/remove-coupons`, {
      sub_coupon_id: sub_coupon_id,
    })
    .then(response => {
      return response.statusText;
    });
};

export const genRemovePhaseCouponInDetailPage = async (
  subId: number,
  sub_coupon_id: number,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/subscriptions/${subId}/remove-coupons`, {
      sub_coupon_id: sub_coupon_id,
    })
    .then(response => {
      return response.statusText;
    });
};

export interface IAddShippingAddressRequestPayload {
  address: { id: number } | INewAddressFormData;
  address_type: string;
}

export const genAddShippingAddressSubscription = async (
  id: number,
  data: IAddShippingAddressRequestPayload,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/subscriptions/${id}/add-address`, data).then(() => {
    return true;
  });
};

export const genChangeAutoCollection = async (
  id: number,
  policy: string,
): Promise<boolean> => {
  return axios
    .put(`${hostUrl}/subscriptions/${id}/auto-collection/${policy}`)
    .then(() => {
      return true;
    });
};

export const changePaymentMethod = async (
  id: number,
  paymentMethodId: number,
): Promise<boolean> => {
  return axios
    .put(`${hostUrl}/subscriptions/${id}/payment-method/${paymentMethodId}`)
    .then(() => {
      return true;
    });
};
export const genRequestPaymentMethodForSubscription = async (
  id: number,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/subscriptions/${id}/request-payment-method`).then(() => {
    return true;
  });
};

export const addTempAddressForSubscription = async (
  subId: number,
  data: INewAddressFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/subscriptions/${subId}/temp-address`, data).then(() => {
    return true;
  });
};

export const genTempAddressForSubscription = async (
  subId: number,
): Promise<IEpSubsDBSubscription_addressValue[]> => {
  return axios.get(`${hostUrl}/subscriptions/${subId}/temp-address`).then(response => {
    return response.data.data;
  });
};

export const editTempAddressForSubscription = async (
  subId: number,
  tempAddressId: number,
  data: INewTempAddressFormData,
): Promise<boolean> => {
  return axios
    .put(`${hostUrl}/subscriptions/${subId}/temp-address/${tempAddressId}`, data)
    .then(() => {
      return true;
    });
};

export const deleteTempAddressForSubscription = async (
  subId: number,
  tempAddressId: number,
): Promise<boolean> => {
  return axios
    .delete(`${hostUrl}/subscriptions/${subId}/temp-address/${tempAddressId}`)
    .then(() => {
      return true;
    });
};

export const getSubscriptionTags = async (): Promise<IEpSubsTagsListValue[]> => {
  return axios.get(`${hostUrl}/subscriptions/tags`).then(response => {
    return response.data ?? [];
  });
};

export const getSubscriptionTagsById = async (
  id: number,
): Promise<IEpSubsTagsListValue[]> => {
  return axios.get(`${hostUrl}/subscriptions/${id}/tags`).then(response => {
    return response.data ?? [];
  });
};

export const editSubscriptionTags = async (
  id: number,
  data: ITagsFormData,
): Promise<IEpSubsTagsListValue[]> => {
  return axios
    .patch(`${hostUrl}/subscriptions/${id}/tags`, JSON.stringify(data.tags.join(',')))
    .then(response => {
      return response.data ?? [];
    });
};

export interface IEpSubsItemsValue {
  id: number;
  subscription_id?: number;
  billing_cycles?: number;
  entity_key?: string;
  entity_type?: string;
  quantity?: number;
  unit_price?: number;
  status?: string;
}

export const editSubscriptionScheduleItems = async (
  id: number,
  data?: IEpSubsItemsValue[],
): Promise<boolean> => {
  return axios
    .patch(`${hostUrl}/subscriptions/${id}/scheduled-items`, data)
    .then(response => {
      return response.data;
    });
};

export const addSubscriptionScheduleItems = async (
  id: number,
  data: Omit<IEpSubsItemsValue, 'id'>[],
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/subscriptions/${id}/scheduled-items`, data)
    .then(response => {
      return response.data;
    });
};
