import { atomFamily, selectorFamily } from 'recoil';
import {
  TEpSubsDBResponseOk,
  IEpSubsDBInvoiceValue,
  IEpSubsDBCredit_noteValue,
  IEpSubsDBTransactionValue,
  IEpSubsDBPromotional_creditValue,
  IEpSubsDBEmail_logApiValue,
  IEpSubsDBGift_cardValue,
} from '../../models';
import {
  genCreditNotes,
  genEmailLogs,
  genGiftCards,
  genInvoices,
  genPromotionalCredits,
  genTransactions,
} from '../../services';

import { TTabSource } from 'src/components/tabs/EpSubsTabList';
import { currentSubscriptionIdAtom, currentCustomerIdAtom } from '..';
import { DEBUGGING } from 'src/config';

export const REQUEST_NOT_READY = -1;
export const MIN_EFFECTIVE_REQUEST_ID = 0;
// *********************************************** //
//       Detail Page Invoices List States          //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each customer
 */
export const currentDetailPageInvoiceTabRefreshRequestIDAtoms = atomFamily<
  number,
  TTabSource
>({
  key: 'currentDetailPageInvoiceTabRefreshRequestIDAtoms',
  default: REQUEST_NOT_READY,
});

/**
 * An selectorFamily that stores Detail Page Invoice Tab data for current customer/subscription.
 */
export const currentDetailPageInvoiceTabSelectors = selectorFamily<
  TEpSubsDBResponseOk<IEpSubsDBInvoiceValue> | null,
  TTabSource
>({
  key: 'currentDetailPageInvoiceTabSelectors',
  get:
    source =>
    async ({ get }) => {
      const requestId = get(currentDetailPageInvoiceTabRefreshRequestIDAtoms(source)); // Enable Refresh by Hook
      let id: number | null;
      switch (source) {
        case 'customers':
          id = get(currentCustomerIdAtom);
          break;
        case 'subscriptions':
          id = get(currentSubscriptionIdAtom);
          break;
      }
      // Do not call API if the current[ Customer | Subscription ]IdAtom or the component has not been initiated yet.
      if (id === null || requestId === REQUEST_NOT_READY) return null;

      const invoiceData = await genInvoices(id, source);
      DEBUGGING &&
        console.log(
          'recoil, currentDetailPageInvoice invoiceData:',
          source,
          id,
          invoiceData,
        );
      return invoiceData;
    },
});

// *********************************************** //
//      Detail Page Credit Notes List States       //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each customer
 */
export const currentDetailPageCreditNoteTabRefreshRequestIDAtoms = atomFamily<
  number,
  TTabSource
>({
  key: 'currentDetailPageCreditNoteTabRefreshRequestIDAtoms',
  default: REQUEST_NOT_READY,
});

/**
 * An selectorFamily that stores Detail Page CreditNote Tab data for current customer/subscription.
 */
export const currentDetailPageCreditNoteTabSelectors = selectorFamily<
  TEpSubsDBResponseOk<IEpSubsDBCredit_noteValue> | null,
  TTabSource
>({
  key: 'currentDetailPageCreditNoteTabSelectors',
  get:
    source =>
    async ({ get }) => {
      const requestId = get(currentDetailPageCreditNoteTabRefreshRequestIDAtoms(source)); // Enable Refresh by Hook
      let id: number | null;
      switch (source) {
        case 'customers':
          id = get(currentCustomerIdAtom);
          break;
        case 'subscriptions':
          id = get(currentSubscriptionIdAtom);
          break;
      }
      // Do not call API if the current[ Customer | Subscription ]IdAtom or the component has not been initiated yet.
      if (id === null || requestId === REQUEST_NOT_READY) return null;

      const creditNoteData = await genCreditNotes(id, source);
      DEBUGGING &&
        console.log(
          'recoil, currentDetailPageCreditNote creditNoteData:',
          source,
          id,
          `requestID:${requestId}`,
          creditNoteData,
        );
      return creditNoteData;
    },
});

// *********************************************** //
//      Detail Page Transactions List States       //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each customer
 */
export const currentDetailPageTransactionTabRefreshRequestIDAtoms = atomFamily<
  number,
  TTabSource
>({
  key: 'currentDetailPageTransactionTabRefreshRequestIDAtoms',
  default: REQUEST_NOT_READY,
});

/**
 * An selectorFamily that stores Detail Page Transaction Tab data for current customer/subscription.
 */
export const currentDetailPageTransactionTabSelectors = selectorFamily<
  TEpSubsDBResponseOk<IEpSubsDBTransactionValue> | null,
  TTabSource
>({
  key: 'currentDetailPageTransactionTabSelectors',
  get:
    source =>
    async ({ get }) => {
      const requestId = get(currentDetailPageTransactionTabRefreshRequestIDAtoms(source)); // Enable Refresh by Hook
      let id: number | null;
      switch (source) {
        case 'customers':
          id = get(currentCustomerIdAtom);
          break;
        case 'subscriptions':
          id = get(currentSubscriptionIdAtom);
          break;
      }
      // Do not call API if the current[ Customer | Subscription ]IdAtom or the component has not been initiated yet.
      if (id === null || requestId === REQUEST_NOT_READY) return null;

      const transactionData = await genTransactions(id, source);
      DEBUGGING &&
        console.log(
          'recoil, currentDetailPageTransaction transactionData:',
          source,
          id,
          `requestID:${requestId}`,
          transactionData,
        );
      return transactionData;
    },
});

// *********************************************** //
//   Detail Page PromotionalCredits List States    //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each customer
 */
export const currentDetailPagePromotionalCreditTabRefreshRequestIDAtoms = atomFamily<
  number,
  Extract<TTabSource, 'customers'>
>({
  key: 'currentDetailPagePromotionalCreditTabRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores Detail Page PromotionalCredit Tab data for current customer.
 */
export const currentDetailPagePromotionalCreditTabSelectors = selectorFamily<
  TEpSubsDBResponseOk<IEpSubsDBPromotional_creditValue> | null,
  Extract<TTabSource, 'customers'>
>({
  key: 'currentDetailPagePromotionalCreditTabSelectors',
  get:
    source =>
    async ({ get }) => {
      const requestId = get(
        currentDetailPagePromotionalCreditTabRefreshRequestIDAtoms(source),
      ); // Enable Refresh by Hook
      let id: number | null;
      switch (source) {
        case 'customers':
          id = get(currentCustomerIdAtom);
          break;
      }
      // Do not call API if the currentCustomerIdAtom or the component has not been initiated yet.
      if (id === null || requestId === REQUEST_NOT_READY) return null;

      const promotionalCreditData = await genPromotionalCredits(id, source);
      DEBUGGING &&
        console.log(
          'recoil, currentDetailPagePromotionalCredit promotionalCreditData:',
          source,
          id,
          `requestID:${requestId}`,
          promotionalCreditData,
        );
      return promotionalCreditData;
    },
});

// *********************************************** //
//        Detail Page EmailLogs List States        //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each customer
 */
export const currentDetailPageEmailLogTabRefreshRequestIDAtoms = atomFamily<
  number,
  TTabSource
>({
  key: 'currentDetailPageEmailLogTabRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores Detail Page EmailLog Tab data for current customer.
 */
export const currentDetailPageEmailLogTabSelectors = selectorFamily<
  TEpSubsDBResponseOk<IEpSubsDBEmail_logApiValue> | null,
  TTabSource
>({
  key: 'currentDetailPageEmailLogTabSelectors',
  get:
    source =>
    async ({ get }) => {
      const requestId = get(currentDetailPageEmailLogTabRefreshRequestIDAtoms(source)); // Enable Refresh by Hook
      let id: number | null;
      switch (source) {
        case 'customers':
          id = get(currentCustomerIdAtom);
          break;
        case 'subscriptions':
          id = get(currentSubscriptionIdAtom);
          break;
      }
      // Do not call API if the currentCustomerIdAtom or the component has not been initiated yet.
      if (id === null || requestId === REQUEST_NOT_READY) return null;

      const emailLogData = await genEmailLogs(id, source);
      DEBUGGING &&
        console.log(
          'recoil, currentDetailPageEmailLog emailLogData:',
          source,
          id,
          `requestID:${requestId}`,
          emailLogData,
        );
      return emailLogData;
    },
});

// *********************************************** //
//       Detail Page Gift Cards List States          //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each customer
 */
export const currentDetailPageGiftCardTabRefreshRequestIDAtoms = atomFamily<
  number,
  TTabSource
>({
  key: 'currentDetailPageGiftCardTabRefreshRequestIDAtoms',
  default: REQUEST_NOT_READY,
});

/**
 * An atomFamily that stores the refresh Request ID for each invoice
 */
export const giftCardRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'giftCardRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores Detail Page Invoice Tab data for current customer/subscription.
 */
export const currentDetailPageGiftCardTabSelectors = selectorFamily<
  TEpSubsDBResponseOk<IEpSubsDBGift_cardValue> | null,
  TTabSource
>({
  key: 'currentDetailPageGiftCardTabSelectors',
  get:
    source =>
    async ({ get }) => {
      const requestId = get(currentDetailPageGiftCardTabRefreshRequestIDAtoms(source)); // Enable Refresh by Hook
      let id: number | null;
      switch (source) {
        case 'customers':
          id = get(currentCustomerIdAtom);
          break;
        case 'subscriptions':
          id = get(currentSubscriptionIdAtom);
          break;
      }
      // Do not call API if the current[ Customer | Subscription ]IdAtom or the component has not been initiated yet.
      if (id === null || requestId === REQUEST_NOT_READY) return null;

      const giftCardData = await genGiftCards(id, source);
      DEBUGGING &&
        console.log(
          'recoil, currentDetailPageGiftCard giftCardData:',
          source,
          id,
          giftCardData,
        );
      return giftCardData;
    },
});
