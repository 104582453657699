import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBCustomerPost,
  IEpSubsDBCustomerValue,
  TEpSubsDBCustomerValueKeys,
  TEpSubsDBResponseOk,
  IEpSubsDBCustomer_activityValue,
  IEpSubsDBCustomer_commentValue,
  IEpSubsCompositeDBCustomerValue,
  IEpSubsDBPromotional_creditPost,
  IEpSubsDBPromotional_creditValue,
  IEpSubsCustomer_payment_methodApiValue,
  TFilterData,
  IEpSubsDBGift_cardValue,
  IEpSubsTagsListValue,
} from '../models';
import { EMPTY_EXPORT_ERROR, TSortingItem } from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { IOneTimeChargeFormData } from 'src/containers/customers/customerDetail/EpSubsOneTimeChargeForm';
import { ESimpleSearchFields } from 'src/js/constants/subs';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm.js';
import {
  IAddExcessPaymentFormDB,
  IRefundExcessPaymentFormDB,
} from 'src/containers/customers/customerDetail/EpSubsExcessPaymentForm.js';
import { putNoty } from '../../src/utilities/epSubsNoty';
import { IPurchaseGiftCardFormData } from 'src/containers/customers/customerDetail/EpSubsPurchaseGiftCardForm.js';
import { IGiftCardFormData } from 'src/containers/customers/customerDetail/EpSubsCustomerDetail.js';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';
import { ITagsFormData } from 'src/components/modals/EpSubsTagModal.js';

export const genCustomerListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: TSortingItem<TEpSubsDBCustomerValueKeys> | null = null,
  simpleQuery = '',
  simpleQueryFieldKey = ESimpleSearchFields.ALL,
): Promise<TEpSubsDBResponseOk<IEpSubsDBCustomerValue>> => {
  return axios
    .post(`${hostUrl}/customers/search`, {
      keyword: simpleQuery,
      element: simpleQueryFieldKey,
      sort_field: !!sortingItems
        ? {
            field: sortingItems?.key,
            // Descending is the default value if not provided
            asc: sortingItems?.ascending === 'ASC' ? true : false,
          }
        : null,
      page_index: pageIdx - 1,
      per_page: perPage,
    })
    .then(response => {
      return response.data;
    });
};

export const genExportCustomerList = async (
  filterMetaData: Pick<TFilterData, 'sort_field'>,
  simpleQuery = '',
  simpleQueryFieldKey: ESimpleSearchFields = ESimpleSearchFields.ALL,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/customers/export`, {
      keyword: simpleQuery,
      element: simpleQueryFieldKey,
      sort_field: filterMetaData.sort_field,
    })
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `Customers-${unixtimeWithoutTime(Date.now())}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const genCustomerData = async (
  id: number,
): Promise<IEpSubsCompositeDBCustomerValue> => {
  return axios.get(`${hostUrl}/customers/${id}`).then(response => {
    return response.data;
  });
};

export const genCreateCustomer = async (data: IEpSubsDBCustomerPost): Promise<string> => {
  return axios.post(`${hostUrl}/customers`, data).then(response => {
    return response.data.data;
  });
};

export const genEditCustomer = async (data: IEpSubsDBCustomerPost): Promise<boolean> => {
  return axios.put(`${hostUrl}/customers/${data.id}`, data).then(() => {
    return true;
  });
};

export const genCustomerPaymentMethods = async (
  id: number,
): Promise<IEpSubsCustomer_payment_methodApiValue[]> => {
  return axios.get(`${hostUrl}/customers/${id}/payment-methods`).then(response => {
    return response.data.data ?? response.data ?? [];
  });
};

export const genCreateCustomerPaymentMethod = async (
  id: number,
  data: TNewPaymentFormData,
): Promise<number | { url?: string; returnUrl?: string }> => {
  return axios.post(`${hostUrl}/customers/${id}/payment-methods`, data).then(response => {
    if (response.data.code != undefined) {
      const { url, return_url } = JSON.parse(response.data.data);
      return { url, returnUrl: return_url };
    } else {
      const newSubscriptionId = response.data.data;
      return newSubscriptionId;
    }
  });
};

export const genMarkPaymentMethodPrimary = async (
  id: number,
  paymentMethodId: number,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/customers/${id}/payment-methods/${paymentMethodId}/make-primary`)
    .then(() => {
      return true;
    });
};

export const genCustomerActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBCustomer_activityValue[]> => {
  return axios.get(`${hostUrl}/customers/${id}/actlogs?limit=${limit}`).then(response => {
    return response.data.data;
  });
};

export const genCustomerActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBCustomer_activityValue> => {
  return axios.get(`${hostUrl}/customers/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genCustomerCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBCustomer_commentValue[]> => {
  return axios
    .get(`${hostUrl}/customers/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreateCustomerComment = async (
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/customers/comments`, data).then(response => {
    removeAllUploadedImage();
    return response.data.data;
  });
};

export const genSynCustomerSearchEngine = async (id: number): Promise<void> => {
  return axios.post(`${hostUrl}/customers/sync-es/${id}`).then(response => {
    return response.data;
  });
};

export type TPromotionalCreditActionType = 'add' | 'deduct';
export const genAddPromotionalCredit = async (
  data: IEpSubsDBPromotional_creditPost,
  type: TPromotionalCreditActionType,
): Promise<string> => {
  return axios.post(`${hostUrl}/promotional-credits/${type}`, data).then(response => {
    return response.data.data;
  });
};

export const genPromotionalCredits = async (
  id: number,
  source = 'customers',
): Promise<TEpSubsDBResponseOk<IEpSubsDBPromotional_creditValue>> => {
  return axios.get(`${hostUrl}/${source}/${id}/promotional-credits`).then(response => {
    return response.data;
  });
};

export const genOneTimeChargeInvoice = async (
  formData: IOneTimeChargeFormData,
  customer_id?: number,
  subscription_id?: number,
): Promise<number | { url?: string; returnUrl?: string }> => {
  const data = { ...formData, customer_id, subscription_id };
  return axios.post(`${hostUrl}/invoices/charge`, data).then(response => {
    if (response.data.code != undefined) {
      const message = `${response.data.message}`;
      putNoty({ type: 'error', text: message });
      const { url, return_url } = JSON.parse(response.data.data);
      return { url, returnUrl: return_url };
    } else {
      const oneTimeChargeResponseData = response.data.data;
      return oneTimeChargeResponseData;
    }
  });
};

export const genRequestPaymentMethodForCustomer = async (
  userId: number,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/customers/${userId}/request-payment-method`)
    .then(() => true);
};

export const genUpdatePaymentCardForCustomer = async (
  paymentMethodId: number,
  customerId: number,
  newPaymentData: TNewPaymentFormData,
): Promise<number | { url?: string; returnUrl?: string }> => {
  return axios
    .patch(
      `${hostUrl}/customers/${customerId}/payment-methods/${paymentMethodId}`,
      newPaymentData,
    )
    .then(response => {
      if (response.data.code != undefined) {
        const message = `${response.data.message}`;
        putNoty({ type: 'error', text: message });
        const { url, return_url } = JSON.parse(response.data.data);
        return { url, returnUrl: return_url };
      } else {
        const newCustomerPaymentId = response.data.data.id;
        return newCustomerPaymentId;
      }
    });
};

export const genDeletePaymentCardForCustomer = async (
  paymentMethodId: number,
  customerId: number,
): Promise<boolean> => {
  return axios
    .delete(`${hostUrl}/customers/${customerId}/payment-methods/${paymentMethodId}`)
    .then(() => true);
};

export const genAddExcessPayment = async (
  data: Omit<IAddExcessPaymentFormDB, 'type'>,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/customers/${data.customer_id}/excess-payment`, data)
    .then(response => {
      return response.data;
    });
};

export const genPurchaseGiftCard = async (
  data: IPurchaseGiftCardFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/gift-card`, data).then(response => {
    return response.data;
  });
};

export const genClaimGiftCard = async (data: IGiftCardFormData): Promise<boolean> => {
  return axios.post(`${hostUrl}/gift-card/claim`, data).then(response => {
    return response.data;
  });
};

export const genGiftCards = async (
  id: number,
  source = 'customers',
): Promise<TEpSubsDBResponseOk<IEpSubsDBGift_cardValue>> => {
  return axios.get(`${hostUrl}/${source}/${id}/gift-cards`).then(response => {
    return response.data;
  });
};

export const getAllGiftCardsList = async (
  id: number,
): Promise<IEpSubsDBGift_cardValue[]> => {
  return axios.get(`${hostUrl}/customers/${id}/gift-cards`).then(response => {
    return response.data.data ?? [];
  });
};

type IClientData = {
  gateway_account_id: number;
  customer: {
    id?: number;
    first_name?: string;
    last_name?: string;
    email?: string;
  };
};

interface clientData {
  client_secret: string;
  gateway_customer_id: string;
}

export const genClientSecretForPortal = async (
  token: string,
  data: IClientData,
): Promise<clientData> => {
  return axios
    .post(
      `${hostUrl}/payment-sources/sepa/create-client-secret?user_token=${token}`,
      data,
    )
    .then(response => {
      return response.data.data ?? '';
    });
};

export const genClientSecretForConductor = async (
  data: IClientData,
): Promise<clientData> => {
  return axios.post(`${hostUrl}/customers/create-client-secret`, data).then(response => {
    return response.data.data ?? '';
  });
};

export const getCustomerTags = async (): Promise<IEpSubsTagsListValue[]> => {
  return axios.get(`${hostUrl}/customers/tags`).then(response => {
    return response.data ?? [];
  });
};

export const getCustomerTagsById = async (
  id: number,
): Promise<IEpSubsTagsListValue[]> => {
  return axios.get(`${hostUrl}/customers/${id}/tags`).then(response => {
    return response.data ?? [];
  });
};

export const editCustomerTags = async (
  id: number,
  data: ITagsFormData,
): Promise<IEpSubsTagsListValue[]> => {
  return axios
    .patch(`${hostUrl}/customers/${id}/tags`, JSON.stringify(data.tags.join(',')))
    .then(response => {
      return response.data ?? [];
    });
};

export const genRefundExcessPayment = async (
  data: Omit<IRefundExcessPaymentFormDB, 'type'>,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/customers/${data.customer_id}/refund-excess-payment`, data)
    .then(response => {
      return response.data;
    });
};
