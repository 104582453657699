import React, { BaseSyntheticEvent, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import style from './EpSubsUserInfoModal.module.scss';
import {
  IEpSubsChangeNamePut,
  IEpSubsChangePasswordPut,
  IEpSubsProfile,
} from 'src/models/epSubsCompositeDBTypes';
import EpSubsProfileModal, {
  IChangeNameForm,
  IChangePasswordForm,
} from 'src/containers/EpSubsProfileModal';
import { useLogout, useRefreshUserInfo, useRefreshUserList } from 'src/recoil';
import { disableButton, reEnableButton } from 'src/utilities/commonUtils';
import { changeName, changeUserPassword } from 'src/services';
import { putNoty } from 'src/utilities/epSubsNoty';
import { EpIcon } from 'src/assets';

type Props = {
  modalShow: boolean;
  profile: IEpSubsProfile;
  onClose: () => void;
};

const EpSubsUserInfoModal = ({ modalShow, profile, onClose }: Props): JSX.Element => {
  const refreshUserList = useRefreshUserList();
  const refreshUserInfo = useRefreshUserInfo();
  const [profileModalShow, setProfileModalShow] = useState(false);
  const logout = useLogout();

  return (
    <>
      <Modal
        show={modalShow}
        onHide={onClose}
        centered
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className={style.modal_body}>
          <div className={style.email}>{profile.email}</div>
          <div className={style.circle}>
            {Array.from(`${profile.first_name}`)[0]?.toUpperCase() ?? 'N'}
          </div>
          <div className={style.name}>Hi, {profile.first_name ?? 'Null'}!</div>

          <div style={{ margin: 'auto', textAlign: 'center', marginTop: '20px' }}>
            <Button
              variant="secondary"
              className=" rounded-pill"
              onClick={() => {
                setProfileModalShow(true);
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Edit Conductor Profile</div>
            </Button>
          </div>
          <div style={{ margin: 'auto', textAlign: 'center', marginTop: '20px' }}>
            <Button
              variant="outline-danger"
              className=" rounded-pill"
              onClick={() => {
                logout();
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                <EpIcon name={'logOut'} />
                Logout
              </div>
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <EpSubsProfileModal
        profile={profile}
        modalShow={profileModalShow}
        onClose={() => setProfileModalShow(false)}
        onChangePasswordSubmit={async (
          formData: IChangePasswordForm,
          event?: BaseSyntheticEvent,
        ) => {
          event && event.preventDefault();
          event && disableButton(event);
          const response = await onChangePassword({
            formData,
            refreshUserInfo,
            modalControl: setProfileModalShow,
            event,
          });
          event && reEnableButton(event);
          return response;
        }}
        onChangeNameSubmit={async (
          formData: IChangeNameForm,
          event?: BaseSyntheticEvent,
        ) => {
          event && event.preventDefault();
          event && disableButton(event);
          const response = await onChangeName({
            formData,
            refreshUserInfo,
            modalControl: setProfileModalShow,
            event,
          });
          event && reEnableButton(event);
          await refreshUserList();
          return response;
        }}
      />
    </>
  );
};

export interface IOnChangePasswordProps {
  formData: IChangePasswordForm;
  refreshUserInfo: any;
  modalControl: React.Dispatch<React.SetStateAction<boolean>>;
  event?: BaseSyntheticEvent;
}

const onChangePassword = async ({
  formData,
  refreshUserInfo,
  modalControl,
  event,
}: IOnChangePasswordProps): Promise<boolean> => {
  const data: IEpSubsChangePasswordPut = {
    old_password: formData.old_password,
    new_password: formData.new_password,
    new_password_confirmation: formData.new_password_confirmation,
  };
  try {
    await changeUserPassword(data);
    putNoty({
      type: 'success',
      text: `Password has been changed successfully`,
    });
    modalControl(false);
    refreshUserInfo();
    event && event.target.reset();
  } catch (e) {
    modalControl(false);
    refreshUserInfo();
    event && reEnableButton(event);
  }
  return false;
};

export interface IOnChangeNameProps {
  formData: IChangeNameForm;
  refreshUserInfo: any;
  modalControl: React.Dispatch<React.SetStateAction<boolean>>;
  event?: BaseSyntheticEvent;
}
const onChangeName = async ({
  formData,
  refreshUserInfo,
  modalControl,
  event,
}: IOnChangeNameProps): Promise<boolean> => {
  const data: IEpSubsChangeNamePut = {
    first_name: formData.first_name,
    last_name: formData.last_name,
  };
  try {
    await changeName(data);
    putNoty({
      type: 'success',
      text: `Name has been changed successfully`,
    });
    modalControl(false);
    refreshUserInfo();
    event && event.target.reset();
  } catch (e) {
    modalControl(false);
    refreshUserInfo();
    event && reEnableButton(event);
  }
  return false;
};

export default EpSubsUserInfoModal;
