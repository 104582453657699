import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from 'src/components/tables/EpFullFeaturedTable';
import * as H from 'history';
import { offsetToPageId } from 'src/components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { IEpSubsDBPluginValue, TEpSubsDBPluginValueKeys } from 'src/models';
import { arrayToMap } from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import { CURRENT_ID_ATOM_DEFAULT_VALUE } from '../recoilConstants';
import { DEBUGGING } from 'src/config';
import {
  genPluginData,
  genPluginListData,
  getAllPluginList,
} from 'src/services/epSubsPluginService';

/**
 * An atom that stores the fresh Request ID for plugin list
 */
export const pluginListRequestIDAtom = atom<number>({
  key: 'pluginListRequestIDAtom',
  default: 0,
});

/**
 * An atomFamily that stores the refresh Request ID for each plugin
 */
export const pluginRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'pluginRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An atomFamily that stores the all logs data for each plugin
 */
export const pluginLogsRequestIDAtoms = atomFamily<number, number>({
  key: 'pluginLogsRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores plugin details for each plugin
 */
export const pluginSelectors = selectorFamily<IEpSubsDBPluginValue, number>({
  key: 'pluginSelectors',
  get:
    pluginId =>
    async ({ get }) => {
      get(pluginRefreshRequestIDAtoms(pluginId)); // Add request ID as a dependency
      const pluginData = await genPluginData(pluginId);
      DEBUGGING && console.log('recoil, pluginSelectors pluginData:', pluginData);
      return pluginData;
    },
});

/**
 * An selector that stores plugin list
 */
export const pluginSelector = selector<IEpSubsDBPluginValue[]>({
  key: 'pluginListSelector',
  get: async ({ get }) => {
    get(pluginListRequestIDAtom);
    const pluginList = await getAllPluginList();
    return pluginList;
  },
});

/**
 * An selector that stores a map from plugin.id to plugin Object
 */
export const pluginMapSelector = selector<Map<number, IEpSubsDBPluginValue>>({
  key: 'pluginMapSelector',
  get: async ({ get }) => {
    const pluginList = get(pluginSelector);
    return arrayToMap(pluginList, plugin => plugin.id);
  },
});

/**
 * An selector that stores the default plugin
 */
export const defaultPluginSelector = selector<IEpSubsDBPluginValue>({
  key: 'defaultPluginSelector',
  get: async ({ get }) => {
    const pluginList = get(pluginSelector);
    if (pluginList.length === 0) {
      const message = 'No default plugin set! Please set on backend.';
      putNoty({ type: 'error', text: message });
      throw new Error(message);
    }
    return pluginList[0];
  },
});

// *********************************************** //
//             plugin List Page States            //
// *********************************************** //
export const DEFAULT_PLUGIN_SEARCH_PARAMETER = '';

/**
 * An atom that stores the latest plugin search param
 */
export const latestPluginSearchParamAtom = atom<TLocationRecord>({
  key: 'latestPluginSearchParamAtom',
  default: { search: DEFAULT_PLUGIN_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for plugin list
 */
export const pluginSearchRequestIDAtom = atom<number>({
  key: 'pluginSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for plugin list
 */
export const pluginListSelector = selector<ListState<IEpSubsDBPluginValue>>({
  key: 'pluginIdListSelector',
  get: async ({ get }) => {
    const requestID = get(pluginSearchRequestIDAtom); // Add request ID as a dependency
    const pluginSearchParam = get(latestPluginSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, pluginBasicListSelector pluginSearchParam',
        pluginSearchParam,
        'requestID',
        requestID,
      );

    // fetch plugin
    const routeParams = getParamsFromSearch<TEpSubsDBPluginValueKeys>(
      pluginSearchParam.search as H.Search,
    );
    const { order, sort, page, perPage, simpleQuery } = routeParams;
    const offset = (page - 1) * perPage;
    try {
      const response = await genPluginListData(
        perPage,
        offsetToPageId(offset, perPage),
        sort === ''
          ? [{ key: 'id', ascending: 'DESC' }]
          : [{ key: sort, ascending: order }],
        simpleQuery,
      );

      console.log('plug in response', response);
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: response.data ?? [],
        total: response.data.length,
      };
    } catch (error) {
      return {
        loading: false,
        err: (error as Error).message,
        data: [],
        total: 0,
      };
    }
  },
});

// *********************************************** //
//           plugin Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected plugin id
 */
export const currentPluginIdAtom = atom<number | null>({
  key: 'currentPluginIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected plugin details
 */
export const currentPluginSelector = selector<IEpSubsDBPluginValue | undefined>({
  key: 'currentPluginSelector',
  get: ({ get }) => {
    const id = get(currentPluginIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(pluginSelectors(id));
    }
  },
});
