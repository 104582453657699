import { selector, selectorFamily, waitForAll } from 'recoil';
import * as H from 'history';
import {
  IEpSubsDBCouponValue,
  TEpSubsDBCouponValueKeys,
  IEpSubsDBCoupon_activityValue,
  IEpSubsDBCoupon_commentValue,
  IEpSubsCompositeDBCouponValueInList,
  IEpSubsCompositeDBCouponValue,
  UiPermission,
} from '../../models';
import {
  genCouponData,
  genCouponListData,
  genActiveCouponListData,
  genCouponActivityData,
  genCouponActivityDetail,
  genCouponCommentData,
  genCouponAllListData,
} from '../../services/epSubsCouponService';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
} from '../../components/tables/EpFullFeaturedTable';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { CURRENT_ID_ATOM_DEFAULT_VALUE, hasPermissionSelectors } from '..';
import { reduce } from 'lodash';
import { atom, atomFamily } from 'recoil';
import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';
import {
  COUPON_STATUS_FILTER_GROUP_FIELD,
  DEFAULT_COUPON_SEARCH_OPTION,
} from 'src/containers/coupons/EpSubsCouponNav';
import { getSimpleFilterData } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//             Coupon List Page States             //
// *********************************************** //
export const DEFAULT_COUPON_SEARCH_PARAMETER = `?filter=${JSON.stringify(
  DEFAULT_COUPON_SEARCH_OPTION,
)}`;
/**
 * An atom that stores the latest coupon search param
 */
export const latestCouponSearchParamAtom = atom<TLocationRecord>({
  key: 'latestCouponSearchParamAtom',
  default: { search: DEFAULT_COUPON_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for coupon list
 */
export const couponSearchRequestIDAtom = atom<number>({
  key: 'couponSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for coupon list based on current searchParam
 */
export const couponBasicListSelector = selector<
  ListState<IEpSubsCompositeDBCouponValueInList>
>({
  key: 'couponIdListSelector',
  get: async ({ get }) => {
    const requestID = get(couponSearchRequestIDAtom); // Add request ID as a dependency
    const couponSearchParam = get(latestCouponSearchParamAtom);

    // fetch coupons
    if (couponSearchParam.ready && couponSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBCouponValueKeys>(
        couponSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const filter = decodeURIComponent(routeParams.filter);
      const offset = (page - 1) * perPage;
      const filterData = getSimpleFilterData(filter);

      DEBUGGING &&
        console.log(
          'recoil, couponBasicListSelector couponSearchParam',
          couponSearchParam,
          'requestID',
          requestID,
          'filterData',
          filterData,
        );

      try {
        const response = await genCouponListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'created_at', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          filterData[COUPON_STATUS_FILTER_GROUP_FIELD][0],
        );

        DEBUGGING &&
          console.log('recoil, couponBasicListSelector response', response, filterData);

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data:
            response.data.map(coupon => {
              return {
                coupon,
              } as IEpSubsCompositeDBCouponValueInList;
            }) ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An selector that stores the coupon detail list based on current searchParam
 */
export const couponCompleteListSelector = selector<
  ListState<IEpSubsCompositeDBCouponValueInList>
>({
  key: 'couponCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(couponBasicListSelector);

    if (!idListState.err) {
      const couponIds = idListState.data.map(couponData => couponData.coupon.id);
      const couponDetailList = get(
        waitForAll(couponIds.map(couponId => couponSelectors(couponId))),
      );
      DEBUGGING && console.log('recoil, couponCompleteListSelector', couponDetailList);

      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: couponDetailList ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from couponBasicListSelector
      return idListState as ListState<IEpSubsCompositeDBCouponValue>;
    }
  },
});

/**
 * An atomFamily that stores the refresh Request ID for each coupon
 */
export const couponRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'couponRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores coupon details for each coupon
 */
export const couponSelectors = selectorFamily<IEpSubsCompositeDBCouponValue, number>({
  key: 'couponSelectors',
  get:
    couponId =>
    async ({ get }) => {
      get(couponRefreshRequestIDAtoms(couponId)); // Add request ID as a dependency
      const couponData = await genCouponData(couponId);
      DEBUGGING && console.log('recoil, couponSelectors couponData:', couponData);
      return couponData;
    },
});

// *********************************************** //
//           Coupon Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected coupon id
 */
export const currentCouponIdAtom = atom<number | null>({
  key: 'currentCouponIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected coupon details
 */
export const currentCouponSelector = selector<IEpSubsCompositeDBCouponValue | undefined>({
  key: 'currentCouponSelector',
  get: ({ get }) => {
    const id = get(currentCouponIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(couponSelectors(id));
    }
  },
});

/**
 * An atom that stores the currently selected coupon Comments query parameters
 */
export const currentCouponCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentCouponCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected coupon Comments
 */
export const currentCouponCommentsSelector = selector<IEpSubsDBCoupon_commentValue[]>({
  key: 'currentCouponCommentsSelector',
  get: async ({ get }) => {
    const id = get(currentCouponIdAtom);
    const hasPermission = get(
      hasPermissionSelectors(UiPermission['coupon.view_comment']),
    );
    const params = get(currentCouponCommentsSearchParamAtom);
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genCouponCommentData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An atom that stores the currently selected coupon Activities query parameters
 */
export const currentCouponActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentCouponActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected coupon Activities
 */
export const currentCouponActivitiesSelector = selector<IEpSubsDBCoupon_activityValue[]>({
  key: 'currentCouponActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentCouponIdAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['coupon.view_log']));
    const params = get(currentCouponActivitiesSearchParamAtom);

    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genCouponActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores Coupon Activity Detail for each activity
 */
export const couponActivityDetailSelectors = selectorFamily<
  IEpSubsDBCoupon_activityValue,
  number
>({
  key: 'couponActivityDetailSelectors',
  get: actLogId => async () => {
    const couponActivityData = await genCouponActivityDetail(actLogId);
    DEBUGGING &&
      console.log('recoil, couponSelectors couponActivityData:', couponActivityData);
    return couponActivityData;
  },
});

// *********************************************** //
//          Coupon Dropdown Data Selectors           //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for active coupon list
 */
export const activeCouponsRequestIDAtom = atom<number>({
  key: 'activeCouponsRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores all the active coupon detail list
 */
export const activeCouponListStateSelector = selector<IEpSubsDBCouponValue[]>({
  key: 'activeCouponListStateSelector',
  get: async ({ get }) => {
    get(activeCouponsRequestIDAtom);
    get(couponSearchRequestIDAtom);
    const response = await genActiveCouponListData();

    DEBUGGING && console.log('recoil, activeCouponListStateSelector response', response);

    return response ?? [];
  },
});

/**
 * An selector that stores all the active invoice coupon detail list,
 * used when we need to check matched coupon from more than one subscription item
 */
export const activeInvoiceCouponListStateSelector = selector<IEpSubsDBCouponValue[]>({
  key: 'activeInvoiceCouponListStateSelector',
  get: async ({ get }) => {
    get(activeCouponsRequestIDAtom);
    const response = get(activeCouponListStateSelector);

    DEBUGGING &&
      console.log('recoil, activeInvoiceCouponListStateSelector response', response);

    return response.filter(coupon => coupon.apply_on === 'invoice') ?? [];
  },
});

/**
 * An selector that stores the map from coupon_id to coupon_basic details
 */
export const allCouponDataMapSelector = selector<Map<string, IEpSubsDBCouponValue>>({
  key: 'allCouponDataMapSelector',
  get: async ({ get }) => {
    // To be replaced with a all coupon list API
    const couponList = get(activeCouponListStateSelector) ?? [];
    return reduce<IEpSubsDBCouponValue, Map<string, IEpSubsDBCouponValue>>(
      couponList,
      (couponIdMap, couponRecord) => {
        couponIdMap.set(couponRecord.coupon_id, couponRecord);
        return couponIdMap;
      },
      new Map(),
    );
  },
});

export const allCouponSelectors = selectorFamily<IEpSubsDBCouponValue[], boolean>({
  key: 'allCouponSelectors',
  get:
    () =>
    async ({}) => {
      return genCouponAllListData();
    },
});
