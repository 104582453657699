import { atom, selectorFamily } from 'recoil';
import { selector } from 'recoil';
import {
  genTransactionActivityData,
  genTransactionActivityDetail,
  genTransactionCommentData,
} from 'src/services';
import {
  CURRENT_ID_ATOM_DEFAULT_VALUE,
  hasPermissionSelectors,
  transactionSelectors,
} from '..';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';
import {
  IEpSubsCompositeDBTransactionValue,
  IEpSubsDBTransaction_activityValue,
  IEpSubsDBTransaction_commentValue,
  UiPermission,
} from '../../models';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//           Transaction Detail Page Selectors     //
// *********************************************** //

/**
 * An atom that stores the currently selected transaction id
 */
export const currentTransactionIdAtom = atom<number | null>({
  key: 'currentTransactionIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected transaction details
 */
export const currentTransactionSelector = selector<
  IEpSubsCompositeDBTransactionValue | undefined
>({
  key: 'currentTransactionSelector',
  get: ({ get }) => {
    const id = get(currentTransactionIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(transactionSelectors(id));
    }
  },
});

// *********************************************** //
//        Current Transaction Comments States      //
// *********************************************** //

/**
 * An atom that stores the currently selected transaction Comments query parameters
 */
export const currentTransactionCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentTransactionCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected transaction Comments
 */
export const currentTransactionCommentsSelector = selector<
  IEpSubsDBTransaction_commentValue[]
>({
  key: 'currentTransactionCommentsSelector',
  get: async ({ get }) => {
    const id = get(currentTransactionIdAtom);
    const params = get(currentTransactionCommentsSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['tran.view_comment']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genTransactionCommentData(id, params.limit);
    } else {
      return [];
    }
  },
});

// *********************************************** //
//      Current Transaction Activities States      //
// *********************************************** //

/**
 * An atom that stores the currently selected transaction Activities query parameters
 */
export const currentTransactionActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentTransactionActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected transaction Activities
 */
export const currentTransactionActivitiesSelector = selector<
  IEpSubsDBTransaction_activityValue[]
>({
  key: 'currentTransactionActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentTransactionIdAtom);
    const params = get(currentTransactionActivitiesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['tran.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genTransactionActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores Transaction Activity Detail for each activity
 */
export const transactionActivityDetailSelectors = selectorFamily<
  IEpSubsDBTransaction_activityValue,
  number
>({
  key: 'transactionActivityDetailSelectors',
  get: actLogId => async () => {
    const transactionActivityData = await genTransactionActivityDetail(actLogId);
    DEBUGGING &&
      console.log(
        'recoil, transactionSelectors transactionActivityData:',
        transactionActivityData,
      );
    return transactionActivityData;
  },
});
// *********************************************** //
//          Transaction Dropdown Data Selectors    //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for active transaction list
 */
export const activeTransactionsRequestIDAtom = atom<number>({
  key: 'activeTransactionsRequestIDAtom',
  default: 0,
});
