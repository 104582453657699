import { useRecoilCallback } from 'recoil';
import {
  allProductsRequestIDAtom,
  // currentProductActivitiesSearchParamAtom,
  // currentProductCommentsSearchParamAtom,
  productRefreshRequestIDAtoms,
  productSearchRequestIDAtom,
} from '..';

// *********************************************** //
//                Product List Page Hooks             //
// *********************************************** //

export const useRefreshProductList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh product list
        set(productSearchRequestIDAtom, requestID => requestID + 1);
        set(allProductsRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshProduct = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        // refresh product
        set(productRefreshRequestIDAtoms(id), requestID => requestID + 1);
      },
    [],
  );
};
