import axios from 'axios';
import { hostUrl } from '../config.js';
import { IEpSubsDBScheduled_taskValue } from '../models';

// interface
export interface LoggerValue {
  name: string; // logger name
  value: string; // logging level
}

export interface ScheduledTaskValue extends IEpSubsDBScheduled_taskValue {
  status: string;
  time_mask: string;
}

// get scheduled task list
export const getScheduledTaskList = async (): Promise<ScheduledTaskValue[]> => {
  return axios.get(`${hostUrl}/admin/scheduled-tasks`).then(response => {
    return response.data;
  });
};

export const pauseScheduledTask = async (taskId: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/admin/scheduled-tasks/${taskId}/pause`, {}).then(() => {
    return true;
  });
};

export const resumeScheduledTask = async (taskId: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/admin/scheduled-tasks/${taskId}/resume`, {}).then(() => {
    return true;
  });
};

export const changeScheduledTaskInterval = async (
  taskId: number,
  interval: string,
): Promise<boolean> => {
  const params = new URLSearchParams();
  params.append('interval', interval);
  return axios({
    method: 'PUT',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: params.toString(),
    url: `${hostUrl}/admin/scheduled-tasks/${taskId}/interval`,
  }).then(() => {
    return true;
  });
};
