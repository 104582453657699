import axios from 'axios';
import { hostUrl } from '../config.js';
import { IEpSubsDBEmail_logApiValue, TEpSubsDBResponseOk } from '../models';
import { getSortSQLStrFromSortingItems, TSortingItem } from './epSubsServiceUtils';

export const genEmailLogListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<keyof IEpSubsDBEmail_logApiValue>> = [],
  simpleQuery = '',
  statusList: string[] = [],
): Promise<TEpSubsDBResponseOk<IEpSubsDBEmail_logApiValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<keyof IEpSubsDBEmail_logApiValue>(sortingItems);
  return axios
    .get(
      `${hostUrl}/logs/emails?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          `server_status=${statusList.join(',')}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const genEmailLogData = async (
  id: number,
): Promise<IEpSubsDBEmail_logApiValue> => {
  return axios.get(`${hostUrl}/logs/emails/${id}`).then(response => {
    return response.data;
  });
};

export const genEmailLogs = async (
  id: number,
  source: 'subscriptions' | 'customers',
): Promise<TEpSubsDBResponseOk<IEpSubsDBEmail_logApiValue>> => {
  return axios.get(`${hostUrl}/${source}/${id}/emails`).then(response => {
    return response.data;
  });
};

export const forwardEmailLogEmail = async (
  emailLogId: number,
  data: { email: string },
): Promise<string> => {
  return axios.post(`${hostUrl}/email/${emailLogId}/forward`, data).then(response => {
    return response.data.message;
  });
};
