import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import {
  cleanUpInTheEndOfSubmit,
  disableButton,
  reEnableButton,
} from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import { TTokenizeCallback } from '../subscriptions/subscriptionOperations/EpSubsCreateSubscription';
import { genUpdatePaymentForCustomer } from './services/epSubsPortalPaymentsService';
import './styles.scss';
import Button from './components/Button';
import { useLoadOptionalRecoilSelector, useRefreshPaymentList } from '../../recoil';
import { GATEWAYS } from 'src/models';
import { Stripe } from '@stripe/stripe-js';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm';
import { Modal } from 'react-bootstrap';
import { EpSubsPortalEditPaymentForm } from './EpSubsPortalEditPaymentForm';
import { gatewaysPortalMapSelector } from 'src/recoil/gateway/gatewayStates';
import { languageOptions } from 'src/models/i18n';
import { genClientSecretForPortal } from 'src/services';
import * as stripeJs from '@stripe/stripe-js';

interface Props {
  token: string;
  id: number;
  originalGatewayAccountId: number;
  paymentType?: string;
  customerID: number;
}

const EpSubsPortalEditPaymentModal = ({
  token,
  id,
  originalGatewayAccountId,
  paymentType,
  customerID,
}: // defaultPaymentData
Props) => {
  const [tokenize, setTokenize] = useState<TTokenizeCallback>();
  const [editPaymentModalShow, setEditPaymentModalShow] = useState(false);
  const refreshPaymentList = useRefreshPaymentList();
  const [stripe, setStripe] = useState<Stripe>();
  const [stripeElements, setStripeCardElement] = useState<stripeJs.StripeElements>();
  const gatewaysPortalListMap = useLoadOptionalRecoilSelector(gatewaysPortalMapSelector);
  const onStripeInstance = (stripe: Stripe, stripeElements?: stripeJs.StripeElements) => {
    setStripe(() => stripe);
    setStripeCardElement(() => stripeElements);
  };

  const onSepaInstance = (stripe: Stripe) => {
    setStripe(() => stripe);
  };

  useEffect(() => {
    onStripeInstance;
    onSepaInstance;
  }, []);

  const currentUrl = window.location.href;

  const onSubmitEditPaymentMethod = async (
    gatewayAccountId: number,
    formData: TNewPaymentFormData,
    setIsLoading: (isLoading: boolean) => void,
    event?: BaseSyntheticEvent<any>,
  ) => {
    event && disableButton(event);
    let data;
    const gatewayType = gatewaysPortalListMap?.get(gatewayAccountId)?.type;
    switch (gatewayType) {
      case GATEWAYS.Braintree:
        // The method will be set by the paymentFormSection in gateway API callback
        if (!tokenize) {
          putNoty({
            type: 'error',
            text: `${languageOptions().portal.error.braintree.failure}`,
          });
          return cleanUpInTheEndOfSubmit(event);
        }
        setIsLoading(true);
        const paymentFields = event && (await tokenize(event, gatewayAccountId));
        if (!paymentFields) {
          const message = `${languageOptions().portal.error.braintree.failurePayment}`;
          putNoty({ type: 'error', text: message });
          setIsLoading(false);
          return cleanUpInTheEndOfSubmit(event);
        }
        data = {
          ...paymentFields,
          make_primary: formData.make_primary,
          apply_to_all_subs: formData.apply_to_all_subs,
          gateway: gatewayAccountId,
        };
        break;
      case GATEWAYS.Stripe:
        if (!(stripe && stripeElements)) {
          putNoty({
            type: 'error',
            text: `${languageOptions().portal.error.stripe.failureInitialize}`,
          });
          return cleanUpInTheEndOfSubmit(event);
        }

        setIsLoading(true);
        const { error: submitError } = await stripeElements.submit();

        const clientSecretData = await genClientSecretForPortal(token, {
          gateway_account_id: gatewayAccountId,
          customer: {
            id: customerID,
          },
        });

        const { setupIntent, error } = await stripe.confirmSetup({
          elements: stripeElements,
          clientSecret: clientSecretData.client_secret,
          confirmParams: {
            return_url: `${window.location.href}`,
          },
          redirect: 'if_required',
        });

        if (error || !setupIntent || submitError) {
          const message = `${error?.message} ${
            error?.decline_code ? `(${error?.decline_code})` : ''
          }`;
          error && putNoty({ type: 'error', text: message });
          submitError && putNoty({ type: 'error', text: `${submitError?.message}` });
          setIsLoading(false);
          return cleanUpInTheEndOfSubmit(event);
        }
        if (!setupIntent.id) {
          const message = `${languageOptions().portal.error.paymentMethod.failure}`;
          putNoty({ type: 'error', text: message });
          setIsLoading(false);
          return cleanUpInTheEndOfSubmit(event);
        }

        data = {
          nonce: setupIntent.id,
          gateway: gatewayAccountId,
          make_primary: formData.make_primary,
          apply_to_all_subs: formData.apply_to_all_subs,
          use_payment_element: true,
        };

        break;
      default:
        putNoty({
          type: 'error',
          text: `${languageOptions().portal.error.gateway.failure}`,
        });
        return cleanUpInTheEndOfSubmit(event);
    }
    try {
      setIsLoading(true);
      const paymentsResp = await genUpdatePaymentForCustomer(token, id, data, currentUrl);
      if (typeof paymentsResp == 'number') {
        const newPaymentMethodId = paymentsResp;
        setIsLoading(false);
        const message = `Id = ${newPaymentMethodId} ${
          languageOptions().portal.create.success
        }`;
        putNoty({ type: 'success', text: message });
      } else {
        setIsLoading(false);
        window.location.replace(`${paymentsResp.url}`);
      }
    } catch (e) {
      event && reEnableButton(event);
      setIsLoading(false);
      // putNoty({ type: 'error', text: (e as Error).message });
    } finally {
      refreshPaymentList();
    }

    return cleanUpInTheEndOfSubmit(event);
  };

  const headerName = `${languageOptions().portal.update.header}`;
  const onBraintreeInstance = (tokenize: TTokenizeCallback) => {
    setTokenize(() => tokenize);
  };
  const onClose = () => {
    setEditPaymentModalShow(false);
  };

  return (
    <div>
      <Button
        btnType="primary"
        onClick={() => {
          setEditPaymentModalShow(true);
        }}
      >
        {languageOptions().portal.buttons.edit}
      </Button>
      <div style={{ fontSize: '12px', maxWidth: '80%' }}>
        <Modal
          show={editPaymentModalShow}
          onHide={onClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          contentClassName="px-5 pb-5"
          centered
        >
          <Modal.Header
            closeButton
            className="mt-3 mb-3"
            style={{ borderBottom: 'none' }}
          >
            <Modal.Title id="contained-modal-title-vcenter">{headerName}</Modal.Title>
          </Modal.Header>
          <EpSubsPortalEditPaymentForm
            paymentType={paymentType}
            gatewayAccountId={originalGatewayAccountId}
            onClosePaypalForm={onClose}
            onSubmit={onSubmitEditPaymentMethod}
            onBraintreeInstance={onBraintreeInstance}
            onStripeInstance={onStripeInstance}
          />
        </Modal>
      </div>
    </div>
  );
};
export default EpSubsPortalEditPaymentModal;
