import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import style from './EpSubsLogin.module.scss';

type Props = {
  modalShow: boolean;
  onClose: () => void;
  onSubmit: (email: string, e: React.BaseSyntheticEvent) => void;
};

const EpSubsResetPwModal = ({ modalShow, onClose, onSubmit }: Props): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Modal
      show={modalShow}
      onHide={() => {
        onClose();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Reset Password</Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e: React.BaseSyntheticEvent) => onSubmit(email, e)}>
        <Modal.Body>
          <p style={{ padding: '10px', margin: '12px' }}>
            To reset your password, please provide us your email. We will send a link to
            your email to reset the password.
          </p>

          <Form.Row className="mt-2 ml-4">
            <Form.Group className="w-50">
              <Form.Control
                autoFocus
                type="email"
                placeholder="Enter email"
                value={email}
                required
                onChange={e => {
                  setEmail(e.target.value);
                  setErrorMessage('');
                }}
              />
              {errorMessage && <div className={style.error}>{errorMessage}</div>}
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button id="Ep_save_comment" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EpSubsResetPwModal;
