import React, { BaseSyntheticEvent, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import style from './EpSubsPaymentFormComponent.module.scss';
import { putNoty } from '../../utilities/epSubsNoty';
import { BraintreeError, HostedFields } from 'braintree-web';
import BraintreeHostedFields from '../../containers/gateways/braintree/BraintreeWebHostedFields';
import { TNewPaymentFormData } from '../subscriptions/EpSubsSubscriptionForm';
import { TTokenizeCallback } from '../../containers/subscriptions/subscriptionOperations/EpSubsCreateSubscription';
import { disableButton, reEnableButton } from 'src/utilities/commonUtils';
import { useLoadOptionalRecoilSelector } from 'src/recoil';
import { gatewaysPortalMapSelector } from 'src/recoil/gateway/gatewayStates';
import { languageOptions } from 'src/models/i18n';
import { DEBUGGING } from 'src/config';

type Props = {
  onInstanceEnding: (tokenize: TTokenizeCallback) => void;
  gatewayAccountId: number;
  make_primary: boolean;
  vaultCard: boolean;
  defaultPaymentData?: TNewPaymentFormData;
};

export const EpSubsBraintreeFormSection = ({
  onInstanceEnding,
  gatewayAccountId,
  make_primary,
  vaultCard = false,
}: Props): JSX.Element => {
  const onInstance = useCallback(
    (hostedFieldsInstance: HostedFields) => {
      DEBUGGING &&
        console.log(
          'onInstance redefined: ',
          hostedFieldsInstance,
          onInstanceEnding,
          vaultCard,
          make_primary,
        );
      const tokenize = async (
        event: BaseSyntheticEvent<any>,
        gatewayAccountId: number,
      ): Promise<TNewPaymentFormData | null> => {
        event && event.preventDefault();
        disableButton(event);
        try {
          const payload = await hostedFieldsInstance.tokenize({
            vault: vaultCard,
          });
          DEBUGGING && console.log('Tokenize Payload: ', payload);

          const paymentFields = {
            nonce: payload.nonce,
            card_type: payload.details.cardType,
            last4_digits: payload.details.lastFour,
            expiration_year: payload.details.expirationYear,
            expiration_month: payload.details.expirationMonth,
            payment_type: payload.type,
            make_primary,
            gateway: gatewayAccountId,
          };
          reEnableButton(event);
          return paymentFields;
        } catch (tokenizeErr) {
          const errorMessage = handleTokenizeErr(tokenizeErr as BraintreeError);
          putNoty({ type: 'error', text: errorMessage });
          reEnableButton(event);
          return null;
        }
      };
      onInstanceEnding(tokenize);
    },
    [vaultCard, make_primary],
  );
  const gatewayMap = useLoadOptionalRecoilSelector(gatewaysPortalMapSelector);
  const client_id = gatewayMap?.get(gatewayAccountId)?.client_id;

  const paymentFormData = !client_id ? (
    <></>
  ) : (
    <div id="payment-form-section">
      <BraintreeHostedFields
        className={style.card_form}
        options={{
          authorization: client_id,
        }}
        onInstance={onInstance}
      >
        <Form.Group>
          {false && <Form.Control name="payment_method_nonce" />}
          <Form.Row>
            <Form.Label className="card-number" style={{ fontSize: '18px' }}>
              {languageOptions().portal.add.cardNumber}
            </Form.Label>
            <div id="card-number" className={style.hosted_field}></div>
          </Form.Row>
          <Form.Row>
            <Form.Label className="cvv" style={{ fontSize: '18px' }}>
              {languageOptions().portal.add.cvv}
            </Form.Label>
            <div id="cvv" className={style.hosted_field}></div>
          </Form.Row>
          <Form.Row>
            <Form.Label className="expiration-date" style={{ fontSize: '18px' }}>
              {languageOptions().portal.add.expirationDate}
            </Form.Label>
            <div id="expiration-date" className={style.hosted_field}></div>
          </Form.Row>
          <Form.Row>
            <Form.Label className="postal-code" style={{ fontSize: '18px' }}>
              {languageOptions().portal.add.postalCode}
            </Form.Label>
            <div id="postal-code" className={style.hosted_field}></div>
          </Form.Row>
        </Form.Group>
      </BraintreeHostedFields>
    </div>
  );
  return paymentFormData;
};

export default EpSubsBraintreeFormSection;

export const handleTokenizeErr = (tokenizeErr: BraintreeError): string => {
  let errorMessage: string;
  switch (tokenizeErr.code) {
    case 'HOSTED_FIELDS_FIELDS_EMPTY':
      errorMessage = `${languageOptions().portal.reminder.allField}`;
      console.error(errorMessage);
      break;
    case 'HOSTED_FIELDS_FIELDS_INVALID':
      errorMessage =
        `${languageOptions().portal.reminder.someField}:` +
        String(tokenizeErr.details.invalidFieldKeys);
      console.error('Some fields are invalid: ', tokenizeErr.details.invalidFieldKeys);
      break;
    case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
      errorMessage = `${languageOptions().portal.error.token}`;
      console.error(errorMessage);
      break;
    case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
      errorMessage = `${languageOptions().portal.netWork}`;
      console.error(errorMessage);
      break;
    default:
      errorMessage =
        `${languageOptions().portal.error.token.somethingWrong}` + String(tokenizeErr);
      console.error('Something bad happened!', tokenizeErr);
  }
  return errorMessage;
};
