import { useRecoilCallback } from 'recoil';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';
import {
  currentTransactionActivitiesSearchParamAtom,
  currentTransactionCommentsSearchParamAtom,
  transactionRefreshRequestIDAtoms,
  transactionSearchRequestIDAtom,
} from '../';

// *********************************************** //
//           Transaction List Page Hooks          //
// *********************************************** //

export const useRefreshTransactionList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh transaction list
        set(transactionSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshTransaction = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number): Promise<void> => {
        // refresh transaction
        set(transactionRefreshRequestIDAtoms(id), requestID => requestID + 1);

        // refresh Activity log
        set(currentTransactionActivitiesSearchParamAtom, prevParam => {
          return {
            limit: prevParam.limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

// *********************************************** //
//         Transaction Detail Page Hooks           //
// *********************************************** //

export const useRefreshCurrentTransactionActivities = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ACTIVITY_COUNT) => {
        set(currentTransactionActivitiesSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useRefreshCurrentTransactionComments = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_COMMENT_COUNT) => {
        set(currentTransactionCommentsSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};
