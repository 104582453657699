import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import style from './EpSubsSpinnerModal.module.scss';

type Props = {
  isLoading: boolean;
};

const EpSubsSpinnerModal = (props: Props): JSX.Element => {
  return (
    <>
      <Modal
        show={props.isLoading}
        size="xl"
        dialogClassName={style.full_screen_modal}
        contentClassName={style.hide_modal_content}
        onHide={() => {
          return '';
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: '50px', height: '50px' }}
          />
        </div>
      </Modal>
    </>
  );
};
export default EpSubsSpinnerModal;
