import { useRecoilCallback } from 'recoil';
import {
  currentDetailPageInvoiceTabRefreshRequestIDAtoms,
  currentInvoiceActivitiesSearchParamAtom,
  currentInvoiceCommentsSearchParamAtom,
  invoiceRefreshRequestIDAtoms,
  invoiceSearchRequestIDAtom,
} from '../';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';

// *********************************************** //
//           Invoice List Page Hooks          //
// *********************************************** //

export const useRefreshInvoiceList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh invoice list
        set(invoiceSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

/**
 * Refresh Invoice and its activity log.
 * - If associatedCustomerId or associatedSubscriptionId provided,
 * will refresh the invoice tab there as well
 */
export const useRefreshInvoice = (): ((props: {
  id: number;
  associatedCustomerId?: number;
  associatedSubscriptionId?: number;
}) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async ({
        id,
        associatedCustomerId = null,
        associatedSubscriptionId = null,
      }): Promise<void> => {
        // refresh invoice
        set(invoiceRefreshRequestIDAtoms(id), requestID => requestID + 1);

        // refresh Activity log
        set(currentInvoiceActivitiesSearchParamAtom, prevParam => {
          return {
            limit: prevParam.limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });

        // refresh memoized customer detail page invoice tab if associated id is given
        if (associatedCustomerId) {
          set(
            currentDetailPageInvoiceTabRefreshRequestIDAtoms('customers'),
            requestID => requestID + 1,
          );
        }

        // refresh memoized subscription detail page invoice tab if associated id is given
        if (associatedSubscriptionId) {
          set(
            currentDetailPageInvoiceTabRefreshRequestIDAtoms('subscriptions'),
            requestID => requestID + 1,
          );
        }
      },
    [],
  );
};

// *********************************************** //
//           Invoice Detail Page Hooks             //
// *********************************************** //

export const useRefreshCurrentInvoiceActivities = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ACTIVITY_COUNT) => {
        set(currentInvoiceActivitiesSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useRefreshCurrentInvoiceComments = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_COMMENT_COUNT) => {
        set(currentInvoiceCommentsSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};
