import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import { IEpSubsCompositeDBPlanValue } from '../../models/';

export const FETCH_PLAN_DATA = 'FETCH_PLAN_DATA';
export const FETCH_PLAN_DATA_SUCCESS = 'FETCH_PLAN_DATA_SUCCESS';
export const FETCH_PLAN_DATA_FAILURE = 'FETCH_PLAN_DATA_FAILURE';
export const SAVE_PLAN_DATA_SUCCESS = 'SAVE_PLAN_DATA_SUCCESS';
export const SAVE_LATEST_PLAN_SEARCH_PARAM = 'SAVE_LATEST_PLAN_SEARCH_PARAM';
export const ARCHIVE_PLAN = 'ARCHIVE_PLAN';
export const ARCHIVE_PLAN_FAILURE = 'ARCHIVE_PLAN_FAILURE';

export type IFetchPlanDataAction = {
  type: typeof FETCH_PLAN_DATA;
  id: number;
  refresh?: boolean;
};

export type IFetchPlanDataSuccessAction = {
  type: typeof FETCH_PLAN_DATA_SUCCESS;
  id: number;
  planData: IEpSubsCompositeDBPlanValue;
};

export type ISaveLatestPlanSearchParamAction = {
  type: typeof SAVE_LATEST_PLAN_SEARCH_PARAM;
  loc: TLocationRecord;
};

export type IArchivePlanAction = {
  type: typeof ARCHIVE_PLAN;
  id: number;
  archive?: boolean;
};

export type epSubsPlanActionTypes =
  | IFetchPlanDataAction
  | IFetchPlanDataSuccessAction
  | ISaveLatestPlanSearchParamAction;
