import { LOCALE } from 'src/config';
import { ELocale } from './Locale';
import { en_US } from './textResourses/en_US';
import { ja_JP } from './textResourses/ja_JP';
import { de_DE } from './textResourses/de_DE';

export const languageOptions = (locale = LOCALE as ELocale): TLanguageResource => {
  switch (locale) {
    case ELocale.en_AU:
    case ELocale.en_UK:
    case ELocale.cs_CZ:
    case ELocale.sk_SK:
    case ELocale.es_US:
    case ELocale.es_MX:
    case ELocale.fr_FR:
    case ELocale.en_US:
    case ELocale.pt_BR:
      return en_US;
    case ELocale.de_DE:
      return de_DE;
    case ELocale.ja_JP:
      return ja_JP;
  }
};

export type TLanguageResource = {
  portal: TPortalPageLanguageResource;
};

export type TPortalPageLanguageResource = {
  logo: {
    logo: string;
    title: string;
    subTitle: string;
  };
  payments: {
    header: string;
    primaryContent: string;
    backupContent: string;
  };
  update: {
    header: string;
    cardNumber: string;
    cvv: string;
    expirationDate: string;
    postalCode?: string;
    confirm: string;
  };
  add: {
    header: string;
    subTitle1: string;
    subTitle2: string;
    subTitle3: string;
    subTitle4: string;
    creditCard?: string;
    cardNumber: string;
    cvv: string;
    expirationDate: string;
    postalCode?: string;
    first_name: string;
    last_name: string;
    email: string;
    iban: string;
    confirm: string;
  };
  buttons: {
    add: string;
    edit: string;
    remove: string;
    primary: string;
  };
  isPrimary: {
    name: string;
  };
  primaryCard: {
    header: string;
    content: string;
    cancel: string;
    confirm: string;
  };
  removeCard: {
    header: string;
    content: string;
    cancel: string;
    confirm: string;
  };
  paypalCheckout: {
    name: string;
  };
  create: {
    success: string;
    failure: string;
  };
  edit: {
    success: string;
    failure: string;
  };
  delete: {
    success: string;
    failure: string;
  };
  error: {
    braintree: {
      failure: string;
      failurePayment: string;
    };
    stripe: {
      failureInitialize: string;
    };
    paypal: {
      failure: string;
    };
    token: {
      failure: string;
      somethingWrong: string;
    };
    postalCode: {
      frontLength: string;
      endLength: string;
      failure: string;
    };
    link: {
      failure: string;
    };
    gateway: {
      failure: string;
    };
    paymentMethod: {
      failure: string;
    };
    unknown: string;
  };
  netWork: string;
  reminder: {
    allField: string;
    someField: string;
    contact: string;
  };
  sepa: {
    header: string;
    error?: string;
  };
  default: {
    make_default: string;
    apply_all: string;
  };
  link: string;
  address?: {
    address?: string;
    postal_code?: string;
    city?: string;
  };
};
