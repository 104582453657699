import { atom, atomFamily, selectorFamily } from 'recoil';
import {
  addDays,
  addYears,
  DAYS_IN_HALF,
  DAYS_IN_MONTH,
  DAYS_IN_QUARTER,
  DAYS_IN_WEEK,
  DAYS_IN_YEAR,
  diffInDays,
  getChartDate,
  getChartDateStr,
  getChartHalfStr,
  getChartMonthStr,
  getChartQuarterStr,
  getChartYearStr,
  isDataRecordInRange,
} from 'src/components/formComponents';
import { METRIC_PERIOD, TMetricPeriod } from 'src/js/constants/subs';
import { TEpSubsDailyStatsSingleMetricData } from 'src/models';
import { localTimeZone } from 'src/models/i18n';
import { genReportsChartData } from 'src/services';
import { agg, arrayToMap, sum } from 'src/utilities/commonUtils';

// *********************************************** //
//                 Raw Data States                 //
// *********************************************** //
/**
 * 1. Have a refresh button to refresh stats
 * 2. Schedule regular refresh automatically after every 5 mins (To be discussed)?
 */

// /**
//  * An atom that stores the refresh Request ID for home page daily stats data
//  */
// export const homePageDailyStatsRequestIdAtom = atom<number>({
//   key: 'homePageDailyStatsRequestIdAtom',
//   default: 0,
// });

export const metricChartMinStartDateAtom = atom<Date>({
  key: 'metricChartMinStartDateAtom',
  default: addYears(getChartDate(localTimeZone(new Date())), -2),
});

export const metricChartMaxEndDateAtom = atom<Date>({
  key: 'metricChartMaxEndDateAtom',
  default: getChartDate(localTimeZone(new Date())),
});

export type TMetricId = number;
export const rawDailyMetricChartDataRequestIdAtomsFamily = atomFamily<number, TMetricId>({
  key: 'rawDailyMetricChartDataRequestIdAtomsFamily',
  default: 1,
});

export const rawDailyMetricChartDataSelectorsFamily = selectorFamily<
  TEpSubsDailyStatsSingleMetricData,
  TMetricId
>({
  key: 'rawDailyMetricChartDataSelectorsFamily',
  get:
    metricId =>
    async ({ get }) => {
      const minStartDate = get(metricChartMinStartDateAtom);
      const maxEndDate = get(metricChartMaxEndDateAtom);
      const totalDays = diffInDays(maxEndDate, minStartDate) + 1;
      get(rawDailyMetricChartDataRequestIdAtomsFamily(metricId)); // Used to refresh

      const metricData = await genReportsChartData({
        metric_id: metricId,
        view_period: 'daily',
        number_of_records: totalDays,
      });
      return metricData;
    },
});

// *********************************************** //
//                 Metric Card Data States         //
// *********************************************** //

export const startDayOfWeekAtom = atom<number>({
  key: 'startDayOfWeekAtom',
  default: 1,
});
export const homepageMetricChartStartDateAtom = atom<Date>({
  key: 'homepageMetricChartStartDateAtom',
  default: addYears(getChartDate(localTimeZone(new Date())), -1),
});

export const homepageMetricChartEndDateAtom = atom<Date>({
  key: 'homepageMetricChartEndDateAtom',
  default: getChartDate(localTimeZone(new Date())),
});

type DateStr = string;
export type TMetricAggMethodKey = 'sum' | 'last';
export type TMetricChartDataParameters = {
  metricId: TMetricId;
  period: TMetricPeriod;
  isRolling: boolean;
  startDate: DateStr;
  endDate: DateStr;
  aggMethodKey: TMetricAggMethodKey;
};

export const getGroupByKeyMethod = (
  period: Exclude<TMetricPeriod, typeof METRIC_PERIOD.DAILY>,
  isRolling: boolean,
  startDayOfWeek?: number,
): ((keyDate: Date, endAggDate: Date) => string) => {
  switch (period) {
    case METRIC_PERIOD.WEEKLY:
      switch (isRolling) {
        case true:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date, endAggDate: Date) => {
            const weekKey = getRollingPeriodKeyDate(
              getChartDate(keyDate),
              endAggDate,
              DAYS_IN_WEEK,
            );
            return getChartDateStr(weekKey, 'key');
          };
        case false:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date, endAggDate: Date) => {
            const itemDate = getChartDate(keyDate);
            const endPeriodKeyDate = addDays(
              endAggDate,
              -(endAggDate.getDay() - (startDayOfWeek ?? 1)),
            );
            const weekKey = getCalendarPeriodKeyDate(
              itemDate,
              endPeriodKeyDate,
              METRIC_PERIOD.WEEKLY,
            );
            return getChartDateStr(weekKey, 'key');
          };
      }
      break;
    case METRIC_PERIOD.MONTHLY:
      switch (isRolling) {
        case true:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date, endAggDate: Date) => {
            const monthKey = getRollingPeriodKeyDate(
              getChartDate(keyDate),
              endAggDate,
              DAYS_IN_MONTH,
            );
            return getChartDateStr(monthKey, 'key');
          };
        case false:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date) => {
            const itemDate = getChartDate(keyDate);
            return getChartMonthStr(itemDate, 'key');
          };
      }
      break;
    case METRIC_PERIOD.QUARTERLY:
      switch (isRolling) {
        case true:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date, endAggDate: Date) => {
            const quarterKey = getRollingPeriodKeyDate(
              getChartDate(keyDate),
              endAggDate,
              DAYS_IN_QUARTER,
            );
            return getChartDateStr(quarterKey, 'key');
          };
        case false:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date) => {
            const itemDate = getChartDate(keyDate);
            return getChartQuarterStr(new Date(itemDate));
          };
      }
      break;
    case METRIC_PERIOD.SEMIANNUAL:
      switch (isRolling) {
        case true:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date, endAggDate: Date) => {
            const halfKey = getRollingPeriodKeyDate(
              getChartDate(keyDate),
              endAggDate,
              DAYS_IN_HALF,
            );
            return getChartDateStr(halfKey, 'key');
          };

        case false:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date) => {
            const itemDate = getChartDate(keyDate);
            return getChartHalfStr(new Date(itemDate));
          };
      }
      break;
    case METRIC_PERIOD.YEARLY:
      switch (isRolling) {
        case true:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date, endAggDate: Date) => {
            const yearKey = getRollingPeriodKeyDate(
              getChartDate(keyDate),
              endAggDate,
              DAYS_IN_YEAR,
            );
            return getChartDateStr(yearKey, 'key');
          };

        case false:
          // Could be optimized with Lodash build-in method
          return (keyDate: Date) => {
            const itemDate = getChartDate(keyDate);
            return getChartYearStr(new Date(itemDate));
          };
      }
      break;
  }
};

export type TDailyMetricChartDataMap = Map<
  TEpSubsDailyStatsSingleMetricData[number]['day'],
  TEpSubsDailyStatsSingleMetricData[number]['value']
>;
export const dailyAggMetricChartDataSelectorsFamily = selectorFamily<
  TDailyMetricChartDataMap,
  TMetricChartDataParameters
>({
  key: 'dailyAggMetricChartDataSelectorsFamily',
  get:
    ({ metricId, period, isRolling, startDate, endDate, aggMethodKey }) =>
    async ({ get }) => {
      const startAggDate = new Date(startDate);
      const endAggDate = new Date(endDate);

      const rawMetricData: TEpSubsDailyStatsSingleMetricData = get(
        rawDailyMetricChartDataSelectorsFamily(metricId),
      ).filter(dataRecord =>
        isDataRecordInRange(new Date(dataRecord.day), startAggDate, endAggDate),
      );

      let aggMethod: (_key: string, item: TEpSubsDailyStatsSingleMetricData) => number;
      switch (aggMethodKey) {
        case 'sum':
          aggMethod = metricItemSum;
          break;
        case 'last':
          aggMethod = metricItemLast;
          break;
      }
      // const totalDays = diffInDays(endDate, startDate) + 1;
      let aggregatedMetricData: TDailyMetricChartDataMap;
      switch (period) {
        case METRIC_PERIOD.DAILY:
          aggregatedMetricData = arrayToMap(
            rawMetricData,
            item => getChartDateStr(new Date(item.day), 'key'),
            item => item.value,
          ) as TDailyMetricChartDataMap;
          // TEpSubsDailyStatsSingleMetricData[]['value']
          break;
        case METRIC_PERIOD.WEEKLY:
          switch (isRolling) {
            case true:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;

            case false:
              // Could be optimized with Lodash build-in method
              const startDayOfWeek = get(startDayOfWeekAtom);
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(
                    period,
                    isRolling,
                    startDayOfWeek,
                  )(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;
          }
          break;
        case METRIC_PERIOD.MONTHLY:
          switch (isRolling) {
            case true:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;

            case false:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;
          }
          break;
        case METRIC_PERIOD.QUARTERLY:
          switch (isRolling) {
            case true:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;

            case false:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;
          }
          break;
        case METRIC_PERIOD.SEMIANNUAL:
          switch (isRolling) {
            case true:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;

            case false:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;
          }
          break;
        case METRIC_PERIOD.YEARLY:
          switch (isRolling) {
            case true:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;

            case false:
              // Could be optimized with Lodash build-in method
              aggregatedMetricData = agg({
                array: rawMetricData,
                groupByKeyMethod: item =>
                  getGroupByKeyMethod(period, isRolling)(new Date(item.day), endAggDate),
                aggMethod,
              });
              break;
          }
          break;
      }
      return aggregatedMetricData;
    },
});

const getRollingPeriodKeyDate = (
  itemDate: Date,
  endDate: Date,
  periodLength: number,
): Date => {
  // The last period is called the -1 (last first) period.
  const periodNum = Math.abs(
    Math.round(diffInDays(endDate, itemDate) / periodLength) + 1,
  );
  // Use the start day of the rolling period as the period key
  const periodKey = addDays(endDate, -periodNum * periodLength + 1);
  return periodKey;
};

const getCalendarPeriodKeyDate = (
  itemDate: Date,
  endPeriodKeyDate: Date,
  period: TMetricPeriod,
): Date => {
  switch (period) {
    case METRIC_PERIOD.WEEKLY:
      return getRollingPeriodKeyDate(itemDate, endPeriodKeyDate, DAYS_IN_WEEK);
    // case METRIC_PERIOD.MONTHLY:
    //   const periodKey = endPeriodKeyDate;
    //   return periodKey;
  }
  // To be modified
  return localTimeZone(new Date());
};

export const metricItemSum = (
  _key: string,
  item: TEpSubsDailyStatsSingleMetricData,
): number =>
  sum({
    array: item,
    initialItem: { day: 'sum', value: 0 },
    sumMethod: (item1, item2) => {
      return { day: 'sum', value: item1.value + item2.value };
    },
    valueMethod: item => item.value,
  });

export const metricItemLast = (
  _key: string,
  item: TEpSubsDailyStatsSingleMetricData,
): number => item[item.length - 1].value;
