import * as React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FormControl from 'react-bootstrap/FormControl';

interface Props {
  options: any;
  onSearch: any;
}

const SearchBar = (props: Props): JSX.Element => {
  const [selectedOptionKey, setSelectedOptionKey] = useState('');
  const [userInput, setUserInput] = useState('');

  const handleSelect = (eventKey: any) => {
    setSelectedOptionKey(eventKey);
  };
  const handleInputChange = (e: any) => {
    setUserInput(e.target.value);
  };

  const result = { option: selectedOptionKey, input: userInput };

  return (
    <InputGroup className="mb-3 mx-auto" style={{ maxWidth: '700px' }}>
      <DropdownButton
        variant="outline-secondary"
        title={`Search by ${selectedOptionKey ? props.options[selectedOptionKey] : ''}`}
        onSelect={handleSelect}
      >
        {Object.keys(props.options).map(key => {
          return (
            <Dropdown.Item key={key} eventKey={key}>
              {props.options[key]}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
      <FormControl
        placeholder="Placeholder"
        aria-label="Placeholder"
        aria-describedby="basic-addon2"
        className={'mr-3 rounded'}
        onChange={handleInputChange}
      />
      <Button variant="primary" onClick={() => props.onSearch(result)}>
        Search
      </Button>
    </InputGroup>
  );
};

export default SearchBar;
