import { atom, selector } from 'recoil';
import { IEpSubsTagsListValue } from 'src/models';
import { getCustomerTags, getInvoiceTags, getSubscriptionTags } from 'src/services';
import { arrayToMap } from 'src/utilities/commonUtils';

export const customerTagsRequestAtom = atom<number>({
  key: 'customerTagsRequestAtom',
  default: 0,
});

export const subscriptionTagsRequestAtom = atom<number>({
  key: 'subscriptionTagsRequestAtom',
  default: 0,
});

export const invoiceTagsRequestAtom = atom<number>({
  key: 'invoiceTagsRequestAtom',
  default: 0,
});

export const allCustomerTagsSelector = selector<IEpSubsTagsListValue[]>({
  key: 'allCustomerTagsSelector',
  get: async ({ get }) => {
    get(customerTagsRequestAtom);
    const allCustomerTags = await getCustomerTags();
    return allCustomerTags;
  },
});

export const allSubscriptionTagsSelector = selector<IEpSubsTagsListValue[]>({
  key: 'allSubscriptionTagsSelector',
  get: async ({ get }) => {
    get(subscriptionTagsRequestAtom);
    const allSubscriptionTags = await getSubscriptionTags();
    return allSubscriptionTags;
  },
});

export const allInvoiceTagsSelector = selector<IEpSubsTagsListValue[]>({
  key: 'allInvoiceTagsSelector',
  get: async ({ get }) => {
    get(invoiceTagsRequestAtom);
    const allInvoiceTags = await getInvoiceTags();
    return allInvoiceTags;
  },
});

export const customerTagsMapSelector = selector<Map<string, IEpSubsTagsListValue>>({
  key: 'customerTagsMapSelector',
  get: async ({ get }) => {
    const tagsList = get(allCustomerTagsSelector);
    return arrayToMap(tagsList, val => val.tag_id);
  },
});

export const subscriptionTagsMapSelector = selector<Map<string, IEpSubsTagsListValue>>({
  key: 'subscriptionTagsMapSelector',
  get: async ({ get }) => {
    const tagsList = get(allSubscriptionTagsSelector);
    return arrayToMap(tagsList, val => val.tag_id);
  },
});

export const invoiceTagsMapSelector = selector<Map<string, IEpSubsTagsListValue>>({
  key: 'invoiceTagsMapSelector',
  get: async ({ get }) => {
    const tagsList = get(allInvoiceTagsSelector);
    return arrayToMap(tagsList, val => val.tag_id);
  },
});
