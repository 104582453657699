import { useRecoilCallback } from 'recoil';
import {
  currentCustomerActivitiesSearchParamAtom,
  currentCustomerAddressesSearchParamAtom,
  currentCustomerCommentsSearchParamAtom,
  customerRefreshRequestIDAtoms,
  customerSearchRequestIDAtom,
} from '../';
import {
  DEFAULT_ACTIVITY_COUNT,
  DEFAULT_ADDRESS_COUNT,
  DEFAULT_COMMENT_COUNT,
} from '../../js/constants/subs';
import { currentCustomerPaymentMethodsRequestIDAtom } from './customerDetailPageStates';

// *********************************************** //
//                Customer List Page Hooks             //
// *********************************************** //

export const useRefreshCustomerList = (): (() => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh customer list
        set(customerSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshCustomer = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        // refresh customer
        set(customerRefreshRequestIDAtoms(id), requestID => requestID + 1);

        // refresh Activity log
        set(currentCustomerActivitiesSearchParamAtom, prevParam => {
          return {
            limit: prevParam.limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

// *********************************************** //
//            Customer Detail Page Hooks           //
// *********************************************** //

export const useRefreshCurrentCustomerAddresses = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ADDRESS_COUNT) => {
        set(currentCustomerAddressesSearchParamAtom, prevParam => {
          return {
            limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useRefreshCurrentCustomerPaymentMethods = (): (() => void) => {
  return useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          currentCustomerPaymentMethodsRequestIDAtom,
          prevParam => prevParam + 1, // for refresh query
        );
      },
    [],
  );
};

export const useRefreshCurrentCustomerActivities = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ACTIVITY_COUNT) => {
        set(currentCustomerActivitiesSearchParamAtom, prevParam => {
          return {
            limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useRefreshCurrentCustomerComments = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_COMMENT_COUNT) => {
        set(currentCustomerCommentsSearchParamAtom, prevParam => {
          return {
            limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};
