import React from 'react';
import style from './EpSubsMoneyCell.module.scss';
import { convertMoneySignDependLocale, getCurrencyCode } from 'src/models/i18n';

type Props = {
  money?: number | null;
  currencyCode?: string;
  direction?: 'rtl' | 'ltr';
  align?: 'right' | 'left';
};

export const EpSubsMoneyCell = (props: Props): JSX.Element => {
  const {
    money,
    currencyCode = getCurrencyCode(),
    direction = 'rtl',
    align = 'right',
  } = props;
  return (
    <div className={`${style.money_row} ${style[`align_${align}`]}`}>
      <div className={style.flex_row} style={{ direction }}>
        {/* Need direction: rtl (right to left for right alignment) */}
        <div className={style.unit}>{currencyCode}</div>
        <div>{convertMoneySignDependLocale(money ?? 0)}</div>
      </div>
    </div>
  );
};
