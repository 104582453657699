import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { FILTER_SOURCE, USER_STATUS } from 'src/js/constants/subs';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import EpSubsListNav from '../../../components/navBars/EpSubsListNav/EpSubsListNav';
import EpSubsUserRoleModal, {
  IUserRoleFormData,
} from '../../../components/modals/EpSubsUserRoleModal';
import { putNoty } from '../../../utilities/epSubsNoty';
import { inviteUser } from '../../../services';
import { useResetRecoilState } from 'recoil';
import { useRefreshUserList, userRolesSelectedRolesAtom } from '../../../recoil';

const USER_STATUS_FILTER_GROUP_NAME = 'user_status';
export const USER_STATUS_FILTER_GROUP_FIELD = 'status';

export const DEFAULT_USER_SEARCH_OPTION = {
  [USER_STATUS_FILTER_GROUP_FIELD]: [USER_STATUS.ACTIVE],
};

const EpSubsUsersListNav = (routerProps: RouteComponentProps): JSX.Element => {
  const [showInviteModal, setShowInviteModal] = useState(false);

  const history = useHistory();

  const createBtnText = '+ Invite Members';

  const resetUserRolesSelectedRoles = useResetRecoilState(userRolesSelectedRolesAtom);
  const refreshUserList = useRefreshUserList();

  const onCreateBtnClick = () => {
    setShowInviteModal(true);
    resetUserRolesSelectedRoles();
  };

  const onInviteMember = async (data: IUserRoleFormData) => {
    if (data['rolesList'] == undefined) {
      data['roles'] = '1';
    } else if (data['rolesList'].length == 0) {
      putNoty({ type: 'error', text: 'Select at least one role!' });
      return false;
    } else {
      data['roles'] = data['rolesList'].join(',');
    }
    try {
      const message = `The user has successfully been sent an invitation email. The new account is currently in pending status.`;
      await inviteUser(data);
      putNoty({ type: 'success', text: `${message}` });
    } catch (e) {
      // putNoty({ type: 'error', text: (e as Error).message });
    }
    await refreshUserList();
    setShowInviteModal(false);
    return true;
  };

  return (
    <>
      <EpSubsListNav
        key="users"
        {...{
          routerProps,
          createBtnText,
          onCreateBtnClick,
          history,
          source: FILTER_SOURCE.USER,
          allSimplyFilterOptions: {
            [USER_STATUS_FILTER_GROUP_FIELD]: Object.values(USER_STATUS).map(status => {
              return {
                label: toInitialUpperCase(status),
                value: status,
                groupName: USER_STATUS_FILTER_GROUP_NAME,
                groupField: USER_STATUS_FILTER_GROUP_FIELD,
              };
            }),
          },
          defaultSimplyFilterOption: DEFAULT_USER_SEARCH_OPTION,
        }}
      />
      <EpSubsUserRoleModal
        modalShow={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        headerName="Invite a team member to use Conductor"
        onSubmit={onInviteMember}
        showChecked={false}
      />
    </>
  );
};

export default EpSubsUsersListNav;
