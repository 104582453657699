import React, { ReactNode, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import style from './EpSubsFormInvisibleInput.module.scss';

/**
 * A container with invisible input tag to trigger validation effects
 * @param className Define the style of the EpSubsFormInvisibleInput as div container
 * @param validate Callback on form validation failure
 * @param name Form attributes name on formData
 * @param checked Value of the field taken from watch of useForm hook
 * @param register Directly from useForm hook
 * @param control Directly from useForm hook
 */
const EpSubsFormInvisibleInput = (props: {
  className: string;
  checked: boolean;
  name?: string;
  register?: any;
  children: ReactNode;
  control: any;
  onClick: () => void;
  validate?: () => boolean;
}) => {
  const {
    className,
    name = 'invisible_input',
    checked,
    register,
    children,
    control,
    validate,
  } = props;
  const onClick = () => {
    props.onClick();
  };
  const selectRef = useRef<HTMLDivElement | null>(null);

  let rules = {};
  if (validate) {
    rules = { validate };
  }
  return (
    <Controller
      name={name}
      control={control}
      ref={register()}
      defaultValue={checked}
      rules={rules}
      onFocus={() => {
        if (selectRef.current != null) selectRef.current.focus();
      }}
      render={({ value }) => (
        <div
          tabIndex={0}
          onClick={onClick}
          className={`${style.subs_toggle} ${
            checked ? style.subs_toggle__checked : ''
          } ${className}`}
          ref={ref => (selectRef.current = ref)}
        >
          <Form.Control
            className={style.subs_toggle_input}
            as="input"
            type="checkbox"
            aria-label={name}
            name={name}
            id={name}
            defaultChecked={value}
          />
          {children}
        </div>
      )}
    />
  );
};

export default EpSubsFormInvisibleInput;
