import axios from 'axios';
import {
  IEpSubsDBWebhookPost,
  IEpSubsDBWebhookValue,
  IEpSubsWebhookLogValue,
  TEpSubsDBWebhookTypeValue,
  TEpSubsDBWebhookValueKeys,
} from 'src/models/epSubsCompositeDBTypes.js';
import { TEpSubsDBResponseOk } from 'src/models/epSubsDBGenTypes.js';
import { hostUrl } from '../config.js';
import { getSortSQLStrFromSortingItems, TSortingItem } from './epSubsServiceUtils';

// *********************************************** //
//                Webhook Api Service            //
// *********************************************** //

export const genWebhookListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBWebhookValueKeys>> = [],
  simpleQuery?: string,
  // status = '',
  // productType = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBWebhookValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBWebhookValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/webhook?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const getAllWebhookList = async (): Promise<IEpSubsDBWebhookValue[]> => {
  return axios.get(`${hostUrl}/webhook`).then(response => {
    return response.data.data ?? [];
  });
};
export const genCreateWebhook = async (data: IEpSubsDBWebhookPost): Promise<number> => {
  return axios.post(`${hostUrl}/webhook`, data).then(response => {
    return response.data.data;
  });
};

export const genWebhookData = async (id: number): Promise<IEpSubsDBWebhookValue> => {
  return axios.get(`${hostUrl}/webhook/${id}`).then(response => {
    return response.data;
  });
};

export const genEditWebhook = async (
  id: number,
  data: IEpSubsDBWebhookPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/webhook/${id}`, data).then(() => {
    return true;
  });
};

export const genDeleteWebhook = async (id: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/webhook/${id}`).then(() => {
    return true;
  });
};

export const getWebhookToggle = async (id: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/webhook/${id}/toggle`).then(response => {
    return response.data.data;
  });
};

export const getWebhookTypes = async (): Promise<TEpSubsDBWebhookTypeValue[]> => {
  return axios.get(`${hostUrl}/webhook/event-types/active`).then(response => {
    return response.data.data;
  });
};

export const getWebhookLogs = async (
  id: number,
  limit: number,
): Promise<IEpSubsWebhookLogValue[]> => {
  return axios.get(`${hostUrl}/webhook/${id}/log?limit=${limit}`).then(response => {
    return response.data.data;
  });
};
