export const SYSTEM_CREATOR = ['system', 'scheduler_job'];
// post Addon
export interface IEpSubsDBAddonPost {
  id?: number | null;
  addon_id?: string | null;
  name?: string | null;
  invoice_name?: string | null;
  description?: string | null;
  pricing_model?: string | null;
  charge_type?: string | null;
  price?: number | null;
  currency_code?: string | null;
  period?: number | null;
  period_unit?: string | null;
  period_totaldays?: number | null;
  included_in_mrr?: boolean | null;
  status?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
  archived_at?: string | null;
  taxability?: string | null;
  version?: number | null;
}

// get Addon
export interface IEpSubsDBAddonValue extends IEpSubsDBAddonPost {
  id: number;
  addon_id: string;
  name: string;
  pricing_model: string;
  charge_type: string;
  price: number;
  currency_code: string;
  period: number;
  period_unit: string;
  period_totaldays: number;
  included_in_mrr: boolean;
  status: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  taxability: string;
  version: number;
}

export type TEpSubsDBAddonValueKeys = keyof IEpSubsDBAddonValue;

export type TEpSubsDBAddonListData = Array<IEpSubsDBAddonValue>;

// post Addon_activity
export interface IEpSubsDBAddon_activityPost {
  id?: number | null;
  addon_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Addon_activity
export interface IEpSubsDBAddon_activityValue extends IEpSubsDBAddon_activityPost {
  id: number;
  addon_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBAddon_activityValueKeys = keyof IEpSubsDBAddon_activityValue;

export type TEpSubsDBAddon_activityListData = Array<IEpSubsDBAddon_activityValue>;

// post Addon_comment
export interface IEpSubsDBAddon_commentPost {
  id?: number | null;
  addon_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Addon_comment
export interface IEpSubsDBAddon_commentValue extends IEpSubsDBAddon_commentPost {
  id: number;
  addon_id: number;
  body: string;
  attachment_url: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBAddon_commentValueKeys = keyof IEpSubsDBAddon_commentValue;

export type TEpSubsDBAddon_commentListData = Array<IEpSubsDBAddon_commentValue>;

// post Apikey
export interface IEpSubsDBApikeyPost {
  id?: number | null;
  api_key?: string | null;
  name?: string | null;
  access_level?: string | null;
  ip_filter?: string | null;
  enabled?: boolean | null;
  deleted?: boolean | null;
  created_at?: string | null;
  created_by?: number | null;
  updated_at?: string | null;
  updated_by?: number | null;
}

// get Apikey
export interface IEpSubsDBApikeyValue extends IEpSubsDBApikeyPost {
  id: number;
  api_key: string;
  name: string;
  access_level: string;
  enabled: boolean;
  deleted: boolean;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

export type TEpSubsDBApikeyValueKeys = keyof IEpSubsDBApikeyValue;

export type TEpSubsDBApikeyListData = Array<IEpSubsDBApikeyValue>;

// post Coupon
export interface IEpSubsDBCouponPost {
  id?: number | null;
  coupon_id?: string | null;
  name?: string | null;
  invoice_name?: string | null;
  status?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
  discount_type?: string | null;
  discount_value?: number | null;
  discount_period_unit?: string | null;
  duration_type?: string | null;
  duration_period?: number | null;
  duration_period_unit?: string | null;
  max_redemptions?: number | null;
  valid_till?: number | null;
  num_redemptions?: number | null;
  apply_on?: string | null;
  plan_applicable?: string | null;
  addon_applicable?: string | null;
  version?: number | null;
}

// get Coupon
export interface IEpSubsDBCouponValue extends IEpSubsDBCouponPost {
  id: number;
  coupon_id: string;
  name: string;
  status: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  discount_type: string;
  discount_value: number;
  duration_type: string;
  num_redemptions: number;
  apply_on: string;
  version: number;
}

export type TEpSubsDBCouponValueKeys = keyof IEpSubsDBCouponValue;

export type TEpSubsDBCouponListData = Array<IEpSubsDBCouponValue>;

// post Coupon_activity
export interface IEpSubsDBCoupon_activityPost {
  id?: number | null;
  coupon_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Coupon_activity
export interface IEpSubsDBCoupon_activityValue extends IEpSubsDBCoupon_activityPost {
  id: number;
  coupon_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCoupon_activityValueKeys = keyof IEpSubsDBCoupon_activityValue;

export type TEpSubsDBCoupon_activityListData = Array<IEpSubsDBCoupon_activityValue>;

// post Coupon_comment
export interface IEpSubsDBCoupon_commentPost {
  id?: number | null;
  coupon_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Coupon_comment
export interface IEpSubsDBCoupon_commentValue extends IEpSubsDBCoupon_commentPost {
  id: number;
  coupon_id: number;
  body: string;
  attachment_url: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCoupon_commentValueKeys = keyof IEpSubsDBCoupon_commentValue;

export type TEpSubsDBCoupon_commentListData = Array<IEpSubsDBCoupon_commentValue>;

// post Coupon_applicable
export interface IEpSubsDBCoupon_applicablePost {
  id?: number | null;
  coupon_id?: number | null;
  item_id?: string | null;
  item_type?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Coupon_applicable
export interface IEpSubsDBCoupon_applicableValue extends IEpSubsDBCoupon_applicablePost {
  id: number;
  coupon_id: number;
  item_id: string;
  item_type: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCoupon_applicableValueKeys = keyof IEpSubsDBCoupon_applicableValue;

export type TEpSubsDBCoupon_applicableListData = Array<IEpSubsDBCoupon_applicableValue>;

// post Credit_note
export interface IEpSubsDBCredit_notePost {
  id?: number | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  invoice_id?: number | null;
  credit_dest?: string | null;
  transaction_id?: number | null;
  type?: string | null;
  reason_code?: string | null;
  status?: string | null;
  total?: number | null;
  amount_allocated?: number | null;
  amount_available?: number | null;
  amount_refunded?: number | null;
  customer_notes?: string | null;
  comment?: string | null;
  refunded_at?: string | null;
  voided_at?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Credit_note
export interface IEpSubsDBCredit_noteValue extends IEpSubsDBCredit_notePost {
  id: number;
  customer_id: number;
  invoice_id: number;
  credit_dest: string;
  transaction_id: number;
  type: string;
  reason_code: string;
  status: string;
  total: number;
  amount_allocated: number;
  amount_available: number;
  amount_refunded: number;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCredit_noteValueKeys = keyof IEpSubsDBCredit_noteValue;

export type TEpSubsDBCredit_noteListData = Array<IEpSubsDBCredit_noteValue>;

// post Credit_note_allocation
export interface IEpSubsDBCredit_note_allocationPost {
  id?: number | null;
  credit_note_id?: number | null;
  invoice_id?: number | null;
  allocated_amount?: number | null;
  allocated_at?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Credit_note_allocation
export interface IEpSubsDBCredit_note_allocationValue
  extends IEpSubsDBCredit_note_allocationPost {
  id: number;
  credit_note_id: number;
  invoice_id: number;
  allocated_amount: number;
  allocated_at: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCredit_note_allocationValueKeys =
  keyof IEpSubsDBCredit_note_allocationValue;

export type TEpSubsDBCredit_note_allocationListData =
  Array<IEpSubsDBCredit_note_allocationValue>;

// post Credit_note_comment
export interface IEpSubsDBCredit_note_commentPost {
  id?: number | null;
  credit_note_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Credit_note_comment
export interface IEpSubsDBCredit_note_commentValue
  extends IEpSubsDBCredit_note_commentPost {
  id: number;
  credit_note_id: number;
  body: string;
  attachment_url: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCredit_note_commentValueKeys =
  keyof IEpSubsDBCredit_note_commentValue;

export type TEpSubsDBCredit_note_commentListData =
  Array<IEpSubsDBCredit_note_commentValue>;

// post Credit_note_activity
export interface IEpSubsDBCredit_note_activityPost {
  id?: number | null;
  credit_note_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Credit_note_activity
export interface IEpSubsDBCredit_note_activityValue
  extends IEpSubsDBCredit_note_activityPost {
  id: number;
  credit_note_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCredit_note_activityValueKeys =
  keyof IEpSubsDBCredit_note_activityValue;

export type TEpSubsDBCredit_note_activityListData =
  Array<IEpSubsDBCredit_note_activityValue>;

// post Credit_note_refund
export interface IEpSubsDBCredit_note_refundPost {
  id?: number | null;
  credit_note_id?: number | null;
  transaction_id?: number | null;
  applied_amount?: number | null;
  applied_at?: string | null;
  refund_reason_code?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
}

// get Credit_note_refund
export interface IEpSubsDBCredit_note_refundValue
  extends IEpSubsDBCredit_note_refundPost {
  id: number;
  credit_note_id: number;
  transaction_id: number;
  applied_amount: number;
  applied_at: string;
  refund_reason_code: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export type TEpSubsDBCredit_note_refundValueKeys = keyof IEpSubsDBCredit_note_refundValue;

export type TEpSubsDBCredit_note_refundListData = Array<IEpSubsDBCredit_note_refundValue>;

// post Customer
export interface IEpSubsDBCustomerPost {
  id?: number | null;
  customer_key?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
  pin?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone?: string | null;
  phone_digital?: string | null;
  phone_type?: string | null;
  company?: string | null;
  tags?: string | null;
  auto_collection?: boolean | null;
  promotional_credits?: number | null;
  refundable_credits?: number | null;
  giftcard_amount?: number | null;
  excess_payments?: number | null;
  primary_payment_method_id?: number | null;
  unbilled_charges?: number | null;
  locale?: string | null;
  card_status?: string | null;
  taxability?: string | null;
  email_notification?: boolean | null;
  dunning_notification?: boolean | null;
  state_code?: string | null;
  ip_address?: string | null;
  geoinfo?: string | null;
  version?: number | null;
}

// get Customer
export interface IEpSubsDBCustomerValue extends IEpSubsDBCustomerPost {
  id: number;
  customer_key: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  pin: string;
  first_name: string;
  last_name: string;
  phone: string;
  phone_digital: string;
  company: string;
  auto_collection: boolean;
  promotional_credits: number;
  refundable_credits: number;
  giftcard_amount: number;
  excess_payments: number;
  unbilled_charges: number;
  locale: string;
  card_status: string;
  taxability: string;
  email_notification: boolean;
  dunning_notification: boolean;
  version: number;
}

export type TEpSubsDBCustomerValueKeys = keyof IEpSubsDBCustomerValue;

export type TEpSubsDBCustomerListData = Array<IEpSubsDBCustomerValue>;

// post Customer_address
export interface IEpSubsDBCustomer_addressPost {
  id?: number | null;
  customer_id?: number | null;
  first_name?: string | null;
  last_name?: string | null;
  company?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state_code?: string | null;
  zip?: string | null;
  country?: string | null;
  validation_status?: boolean | null;
  start_at?: string | null;
  end_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

// get Customer_address
export interface IEpSubsDBCustomer_addressValue extends IEpSubsDBCustomer_addressPost {
  id: number;
  customer_id: number;
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state_code: string;
  zip: string;
  country: string;
  validation_status: boolean;
  created_at: string;
}

export type TEpSubsDBCustomer_addressValueKeys = keyof IEpSubsDBCustomer_addressValue;

export type TEpSubsDBCustomer_addressListData = Array<IEpSubsDBCustomer_addressValue>;

// post Customer_activity
export interface IEpSubsDBCustomer_activityPost {
  id?: number | null;
  customer_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
}

// get Customer_activity
export interface IEpSubsDBCustomer_activityValue extends IEpSubsDBCustomer_activityPost {
  id: number;
  customer_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
}

export type TEpSubsDBCustomer_activityValueKeys = keyof IEpSubsDBCustomer_activityValue;

export type TEpSubsDBCustomer_activityListData = Array<IEpSubsDBCustomer_activityValue>;

// post Customer_comment
export interface IEpSubsDBCustomer_commentPost {
  id?: number | null;
  customer_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_by?: string | null;
  created_at?: string | null;
}

// get Customer_comment
export interface IEpSubsDBCustomer_commentValue extends IEpSubsDBCustomer_commentPost {
  id: number;
  customer_id: number;
  body: string;
  attachment_url: string;
  created_by: string;
  created_at: string;
}

export type TEpSubsDBCustomer_commentValueKeys = keyof IEpSubsDBCustomer_commentValue;

export type TEpSubsDBCustomer_commentListData = Array<IEpSubsDBCustomer_commentValue>;

// post Customer_gateway
export interface IEpSubsDBCustomer_gatewayPost {
  id?: number | null;
  customer_id?: number | null;
  gateway?: number | null;
  gateway_customer_id?: string | null;
  created_at?: string | null;
}

// get Customer_gateway
export interface IEpSubsDBCustomer_gatewayValue extends IEpSubsDBCustomer_gatewayPost {
  id: number;
  customer_id: number;
  gateway: number;
  gateway_customer_id: string;
  created_at: string;
}

export type TEpSubsDBCustomer_gatewayValueKeys = keyof IEpSubsDBCustomer_gatewayValue;

export type TEpSubsDBCustomer_gatewayListData = Array<IEpSubsDBCustomer_gatewayValue>;

// post Customer_invalid
export interface IEpSubsDBCustomer_invalidPost {
  id?: number | null;
  customer_id?: number | null;
  email?: string | null;
  error?: string | null;
  created_at?: string | null;
}

// get Customer_invalid
export interface IEpSubsDBCustomer_invalidValue extends IEpSubsDBCustomer_invalidPost {
  id: number;
  customer_id: number;
  error: string;
  created_at: string;
}

export type TEpSubsDBCustomer_invalidValueKeys = keyof IEpSubsDBCustomer_invalidValue;

export type TEpSubsDBCustomer_invalidListData = Array<IEpSubsDBCustomer_invalidValue>;

// post Customer_payment_method
export interface IEpSubsDBCustomer_payment_methodPost {
  id?: number | null;
  pm_id?: string | null;
  customer_id?: number | null;
  alias?: string | null;
  payment_type?: string | null;
  gateway?: number | null;
  payment_source_id?: string | null;
  mandate_id?: string | null;
  card_type?: string | null;
  last4_digits?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  expiration_year?: string | null;
  expiration_month?: string | null;
  zip?: string | null;
  status?: number | null;
  deactive_reason?: string | null;
  reference_id?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

// get Customer_payment_method
export interface IEpSubsDBCustomer_payment_methodValue
  extends IEpSubsDBCustomer_payment_methodPost {
  id: number;
  pm_id: string;
  customer_id: number;
  payment_type: string;
  gateway: number;
  status: number;
  created_at: string;
}

export type TEpSubsDBCustomer_payment_methodValueKeys =
  keyof IEpSubsDBCustomer_payment_methodValue;

export type TEpSubsDBCustomer_payment_methodListData =
  Array<IEpSubsDBCustomer_payment_methodValue>;

// post Dunning_attempt
export interface IEpSubsDBDunning_attemptPost {
  id?: number | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  invoice_id?: number | null;
  transaction_id?: number | null;
  attempt?: number | null;
  dunning_type?: string | null;
  created_at?: string | null;
}

// get Dunning_attempt
export interface IEpSubsDBDunning_attemptValue extends IEpSubsDBDunning_attemptPost {
  id: number;
  customer_id: number;
  subscription_id: number;
  invoice_id: number;
  transaction_id: number;
  attempt: number;
  dunning_type: string;
  created_at: string;
}

export type TEpSubsDBDunning_attemptValueKeys = keyof IEpSubsDBDunning_attemptValue;

export type TEpSubsDBDunning_attemptListData = Array<IEpSubsDBDunning_attemptValue>;

// post Elastic_ui_params
export interface IEpSubsDBElastic_ui_paramsPost {
  id?: number | null;
  group_name?: string | null;
  field?: string | null;
  description?: string | null;
  data_type?: string | null;
  all_values?: string | null;
}

// get Elastic_ui_params
export interface IEpSubsDBElastic_ui_paramsValue extends IEpSubsDBElastic_ui_paramsPost {
  id: number;
  group_name: string;
  field: string;
  description: string;
  data_type: string;
}

export type TEpSubsDBElastic_ui_paramsValueKeys = keyof IEpSubsDBElastic_ui_paramsValue;

export type TEpSubsDBElastic_ui_paramsListData = Array<IEpSubsDBElastic_ui_paramsValue>;

// post Email_log
export interface IEpSubsDBEmail_logPost {
  id?: number | null;
  notification_id?: number | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  invoice_id?: number | null;
  to_email?: string | null;
  meta_data?: string | null;
  status?: number | null;
  message_id?: string | null;
  is_sample?: boolean | null;
  created_at?: string | null;
  server_status?: string | null;
  ip_address?: string | null;
  user_agent?: string | null;
  sent_at?: string | null;
  opened_at?: string | null;
  cliked_at?: string | null;
  is_forward?: boolean | null;
  key_ts?: string | null;
  key_int?: number | null;
  key_char?: string | null;
  key_ts2?: string | null;
  key_int2?: number | null;
  key_char2?: string | null;
  key_ts3?: string | null;
  key_int3?: number | null;
  key_char3?: string | null;
}

// get Email_log
export interface IEpSubsDBEmail_logValue extends IEpSubsDBEmail_logPost {
  id: number;
  notification_id: number;
  customer_id: number;
  invoice_id: number;
  to_email: string;
  meta_data: string;
  status: number;
  message_id: string;
  is_sample: boolean;
  created_at: string;
}

export type TEpSubsDBEmail_logValueKeys = keyof IEpSubsDBEmail_logValue;

export type TEpSubsDBEmail_logListData = Array<IEpSubsDBEmail_logValue>;

// post Excess_payments
export interface IEpSubsDBExcess_paymentsPost {
  id?: number | null;
  customer_id?: number | null;
  type?: string | null;
  amount?: number | null;
  description?: string | null;
  closing_balance?: number | null;
  invoice_id?: number | null;
  transaction_id?: number | null;
  done_by?: string | null;
  created_at?: string | null;
}

// get Excess_payments
export interface IEpSubsDBExcess_paymentsValue extends IEpSubsDBExcess_paymentsPost {
  id: number;
  customer_id: number;
  type: string;
  amount: number;
  description: string;
  closing_balance: number;
  invoice_id: number;
  done_by: string;
  created_at: string;
}

export type TEpSubsDBExcess_paymentsValueKeys = keyof IEpSubsDBExcess_paymentsValue;

export type TEpSubsDBExcess_paymentsListData = Array<IEpSubsDBExcess_paymentsValue>;

// post Fulfillment_center
export interface IEpSubsDBFulfillment_centerPost {
  id?: number | null;
  name?: string | null;
  invoice_name?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state_code?: string | null;
  zip?: string | null;
  country?: string | null;
  contact_person?: string | null;
  contact_phone?: string | null;
  contact_email?: string | null;
  contact_fax?: string | null;
  active?: boolean | null;
  created_by?: number | null;
  created_at?: string | null;
  updated_by?: number | null;
  updated_at?: string | null;
}

// get Fulfillment_center
export interface IEpSubsDBFulfillment_centerValue
  extends IEpSubsDBFulfillment_centerPost {
  id: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state_code: string;
  zip: string;
  country: string;
  active: boolean;
  created_by: number;
  created_at: string;
}

export type TEpSubsDBFulfillment_centerValueKeys = keyof IEpSubsDBFulfillment_centerValue;

export type TEpSubsDBFulfillment_centerListData = Array<IEpSubsDBFulfillment_centerValue>;

// post Fulfillment_delivery
export interface IEpSubsDBFulfillment_deliveryPost {
  id?: number | null;
  subscription_id?: number | null;
  delivery_method_id?: number | null;
  center_id?: number | null;
  shipping_id?: number | null;
  is_default?: boolean | null;
  status?: string | null;
  delivery_instruction?: string | null;
  paused_at?: string | null;
  resumed_at?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  updated_by?: number | null;
}

// get Fulfillment_delivery
export interface IEpSubsDBFulfillment_deliveryValue
  extends IEpSubsDBFulfillment_deliveryPost {
  id: number;
  subscription_id: number;
  delivery_method_id: number;
  center_id: number;
  shipping_id: number;
  is_default: boolean;
  status: string;
  created_at: string;
}

export type TEpSubsDBFulfillment_deliveryValueKeys =
  keyof IEpSubsDBFulfillment_deliveryValue;

export type TEpSubsDBFulfillment_deliveryListData =
  Array<IEpSubsDBFulfillment_deliveryValue>;

// post Fulfillment_delivery_method
export interface IEpSubsDBFulfillment_delivery_methodPost {
  id?: number | null;
  ref_delivery_method_id?: number | null;
  display_name?: string | null;
  center_id?: number | null;
  is_default?: boolean | null;
  created_by?: number | null;
  created_at?: string | null;
  updated_by?: number | null;
  updated_at?: string | null;
}

// get Fulfillment_delivery_method
export interface IEpSubsDBFulfillment_delivery_methodValue
  extends IEpSubsDBFulfillment_delivery_methodPost {
  id: number;
  ref_delivery_method_id: number;
  display_name: string;
  center_id: number;
  is_default: boolean;
  created_by: number;
  created_at: string;
}

export type TEpSubsDBFulfillment_delivery_methodValueKeys =
  keyof IEpSubsDBFulfillment_delivery_methodValue;

export type TEpSubsDBFulfillment_delivery_methodListData =
  Array<IEpSubsDBFulfillment_delivery_methodValue>;

// post Fulfillment_zipcode
export interface IEpSubsDBFulfillment_zipcodePost {
  id?: number | null;
  zipcode?: string | null;
  center_id?: number | null;
}

// get Fulfillment_zipcode
export interface IEpSubsDBFulfillment_zipcodeValue
  extends IEpSubsDBFulfillment_zipcodePost {
  id: number;
  zipcode: string;
  center_id: number;
}

export type TEpSubsDBFulfillment_zipcodeValueKeys =
  keyof IEpSubsDBFulfillment_zipcodeValue;

export type TEpSubsDBFulfillment_zipcodeListData =
  Array<IEpSubsDBFulfillment_zipcodeValue>;

// post Gift_card
export interface IEpSubsDBGift_cardPost {
  id?: number | null;
  invoice_id?: number | null;
  amount?: number | null;
  message?: string | null;
  claim_code?: string | null;
  recipient_email?: string | null;
  status?: string | null;
  gift_invoice_id?: number | null;
  purchased_by?: number | null;
  claimed_by?: number | null;
  created_at?: string | null;
  claimed_at?: string | null;
  meta_info?: string | null;
}

// get Gift_card
export interface IEpSubsDBGift_cardValue extends IEpSubsDBGift_cardPost {
  id: number;
  invoice_id: number;
  amount: number;
  message: string;
  claim_code: string;
  recipient_email: string;
  status: string;
  purchased_by: number;
  created_at: string;
}

export type TEpSubsDBGift_cardValueKeys = keyof IEpSubsDBGift_cardValue;

export type TEpSubsDBGift_cardListData = Array<IEpSubsDBGift_cardValue>;

// post Gift_card_amount
export interface IEpSubsDBGift_card_amountPost {
  id?: number | null;
  customer_id?: number | null;
  type?: string | null;
  amount?: number | null;
  description?: string | null;
  closing_balance?: number | null;
  invoice_id?: number | null;
  done_by?: string | null;
  created_at?: string | null;
}

// get Gift_card_amount
export interface IEpSubsDBGift_card_amountValue extends IEpSubsDBGift_card_amountPost {
  id: number;
  customer_id: number;
  type: string;
  amount: number;
  description: string;
  closing_balance: number;
  done_by: string;
  created_at: string;
}

export type TEpSubsDBGift_card_amountValueKeys = keyof IEpSubsDBGift_card_amountValue;

export type TEpSubsDBGift_card_amountListData = Array<IEpSubsDBGift_card_amountValue>;

// post Invoice
export interface IEpSubsDBInvoicePost {
  id?: number | null;
  invoice_key?: string | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  tags?: string | null;
  recurring?: boolean | null;
  credits_issued?: number | null;
  subtotal?: number | null;
  tax?: number | null;
  taxability?: string | null;
  tax_rate?: number | null;
  total?: number | null;
  status?: string | null;
  due_date?: string | null;
  billing_address?: string | null;
  shipping_address?: string | null;
  po_number?: string | null;
  date_from?: string | null;
  date_to?: string | null;
  amount_to_collect?: number | null;
  amount_due?: number | null;
  amount_paid?: number | null;
  amount_adjusted?: number | null;
  amount_disputed?: number | null;
  created_at?: string | null;
  updated_at?: string | null;
  paid_at?: string | null;
  voided_at?: string | null;
  dunning_status?: string | null;
  next_retry_at?: string | null;
  dunning_phase?: string | null;
  version?: number | null;
}

// get Invoice
export interface IEpSubsDBInvoiceValue extends IEpSubsDBInvoicePost {
  id: number;
  customer_id: number;
  recurring: boolean;
  credits_issued: number;
  subtotal: number;
  tax: number;
  taxability: string;
  tax_rate: number;
  total: number;
  status: string;
  due_date: string;
  amount_to_collect: number;
  amount_due: number;
  amount_paid: number;
  amount_adjusted: number;
  amount_disputed: number;
  created_at: string;
  updated_at: string;
  version: number;
}

export type TEpSubsDBInvoiceValueKeys = keyof IEpSubsDBInvoiceValue;

export type TEpSubsDBInvoiceListData = Array<IEpSubsDBInvoiceValue>;

// post Invoice_activity
export interface IEpSubsDBInvoice_activityPost {
  id?: number | null;
  invoice_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
}

// get Invoice_activity
export interface IEpSubsDBInvoice_activityValue extends IEpSubsDBInvoice_activityPost {
  id: number;
  invoice_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
}

export type TEpSubsDBInvoice_activityValueKeys = keyof IEpSubsDBInvoice_activityValue;

export type TEpSubsDBInvoice_activityListData = Array<IEpSubsDBInvoice_activityValue>;

// post Invoice_comment
export interface IEpSubsDBInvoice_commentPost {
  id?: number | null;
  invoice_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_by?: string | null;
  created_at?: string | null;
}

// get Invoice_comment
export interface IEpSubsDBInvoice_commentValue extends IEpSubsDBInvoice_commentPost {
  id: number;
  invoice_id: number;
  body: string;
  attachment_url: string;
  created_by: string;
  created_at: string;
}

export type TEpSubsDBInvoice_commentValueKeys = keyof IEpSubsDBInvoice_commentValue;

export type TEpSubsDBInvoice_commentListData = Array<IEpSubsDBInvoice_commentValue>;

// post Invoice_item
export interface IEpSubsDBInvoice_itemPost {
  id?: number | null;
  invoice_id?: number | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  subscription_item_id?: number | null;
  amount?: number | null;
  tax?: number | null;
  date_from?: string | null;
  date_to?: string | null;
  entity_type?: string | null;
  entity_id?: number | null;
  quantity?: number | null;
  unit_price?: number | null;
  description?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

// get Invoice_item
export interface IEpSubsDBInvoice_itemValue extends IEpSubsDBInvoice_itemPost {
  id: number;
  invoice_id: number;
  customer_id: number;
  amount: number;
  tax: number;
  entity_type: string;
  quantity: number;
  unit_price: number;
  created_at: string;
  updated_at: string;
}

export type TEpSubsDBInvoice_itemValueKeys = keyof IEpSubsDBInvoice_itemValue;

export type TEpSubsDBInvoice_itemListData = Array<IEpSubsDBInvoice_itemValue>;

// post Invoice_coupon_applied
export interface IEpSubsDBInvoice_coupon_appliedPost {
  id?: number | null;
  invoice_id?: number | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  coupon_id?: string | null;
  description?: string | null;
  amount_total?: number | null;
  amount_allocated?: number | null;
  amount_available?: number | null;
  applied_at?: string | null;
}

// get Invoice_coupon_applied
export interface IEpSubsDBInvoice_coupon_appliedValue
  extends IEpSubsDBInvoice_coupon_appliedPost {
  id: number;
  invoice_id: number;
  customer_id: number;
  subscription_id: number;
  coupon_id: string;
  description: string;
  amount_total: number;
  amount_allocated: number;
  amount_available: number;
  applied_at: string;
}

export type TEpSubsDBInvoice_coupon_appliedValueKeys =
  keyof IEpSubsDBInvoice_coupon_appliedValue;

export type TEpSubsDBInvoice_coupon_appliedListData =
  Array<IEpSubsDBInvoice_coupon_appliedValue>;

// post Invoice_item_coupon_applied
export interface IEpSubsDBInvoice_item_coupon_appliedPost {
  id?: number | null;
  invoice_id?: number | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  invoice_item_id?: number | null;
  entity_type?: string | null;
  entity_id?: number | null;
  coupon_id?: string | null;
  description?: string | null;
  amount_total?: number | null;
  amount_allocated?: number | null;
  amount_available?: number | null;
  applied_at?: string | null;
}

// get Invoice_item_coupon_applied
export interface IEpSubsDBInvoice_item_coupon_appliedValue
  extends IEpSubsDBInvoice_item_coupon_appliedPost {
  id: number;
  invoice_id: number;
  customer_id: number;
  subscription_id: number;
  invoice_item_id: number;
  entity_type: string;
  entity_id: number;
  coupon_id: string;
  description: string;
  amount_total: number;
  amount_allocated: number;
  amount_available: number;
  applied_at: string;
}

export type TEpSubsDBInvoice_item_coupon_appliedValueKeys =
  keyof IEpSubsDBInvoice_item_coupon_appliedValue;

export type TEpSubsDBInvoice_item_coupon_appliedListData =
  Array<IEpSubsDBInvoice_item_coupon_appliedValue>;

// post Notification
export interface IEpSubsDBNotificationPost {
  id?: number | null;
  name?: string | null;
  event_id?: number | null;
  filter_id?: number | null;
  from_email_id?: number | null;
  reply_to_email_id?: number | null;
  email_subject?: string | null;
  email_template?: string | null;
  email_json?: string | null;
  email_subject_draft?: string | null;
  email_template_draft?: string | null;
  email_json_draft?: string | null;
  pepipost_template_id?: number | null;
  sms_message_draft?: string | null;
  sms_message?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
  published_at?: string | null;
  days_offset?: number | null;
  attach_invoice?: boolean | null;
  enabled?: boolean | null;
}

// get Notification
export interface IEpSubsDBNotificationValue extends IEpSubsDBNotificationPost {
  id: number;
  name: string;
  event_id: number;
  filter_id: number;
  from_email_id: number;
  reply_to_email_id: number;
  email_subject: string;
  email_template: string;
  email_json: string;
  email_subject_draft: string;
  email_template_draft: string;
  email_json_draft: string;
  pepipost_template_id: number;
  created_at: string;
  created_by: string;
  days_offset: number;
  attach_invoice: boolean;
}

export type TEpSubsDBNotificationValueKeys = keyof IEpSubsDBNotificationValue;

export type TEpSubsDBNotificationListData = Array<IEpSubsDBNotificationValue>;

// post Notification_event
export interface IEpSubsDBNotification_eventPost {
  id?: number | null;
  notification_type?: string | null;
  event_trigger?: string | null;
  name?: string | null;
  category?: number | null;
  segment_entity?: string | null;
  available_variables?: string | null;
  display_order?: number | null;
  days_offset_enabled?: boolean | null;
  attach_invoice_policy?: string | null;
}

// get Notification_event
export interface IEpSubsDBNotification_eventValue
  extends IEpSubsDBNotification_eventPost {
  id: number;
  event_trigger: string;
  name: string;
  category: number;
  available_variables: string;
  display_order: number;
  days_offset_enabled: boolean;
  attach_invoice_policy: string;
}

export type TEpSubsDBNotification_eventValueKeys = keyof IEpSubsDBNotification_eventValue;

export type TEpSubsDBNotification_eventListData = Array<IEpSubsDBNotification_eventValue>;

// post Notification_from_email
export interface IEpSubsDBNotification_from_emailPost {
  id?: number | null;
  email?: string | null;
  name?: string | null;
  signature?: string | null;
  created_at?: string | null;
}

// get Notification_from_email
export interface IEpSubsDBNotification_from_emailValue
  extends IEpSubsDBNotification_from_emailPost {
  id: number;
  email: string;
  name: string;
  created_at: string;
}

export type TEpSubsDBNotification_from_emailValueKeys =
  keyof IEpSubsDBNotification_from_emailValue;

export type TEpSubsDBNotification_from_emailListData =
  Array<IEpSubsDBNotification_from_emailValue>;

// post Offer
export interface IEpSubsDBOfferPost {
  id?: number | null;
  name?: string | null;
  offer_id?: string | null;
  description?: string | null;
  content?: string | null;
  meta_data?: string | null;
  start_at?: string | null;
  end_at?: string | null;
  status?: string | null;
  created_by?: number | null;
  created_at?: string | null;
  updated_at?: string | null;
  updated_by?: number | null;
}

// get Offer
export interface IEpSubsDBOfferValue extends IEpSubsDBOfferPost {
  id: number;
  name: string;
  offer_id: string;
  content: string;
  status: string;
  created_by: number;
  created_at: string;
  updated_at: string;
}

export type TEpSubsDBOfferValueKeys = keyof IEpSubsDBOfferValue;

export type TEpSubsDBOfferListData = Array<IEpSubsDBOfferValue>;

// post Offer_activity
export interface IEpSubsDBOffer_activityPost {
  id?: number | null;
  offer_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
}

// get Offer_activity
export interface IEpSubsDBOffer_activityValue extends IEpSubsDBOffer_activityPost {
  id: number;
  offer_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
}

export type TEpSubsDBOffer_activityValueKeys = keyof IEpSubsDBOffer_activityValue;

export type TEpSubsDBOffer_activityListData = Array<IEpSubsDBOffer_activityValue>;

// post Payment_method_type
export interface IEpSubsDBPayment_method_typePost {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  display_order?: number | null;
  created_by?: number | null;
  created_at?: string | null;
  updated_by?: number | null;
  updated_at?: string | null;
}

// get Payment_method_type
export interface IEpSubsDBPayment_method_typeValue
  extends IEpSubsDBPayment_method_typePost {
  id: number;
  code: string;
  name: string;
  display_order: number;
  created_by: number;
  created_at: string;
}

export type TEpSubsDBPayment_method_typeValueKeys =
  keyof IEpSubsDBPayment_method_typeValue;

export type TEpSubsDBPayment_method_typeListData =
  Array<IEpSubsDBPayment_method_typeValue>;

// post Plan
export interface IEpSubsDBPlanPost {
  id?: number | null;
  product_id?: number | null;
  name?: string | null;
  plan_id?: string | null;
  invoice_name?: string | null;
  description?: string | null;
  price?: number | null;
  currency_code?: string | null;
  period?: number | null;
  period_unit?: string | null;
  period_totaldays?: number | null;
  pricing_model?: string | null;
  free_quantity?: number | null;
  setup_cost?: number | null;
  billing_cycles?: number | null;
  is_trial?: boolean | null;
  center_id?: number | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  version?: number | null;
}

// get Plan
export interface IEpSubsDBPlanValue extends IEpSubsDBPlanPost {
  id: number;
  product_id: number;
  name: string;
  plan_id: string;
  price: number;
  currency_code: string;
  period: number;
  period_unit: string;
  period_totaldays: number;
  pricing_model: string;
  is_trial: boolean;
  status: string;
  created_at: string;
  updated_at: string;
  version: number;
}

export type TEpSubsDBPlanValueKeys = keyof IEpSubsDBPlanValue;

export type TEpSubsDBPlanListData = Array<IEpSubsDBPlanValue>;

// post Plan_activity
export interface IEpSubsDBPlan_activityPost {
  id?: number | null;
  plan_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
}

// get Plan_activity
export interface IEpSubsDBPlan_activityValue extends IEpSubsDBPlan_activityPost {
  id: number;
  plan_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
}

export type TEpSubsDBPlan_activityValueKeys = keyof IEpSubsDBPlan_activityValue;

export type TEpSubsDBPlan_activityListData = Array<IEpSubsDBPlan_activityValue>;

// post Plan_comment
export interface IEpSubsDBPlan_commentPost {
  id?: number | null;
  plan_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_by?: string | null;
  created_at?: string | null;
}

// get Plan_comment
export interface IEpSubsDBPlan_commentValue extends IEpSubsDBPlan_commentPost {
  id: number;
  plan_id: number;
  body: string;
  attachment_url: string;
  created_by: string;
  created_at: string;
}

export type TEpSubsDBPlan_commentValueKeys = keyof IEpSubsDBPlan_commentValue;

export type TEpSubsDBPlan_commentListData = Array<IEpSubsDBPlan_commentValue>;

// post Plugin
export interface IEpSubsDBPluginPost {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  config?: string | null;
  enabled?: boolean | null;
  configurable?: boolean | null;
  created_at?: string | null;
  created_by?: number | null;
  updated_at?: string | null;
  updated_by?: number | null;
}

// get Plugin
export interface IEpSubsDBPluginValue extends IEpSubsDBPluginPost {
  id: number;
  name: string;
  created_at: string;
}

export type TEpSubsDBPluginValueKeys = keyof IEpSubsDBPluginValue;

export type TEpSubsDBPluginListData = Array<IEpSubsDBPluginValue>;

// post Permission
export interface IEpSubsDBPermissionPost {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  created_at?: string | null;
}

// get Permission
export interface IEpSubsDBPermissionValue extends IEpSubsDBPermissionPost {
  id: number;
  code: string;
  name: string;
  created_at: string;
}

export type TEpSubsDBPermissionValueKeys = keyof IEpSubsDBPermissionValue;

export type TEpSubsDBPermissionListData = Array<IEpSubsDBPermissionValue>;

// post Product
export interface IEpSubsDBProductPost {
  id?: number | null;
  product_key?: string | null;
  name?: string | null;
  digital?: boolean | null;
  shippable?: boolean | null;
  gateway_list?: string | null;
  default_gateway?: number | null;
  default_currency?: string | null;
  created_by?: number | null;
  created_at?: string | null;
  updated_by?: number | null;
  updated_at?: string | null;
  logo?: string | null;
  taxability?: string | null;
  display_order?: number | null;
}

// get Product
export interface IEpSubsDBProductValue extends IEpSubsDBProductPost {
  id: number;
  product_key: string;
  name: string;
  digital: boolean;
  shippable: boolean;
  gateway_list: string;
  default_gateway: number;
  default_currency: string;
  created_by: number;
  created_at: string;
  updated_at: string;
  taxability: string;
  display_order: number;
}

export type TEpSubsDBProductValueKeys = keyof IEpSubsDBProductValue;

export type TEpSubsDBProductListData = Array<IEpSubsDBProductValue>;

// post Promotional_credit
export interface IEpSubsDBPromotional_creditPost {
  id?: number | null;
  customer_id?: number | null;
  type?: string | null;
  amount?: number | null;
  description?: string | null;
  credit_type?: string | null;
  closing_balance?: number | null;
  invoice_id?: number | null;
  done_by?: string | null;
  created_at?: string | null;
}

// get Promotional_credit
export interface IEpSubsDBPromotional_creditValue
  extends IEpSubsDBPromotional_creditPost {
  id: number;
  customer_id: number;
  type: string;
  amount: number;
  description: string;
  credit_type: string;
  closing_balance: number;
  done_by: string;
  created_at: string;
}

export type TEpSubsDBPromotional_creditValueKeys = keyof IEpSubsDBPromotional_creditValue;

export type TEpSubsDBPromotional_creditListData = Array<IEpSubsDBPromotional_creditValue>;

// post Reason_code
export interface IEpSubsDBReason_codePost {
  id?: number | null;
  entity?: string | null;
  reason_code?: string | null;
  active?: number | null;
  description?: string | null;
  display_order?: number | null;
  created_by?: number | null;
  created_at?: string | null;
}

// get Reason_code
export interface IEpSubsDBReason_codeValue extends IEpSubsDBReason_codePost {
  id: number;
  entity: string;
  reason_code: string;
  active: number;
  description: string;
  created_by: number;
  created_at: string;
}

export type TEpSubsDBReason_codeValueKeys = keyof IEpSubsDBReason_codeValue;

export type TEpSubsDBReason_codeListData = Array<IEpSubsDBReason_codeValue>;

// post Ref_delivery_method
export interface IEpSubsDBRef_delivery_methodPost {
  id?: number | null;
  locale?: string | null;
  code?: string | null;
  name?: string | null;
  restriction?: string | null;
}

// get Ref_delivery_method
export interface IEpSubsDBRef_delivery_methodValue
  extends IEpSubsDBRef_delivery_methodPost {
  id: number;
  locale: string;
  code: string;
  name: string;
}

export type TEpSubsDBRef_delivery_methodValueKeys =
  keyof IEpSubsDBRef_delivery_methodValue;

export type TEpSubsDBRef_delivery_methodListData =
  Array<IEpSubsDBRef_delivery_methodValue>;

// post Ref_country
export interface IEpSubsDBRef_countryPost {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  phone?: number | null;
  symbol?: string | null;
  currency?: string | null;
  continent_code?: string | null;
  address_rule_json?: string | null;
  active?: number | null;
  created_at?: string | null;
}

// get Ref_country
export interface IEpSubsDBRef_countryValue extends IEpSubsDBRef_countryPost {
  id: number;
  code: string;
  name: string;
  phone: number;
  active: number;
  created_at: string;
}

export type TEpSubsDBRef_countryValueKeys = keyof IEpSubsDBRef_countryValue;

export type TEpSubsDBRef_countryListData = Array<IEpSubsDBRef_countryValue>;

// post Ref_state
export interface IEpSubsDBRef_statePost {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  country?: string | null;
}

// get Ref_state
export interface IEpSubsDBRef_stateValue extends IEpSubsDBRef_statePost {
  id: number;
  code: string;
  name: string;
  country: string;
}

export type TEpSubsDBRef_stateValueKeys = keyof IEpSubsDBRef_stateValue;

export type TEpSubsDBRef_stateListData = Array<IEpSubsDBRef_stateValue>;

// post Ref_timezone
export interface IEpSubsDBRef_timezonePost {
  id?: number | null;
  tz_code?: string | null;
  std_offset?: number | null;
  dst_offset?: number | null;
  updated_at?: string | null;
}

// get Ref_timezone
export interface IEpSubsDBRef_timezoneValue extends IEpSubsDBRef_timezonePost {
  id: number;
  tz_code: string;
  std_offset: number;
  dst_offset: number;
  updated_at: string;
}

export type TEpSubsDBRef_timezoneValueKeys = keyof IEpSubsDBRef_timezoneValue;

export type TEpSubsDBRef_timezoneListData = Array<IEpSubsDBRef_timezoneValue>;

// post Role
export interface IEpSubsDBRolePost {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  display_order?: number | null;
  created_at?: string | null;
  updated_at?: string | null;
}

// get Role
export interface IEpSubsDBRoleValue extends IEpSubsDBRolePost {
  id: number;
  code: string;
  name: string;
  display_order: number;
  created_at: string;
}

export type TEpSubsDBRoleValueKeys = keyof IEpSubsDBRoleValue;

export type TEpSubsDBRoleListData = Array<IEpSubsDBRoleValue>;

// post Role_permission
export interface IEpSubsDBRole_permissionPost {
  id?: number | null;
  role_id?: number | null;
  permission_id?: number | null;
  created_at?: string | null;
}

// get Role_permission
export interface IEpSubsDBRole_permissionValue extends IEpSubsDBRole_permissionPost {
  id: number;
  role_id: number;
  permission_id: number;
  created_at: string;
}

export type TEpSubsDBRole_permissionValueKeys = keyof IEpSubsDBRole_permissionValue;

export type TEpSubsDBRole_permissionListData = Array<IEpSubsDBRole_permissionValue>;

// post Subscription
export interface IEpSubsDBSubscriptionPost {
  id?: number | null;
  subscription_key?: string | null;
  customer_id?: number | null;
  mrr?: number | null;
  start_date?: string | null;
  current_invoice_id?: number | null;
  remaining_billing_cycles?: number | null;
  po_number?: string | null;
  status?: string | null;
  billing_id?: number | null;
  tags?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  next_billing_at?: string | null;
  orig_next_billing_at?: string | null;
  next_billing_amount?: number | null;
  pause_date?: string | null;
  resume_date?: string | null;
  plan_change_dt?: string | null;
  cancel_requested_at?: string | null;
  cancel_at?: string | null;
  cancel_reason?: string | null;
  payment_method_id?: number | null;
  sub_channels?: string | null;
  current_term_start?: string | null;
  current_term_end?: string | null;
  original_term_end?: string | null;
  last_payment_method?: string | null;
  cancel_options?: string | null;
  change_options?: string | null;
  pause_options?: string | null;
  auto_collection?: string | null;
  version?: number | null;
}

// get Subscription
export interface IEpSubsDBSubscriptionValue extends IEpSubsDBSubscriptionPost {
  id: number;
  subscription_key: string;
  customer_id: number;
  mrr: number;
  current_invoice_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  payment_method_id: number;
  original_term_end: string;
  auto_collection: string;
  version: number;
}

export type TEpSubsDBSubscriptionValueKeys = keyof IEpSubsDBSubscriptionValue;

export type TEpSubsDBSubscriptionListData = Array<IEpSubsDBSubscriptionValue>;

// post Subscription_activity
export interface IEpSubsDBSubscription_activityPost {
  id?: number | null;
  subscription_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
}

// get Subscription_activity
export interface IEpSubsDBSubscription_activityValue
  extends IEpSubsDBSubscription_activityPost {
  id: number;
  subscription_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
}

export type TEpSubsDBSubscription_activityValueKeys =
  keyof IEpSubsDBSubscription_activityValue;

export type TEpSubsDBSubscription_activityListData =
  Array<IEpSubsDBSubscription_activityValue>;

// post Subscription_channel
export interface IEpSubsDBSubscription_channelPost {
  id?: number | null;
  name?: string | null;
  order_id?: number | null;
}

// get Subscription_channel
export interface IEpSubsDBSubscription_channelValue
  extends IEpSubsDBSubscription_channelPost {
  id: number;
  name: string;
  order_id: number;
}

export type TEpSubsDBSubscription_channelValueKeys =
  keyof IEpSubsDBSubscription_channelValue;

export type TEpSubsDBSubscription_channelListData =
  Array<IEpSubsDBSubscription_channelValue>;

// post Subscription_comment
export interface IEpSubsDBSubscription_commentPost {
  id?: number | null;
  subscription_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_by?: string | null;
  created_at?: string | null;
}

// get Subscription_comment
export interface IEpSubsDBSubscription_commentValue
  extends IEpSubsDBSubscription_commentPost {
  id: number;
  subscription_id: number;
  body: string;
  attachment_url: string;
  created_by: string;
  created_at: string;
}

export type TEpSubsDBSubscription_commentValueKeys =
  keyof IEpSubsDBSubscription_commentValue;

export type TEpSubsDBSubscription_commentListData =
  Array<IEpSubsDBSubscription_commentValue>;

// post Subscription_coupon
export interface IEpSubsDBSubscription_couponPost {
  id?: number | null;
  subscription_id?: number | null;
  coupon_id?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  first_applied_at?: string | null;
  last_applied_at?: string | null;
  status?: string | null;
  is_usable?: number | null;
  start_dt?: string | null;
  end_dt?: string | null;
  orig_start_dt?: string | null;
  orig_end_dt?: string | null;
}

// get Subscription_coupon
export interface IEpSubsDBSubscription_couponValue
  extends IEpSubsDBSubscription_couponPost {
  id: number;
  subscription_id: number;
  coupon_id: string;
  created_at: string;
  updated_at: string;
  status: string;
  is_usable: number;
  start_dt: string;
  orig_start_dt: string;
}

export type TEpSubsDBSubscription_couponValueKeys =
  keyof IEpSubsDBSubscription_couponValue;

export type TEpSubsDBSubscription_couponListData =
  Array<IEpSubsDBSubscription_couponValue>;

// post Subscription_item
export interface IEpSubsDBSubscription_itemPost {
  id?: number | null;
  subscription_id?: number | null;
  entity_type?: string | null;
  entity_key?: string | null;
  quantity?: number | null;
  unit_price?: number | null;
  amount?: number | null;
  billing_cycles?: number | null;
  charge_on_event?: string | null;
  charge_once?: boolean | null;
  charge_on_option?: string | null;
  last_calculated_at?: string | null;
  last_charged_at?: string | null;
  service_period_days?: number | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

// get Subscription_item
export interface IEpSubsDBSubscription_itemValue extends IEpSubsDBSubscription_itemPost {
  id: number;
  subscription_id: number;
  entity_type: string;
  entity_key: string;
  quantity: number;
  unit_price: number;
  charge_once: boolean;
  status: string;
  created_at: string;
  updated_at: string;
}

export type TEpSubsDBSubscription_itemValueKeys = keyof IEpSubsDBSubscription_itemValue;

export type TEpSubsDBSubscription_itemListData = Array<IEpSubsDBSubscription_itemValue>;

// post Subscription_marketing_tracking
export interface IEpSubsDBSubscription_marketing_trackingPost {
  id?: number | null;
  subscription_id?: number | null;
  utm_term?: string | null;
  utm_campaign?: string | null;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_content?: string | null;
  promo_code?: string | null;
  other_channels?: string | null;
  source_page_url?: string | null;
  friend_referral?: string | null;
  cancel_reason_id?: number | null;
  cancel_desc?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

// get Subscription_marketing_tracking
export interface IEpSubsDBSubscription_marketing_trackingValue
  extends IEpSubsDBSubscription_marketing_trackingPost {
  id: number;
  subscription_id: number;
  created_at: string;
  updated_at: string;
}

export type TEpSubsDBSubscription_marketing_trackingValueKeys =
  keyof IEpSubsDBSubscription_marketing_trackingValue;

export type TEpSubsDBSubscription_marketing_trackingListData =
  Array<IEpSubsDBSubscription_marketing_trackingValue>;

// post Transaction
export interface IEpSubsDBTransactionPost {
  id?: number | null;
  customer_id?: number | null;
  subscription_id?: number | null;
  invoice_id?: number | null;
  amount?: number | null;
  amount_unused?: number | null;
  gateway?: number | null;
  gateway_account_id?: string | null;
  payment_method?: string | null;
  customer_payment_method_id?: number | null;
  fraud_reason?: string | null;
  reference_number?: string | null;
  transaction_type?: string | null;
  transaction_date?: string | null;
  settled_at?: string | null;
  status?: string | null;
  collect_type?: string | null;
  fraud_flag?: string | null;
  id_at_gateway?: string | null;
  error_code?: string | null;
  decline_code?: string | null;
  error_text?: string | null;
  created_at?: string | null;
  voided_at?: string | null;
  updated_at?: string | null;
  dispute_reason?: string | null;
  masked_card_number?: string | null;
  reference_transaction_id?: number | null;
  todo_other_ref_txn?: number | null;
}

// get Transaction
export interface IEpSubsDBTransactionValue extends IEpSubsDBTransactionPost {
  id: number;
  customer_id: number;
  invoice_id: number;
  amount: number;
  amount_unused: number;
  gateway: number;
  gateway_account_id: string;
  payment_method: string;
  fraud_reason: string;
  reference_number: string;
  transaction_type: string;
  transaction_date: string;
  status: string;
  collect_type: string;
  id_at_gateway: string;
  error_code: string;
  decline_code: string;
  error_text: string;
  created_at: string;
  updated_at: string;
  masked_card_number: string;
}

export type TEpSubsDBTransactionValueKeys = keyof IEpSubsDBTransactionValue;

export type TEpSubsDBTransactionListData = Array<IEpSubsDBTransactionValue>;

// post Transaction_activity
export interface IEpSubsDBTransaction_activityPost {
  id?: number | null;
  transaction_id?: number | null;
  event_type?: string | null;
  body?: string | null;
  updated_fields?: string | null;
  created_at?: string | null;
  created_by?: string | null;
}

// get Transaction_activity
export interface IEpSubsDBTransaction_activityValue
  extends IEpSubsDBTransaction_activityPost {
  id: number;
  transaction_id: number;
  event_type: string;
  body: string;
  updated_fields: string;
  created_at: string;
  created_by: string;
}

export type TEpSubsDBTransaction_activityValueKeys =
  keyof IEpSubsDBTransaction_activityValue;

export type TEpSubsDBTransaction_activityListData =
  Array<IEpSubsDBTransaction_activityValue>;

// post Transaction_comment
export interface IEpSubsDBTransaction_commentPost {
  id?: number | null;
  transaction_id?: number | null;
  body?: string | null;
  attachment_url?: string | null;
  created_by?: string | null;
  created_at?: string | null;
}

// get Transaction_comment
export interface IEpSubsDBTransaction_commentValue
  extends IEpSubsDBTransaction_commentPost {
  id: number;
  transaction_id: number;
  body: string;
  attachment_url: string;
  created_by: string;
  created_at: string;
}

export type TEpSubsDBTransaction_commentValueKeys =
  keyof IEpSubsDBTransaction_commentValue;

export type TEpSubsDBTransaction_commentListData =
  Array<IEpSubsDBTransaction_commentValue>;

// post Scheduled_task
export interface IEpSubsDBScheduled_taskPost {
  id?: number | null;
  job_group?: string | null;
  name?: string | null;
  description?: string | null;
  job_interval?: string | null;
  singleton?: boolean | null;
  enabled?: boolean | null;
  run_when_standby?: boolean | null;
  info?: string | null;
  time_mask?: string | null;
  last_err?: string | null;
  last_ran_at?: string | null;
  updated_by?: string | null;
  updated_at?: string | null;
}

// get Scheduled_task
export interface IEpSubsDBScheduled_taskValue extends IEpSubsDBScheduled_taskPost {
  id: number;
  job_group: string;
  name: string;
  description: string;
  job_interval: string;
  singleton: boolean;
  enabled: boolean;
  run_when_standby: boolean;
  info: string;
  updated_by: string;
  updated_at: string;
}

export type TEpSubsDBScheduled_taskValueKeys = keyof IEpSubsDBScheduled_taskValue;

export type TEpSubsDBScheduled_taskListData = Array<IEpSubsDBScheduled_taskValue>;

// post Stats_daily
export interface IEpSubsDBStats_dailyPost {
  id?: number | null;
  day?: string | null;
  signups?: number | null;
  cashflow?: number | null;
  cancels?: number | null;
  payments?: number | null;
  refunds?: number | null;
  total_active_subs?: number | null;
  total_mrr?: number | null;
}

// get Stats_daily
export interface IEpSubsDBStats_dailyValue extends IEpSubsDBStats_dailyPost {
  id: number;
  day: string;
  signups: number;
  cashflow: number;
  cancels: number;
  payments: number;
  refunds: number;
  total_active_subs: number;
  total_mrr: number;
}

export type TEpSubsDBStats_dailyValueKeys = keyof IEpSubsDBStats_dailyValue;

export type TEpSubsDBStats_dailyListData = Array<IEpSubsDBStats_dailyValue>;

// post Tag
export interface IEpSubsDBTagPost {
  id?: number | null;
  type?: string | null;
  tag_id?: string | null;
  name?: string | null;
  created_at?: string | null;
  updatd_at?: string | null;
}

// get Tag
export interface IEpSubsDBTagValue extends IEpSubsDBTagPost {
  id: number;
  type: string;
  tag_id: string;
  name: string;
  created_at: string;
  updatd_at: string;
}

export type TEpSubsDBTagValueKeys = keyof IEpSubsDBTagValue;

export type TEpSubsDBTagListData = Array<IEpSubsDBTagValue>;

// post User
export interface IEpSubsDBUserPost {
  id?: number | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  password?: string | null;
  active?: number | null;
  created_at?: string | null;
  updated_at?: string | null;
  updated_by?: number | null;
  last_login?: string | null;
  activate_token?: string | null;
  token_expired_at?: string | null;
  invited_by?: number | null;
  invited_at?: string | null;
}

// get User
export interface IEpSubsDBUserValue extends IEpSubsDBUserPost {
  id: number;
  email: string;
  password: string;
  active: number;
  created_at: string;
}

export type TEpSubsDBUserValueKeys = keyof IEpSubsDBUserValue;

export type TEpSubsDBUserListData = Array<IEpSubsDBUserValue>;

// post User_role
export interface IEpSubsDBUser_rolePost {
  id?: number | null;
  user_id?: number | null;
  role_id?: number | null;
  created_at?: string | null;
}

// get User_role
export interface IEpSubsDBUser_roleValue extends IEpSubsDBUser_rolePost {
  id: number;
  user_id: number;
  role_id: number;
  created_at: string;
}

export type TEpSubsDBUser_roleValueKeys = keyof IEpSubsDBUser_roleValue;

export type TEpSubsDBUser_roleListData = Array<IEpSubsDBUser_roleValue>;

// post Static_tax_rate
export interface IEpSubsDBStatic_tax_ratePost {
  id?: number | null;
  entity_type?: string | null;
  entity_id?: number | null;
  country?: string | null;
  state?: string | null;
  tax_rate?: number | null;
  applied_price?: string | null;
  threshold?: number | null;
}

// get Static_tax_rate
export interface IEpSubsDBStatic_tax_rateValue extends IEpSubsDBStatic_tax_ratePost {
  id: number;
  entity_type: string;
  entity_id: number;
  country: string;
  state: string;
  tax_rate: number;
  applied_price: string;
  threshold: number;
}

export type TEpSubsDBStatic_tax_rateValueKeys = keyof IEpSubsDBStatic_tax_rateValue;

export type TEpSubsDBStatic_tax_rateListData = Array<IEpSubsDBStatic_tax_rateValue>;

// post Gateway_account
export interface IEpSubsDBGateway_accountPost {
  id?: number | null;
  name?: string | null;
  type?: number | null;
  url?: string | null;
  version?: string | null;
  client_id?: string | null;
  api_key?: string | null;
  minimal_charge?: number | null;
  ext_options?: string | null;
  payment_method_types?: string | null;
  enabled?: boolean | null;
  is_default?: boolean | null;
  created_by?: number | null;
  created_at?: string | null;
  updated_by?: number | null;
  updated_at?: string | null;
}

// get Gateway_account
export interface IEpSubsDBGateway_accountValue extends IEpSubsDBGateway_accountPost {
  id: number;
  name: string;
  type: number;
  url: string;
  version: string;
  client_id: string;
  api_key: string;
  minimal_charge: number;
  payment_method_types: string;
  enabled: boolean;
  is_default: boolean;
  created_by: number;
  created_at: string;
}

export type TEpSubsDBGateway_accountValueKeys = keyof IEpSubsDBGateway_accountValue;

export type TEpSubsDBGateway_accountListData = Array<IEpSubsDBGateway_accountValue>;

// post Invoice_pdf
export interface IEpSubsDBInvoice_pdfPost {
  id?: number | null;
  invoice_id?: number | null;
  version?: number | null;
  source?: string | null;
  change_type?: string | null;
  operation?: string | null;
  amount?: number | null;
  file_path?: string | null;
  created_at?: string | null;
}

// get Invoice_pdf
export interface IEpSubsDBInvoice_pdfValue extends IEpSubsDBInvoice_pdfPost {
  id: number;
  invoice_id: number;
  version: number;
  source: string;
  change_type: string;
  operation: string;
  amount: number;
  created_at: string;
}

export type TEpSubsDBInvoice_pdfValueKeys = keyof IEpSubsDBInvoice_pdfValue;

export type TEpSubsDBInvoice_pdfListData = Array<IEpSubsDBInvoice_pdfValue>;
