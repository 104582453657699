import * as React from 'react';

import style from './EpToolTip.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export type TToolTip = JSX.Element | string;
export type TOverlayPlacement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';
export const renderTooltip = (tooltip: TToolTip) => {
  if (typeof tooltip === 'string') {
    return (
      <Tooltip id="button-tooltip" className={style.tooltip}>
        {tooltip}
      </Tooltip>
    );
  }
  return tooltip;
};

/**
 * A wrapper for tooltip to handle the case when tooltip is not provided.
 * @param tooltip Optional input, if not provided, overlayTrigger will not be added to wrap the children.
 * @param childDisabled Used to show not-allowed cursor when the children is disabled
 * @returns Children with the OverlayTrigger wrapper or children itself.
 */
function EpToolTip({
  tooltip,
  children,
  placement = 'top',
  delayOnShow = 0,
  delayOnHide = 200,
  childDisabled = false,
}: {
  tooltip?: TToolTip;
  children: JSX.Element;
  placement?: TOverlayPlacement;
  delayOnShow?: number;
  delayOnHide?: number;
  childDisabled?: boolean;
}): JSX.Element {
  return tooltip ? (
    <OverlayTrigger
      trigger="hover"
      placement={placement}
      delay={{ show: delayOnShow, hide: delayOnHide }}
      overlay={renderTooltip(tooltip ?? '')}
    >
      <div
        className={`${style.container_with_tooltip}`}
        style={childDisabled ? { cursor: 'not-allowed' } : {}}
      >
        {children}
      </div>
    </OverlayTrigger>
  ) : (
    children
  );
}

export default EpToolTip;
