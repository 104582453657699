export const DB_RESPONSE_OK = 'ok';
export const DB_RESPONSE_OK_EMPTY = '';
export const DB_RESPONSE_FAILED = 'failed';

export type TEpSubsDBResponseOk<TDBRecord> = {
  status: typeof DB_RESPONSE_OK | typeof DB_RESPONSE_OK_EMPTY;
  total: number;
  data: Array<TDBRecord>;
};

export type TEpSubsDBResponseFailed = {
  status: typeof DB_RESPONSE_FAILED;
  error: string;
};

export type TEpSubsDBResponse<TDBRecord> =
  | TEpSubsDBResponseOk<TDBRecord>
  | TEpSubsDBResponseFailed;
