import { useRecoilCallback } from 'recoil';
import {
  updateSettingsRule,
  lockSettingsRule,
  unlockSettingsRule,
  getSettingsRules,
} from '../../services/epSubsSettingsService';
import { IEpSubsDBSettingPost } from '../../models';
import {
  settingsGroupListRequestIDAtom,
  settingsGroupRequestIDAtoms,
} from './settingsAtoms';
import { putNoty } from '../../utilities/epSubsNoty';

export const useRefreshSettingsGroupList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(settingsGroupListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshSettingsGroup = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async (groupCode: string) => {
        set(settingsGroupRequestIDAtoms(groupCode), requestID => requestID + 1);
      },
    [],
  );
};

export const useUpdateSettingsRule = (): ((
  groupCode: string,
  ruleCode: string,
  data: IEpSubsDBSettingPost,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (groupCode: string, ruleCode: string, data: IEpSubsDBSettingPost) => {
        // update settings rule
        try {
          const updateSuccess = await updateSettingsRule(ruleCode, data);
          await getSettingsRules(groupCode);
          if (updateSuccess.code == 199) {
            putNoty({ type: 'success', text: updateSuccess.message });
          } else {
            putNoty({ type: 'success', text: 'Successfully updated settings rule.' });
          }
        } catch (error) {
          console.log('error', error);
        }
        console.log('checking atom value:', settingsGroupRequestIDAtoms(groupCode));
        set(settingsGroupRequestIDAtoms(groupCode), requestID => requestID + 1);
      },
    [],
  );
};

export const useLockSettingsRule = (): ((
  groupCode: string,
  ruleCode: string,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (groupCode: string, ruleCode: string) => {
        // lock settings rule
        try {
          const lockSuccess = await lockSettingsRule(ruleCode);
          if (lockSuccess) {
            const message = `Successfully locked settings rule.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh settings group
        set(settingsGroupRequestIDAtoms(groupCode), requestID => requestID + 1);
      },
    [],
  );
};

export const useUnlockSettingsRule = (): ((
  groupCode: string,
  ruleCode: string,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (groupCode: string, ruleCode: string) => {
        // unlock settings rule
        try {
          const unlockSuccess = await unlockSettingsRule(ruleCode);
          if (unlockSuccess) {
            const message = `Successfully unlocked settings rule.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh settings group
        set(settingsGroupRequestIDAtoms(groupCode), requestID => requestID + 1);
      },
    [],
  );
};
