import { LOCALE } from 'src/config';
import { ELocale } from '.';

export const getRequiredZipDigits = (locale = LOCALE as ELocale): number => {
  switch (locale) {
    case ELocale.ja_JP:
      // Japan does not require zipcode, if use, it is xxx-xxxx
      return 7;
    case ELocale.en_AU:
      return 4;
    case ELocale.en_UK:
      return 8;
    case ELocale.cs_CZ:
      return 5;
    case ELocale.sk_SK:
      return 6;
    case ELocale.en_US:
    case ELocale.es_MX:
    case ELocale.es_US:
    case ELocale.de_DE:
    case ELocale.pt_BR:
    case ELocale.fr_FR:
    default:
      return 10;
  }
};
