import React, { BaseSyntheticEvent, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import {
  currentSubscriptionIdAtom,
  useHasPermission,
  useRefreshCurrentCustomerAddresses,
  useRefreshSubscription,
} from 'src/recoil';
import {
  genCustomerAddressUsage,
  genDeleteCustomerAddress,
  genUpdateCustomerAddress,
  genUpdateSubscriptionAddresses,
} from 'src/services/epSubsAddressService';
import { putNoty } from 'src/utilities/epSubsNoty';
import {
  IEpSubsDBCustomer_addressValue,
  ELocale,
  INewAddressFormData,
  TEpSubsAddressUsage,
  TEpSubsSubscriptionAddressUpdateData,
  UiPermission,
} from '../../models';
import style from './EpSubsAddressCard.module.scss';
import { TTabSource } from '../tabs/EpSubsTabList';
import { IPureAddressData } from './EpSubsAddressFormSubSection';
import EpSubsConfirmModal from 'src/components/modals/EpSubsConfirmModal';
import {
  isObjectDataSameForGivenProperties,
  disableButton,
  iValid,
  reEnableButton,
} from 'src/utilities/commonUtils';
import EpSubsEditAddressModal from './EpSubsEditAddressModal';
import { useRecoilValue } from 'recoil';
import { EpSubsUpdateAddressUsageModal } from './EpSubsUpdateAddressUsageModal';
import EpSubsSpinnerModal from '../spinnerModal/EpSubsSpinnerModal';
import { DEBUGGING, LOCALE } from 'src/config';
import { COUNTRY_OPTIONS } from 'src/js/constants/countries';

type TAddressSource = TTabSource;

type Props = {
  source: TAddressSource;
  addressId: number;
  customerId: number;
  addressData: INewAddressFormData;
  // addCommentClicked: () => void;
  totalSubsForThisAddress?: number;
  hideCountry?: boolean;
  // For Address Usage Info
  isShipping?: boolean;
  subscriptionId?: number;
  customerLocale: ELocale;
};

export const NullAddressId = 0;
export const NullPaymentId = 0;

const EpSubsAddressCard = ({
  source,
  addressId,
  customerId,
  addressData,
  totalSubsForThisAddress,
  hideCountry = false,
  isShipping,
  subscriptionId,
  customerLocale,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false);
  const [updatedAddressData, setUpdatedAddressData] = useState<INewAddressFormData>();
  const [addressUsage, setAddressUsage] = useState<TEpSubsAddressUsage[]>();
  const [showAddressUsageModal, setShowAddressUsageModal] = useState(false);
  const [selectDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const currentSubscriptionId = useRecoilValue(currentSubscriptionIdAtom);

  const allowCustomerEditPermission = useHasPermission(UiPermission['cust.edit']);
  const refreshCurrentCustomerAddresses = useRefreshCurrentCustomerAddresses();
  const refreshSubscription = useRefreshSubscription();
  const closeDeleteConfirmModal = (): void => {
    setShowDeleteConfirmModal(false);
  };
  const confirmDeleteComment = async () => {
    closeDeleteConfirmModal();
    try {
      setIsLoading(true);
      await genDeleteCustomerAddress(customerId, addressId);
      putNoty({ type: 'success', text: 'Delete address success!' });
      // Refresh the updated data
      // dispatch({ type: FETCH_CUSTOMER_DATA, id, refresh: true });
      refreshCurrentCustomerAddresses();
    } catch (e) {
      // putNoty({ type: 'error', text: (e as Error).message });
    } finally {
      setIsLoading(false);
    }
    // Return false to prevent posting the whole form to url
    return false;
  };

  const onPreviewOrConfirmAddressUpdate = async (
    formData: INewAddressFormData,
    event?: BaseSyntheticEvent,
  ): Promise<boolean> => {
    const data: INewAddressFormData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      company: formData.company,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      zip: formData.zip,
      state_code: formData.state_code,
      country: formData.country,
      validation_status: !!formData.validation_status ? true : false,
    };

    const addressNotEdited = isObjectDataSameForGivenProperties(data, addressData, [
      'first_name',
      'last_name',
      'company',
      'address1',
      'address2',
      'city',
      'state_code',
      'zip',
      'country',
      'validation_status',
    ]);
    DEBUGGING &&
      console.log('Check Address Edited: ', addressNotEdited, addressData, data);
    if (addressNotEdited) {
      putNoty({ type: 'error', text: 'Please Edit Address First!' });
      return false;
    }

    event && disableButton(event);
    try {
      switch (source) {
        case 'customers': // For unused address only
          setIsLoading(true);
          await genUpdateCustomerAddress(customerId, addressId, data);
          putNoty({ type: 'success', text: 'Update address success!' });
          await refreshCurrentCustomerAddresses(-1);
          setShowAddressUpdateModal(false);
          break;
        case 'subscriptions':
          setIsLoading(true);
          const addressUsage = await genCustomerAddressUsage(addressId);
          setAddressUsage(addressUsage);
          setUpdatedAddressData(data);
          setShowAddressUsageModal(true);
          break;
      }
    } catch (e) {
      event && reEnableButton(event);
      // putNoty({ type: 'error', text: (e as Error).message });
    } finally {
      setIsLoading(false);
    }
    // Return false to prevent posting the whole form to url
    event && reEnableButton(event);
    return false;
  };

  const onConfirmAddressUpdate = async (
    formData: Pick<TEpSubsSubscriptionAddressUpdateData, 'apply_to'>,
    event?: BaseSyntheticEvent,
  ): Promise<boolean> => {
    if (!updatedAddressData) {
      putNoty({ type: 'error', text: 'Missing Updated Address! ' });
      return false;
    }
    if (formData.apply_to.length === 0) {
      const message = 'Please select a usage to update before confirm!';
      putNoty({ type: 'error', text: message });
      return false;
    }

    const data: TEpSubsSubscriptionAddressUpdateData = {
      address: updatedAddressData,
      apply_to: formData.apply_to,
    };

    event && disableButton(event);
    try {
      setIsLoading(true);
      await genUpdateSubscriptionAddresses(addressId, data);
      putNoty({ type: 'success', text: 'Update addresses success!' });
      switch (source) {
        case 'customers': // Not Enabled Yet
          break;
        case 'subscriptions':
          currentSubscriptionId && refreshSubscription(currentSubscriptionId);
          break;
      }
      const subscriptionsToRefresh = new Set<number>();
      formData.apply_to.map(usage => subscriptionsToRefresh.add(usage.subscription_id));
      subscriptionsToRefresh.forEach(subscriptionId =>
        refreshSubscription(subscriptionId),
      );
      refreshCurrentCustomerAddresses(-1);
      setShowAddressUsageModal(false);
      setShowAddressUpdateModal(false);
    } catch (e) {
      event && reEnableButton(event);
      // putNoty({ type: 'error', text: (e as Error).message });
    } finally {
      setIsLoading(false);
    }
    // Return false to prevent posting the whole form to url
    event && reEnableButton(event);
    return false;
  };

  return (
    <div>
      <Card className={style.card}>
        <Row className="d-flex flex-row" style={{ justifyContent: 'space-between' }}>
          <div>
            <Card.Title className={style.name}>
              <div style={{ display: 'flex', marginLeft: '12px' }}>
                <span>
                  {addressData.first_name} {addressData.last_name}
                </span>
                <span>
                  <Button variant="primary" className="badge badge-primary ml-2">
                    {iValid(totalSubsForThisAddress, 0) > 0 ? 'USED' : undefined}
                  </Button>
                </span>
              </div>
            </Card.Title>
          </div>
          <div
            className={style.action_text}
            style={{ margin: '0px', textAlign: 'right', display: 'flex' }}
          >
            {shouldDisplayRemoveButton(source, totalSubsForThisAddress) && (
              <p
                className={`ml-2 ${style.edit_text}`}
                onClick={() => setShowDeleteConfirmModal(true)}
              >
                Remove
              </p>
            )}
            <EpSubsConfirmModal
              headerName={'Delete'}
              contextContent={'Do you want remove the address?'}
              modalShow={selectDeleteConfirmModal}
              onClose={closeDeleteConfirmModal}
              onConfirm={confirmDeleteComment}
            />
            {allowCustomerEditPermission && (
              <p
                id="Ep_address_edit"
                onClick={() => setShowAddressUpdateModal(true)}
                className={style.edit_text}
              >
                Edit
              </p>
            )}
          </div>
        </Row>
        <hr className="p-0 m-0" />
        {LOCALE == ELocale.de_DE && (
          <div style={{ fontWeight: 'normal' }}>
            Company:{' ' + addressData.company ?? ''}
          </div>
        )}
        {EpSubsAddressCardBody(addressData, hideCountry)}
      </Card>
      <EpSubsSpinnerModal isLoading={isLoading} />
      <EpSubsEditAddressModal
        modalShow={showAddressUpdateModal}
        addressData={addressData}
        onSubmit={onPreviewOrConfirmAddressUpdate}
        onCancel={() => setShowAddressUpdateModal(false)}
        customerLocale={customerLocale}
      />
      <EpSubsUpdateAddressUsageModal
        modalShow={showAddressUsageModal}
        prevAddressData={addressData}
        updatedAddressData={updatedAddressData}
        addressUsageData={addressUsage}
        isShipping={isShipping}
        subscriptionId={subscriptionId}
        onSubmit={onConfirmAddressUpdate}
        onCancel={() => setShowAddressUsageModal(false)}
      />
    </div>
  );
};
export default EpSubsAddressCard;

const shouldDisplayRemoveButton = (
  source: TTabSource,
  totalSubsForThisAddress?: number,
): boolean => {
  if (source === 'customers' && totalSubsForThisAddress === 0) {
    return true;
  }
  return false;
};

export const getFullAddress = (
  address?: IEpSubsDBCustomer_addressValue | null,
): string => {
  if (address) {
    return (
      address.address1 +
      ', ' +
      address.city +
      ', ' +
      address.state_code +
      ' ' +
      address.zip +
      ', ' +
      address.country
    );
  }
  return 'None Available';
};

export const EpSubsAddressCardBody = (
  addressData: IPureAddressData | Error | null,
  hideCountry: boolean,
) => {
  if (addressData == null) {
    return (
      <Card.Text className="pt-2 m-0">
        Please input address first before verification!
      </Card.Text>
    );
  } else if (addressData instanceof Error) {
    return (
      <Card.Text className="pt-2 m-0">
        Failed to verify the input address! Please revise it or double confirm with
        clients.
      </Card.Text>
    );
  } else {
    const { address1, address2, city, zip, state_code, country } = addressData;
    const address =
      LOCALE == ELocale.en_AU
        ? `${address1 ? address1?.trim() : address1}${address2 ? ' ' + address2 : ''}`
        : `${address1 ? address1?.trim() : address1}${address2 ? ' ' + address2 : ''},`;
    const cityStateZip =
      LOCALE == ELocale.en_AU
        ? `${(city ?? '').toUpperCase() ?? ''} ${
            (state_code ?? '').toUpperCase() ?? ''
          } ${(zip ?? '').toUpperCase() ?? ''}`
        : `${city ?? ''}, ${state_code ?? ''} ${zip ?? ''}`;

    return (
      <>
        <Card.Text className="pt-2 m-0">
          {address?.trim() === ',' ? '' : address}
        </Card.Text>
        <Card.Text className="pt-2 m-0">
          {cityStateZip?.trim() === ',' ? '' : cityStateZip}
        </Card.Text>
        {!hideCountry && (
          <Card.Text className="pt-2 m-0">
            {COUNTRY_OPTIONS.map(val => {
              if (val.value == country) {
                return val.label;
              } else return '';
            })}
          </Card.Text>
        )}
      </>
    );
  }
};
