import React, { BaseSyntheticEvent } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import * as stripeJs from '@stripe/stripe-js';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm';
import { TTokenizeCallback } from '../subscriptions/subscriptionOperations/EpSubsCreateSubscription';
import { useLoadOptionalRecoilSelector } from 'src/recoil';
import {
  defaultBraintreeGatewaySelector,
  gatewaysPortalMapSelector,
} from 'src/recoil/gateway/gatewayStates';
import { GATEWAYS } from 'src/models';
import { languageOptions } from 'src/models/i18n';
import { EpSubsPayPalExpressCheckout } from '../payPalExpress/EpSubsPayPalExpressCheckout';
import { EpSubsPaymentStripeCardForm } from './components/paymentForms/EpSubsPaymentStripeCardForm';
import { EpSubsPaymentBrainTreeCardForm } from './components/paymentForms/EpSubsPaymentBrainTreeCardForm';
import { EpSubsPaymentPortalPaypalForm } from './components/paymentForms/EpSubsPaymentPortalPaypalForm';
import { Spinner } from 'react-bootstrap';

interface ParamType {
  token: string;
}

type Props = {
  paymentType?: string;
  gatewayAccountId: number;
  onSubmit: (
    gateway: number,
    data: TNewPaymentFormData,
    setIsLoading: (isLoading: boolean) => void,
    event?: BaseSyntheticEvent<any>,
  ) => Promise<boolean>;
  onBraintreeInstance: (tokenize: TTokenizeCallback) => void;
  onStripeInstance: (stripe: Stripe, stripeElements?: stripeJs.StripeElements) => void;
  onClosePaypalForm?: () => void;
};

export const EpSubsPortalEditPaymentForm = ({
  paymentType,
  gatewayAccountId,
  onSubmit,
  onBraintreeInstance,
  onStripeInstance,
  onClosePaypalForm,
}: Props): JSX.Element => {
  const token = useParams<ParamType>().token;
  const gatewaysPortalListMap = useLoadOptionalRecoilSelector(gatewaysPortalMapSelector);
  const defaultBraintreeGateway = useLoadOptionalRecoilSelector(
    defaultBraintreeGatewaySelector,
    undefined,
  );
  const creditCardForm =
    paymentType &&
    !['PayPalAccount', 'PayPalExpress'].includes(paymentType) &&
    (() => {
      if (!gatewaysPortalListMap)
        return <Spinner animation="border" variant="primary" size="sm" />;
      const gatewayType = gatewaysPortalListMap.get(gatewayAccountId)?.type;
      switch (gatewayType) {
        case GATEWAYS.Braintree:
          return (
            <EpSubsPaymentBrainTreeCardForm
              {...{ onSubmit, onBraintreeInstance, gatewayAccountId }}
            />
          );
        case GATEWAYS.Stripe:
          return (
            <EpSubsPaymentStripeCardForm
              {...{ onSubmit, onStripeInstance, gatewayAccountId }}
            />
          );
        default:
          return <p className="p-5">{languageOptions().portal.error.unknown}</p>;
      }
    })();
  const payPalAccountForm =
    paymentType &&
    ['PayPalAccount'].includes(paymentType) &&
    (() => {
      if (defaultBraintreeGateway === undefined)
        return <Spinner animation="border" variant="primary" size="sm" />;
      if (defaultBraintreeGateway === null) return <></>;

      return (
        <EpSubsPaymentPortalPaypalForm
          {...{
            token,
            onClosePaypalForm,
            gatewayAccountId: defaultBraintreeGateway.id,
          }}
        />
      );
    })();
  const payPalExpressForm =
    paymentType &&
    ['PayPalExpress'].includes(paymentType) &&
    (() => {
      return <EpSubsPayPalExpressCheckout />;
    })();

  return (
    <>
      {creditCardForm}
      {payPalAccountForm}
      {payPalExpressForm}
    </>
  );
};
