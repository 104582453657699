import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/noty/lib/noty.css';
import '../node_modules/noty/lib/themes/mint.css';
import './styles/globalStyle.scss'; // OverWrite bootstrap styles
import { UI_VERSION } from './config';
import EpSubsMainContainer from './containers/EpSubsMainContainer';
import MainContainer from './v2/containers/MainContainer';

function App(): JSX.Element {
  return (
    <div className="App">
      <Router>{UI_VERSION === 'v2' ? <MainContainer /> : <EpSubsMainContainer />}</Router>
    </div>
  );
}

export default App;
