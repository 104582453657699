import { pick, reduce } from 'lodash';
import moment from 'moment';
import { BaseSyntheticEvent } from 'react';
import { ELocale } from 'src/models';
import { LOCALE } from 'src/config';
/**
 * Used together with filter method of array to get rid of null and undefined types:
 * @template [].filter(notEmpty)
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

/**
 * O(n) Time Complexity
 */
export function groupBy<TItem, TGroupByKey>(
  array: TItem[],
  groupByKeyMethod: (item: TItem) => TGroupByKey,
): Map<TGroupByKey, TItem[]> {
  return reduce<TItem, Map<TGroupByKey, TItem[]>>(
    array,
    (accumulatorMap, item) => {
      if (!accumulatorMap.get(groupByKeyMethod(item))) {
        accumulatorMap.set(groupByKeyMethod(item), []);
      }
      (accumulatorMap.get(groupByKeyMethod(item)) as NonNullable<TItem[]>).push(item);
      return accumulatorMap;
    },
    new Map(),
  );
}

/**
 * O(n) Time Complexity
 */
export function agg<TItem, TGroupByKey, TAggValue>({
  array,
  groupByKeyMethod,
  aggMethod,
}: {
  array: TItem[];
  groupByKeyMethod: (item: TItem) => TGroupByKey;
  aggMethod: (key: TGroupByKey, item: TItem[]) => TAggValue;
}): Map<TGroupByKey, TAggValue> {
  const groupedDataMap = groupBy<TItem, TGroupByKey>(array, groupByKeyMethod);
  return new Map(
    Array.from(groupedDataMap, item => [item[0], aggMethod(item[0], item[1])]),
  );
}

/**
 * O(n) Time Complexity
 */
export function sum<TItem, TSumValue>({
  array,
  initialItem,
  sumMethod,
  valueMethod,
}: {
  array: TItem[];
  initialItem: TItem;
  sumMethod: (item1: TItem, item2: TItem) => TItem;
  valueMethod: (item: TItem) => TSumValue;
}): TSumValue {
  return valueMethod(
    reduce<TItem, TItem>(
      array,
      (summedItem, item) => {
        return sumMethod(item, summedItem);
      },
      initialItem,
    ),
  );
}

/**
 * O(n) Time Complexity
 * Might cause problem if used in constant defining stage
 */
export function arrayToObject<TItem, TKey extends string | number, TValue = TItem>(
  array: TItem[],
  keyMethod: (item: TItem) => TKey,
  valueMethod?: (item: TItem) => TValue,
): { [key in TKey]: TValue } {
  return array.reduce(
    (partialObject, item) => ({
      ...partialObject,
      [keyMethod(item)]: !!valueMethod ? valueMethod(item) : item,
    }),
    {} as { [key in TKey]: TValue },
  );
}

/**
 * O(n) Time Complexity
 * Might cause problem if used in constant defining stage
 * @example ['A', 'B'] => {A: 'A', B: 'B'}
 */
export function mirrorObject<TKey extends string | number>(array: TKey[]) {
  return arrayToObject<TKey, TKey>(array, (key: TKey) => key);
}

/**
 * O(n) Time Complexity
 */
export function arrayToMap<TItem, TKey, TValue = TItem>(
  array: TItem[],
  keyMethod: (item: TItem) => TKey,
  valueMethod?: (item: TItem) => TValue,
): Map<TKey, TValue | TItem> {
  return new Map(
    array.map(item => [keyMethod(item), !!valueMethod ? valueMethod(item) : item]),
  );
}

/**
 * @returns null if items is empty array. Or return the item with the minimum value given by valueMethod
 */
export const getMinItemFromList = <TItem, TValue>(
  items: TItem[],
  valueMethod: (item: TItem) => TValue,
): TItem | null => {
  if (items.length === 0) return null;
  let minIndex = 0,
    index = 1;
  while (index < items.length) {
    if (valueMethod(items[index]) < valueMethod(items[minIndex])) {
      minIndex = index;
    }
    index += 1;
  }
  return items[minIndex];
};

export function isObject(obj: any): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj.length === undefined;
}

export function isArray(obj: any): obj is any[] {
  return typeof obj === 'object' && obj.length !== undefined;
}

export function compareJsonData<TJsonData>(
  objectA: TJsonData,
  objectB: TJsonData,
  caseSensitive = true,
): boolean {
  if (isArray(objectA)) {
    if (!isArray(objectB)) return false;
    if (objectA.length !== objectB.length) return false;
    return reduce<keyof TJsonData, boolean>(
      Object.keys(objectA) as (keyof TJsonData)[], // Input Array: key array of given object
      (compareResult, key) =>
        compareResult &&
        compareJsonData(
          objectA[key as keyof TJsonData],
          objectB[key as keyof TJsonData],
          caseSensitive,
        ),
      true, // Initial Value of Accumulator
    );
  } else if (isObject(objectA)) {
    if (!isObject(objectB)) return false;
    if (Object.keys(objectA).length !== Object.keys(objectB).length) false;
    return reduce<keyof TJsonData, boolean>(
      Object.keys(objectA) as (keyof TJsonData)[], // Input Array: key array of given object
      (compareResult, key) =>
        compareResult &&
        compareJsonData(
          objectA[key as keyof TJsonData],
          objectB[key as keyof TJsonData],
          caseSensitive,
        ),
      true, // Initial Value of Accumulator
    );
  } else if (typeof objectA === 'string') {
    if (typeof objectB !== 'string') return false;
    if (!caseSensitive) {
      return objectA.toLowerCase() === objectB.toLowerCase();
    }
  }
  return objectA === objectB;
}

/**
 * Compare two objects by each attributes
 * @returns False if there is any difference.
 */
export function isObjectDataSame<TObjectData>(
  objectA: TObjectData,
  objectB: TObjectData,
): boolean {
  return reduce<keyof TObjectData, boolean>(
    Object.keys(objectA) as (keyof TObjectData)[], // Input Array: key array of given object
    (compareResult, key) =>
      compareResult &&
      objectA[key as keyof TObjectData] === objectB[key as keyof TObjectData],
    true, // Initial Value of Accumulator
  );
}

/**
 * Compare two objects by each attributes
 * @returns False if there is any difference.
 */
export function isObjectDataSameForGivenProperties<TObjectData>(
  objectA: TObjectData,
  objectB: TObjectData,
  properties: (keyof TObjectData)[],
): boolean {
  const objectAProperties = pick(objectA, properties);
  const objectBProperties = pick(objectB, properties);
  return reduce<keyof TObjectData, boolean>(
    properties, // Input Array: key array of given object
    (compareResult, key) =>
      compareResult &&
      objectAProperties[key as keyof TObjectData] ===
        objectBProperties[key as keyof TObjectData],
    true, // Initial Value of Accumulator
  );
}

export function areSetsEqual<T = any>(setA: Set<T>, setB: Set<T>) {
  return setA.size === setB.size && [...setA].every(value => setB.has(value));
}

/**
 * Not needed any more, as long as you do not try to decode an unencoded URIComponent
 * Preprocess by Converting % to %25 before passing it to decode/encodeURIComponent
 */
// export function preprocessURIComponent(uri: string): string {
//   return uri.replace(/%(?![0-9a-fA-F][0-9a-fA-F])/g, '%25');
// }

export const datePart = (inputDate: number): string => {
  return inputDate.toLocaleString(`${LOCALE as ELocale}`, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const dateCalculation = (date: string) => {
  const now = moment(new Date()); //todays date
  const start_date = moment(date);
  const duration = moment.duration(now.diff(start_date));
  const displayedDuration = [duration.years(), duration.months(), duration.days()]
    .map(datePart)
    .join('|');
  return displayedDuration;
};

export const minutesCalculation = (date: string) => {
  const now = moment(new Date()); //todays date
  const start_date = moment(date);
  const duration = Math.trunc(now.diff(start_date) / 1000);
  const SEC_IN_MINS = 60;
  const SEC_IN_HOUR = 3600;
  const SEC_IN_DAY = 86400;
  const SEC_IN_MONTH = 2592000;
  if (duration <= SEC_IN_HOUR) {
    return Math.trunc(duration / SEC_IN_MINS) + ' ' + 'mins ago';
  } else if (duration >= SEC_IN_HOUR && duration <= SEC_IN_DAY) {
    return Math.trunc(duration / SEC_IN_HOUR) + ' ' + 'hours ago';
  } else if (duration >= SEC_IN_DAY && duration <= SEC_IN_MONTH) {
    return Math.trunc(duration / SEC_IN_DAY) + ' ' + 'days ago';
  } else if (duration >= SEC_IN_MONTH) {
    return Math.trunc(duration / SEC_IN_MONTH) + ' ' + 'months ago';
  }
};

export const disableButton = (event: BaseSyntheticEvent) => {
  const buttons = event.target.getElementsByTagName('button');
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].setAttribute('disabled', 'disabled');
  }
};

export const reEnableButton = (event: BaseSyntheticEvent) => {
  const buttons = event.target.getElementsByTagName('button');
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].removeAttribute('disabled');
  }
};

/**
 * Enable the submit button at the end of or on early return of onSubmit
 * @returns Return false to prevent posting the whole form to url
 */
export const cleanUpInTheEndOfSubmit = (
  event?: BaseSyntheticEvent,
  callback?: () => void,
): boolean => {
  event && reEnableButton(event);
  callback && callback();
  // Return false to prevent posting the whole form to url
  return false;
};

/**
 * @return return input if it is not undefined, otherwise return fallbackValue or null;
 */
export function iExisting<Type>(
  input: Type | undefined | null,
  fallbackValue: NonNullable<Type> | null = null,
): NonNullable<Type> | null {
  return input !== undefined ? (input as NonNullable<Type> | null) : fallbackValue;
}

/**
 * @return return input if it is neither undefined nor null,
 * otherwise return fallbackValue ;
 */
export function iValid<Type>(
  input: Type | null | undefined,
  fallbackValue: NonNullable<Type>,
): NonNullable<Type> {
  return input !== undefined && input !== null
    ? (input as NonNullable<Type>)
    : fallbackValue;
}

/**
 * Compare from and to date
 */
export const validateFromToDate = (fromDate?: number, toDate?: number): boolean => {
  if (fromDate === undefined || toDate === undefined) {
    return true;
  }
  const from = fromDate;
  const to = toDate;
  return to > from;
};

/**
 * Compare from and to date
 */
export const validateForDate = (fromDate?: number, toDate?: number): boolean => {
  if (fromDate === undefined || toDate === undefined) {
    return true;
  }
  if (fromDate < toDate) {
    return true;
  } else {
    return false;
  }
};

export const validateForCouponOfferForm = (
  delay_period?: number,
  delay_unit?: string,
  duration_period?: number,
  duration_unit?: string,
): boolean => {
  if (delay_period && delay_unit) {
    return false;
  } else if (duration_period && duration_unit) {
    return false;
  } else if (delay_period && delay_unit && duration_period && duration_unit) {
    return false;
  } else if (!delay_period && !delay_unit && !duration_period && !duration_unit) {
    return false;
  } else {
    return true;
  }
};

/**
 * Convert to Merge Tags which could insert to Email/SMS template
 */
export const convertVariablesToMergeTags = (
  variables: string,
): {
  name: string;
  value: string;
}[] => {
  return variables.split(',').map((word: string) => {
    return {
      name: `${word}`,
      value: `[% ${word} %]`,
    };
  });
};

export const expirationChecker = (
  cardMonth: string,
  cardYear: string,
  isActive: boolean,
) => {
  const dateYear = Number(new Date().getFullYear());
  const dateMonth = Number(new Date().getMonth());
  switch (isActive) {
    case true:
      return true;
    case false:
      if (Number(cardYear) < dateYear && isActive === false) {
        return true;
      } else if (Number(cardYear) === dateYear && Number(cardMonth) < dateMonth) {
        return true;
      } else if (Number(cardYear) >= dateYear) {
        return false;
      }
  }
};

export const isSameDate = (date1: string, date2: string) => {
  const new_date1 = new Date(date1);
  const new_date2 = new Date(date2);
  if (
    new_date1.getFullYear() === new_date2.getFullYear() &&
    new_date1.getMonth() === new_date2.getMonth() &&
    new_date1.getDate() === new_date2.getDate()
  ) {
    return false;
  } else {
    return true;
  }
};
export const unixTimestampToDateString = (unixTimestamp: number, hasSymbol: boolean) => {
  // Create a new Date object using the Unix timestamp
  const date = new Date(unixTimestamp * 1000);

  // Extract the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
  const day = ('0' + date.getDate()).slice(-2);

  // Format the date as "YYYY-MM-DD"
  return hasSymbol ? year + '-' + month + '-' + day : year + month + day;
};

export const fontsForTemplate = [
  {
    label: 'Andale Mono',
    value: 'andale mono,times',
    url: '',
  },
  {
    label: 'Arial',
    value: 'arial,helvetica,sans-serif',
    url: '',
  },
  {
    label: 'Arial Black',
    value: 'arial black,avant garde,arial',
    url: '',
  },
  {
    label: 'Book Antiqua',
    value: 'book antiqua,palatino',
    url: '',
  },
  {
    label: 'Comic Sans MS',
    value: 'comic sans ms,sans-serif',
    url: '',
  },
  {
    label: 'Courier New',
    value: 'courier new,courier',
    url: '',
  },
  {
    label: 'Georgia',
    value: 'georgia,palatino',
    url: '',
  },
  {
    label: 'Helvetica',
    value: 'helvetica,sans-serif',
    url: '',
  },
  {
    label: 'Impact',
    value: 'impact,chicago',
    url: '',
  },
  {
    label: 'Symbol',
    value: 'symbol',
    url: '',
  },
  {
    label: 'Tahoma',
    value: 'tahoma,arial,helvetica,sans-serif',
    url: '',
  },
  {
    label: 'Terminal',
    value: 'terminal,monaco',
    url: '',
  },
  {
    label: 'Times New Roman',
    value: 'times new roman,times',
    url: '',
  },
  {
    label: 'Trebuchet MS',
    value: 'trebuchet ms,geneva',
    url: '',
  },
  {
    label: 'Verdana',
    value: 'verdana,geneva',
    url: '',
  },
  {
    label: 'Lobster Two',
    value: "'Lobster Two',cursive",
    url: 'https://fonts.googleapis.com/css?family=Lobster+Two:400,700',
  },
  {
    label: 'Playfair Display',
    value: "'Playfair Display',serif",
    url: 'https://fonts.googleapis.com/css?family=Playfair+Display:400,700',
  },
  {
    label: 'Rubik',
    value: "'Rubik',sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Rubik:400,700',
  },
  {
    label: 'Source Sans Pro',
    value: "'Source Sans Pro',sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700',
  },
  {
    label: 'Open Sans',
    value: "'Open Sans',sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,700',
  },
  {
    label: 'Crimson Text',
    value: "'Crimson Text',serif",
    url: 'https://fonts.googleapis.com/css?family=Crimson+Text:400,700',
  },
  {
    label: 'Montserrat',
    value: "'Montserrat',sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Montserrat:400,700',
  },
  {
    label: 'Old Standard TT',
    value: "'Old Standard TT',serif",
    url: 'https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700',
  },
  {
    label: 'Lato',
    value: "'Lato',sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Lato:400,700',
  },
  {
    label: 'Raleway',
    value: "'Raleway',sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Raleway:400,700',
  },
  {
    label: 'Cabin',
    value: "'Cabin',sans-serif",
    url: 'https://fonts.googleapis.com/css?family=Cabin:400,700',
  },
  {
    label: 'Pacifico',
    value: "'Pacifico',cursive",
    url: 'https://fonts.googleapis.com/css?family=Pacifico',
  },
];
