import React, { BaseSyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FilterAccessLevel } from 'src/js/constants/subs';
// import style from '../../../../../components/subscriptions/EpSubsSubscriptionForm.module.scss';
import { Modal, Row } from 'react-bootstrap';
import { ESFormTextInput } from 'src/components/formComponents';
import { TEpSubsSaveFilterRequestData } from 'src/models';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import { DEBUGGING } from 'src/config';

export type TEpSubsEditFilterModalFormData = Required<
  Pick<TEpSubsSaveFilterRequestData, 'filter_name'>
> & { access_level: string };
type Props = {
  modalShow: boolean;
  title?: string;
  defaultFilterName: string;
  defaultAccessLevel?: FilterAccessLevel;
  onSubmit: (
    formData: TEpSubsEditFilterModalFormData,
    event?: BaseSyntheticEvent,
  ) => Promise<boolean>;
  onClose: () => void;
};

export const EpSubsEditFilterModal = ({
  modalShow,
  title = 'Update Filter',
  defaultFilterName,
  defaultAccessLevel,
  onSubmit,
  onClose,
}: Props): JSX.Element => {
  const { register, handleSubmit, errors, setValue, watch } =
    useForm<TEpSubsEditFilterModalFormData>({
      mode: 'onBlur',
    });
  const currentAccessLevel = watch('access_level', defaultAccessLevel);

  DEBUGGING &&
    console.log(
      'EpSubsEditFilterModal ',
      defaultFilterName,
      defaultAccessLevel,
      currentAccessLevel,
    );
  return (
    <Modal
      show={modalShow}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {toInitialUpperCase(title)}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
        <Modal.Body className="p-4">
          <Form.Row>
            <Form.Group controlId="formGridFilterName" className="w-100">
              <div className="mx-2">
                <Form.Label>Filter Name</Form.Label>
                <ESFormTextInput
                  // label={'Filter Name'}
                  // controlId={`formGridFilterName`}
                  placeholder={'Filter Name'}
                  name={'filter_name'}
                  required={true}
                  register={register}
                  defaultValue={defaultFilterName}
                  errorField={errors.filter_name}
                  // onChange={() => resetValidationProcess()}
                />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="w-100 mx-3" as={Row}>
              <Form.Check
                name="access_level"
                className="mr-4"
                type="radio"
                label="Private Filter"
                // defaultChecked={defaultAccessLevel === FilterAccessLevel.PRIVATE}
                checked={currentAccessLevel === FilterAccessLevel.PRIVATE}
                onChange={event => setValue('access_level', event.target.value)}
                ref={register}
                id="radioPrivate"
                value={FilterAccessLevel.PRIVATE}
              />
              <Form.Check
                name="access_level"
                type="radio"
                label="Public Filter"
                ref={register}
                // defaultChecked={defaultAccessLevel === FilterAccessLevel.PUBLIC}
                checked={currentAccessLevel === FilterAccessLevel.PUBLIC}
                onChange={event => setValue('access_level', event.target.value)}
                id="radioPublic"
                value={FilterAccessLevel.PUBLIC}
              />
              {/* {errors.change_apply_time && errors.change_apply_time.message} */}
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">
            Save
          </Button>
          <Button type="button" variant="light" className="mx-1" onClick={onClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
