import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

/**
 * Asks user to confirm if want to remove the selected comment.
 * @param param0
 * @returns
 */
type ISmallModal = {
  headerName: string;
  contextContent: string | null;
  modalShow: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children?: JSX.Element;
  confirmText?: string;
  showSecondaryButton?: boolean;
};

const EpSubsConfirmModal = ({
  headerName,
  contextContent,
  modalShow,
  onClose,
  onConfirm,
  children,
  confirmText,
  showSecondaryButton = true,
}: ISmallModal): JSX.Element => {
  return (
    <Modal
      show={modalShow}
      onHide={onClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{headerName}</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <p>{contextContent}</p>
          {children}
        </Modal.Body>
        <Modal.Footer>
          {showSecondaryButton && (
            <Button variant="secondary" type="button" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            id="Ep_modal_confirm"
            type="button"
            variant="primary"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {confirmText ?? 'Confirm'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EpSubsConfirmModal;
