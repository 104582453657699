import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DebugRouter, NoMatch } from './EpSubsRoutingUtil';
import EpSubsUpdatePaymentWrapper from './payments/EpSubsUpdatePaymentWrapper';

export const PORTAL_ROOT = '/portal';

const EpSubsPortalBody = (): JSX.Element => {
  return (
    <>
      <DebugRouter>
        <Switch>
          <Route
            path={PORTAL_ROOT + '/update-payment/:token'}
            component={EpSubsUpdatePaymentWrapper}
          />
          <Route path={PORTAL_ROOT + '*'}>
            <NoMatch />
          </Route>
        </Switch>
      </DebugRouter>
    </>
  );
};

export default EpSubsPortalBody;
