import { atom, atomFamily } from 'recoil';
import {
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from '../../components/tables/EpFullFeaturedTable';
import { selector, selectorFamily, waitForAll } from 'recoil';
import * as H from 'history';
import {
  TEpSubsDBCredit_noteValueKeys,
  IEpSubsCompositeDBCredit_noteValueInList,
  IEpSubsCompositeDBCredit_noteValue,
} from '../../models';
import {
  genCreditNoteData,
  genCreditNoteListData,
} from '../../services/epSubsCreditNoteService';
import {
  getParamsFromSearch,
  ListState,
} from '../../components/tables/EpFullFeaturedTable';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { DEBUGGING } from 'src/config';

/** ************************************************
 * TODO: These List related States are not going to be used for now,
 * but will be used once the search List page is requested, the APIs are ready.
 * *************************************************/
// ************************************************ //
//  CreditNote List Latest Search Parameter States  //
// ************************************************ //

export const DEFAULT_CREDIT_NOTE_SEARCH_PARAMETER = '?sort=created_at&order=DESC';
/**
 * An atom that stores the latest creditNote search param
 */
export const latestCreditNoteSearchParamAtom = atom<TLocationRecord>({
  key: 'latestCreditNoteSearchParamAtom',
  default: { search: DEFAULT_CREDIT_NOTE_SEARCH_PARAMETER, ready: false },
});

// *********************************************** //
//       CreditNote List Search Result States      //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for creditNote list
 */
export const creditNoteSearchRequestIDAtom = atom<number>({
  key: 'creditNoteSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for creditNote list based on current searchParam
 */
export const creditNoteBasicListSelector = selector<
  ListState<IEpSubsCompositeDBCredit_noteValueInList>
>({
  key: 'creditNoteIdListSelector',
  get: async ({ get }) => {
    const requestID = get(creditNoteSearchRequestIDAtom); // Add request ID as a dependency
    const creditNoteSearchParam = get(latestCreditNoteSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, creditNoteBasicListSelector creditNoteSearchParam',
        creditNoteSearchParam,
        'requestID',
        requestID,
      );

    // fetch creditNotes
    if (creditNoteSearchParam.ready && creditNoteSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBCredit_noteValueKeys>(
        creditNoteSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const offset = (page - 1) * perPage;

      try {
        const response = await genCreditNoteListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === '' ? [] : [{ key: sort, ascending: order }],
          simpleQuery,
        );

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data:
            response.data.map(creditNote => {
              return {
                credit_note: creditNote,
              } as IEpSubsCompositeDBCredit_noteValueInList;
            }) ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An selector that stores the creditNote detail list based on current searchParam
 */
export const creditNoteCompleteListSelector = selector<
  ListState<IEpSubsCompositeDBCredit_noteValueInList>
>({
  key: 'creditNoteCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(creditNoteBasicListSelector);

    if (!idListState.err) {
      const creditNoteIds = idListState.data.map(
        creditNoteData => creditNoteData.credit_note.id,
      );
      const creditNoteDetailList = get(
        waitForAll(creditNoteIds.map(creditNoteId => creditNoteSelectors(creditNoteId))),
      );
      DEBUGGING &&
        console.log('recoil, creditNoteCompleteListSelector', creditNoteDetailList);

      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: creditNoteDetailList ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from creditNoteBasicListSelector
      return idListState as ListState<IEpSubsCompositeDBCredit_noteValue>;
    }
  },
});

// *********************************************** //
//        CreditNote Detail Family States        //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each creditNote
 */
export const creditNoteRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'creditNoteRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores creditNote details for each creditNote
 */
export const creditNoteSelectors = selectorFamily<
  IEpSubsCompositeDBCredit_noteValue,
  number
>({
  key: 'creditNoteSelectors',
  get:
    creditNoteId =>
    async ({ get }) => {
      get(creditNoteRefreshRequestIDAtoms(creditNoteId)); // Add request ID as a dependency
      const creditNoteData = await genCreditNoteData(creditNoteId);
      DEBUGGING &&
        console.log('recoil, creditNoteSelectors creditNoteData:', creditNoteData);
      return creditNoteData;
    },
});
