import { selector, selectorFamily } from 'recoil';
import * as H from 'history';
import {
  IEpSubsDBOfferValue,
  IEpSubsDBOffer_activityValue,
  TEpSubsDBOfferValueKeys,
  UiPermission,
} from '../../models';
import { CURRENT_ID_ATOM_DEFAULT_VALUE, hasPermissionSelectors } from '..';
import { atom, atomFamily } from 'recoil';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from '../../components/tables/EpFullFeaturedTable';
import {
  genAllOfferData,
  genAllOfferList,
  genOfferActivityData,
  genOfferActivityDetail,
  genOfferData,
  genOfferDetailData,
  genOfferListData,
} from 'src/services/epSubsOfferService';
import { offsetToPageId } from 'src/components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { DEBUGGING } from 'src/config';
import { DEFAULT_ACTIVITY_COUNT } from 'src/js/constants/subs';
import { reduce } from 'lodash';

// *********************************************** //
//             Offer List Page States            //
// *********************************************** //
export const DEFAULT_OFFER_SEARCH_PARAMETER = '';

/**
 * An atom that stores the latest offer search param
 */
export const latestOfferSearchParamAtom = atom<TLocationRecord>({
  key: 'latestOfferSearchParamAtom',
  default: { search: DEFAULT_OFFER_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for offer list
 */
export const offerSearchRequestIDAtom = atom<number>({
  key: 'offerSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for offer list
 */
export const offerListSelector = selector<ListState<IEpSubsDBOfferValue>>({
  key: 'offerIdListSelector',
  get: async ({ get }) => {
    const requestID = get(offerSearchRequestIDAtom); // Add request ID as a dependency
    const offerSearchParam = get(latestOfferSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, offerBasicListSelector offerSearchParam',
        offerSearchParam,
        'requestID',
        requestID,
      );

    // fetch offers
    if (offerSearchParam.ready && offerSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBOfferValueKeys>(
        offerSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const offset = (page - 1) * perPage;
      // const filter = decodeURIComponent(routeParams.filter);
      // const filterData = getSimpleFilterData(filter);
      try {
        const response = await genOfferListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'created_at', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          // filterData[PRODUCT_FILTER_GROUP_FIELD][0],
        );

        DEBUGGING && console.log('recoil, offerBasicListSelector response', response);

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An atomFamily that stores the refresh Request ID for each offer
 */
export const offerRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'offerRefreshRequestIDAtoms',
  default: 0,
});

export const offerRefreshRequestStringIDAtoms = atomFamily<string, string>({
  key: 'offerRefreshRequestStringIDAtoms',
  default: '',
});

/**
 * An selectorFamily that stores offer details for each offer
 */
export const offerSelectors = selectorFamily<IEpSubsDBOfferValue, number>({
  key: 'offerSelectors',
  get:
    offerId =>
    async ({ get }) => {
      get(offerRefreshRequestIDAtoms(offerId)); // Add request ID as a dependency
      const offerData = await genOfferData(offerId);
      DEBUGGING && console.log('recoil, offerSelectors offerData:', offerData);
      return offerData;
    },
});

export const offerRequestIDAtom = atom<number>({
  key: 'offerRequestIDAtom',
  default: 0,
});

export const offersDependOnProductSelectors = selectorFamily<
  IEpSubsDBOfferValue[],
  {
    productId: number;
    excludeTrialPlan: boolean;
  }
>({
  key: 'offersDependOnProductSelectors',
  get:
    ({ productId, excludeTrialPlan }) =>
    async ({ get }) => {
      get(offerRequestIDAtom);
      const getAllOfferData = await genAllOfferData(productId, excludeTrialPlan);
      return getAllOfferData;
    },
});

// *********************************************** //
//           Offer Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected offer id
 */
export const currentOfferIdAtom = atom<number | null>({
  key: 'currentOfferIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected offer details
 */
export const currentOfferSelector = selector<IEpSubsDBOfferValue | undefined>({
  key: 'currentOfferSelector',
  get: ({ get }) => {
    const id = get(currentOfferIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(offerSelectors(id));
    }
  },
});

/**
 * An atom that stores the currently selected plan Activities query parameters
 */
export const currentOfferActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentOfferActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

export const offerDetailSelectors = selectorFamily<
  | (IEpSubsDBOfferValue & {
      offer_detail: {
        plan: {
          center_id: number;
          product: {
            id: number;
            shippable: boolean;
          };
        };
      };
    })
  | undefined,
  string
>({
  key: 'offerDetailSelectors',
  get:
    offerStringId =>
    async ({ get }) => {
      if (offerStringId !== '') {
        get(offerRefreshRequestStringIDAtoms(offerStringId)); // Add request ID as a dependency
        const offerData = await genOfferDetailData(offerStringId);
        DEBUGGING && console.log('recoil, offerSelectors offerData:', offerData);
        return offerData;
      }
    },
});

/**
 * An selector that stores the currently selected offer Activities
 */
export const currentOfferActivitiesSelector = selector<IEpSubsDBOffer_activityValue[]>({
  key: 'currentOfferActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentOfferIdAtom);
    const params = get(currentOfferActivitiesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['offer.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genOfferActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores offer Activity Detail for each activity
 */
export const offerActivityDetailSelectors = selectorFamily<
  IEpSubsDBOffer_activityValue,
  number
>({
  key: 'offerActivityDetailSelectors',
  get: actLogId => async () => {
    const offerActivityData = await genOfferActivityDetail(actLogId);
    DEBUGGING &&
      console.log('recoil, couponSelectors couponActivityData:', offerActivityData);
    return offerActivityData;
  },
});

export const offerSelector = selector<IEpSubsDBOfferValue[]>({
  key: 'offerSelector',
  get: async ({ get }) => {
    get(offerRequestIDAtom);
    const allOfferData = await genAllOfferList();
    return allOfferData;
  },
});

export const allOfferDataMapSelector = selector<Map<string, IEpSubsDBOfferValue>>({
  key: 'allOfferDataMapSelector',
  get: async ({ get }) => {
    // To be replaced with a all product list API
    const offerList = get(offerListSelector).data ?? [];
    return reduce<IEpSubsDBOfferValue, Map<string, IEpSubsDBOfferValue>>(
      offerList,
      (offerIdMap, offerRecord) => {
        offerIdMap.set(String(offerRecord.id), offerRecord);
        return offerIdMap;
      },
      new Map(),
    );
  },
});
