import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { HIDE_CREATE_NEW_BUTTON_IN_LIST } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { FILTER_SOURCE, COUPON_STATUS } from 'src/js/constants/subs';
import { UiPermission } from 'src/models';
import { useHasPermission } from 'src/recoil';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import EpSubsListNav from '../../components/navBars/EpSubsListNav/EpSubsListNav';

const COUPON_STATUS_FILTER_GROUP_NAME = 'coupon_status';
export const COUPON_STATUS_FILTER_GROUP_FIELD = 'status';
const noFilter = {
  label: 'All Coupons',
  value: '',
  groupField: COUPON_STATUS_FILTER_GROUP_FIELD,
};
export const DEFAULT_COUPON_SEARCH_OPTION = {
  [COUPON_STATUS_FILTER_GROUP_FIELD]: [COUPON_STATUS.ACTIVE],
};

const EpSubsCouponNav = (routerProps: RouteComponentProps): JSX.Element => {
  const history = useHistory();
  const allowCouponCreatePermission = useHasPermission(UiPermission['coupon.create']);

  const createBtnText = '+ Create New Coupon';

  const onCreateBtnClick = allowCouponCreatePermission
    ? () => {
        history.push('/coupons/create-coupon');
      }
    : HIDE_CREATE_NEW_BUTTON_IN_LIST;

  return (
    <EpSubsListNav
      key="coupon"
      {...{
        routerProps,
        createBtnText,
        onCreateBtnClick,
        history,
        source: FILTER_SOURCE.COUPON,
        allSimplyFilterOptions: {
          [COUPON_STATUS_FILTER_GROUP_FIELD]: [noFilter].concat(
            Object.values(COUPON_STATUS).map(status => {
              return {
                label: toInitialUpperCase(status),
                value: status,
                groupName: COUPON_STATUS_FILTER_GROUP_NAME,
                groupField: COUPON_STATUS_FILTER_GROUP_FIELD,
              };
            }),
          ),
        },
        defaultSimplyFilterOption: DEFAULT_COUPON_SEARCH_OPTION,
      }}
    />
  );
};

export default EpSubsCouponNav;
