import { EpIcon, IconFactory } from 'src/assets';
import { toInitialUpperCase } from 'src/utilities/stringUtility';

export const ZERO = 0;
export const DEFAULT_ACTIVITY_COUNT = 6;
export const DEFAULT_DISPLAY_ACTIVITY_COUNT = 5;
export const DEFAULT_COMMENT_COUNT = 5;
export const DEFAULT_ADDRESS_COUNT = 6;
export const PREVENT_FORM_RENDERED_TO_HTML_AFTER_SUBMIT = false;
export const TRUE_INT = 1;
export const FALSE_INT = 0;
export const AUTH_TOKEN = 'auth-token';

export const EpSubsTabs = {
  HOME: 'home',
  CUSTOMERS: 'customers',
  PLANS: 'plans',
  SUBSCRIPTIONS: 'subscriptions',
};

export type TFilterSourceEnum = {
  // Elastic Filter
  CUSTOMER: 'customer';
  SUBSCRIPTION: 'subscription';
  INVOICE: 'invoice';
  // Non-Elastic Filter
  PRODUCT: 'product';
  PLAN: 'plan';
  ADDON: 'addon';
  COUPON: 'coupon';
  OFFER: 'offer';
  EMAIL_LOG: 'emailLog';
  USER: 'user';
  GATEWAY: 'gateway';
  ADMIN_BULK: 'admin_bulk';
  WEBHOOK: 'webhook';
  TRANSACTION: 'transaction';
};
export type TFilterSource = TFilterSourceEnum[keyof TFilterSourceEnum];
export const FILTER_SOURCE: TFilterSourceEnum = {
  // Elastic Filter
  CUSTOMER: 'customer',
  SUBSCRIPTION: 'subscription',
  INVOICE: 'invoice',
  // Non-Elastic Filter
  PRODUCT: 'product',
  PLAN: 'plan',
  ADDON: 'addon',
  COUPON: 'coupon',
  OFFER: 'offer',
  EMAIL_LOG: 'emailLog',
  USER: 'user',
  GATEWAY: 'gateway',
  ADMIN_BULK: 'admin_bulk',
  WEBHOOK: 'webhook',
  TRANSACTION: 'transaction',
};

export enum ESimpleSearchFields {
  ALL = 'all',
  ID = 'id',
  PIN = 'pin',
  CUSTOMER_KEY = 'customer_key',
  SUBSCRIPTION_KEY = 'subscription_key',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS = 'address',
}

const SIMPLE_SEARCH_FIELDS_DISPLAY_LABEL_OVERWRITE_MAP: Map<ESimpleSearchFields, string> =
  new Map(
    Object.entries({
      [ESimpleSearchFields.PIN]: 'PIN',
      [ESimpleSearchFields.CUSTOMER_KEY]: 'Customer ID',
      [ESimpleSearchFields.SUBSCRIPTION_KEY]: 'Subscription ID',
    }) as [ESimpleSearchFields, string][],
  );

export const SEARCH_ICON_MAP: { [key in ESimpleSearchFields]: keyof typeof IconFactory } =
  {
    [ESimpleSearchFields.ALL]: 'search',
    [ESimpleSearchFields.ID]: 'id',
    [ESimpleSearchFields.PIN]: 'key',
    [ESimpleSearchFields.CUSTOMER_KEY]: 'id',
    [ESimpleSearchFields.SUBSCRIPTION_KEY]: 'subscription',
    [ESimpleSearchFields.NAME]: 'customer',
    [ESimpleSearchFields.EMAIL]: 'email',
    [ESimpleSearchFields.PHONE]: 'phone',
    [ESimpleSearchFields.ADDRESS]: 'address',
  };
export type TTabOptionItem<TValue extends string> = {
  value: TValue;
  label: string;
  icon?: JSX.Element | undefined;
};
export const SIMPLE_SEARCH_FIELD_OPTIONS: TTabOptionItem<string>[] = Object.values(
  ESimpleSearchFields,
).map(fieldKey => {
  return {
    label: toInitialUpperCase(
      SIMPLE_SEARCH_FIELDS_DISPLAY_LABEL_OVERWRITE_MAP.get(fieldKey) ?? fieldKey,
      false,
    ),
    value: fieldKey,
    icon: EpIcon({
      name: SEARCH_ICON_MAP[fieldKey],
      style: { marginLeft: '0', marginRight: '1px' },
    }),
  };
});

// *********************************************** //
//               Status Enums                      //
// *********************************************** //
// export interface IProductStatus {
//   ACTIVE: 'Active';
//   ARCHIVED: 'Archived';
// }
// export type TProductStatus = IProductStatus[keyof IProductStatus];
// export const PRODUCT_STATUS: IProductStatus = {
//   ACTIVE: 'Active',
//   ARCHIVED: 'Archived',
// };
export interface IPlanStatus {
  ACTIVE: 'active';
  ARCHIVED: 'archived';
}
export type TPlanStatus = IPlanStatus[keyof IPlanStatus];
export const PLAN_STATUS: IPlanStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};
export interface IAddonStatus {
  ACTIVE: 'active';
  ARCHIVED: 'archived';
}
export type TAddonStatus = IAddonStatus[keyof IAddonStatus];
export const ADDON_STATUS: IAddonStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export interface ITransactionStatus {
  IN_PROGRESS: 'in_progress';
  SUCCESS: 'success';
  VOIDED: 'voided';
  FAILURE: 'failure';
  DISPUTED: 'disputed';
  DISPUTE_WON: 'dispute_won';
  DISPUTE_LOST: 'dispute_lost';
}
export type TTransactionStatus = ITransactionStatus[keyof ITransactionStatus];
export const TRANSACTION_STATUS: ITransactionStatus = {
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'success',
  VOIDED: 'voided',
  FAILURE: 'failure',
  DISPUTED: 'disputed',
  DISPUTE_WON: 'dispute_won',
  DISPUTE_LOST: 'dispute_lost',
};
export interface ICouponStatus {
  ACTIVE: 'active';
  ARCHIVED: 'archived';
  EXPIRED: 'expired';
}
export type TCouponStatus = ICouponStatus[keyof ICouponStatus];
export const COUPON_STATUS: ICouponStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  EXPIRED: 'expired',
};
export enum EOfferStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
  EXPIRED = 'expired',
}
export interface ISubscriptionStatus {
  ACTIVE: 'Active';
  PAUSED: 'Paused';
  CANCELLED: 'Cancelled';
  NON_RENEWING: 'Non Renewing';
  FUTURE: 'Future';
  ON_HOLD: 'OnHold';
  active: 'active';
  paused: 'paused';
  cancelled: 'cancelled';
  non_renewing: 'non_renewing';
  on_hold: 'on_hold';
  future: 'future';
}
export type TSubscriptionStatus = ISubscriptionStatus[keyof ISubscriptionStatus];
export const SUBSCRIPTION_STATUS: ISubscriptionStatus = {
  ACTIVE: 'Active',
  PAUSED: 'Paused',
  CANCELLED: 'Cancelled',
  NON_RENEWING: 'Non Renewing',
  FUTURE: 'Future',
  ON_HOLD: 'OnHold',
  active: 'active',
  paused: 'paused',
  cancelled: 'cancelled',
  non_renewing: 'non_renewing',
  on_hold: 'on_hold',
  future: 'future',
};
export interface IInvoiceStatus {
  PAID: 'paid';
  PAYMENT_DUE: 'payment_due';
  VOIDED: 'voided';
  NOT_PAID: 'not_paid';
  PENDING: 'pending';
}
export type TInvoiceStatus = IInvoiceStatus[keyof IInvoiceStatus];
export const INVOICE_STATUS: IInvoiceStatus = {
  PAID: 'paid',
  PAYMENT_DUE: 'payment_due',
  VOIDED: 'voided',
  NOT_PAID: 'not_paid',
  PENDING: 'pending',
};

export interface IEmailLogStatus {
  SENT: 'sent';
  BOUNCED: 'bounced';
  DROPPED: 'dropped';
  CLICKED: 'clicked';
  OPENED: 'opened';
}
export type TEmailLogStatus = IEmailLogStatus[keyof IEmailLogStatus];
export const EMAIL_LOG_STATUS: IEmailLogStatus = {
  SENT: 'sent',
  BOUNCED: 'bounced',
  DROPPED: 'dropped',
  CLICKED: 'clicked',
  OPENED: 'opened',
};

// *********************************************** //
//               Metric Enums                      //
// *********************************************** //
export interface IMetricPeriod {
  DAILY: 'daily';
  WEEKLY: 'weekly';
  MONTHLY: 'monthly';
  QUARTERLY: 'quarterly';
  SEMIANNUAL: 'semiannual';
  YEARLY: 'yearly';
}
export type TMetricPeriod = IMetricPeriod[keyof IMetricPeriod];
export const METRIC_PERIOD: IMetricPeriod = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  SEMIANNUAL: 'semiannual',
  YEARLY: 'yearly',
};

// *********************************************** //
//               Filter Enums                      //
// *********************************************** //
export enum FilterAccessLevel {
  PRIVATE = '0',
  PUBLIC = '1',
}

// *********************************************** //
//               User AutoCollection Enums         //
// *********************************************** //
export enum AutoCollection {
  ON = 'true',
  OFF = 'false',
}

// *********************************************** //
//      Subscription AutoCollection Enums          //
// *********************************************** //
export enum SubsAutoCollection {
  DEFAULT = 'default',
  ON = 'on',
  OFF = 'off',
}

// *********************************************** //
//               User AutoCollection CheckItems    //
// *********************************************** //
export const userAutoCollectionCheckItems = [
  {
    id: 'auto_collection_on',
    label: 'On',
    value: 'true',
    checkedValue: AutoCollection.ON,
    description:
      "Whenever an invoice is created, an automatic attempt to charge the customer's payment method is made.",
  },
  {
    id: 'auto_collection_off',
    label: 'Off',
    value: 'false',
    checkedValue: AutoCollection.OFF,
    description:
      'Automatic collection of charges will not be made. All payments must be recorded offline.',
  },
];

// *********************************************** //
//               Subs AutoCollection CheckItems    //
// *********************************************** //
export const subsAutoCollectionCheckItems = [
  {
    id: 'auto_collection_default',
    label: 'Default',
    value: 'default',
    checkedValue: SubsAutoCollection.DEFAULT,
    description: 'Use customer default setting.',
  },
  {
    id: 'auto_collection_on',
    label: 'On',
    value: 'on',
    checkedValue: SubsAutoCollection.ON,
    description:
      "Whenever an invoice is created, an automatic attempt to charge the customer's payment method is made.",
  },
  {
    id: 'auto_collection_off',
    label: 'Off',
    value: 'off',
    checkedValue: SubsAutoCollection.OFF,
    description:
      'Automatic collection of charges will not be made. All payments must be recorded offline.',
  },
];

export interface ICreditNotesStatus {
  REFUND_DUE: 'refund_due';
  ADJUSTED: 'adjusted';
  REFUNDED: 'refunded';
  VOIDED: 'voided';
}
export type TCreditNotesStatus = ICreditNotesStatus[keyof ICreditNotesStatus];
export const CREDIT_NOTES_STATUS: ICreditNotesStatus = {
  REFUND_DUE: 'refund_due',
  ADJUSTED: 'adjusted',
  REFUNDED: 'refunded',
  VOIDED: 'voided',
};

export interface IUserStatus {
  ACTIVE: 'active';
  PENDING: 'pending';
  INACTIVE: 'inactive';
}
export type TUserStatus = IUserStatus[keyof IUserStatus];
export const USER_STATUS: IUserStatus = {
  ACTIVE: 'active',
  PENDING: 'pending',
  INACTIVE: 'inactive',
};

// *********************************************** //
//               Credit Note Enums                 //
// *********************************************** //
export enum EActionType {
  VOID = 'void',
  WRITE_OFF = 'write_off',
  DELETE = 'delete',
}
