import React, { BaseSyntheticEvent, useState } from 'react';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { BraintreeWebPaypalCheckout } from 'src/containers/gateways/braintree/BraintreeWebPaypalCheckout';
import { languageOptions } from 'src/models/i18n';
import { useLoadOptionalRecoilSelector, useRefreshPaymentList } from 'src/recoil';
import { gatewaysPortalMapSelector } from 'src/recoil/gateway/gatewayStates';
import { disableButton, reEnableButton } from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import { genAddPayPalPaymentForCustomer } from '../../services/epSubsPortalPaymentsService';

export const EpSubsPaymentPortalPaypalForm = ({
  token,
  onClosePaypalForm,
  gatewayAccountId,
}: {
  token: string;
  onClosePaypalForm?: () => void;
  gatewayAccountId: number;
  // defaultPaymentData?: TNewPaymentFormData;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const refreshPaymentList = useRefreshPaymentList();
  const gatewaysPortalListMap = useLoadOptionalRecoilSelector(gatewaysPortalMapSelector);
  const client_id = gatewaysPortalListMap?.get(gatewayAccountId)?.client_id;

  return (
    <>
      {gatewaysPortalListMap && client_id && (
        <BraintreeWebPaypalCheckout
          options={{ authorization: client_id }}
          onSubmit={async (
            data: paypal.AuthorizationResponse,
            checkboxPayPal: boolean,
            checkboxApplyAllSubs: boolean,
            event?: BaseSyntheticEvent,
          ) => {
            if (data.type != 'PayPalAccount') {
              putNoty({
                type: 'error',
                text: `${languageOptions().portal.error.paypal.failure}`,
              });
              return;
            } else {
              event && disableButton(event);
              try {
                setIsLoading(true);
                const newPaymentMethodId = await genAddPayPalPaymentForCustomer(token, {
                  nonce: data.nonce,
                  email: data.details.email,
                  first_name: data.details.firstName,
                  last_name: data.details.lastName,
                  payment_type: data.type,
                  make_primary: checkboxPayPal,
                  apply_to_all_subs: checkboxApplyAllSubs,
                  gateway: gatewayAccountId,
                });
                refreshPaymentList();
                setIsLoading(false);
                onClosePaypalForm && onClosePaypalForm();
                const message = `Id = ${newPaymentMethodId} ${
                  languageOptions().portal.create.success
                }`;
                putNoty({ type: 'success', text: message });
              } catch (e) {
                setIsLoading(false);
                putNoty({ type: 'error', text: (e as Error).message });
              } finally {
                event && reEnableButton(event);
              }
            }
          }}
        />
      )}
      <EpSubsSpinnerModal isLoading={isLoading} />
    </>
  );
};
