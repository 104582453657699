import { atom, selector } from 'recoil';
import {
  genPaymentListForCustomer,
  IEpSubsCustomer_payment_response,
} from '../../containers/payments/services/epSubsPortalPaymentsService';
import { DEBUGGING } from 'src/config';

/**
 * An atom that stores that stores the token for customer payment
 */
export const currentPaymentTokenAtom = atom<string>({
  key: 'currentPaymentTokenAtom',
  default: '',
});

export const paymentListRequestIDAtom = atom<number>({
  key: 'paymentListRequestIDAtom',
  default: 0,
});

/**
 * An selectorFamily that list all payments
 */
export const paymentListSelector = selector<IEpSubsCustomer_payment_response | undefined>(
  {
    key: 'paymentListSelector',
    get: async ({ get }) => {
      const paymentToken = get(currentPaymentTokenAtom);
      get(paymentListRequestIDAtom); // Add request ID as a dependency to enable refresh
      if (paymentToken !== '') {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const productId = urlParams.get('prd') as string;
        const paymentListData = await genPaymentListForCustomer(paymentToken, productId);
        DEBUGGING &&
          console.log('recoil, paymentListSelector paymentListData:', paymentListData);
        return paymentListData;
      }
    },
  },
);
