import { selector } from 'recoil';
import { ScheduledTaskValue, getScheduledTaskList } from '../../services';
import { scheduledTaskListRequestIDAtom } from './systemAdminAtoms';

/**
 * An selector that stores scheduled task list
 */
export const scheduledTaskListSelector = selector<ScheduledTaskValue[]>({
  key: 'scheduledTaskListSelector',
  get: async ({ get }) => {
    get(scheduledTaskListRequestIDAtom);
    const taskList = await getScheduledTaskList();
    return taskList;
  },
});
