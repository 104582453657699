import axios from 'axios';
import { ISelectFormData } from 'src/containers/home/EpSubsHomeChartForm.js';
import {
  IEpSubsAllMetricsData,
  TEpSubsDailyStatsSingleMetricData,
} from 'src/models/index.js';
import { hostUrl } from '../config.js';

export const genSingleMetricCard = async (): Promise<IEpSubsAllMetricsData[]> => {
  return axios.get(`${hostUrl}/metrics`).then(response => {
    return response.data;
  });
};

export const genReportsChartData = async (
  data: ISelectFormData,
): Promise<TEpSubsDailyStatsSingleMetricData> => {
  return axios.post(`${hostUrl}/reports/chart`, data).then(response => {
    return response.data;
  });
};
