import Noty from 'noty';
export type TNotyType = 'success' | 'error';

interface Props {
  type: TNotyType;
  layout?: Noty.Layout;
  text: string;
  timeout?: number;
}

export function putNoty({
  type,
  layout = 'topRight',
  text,
  timeout = 8000,
}: Props): void {
  new Noty({
    type,
    layout,
    text,
    timeout,
    closeWith: ['button'],
  }).show();
}
