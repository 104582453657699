import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBProductCompositeValue,
  IEpSubsDBProductPost,
  IEpSubsDBProductValue,
  TEpSubsDBProductValueKeys,
  TEpSubsDBResponseOk,
  // IEpSubsDBProduct_activityValue,
  // IEpSubsDBProduct_commentValue,
  // IEpSubsDBAddonValue,
  // IEpSubsDBFulfillment_centerValue,
} from '../models';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { GatewayListDataType } from 'src/containers/payments/EpSubsUpdatePaymentWrapper.js';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';

export const genProductListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBProductValueKeys>> = [],
  simpleQuery = '',
  // status = '',
  // productType = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBProductValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBProductValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/products?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          // `status=${status}`,
          // `product_type=${productType}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

// export const genActiveProductListData = async (): Promise<IEpSubsDBProductValue[]> => {
//   return axios.get(`${hostUrl}/active-products`).then(response => {
//     return response.data;
//   });
// };

export const genAllProductListData = async (): Promise<
  IEpSubsDBProductCompositeValue[]
> => {
  return axios.get(`${hostUrl}/products?per_page=100`).then(response => {
    return response.data.data;
  });
};

export const genExportProductList = async (
  sortingItems: Array<TSortingItem<TEpSubsDBProductValueKeys>> = [],
  simpleQuery = '',
  // status = '',
  // productType = '',
): Promise<string> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBProductValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/products/export?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          // `status=${status}`,
          // `product_type=${productType}`,
        ].join('&'),
    )
    .then(response => {
      const payloadFileStr = response.data;
      if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
      const fileName = `PRODUCTS-${unixtimeWithoutTime(Date.now())}.csv`;
      FileDownload(payloadFileStr, fileName);
      return fileName;
    });
};

export const genProductData = async (
  id: number,
): Promise<IEpSubsDBProductCompositeValue> => {
  return axios.get(`${hostUrl}/products/${id}`).then(response => {
    return response.data;
  });
};

// export const genProductActivityData = async (
//   id: number,
//   limit: number,
// ): Promise<IEpSubsDBProduct_activityValue[]> => {
//   return axios.get(`${hostUrl}/products/${id}/actlogs?limit=${limit}`).then(response => {
//     return response.data.data;
//   });
// };

// export const genProductActivityDetail = async (
//   id: number,
//   actLogId: number,
// ): Promise<IEpSubsDBProduct_activityValue> => {
//   return axios.get(`${hostUrl}/products/${id}/actlogs/${actLogId}`).then(response => {
//     return response.data;
//   });
// };

// export const genProductCommentData = async (
//   id: number,
//   limit?: number,
// ): Promise<IEpSubsDBProduct_commentValue[]> => {
//   return axios
//     .get(`${hostUrl}/products/${id}/comments?${limit ? 'limit=' + limit : ''}`)
//     .then(response => {
//       return response.data.data;
//     });
// };

// export const genCreateProductComment = async (
//   id: number,
//   data: FormData,
//   removeAllUploadedImage: () => void,
// ): Promise<string> => {
//   return axios.post(`${hostUrl}/products/${id}/comments`, data).then(response => {
//     removeAllUploadedImage();
//     return response.data;
//   });
// };

// export const genSampleProductData = async (id: string): Promise<IEpSubsDBProductValue> => {
//   const productData = sampleData.data.filter(record => String(record.id) === id)[0];
//   return productData;
// };

export const genCreateProduct = async (data: IEpSubsDBProductPost): Promise<string> => {
  return axios.post(`${hostUrl}/products`, data).then(response => {
    return response.data.data;
  });
};

export const genCloneProduct = async (id: number): Promise<string> => {
  return axios.post(`${hostUrl}/products/${id}/clone`).then(response => {
    return response.data.data; // newProductID
  });
};

export const genDeleteProduct = async (id: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/products/${id}`).then(() => {
    return true;
  });
};

export const genEditProduct = async (
  id: number,
  data: IEpSubsDBProductPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/products/${id}`, data).then(() => {
    return true;
  });
};

export const genArchiveProduct = async (id: number, archive = true): Promise<boolean> => {
  const archiveParaStr = !archive ? 'archive=false' : '';
  return axios.post(`${hostUrl}/products/${id}/archive?${archiveParaStr}`).then(() => {
    return true;
  });
};

export const genUploadProductLogo = async (data: FormData): Promise<string> => {
  return axios.post(`${hostUrl}/products/upload`, data).then(response => {
    return response.data.data;
  });
};

export const genGatewayListDataFromProductKey = async (
  product_key: string,
): Promise<GatewayListDataType[]> => {
  return axios.get(`${hostUrl}/products/portal/${product_key}`).then(response => {
    return response.data.data;
  });
};
