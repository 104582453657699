import React, { useEffect, useState, useRef } from 'react';
import ReactSelect, { createFilter, StylesConfig } from 'react-select';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { Controller, Control } from 'react-hook-form';
import {
  activePlanListStateSelector,
  activePlansRequestIDAtom,
  allPlanDataMapSelector,
  selectedSubscriptionPlansSummaryTypeAtoms,
} from '../../../recoil';
import {
  IEpSubsCompositeDBPlanValue,
  IEpSubsDBAddonValue,
  IEpSubsDBProductValue,
} from '../../../models/';
import { subscriptionPlansSummaryType } from 'src/components/addresses/EpSubsAddressFormSection';
import {
  EDropdownItemType,
  getGroupedOptions,
  TGroupedOptions,
} from 'src/components/dropdownSelect/ESGeneralDropdownInput';
import { DEBUGGING } from 'src/config';
import { convertMoneySignDependLocale } from 'src/models/i18n';

export const getPlanSelectionLabel = (
  plan: IEpSubsCompositeDBPlanValue,
  product?: IEpSubsDBProductValue,
) => {
  const price = convertMoneySignDependLocale(plan.price);
  const billingPeriod = `${plan.period} ${plan.period_unit}${plan.period > 1 ? 's' : ''}`;
  return `${plan.name}, ${price}/${billingPeriod} [${
    !!product ? product.name : plan.product ? plan.product.name : plan.product_id
  }${plan.is_trial ? ' - Trial' : ''}]`;
};

export const getAddonSelectionLabel = (addon: IEpSubsDBAddonValue) => {
  const addonPrice = convertMoneySignDependLocale(addon.price);
  const billingPeriod = `${addon.period} ${addon.period_unit}${
    addon.period > 1 ? 's' : ''
  }`;
  return `${addon.name}, ${addonPrice}/${billingPeriod}`;
};

export interface IPlanSelectProps {
  defaultValue: string;
  namePath: string;
  control: Control;
  required?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  validate?: () => boolean;
  onBlur?: () => void;
  displayOptionGroup?: boolean;
  setSelectedPlanId?: React.Dispatch<React.SetStateAction<string>>;
  selectedProductId?: string;
  onApiReady?: () => void;
  onApiLoading?: () => void;
  planId?: string;
  excludeTrialPlans?: boolean;
  currentPlanId?: string;
  subscriptionPlanNumberId?: number;
}

type SelectOption = {
  type: string;
  label: string;
  value: string;
  groupName: string;
  productId: string;
};

type IsMulti = false;

export const EpSubsPlanSelect = (props: IPlanSelectProps): JSX.Element => {
  const { displayOptionGroup = true, onApiReady, onApiLoading } = props;
  const selectRef = useRef<ReactSelect<
    SelectOption,
    IsMulti,
    TGroupedOptions<SelectOption>
  > | null>(null);
  // all active plan options:
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [optionsForDisplay, setOptionsForDisplay] = useState<
    SelectOption[] | TGroupedOptions<SelectOption>[]
  >([]);

  const activePlanListLoadable = useRecoilValueLoadable(activePlanListStateSelector);
  const setActivePlansRequestID = useSetRecoilState(activePlansRequestIDAtom);
  const setSelectedSubscriptionPlansSummaryType = useSetRecoilState(
    selectedSubscriptionPlansSummaryTypeAtoms(props.namePath),
  );
  const [activePlanListData, setActivePlanListData] = useState<
    IEpSubsCompositeDBPlanValue[]
  >([]);
  useEffect(() => {
    // refresh all active plans
    setActivePlansRequestID(requestID => requestID + 1);
  }, []);

  const setPlanOptionsForDisplay = (activeOptions: SelectOption[]) => {
    if (props.selectedProductId) {
      // setOptionsForDisplay
      setOptionsForDisplay(
        activeOptions.filter(option => option.productId === props.selectedProductId),
      );
    } else if (activeOptions.length > 0) {
      // show all plans, if there is no selected product
      if (displayOptionGroup && activeOptions[0].type === EDropdownItemType.GROUPED) {
        const groupedSelectOptions = getGroupedOptions<SelectOption>(
          activeOptions,
        ) as TGroupedOptions<SelectOption>[];
        setOptionsForDisplay(groupedSelectOptions);
      } else {
        setOptionsForDisplay(activeOptions);
      }
    }
  };

  useEffect(() => {
    setPlanOptionsForDisplay(options);
    // remove selected plan, when selectedProductId changed
    props.control.setValue(props.namePath, null);
  }, [props.selectedProductId]);
  useEffect(() => {
    switch (activePlanListLoadable.state) {
      case 'loading':
        onApiLoading && onApiLoading();
        break;
      case 'hasValue':
        const activeOptions = props.excludeTrialPlans
          ? activePlanListLoadable.contents.data.filter(
              val => val.is_trial == false && val.id !== props.subscriptionPlanNumberId,
            )
          : activePlanListLoadable.contents.data;

        const updatedActiveOptions = activeOptions.map(plan => {
          const productData = plan.product;
          return {
            type: EDropdownItemType.GROUPED,
            value: '' + plan.plan_id,
            label: getPlanSelectionLabel(plan),
            groupName: !productData ? 'Unknown Product' : productData.name,
            productId: !productData ? '' : '' + productData.id,
          };
        });
        setActivePlanListData(activePlanListLoadable.contents.data);

        setOptions(updatedActiveOptions);
        setPlanOptionsForDisplay(updatedActiveOptions);
        onApiReady && onApiReady();
        break;
      case 'hasError':
        onApiReady && onApiReady();
    }
  }, [activePlanListLoadable.contents]);
  const allPlanDataMapLoadable = useRecoilValueLoadable(allPlanDataMapSelector);
  const [allPlanDataMap, setAllPlanDataMap] =
    useState<Map<string, IEpSubsCompositeDBPlanValue>>();
  useEffect(() => {
    if (allPlanDataMapLoadable.state === 'hasValue') {
      setAllPlanDataMap(allPlanDataMapLoadable.contents);
    }
  }, [allPlanDataMapLoadable.contents]);

  const customStyles: StylesConfig<
    SelectOption,
    IsMulti,
    TGroupedOptions<SelectOption>
  > = {
    option: provided => ({
      ...provided,
      wordWrap: 'break-word',
      paddingLeft: '1rem',
    }),
  };

  // Used to style grouped dropdown.
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    // fontWeight: 'bold',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    // textAlign: 'center',
  };

  const formatGroupLabel = (data: TGroupedOptions<SelectOption>) => (
    <div style={groupStyles}>
      <span>{data.groupName}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  // if there is no options, then return dummy Select
  // only render real Select if there is options
  // otherwise cannot show defaultValue correctly
  if (options.length === 0) return <ReactSelect options={options} />;

  let selectDefaultValue: SelectOption | undefined | null = options.find(
    option => option.value === props.defaultValue,
  );
  let controllerDefaultValue: string | null; // only store plan id, not option object

  if (selectDefaultValue === undefined) {
    // if undefined
    selectDefaultValue = null;
    controllerDefaultValue = null;
  } else {
    controllerDefaultValue = selectDefaultValue.value;
  }

  let rules = {};
  if (props.required) {
    rules = { ...rules, required: true };
  }
  if (props.validate) {
    rules = { ...rules, validate: props.validate };
  }
  return (
    <Controller
      name={props.namePath}
      control={props.control}
      defaultValue={controllerDefaultValue}
      rules={rules}
      onFocus={() => {
        if (selectRef.current !== null) selectRef.current.focus();
      }}
      render={({ onChange, onBlur, value }) => {
        return (
          <ReactSelect
            id={`${props.planId}`}
            isDisabled={props.disabled}
            isClearable={props.isClearable}
            ref={ref => (selectRef.current = ref)}
            styles={customStyles}
            formatGroupLabel={formatGroupLabel}
            options={optionsForDisplay}
            // for typeahead filter, only search 'label' field, don't search 'value' field
            filterOption={createFilter({
              matchFrom: 'any',
              stringify: option => `${option.label}`,
            })}
            defaultValue={selectDefaultValue}
            // only return plan id:
            value={options.find(option => option.value === value) ?? null} // set to null to reset select
            onChange={val => {
              const productData = allPlanDataMap?.get(val?.value ?? '')?.product;
              if (productData) {
                DEBUGGING &&
                  console.log(
                    'EpSubsPlanSelect onChange setSelectedSubscriptionPlansSummaryType: ',
                    val?.value,
                    productData,
                  );
                const planSummaryTypes = [] as subscriptionPlansSummaryType[];
                if (productData.shippable) {
                  planSummaryTypes.push(subscriptionPlansSummaryType.Shippable);
                }
                if (productData.digital) {
                  planSummaryTypes.push(subscriptionPlansSummaryType.Digital);
                }
                setSelectedSubscriptionPlansSummaryType(planSummaryTypes);
              }

              if (
                [
                  'plan.id', // From first plan of EpSubsSubscriptionForm, next_plan is ignored here
                  'plan_id', // From the updated plan of EpSubsSubscriptionPlanChangeModal
                  'content.plan.id', // From the first plan of EpSubsOfferForm
                ].includes(props.namePath)
              ) {
                if (props.setSelectedPlanId) {
                  const selectedPlanId =
                    val == undefined
                      ? '' // When we remove the selected option
                      : activePlanListData.find((plan: IEpSubsCompositeDBPlanValue) => {
                          return plan.plan_id == val.value;
                        })?.plan_id;
                  if (selectedPlanId != null) {
                    // Either we choose an effective option or remove the selected option
                    props.setSelectedPlanId(selectedPlanId);
                  }
                }
              }
              return onChange(val?.value ?? '');
            }}
            onBlur={() => {
              onBlur();
              // for trigger validation in form:
              if (props.onBlur) props.onBlur();
            }}
          />
        );
      }}
    />
  );
};
