import React, { useState } from 'react';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { genPaypalBillingAgreementToken } from './paypalExpressService';
import { EpIcon } from 'src/assets';
import style from './EpSubsPayPalExpressCheckout.module.scss';
import { Button, Form, Row } from 'react-bootstrap';
import { putNoty } from 'src/utilities/epSubsNoty';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { languageOptions } from 'src/models/i18n';
import { DEBUGGING } from 'src/config';

export interface PayPalExpressData {
  BAID: string;
  email: string;
  first_name: string;
  last_name: string;
  payment_type: string;
  make_primary: boolean;
  apply_to_all_subs: boolean;
  gateway: number;
}
interface ParamType {
  token: string;
}

export const EpSubsPayPalExpressCheckout = (): JSX.Element => {
  const { watch, register } = useForm<PayPalExpressData>({
    mode: 'all',
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const user_token = useParams<ParamType>().token;
  const setUpToken = async () => {
    setIsLoading(true);
    try {
      if (user_token !== null) {
        const returnBillingAgreementUrl = await genPaypalBillingAgreementToken(
          `${window.location.href}`,
          `https://www.theepochtimes.com/`,
          0,
          'The Epoch Times Subscription Service',
          true,
          user_token,
        );
        if (typeof returnBillingAgreementUrl == 'boolean') {
          putNoty({ type: 'error', text: 'failed to add paypal express' });
          history.goBack();
        } else {
          return false;
        }
      }
    } catch (e) {
      putNoty({ type: 'error', text: 'failed to add paypal express' });
      setIsLoading(false);
    }
  };

  DEBUGGING &&
    console.log(
      'storage reading:',
      localStorage.getItem('payPalExpressCheckbox'),
      'watch',
      watch().make_primary,
    );

  return (
    <>
      <div className={style.block}>
        <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
          Add a payment method using PayPal
        </p>
        <Row style={{ marginLeft: '10px', marginTop: '20px' }}>
          <Form>
            <Form.Group>
              <Form.Check
                id="paypal_express_checkbox"
                type="checkbox"
                name="make_primary"
                defaultChecked={true}
                ref={register()}
                onChange={() => {
                  localStorage.setItem(
                    'payPalExpressCheckbox',
                    `${watch().make_primary}`,
                  );
                }}
              />
            </Form.Group>
          </Form>
          <p>{languageOptions().portal.default.make_default}</p>
        </Row>
        <Row style={{ marginLeft: '10px' }}>
          <Form>
            <Form.Group>
              <Form.Check
                id="paypal_express_apply_checkbox"
                type="checkbox"
                name="apply_to_all_subs"
                defaultChecked={true}
                ref={register()}
                onChange={() => {
                  localStorage.setItem(
                    'payPalExpressApplyAllSubsCheckbox',
                    `${watch().apply_to_all_subs}`,
                  );
                }}
              />
            </Form.Group>
          </Form>
          <p>{languageOptions().portal.default.apply_all}</p>
        </Row>
        <div
          onClick={() => {
            setUpToken();
          }}
          className={style.paypal}
        >
          <Button style={{ backgroundColor: '#FFC439', borderColor: 'transparent' }}>
            <EpIcon name={'paypalButton'} style={{ height: '30px' }} />
          </Button>
        </div>
        <EpSubsSpinnerModal isLoading={isLoading} />
      </div>
    </>
  );
};
