import { selector } from 'recoil';
import { IEpSubsDBSubscription_channelValue } from 'src/models/_db_generated_/epSubsDBTypes';
import { genSubscriptionChannelData } from 'src/services/epSubsSubscriptionService';
import { arrayToMap } from 'src/utilities/commonUtils';

export const subscriptionChannelsSelector = selector<
  IEpSubsDBSubscription_channelValue[]
>({
  key: 'subscriptionChannelsSelector',
  get: async () => {
    return await genSubscriptionChannelData();
  },
});

/**
 * An selector that stores the map from subsChannel_id to IEpSubsDBSubscription_channelValue
 */
export const allSubsChannelsDataMapSelector = selector<
  Map<string, IEpSubsDBSubscription_channelValue>
>({
  key: 'allSubsChannelsDataMapSelector',
  get: async ({ get }) => {
    // To be replaced with a all subsChannel list API
    const subsChannelList = get(subscriptionChannelsSelector) ?? [];
    return arrayToMap(subsChannelList, subsChannelRecord => String(subsChannelRecord.id));
  },
});
