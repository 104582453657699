import axios from 'axios';
import { hostUrl } from '../config.js';

export const deleteComment = async (
  path: string,
  id: number,
  commentId: number,
): Promise<boolean> => {
  return axios.delete(`${hostUrl}/${path}/${id}/comments/${commentId}`).then(response => {
    if (response.data === false) {
      throw new Error('Failed delete comment!');
    }
    return response.data;
  });
};
