import React, { useEffect, useState, useRef } from 'react';
import ReactSelect, { OptionsType, StylesConfig, createFilter } from 'react-select';
import { useRecoilValueLoadable, RecoilValueReadOnly, useRecoilState } from 'recoil';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { TUseFormSetValue } from 'src/components/formComponents';
import { userRolesSelectedRolesAtom } from 'src/recoil';

export interface IMultipleSelectProps {
  namePath: string;
  control: Control<Record<string, any>>;
  setValue: TUseFormSetValue<any>;
  selector: RecoilValueReadOnly<{ id: string | number; name: string | number }[]>;
  required?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  validate?: () => boolean;
  onBlur?: () => void;
}

type SelectOption = {
  label: string;
  value: string;
};

type IsMulti = false;

export const EpSubsUserRoleMultipleSelect = ({
  namePath,
  control,
  setValue,
  selector,
  required,
  disabled,
  isClearable,
  validate,
  onBlur,
}: IMultipleSelectProps): JSX.Element => {
  const selectRef = useRef<any>(null);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [userRolesSelectedRolesValue, setUserRolesSelectedRolesValue] = useRecoilState(
    userRolesSelectedRolesAtom,
  );
  const multipleListLoadable = useRecoilValueLoadable(selector);

  useEffect(() => {
    if (multipleListLoadable.state === 'hasValue') {
      const options = multipleListLoadable.contents.map(obj => ({
        value: `${obj.id}`,
        label: String(obj.name),
      }));
      setOptions(options);
    }
  }, [multipleListLoadable.contents]);

  const customStyles: StylesConfig<SelectOption, IsMulti> = {
    option: provided => ({
      ...provided,
      wordWrap: 'break-word',
    }),
  };

  if (options.length === 0) return <ReactSelect options={options} />;

  let rules = {};
  if (required) {
    rules = { required: true };
  }
  if (validate) {
    rules = { validate };
  }

  const handleChange = (e: SelectOption | OptionsType<SelectOption> | null) => {
    const updatedOptions = Array.isArray(e) ? e.map(x => x.value) : [];
    // For ReactSelect to render
    setUserRolesSelectedRolesValue(updatedOptions);
    // For useForm submit
    setValue(namePath, updatedOptions);
  };

  return (
    <Controller
      name={namePath}
      control={control}
      rules={rules}
      required={required}
      defaultValue={[]}
      onFocus={() => {
        if (selectRef.current !== null) selectRef.current.focus();
      }}
      render={(renderProps: ControllerRenderProps<Record<string, any>>) => {
        return (
          <ReactSelect
            id="Ep_multi_role_select"
            isMulti
            isClearable={isClearable}
            options={options}
            // for typeahead filter, only search 'label' field, don't search 'value' field
            filterOption={createFilter({
              matchFrom: 'any',
              stringify: option => `${option.label}`,
            })}
            value={options.filter(obj => userRolesSelectedRolesValue.includes(obj.value))}
            onChange={option => handleChange(option)}
            isDisabled={disabled}
            ref={ref => (selectRef.current = ref)}
            styles={customStyles}
            onBlur={() => {
              renderProps.onBlur();
              if (onBlur) onBlur();
            }}
          />
        );
      }}
    />
  );
};
