import { LOCALE } from 'src/config';
import { ELocale } from './Locale';

export const getEnableAddressVerification = (locale = LOCALE as ELocale): boolean => {
  switch (locale) {
    case ELocale.en_US:
      return true;
    case ELocale.es_US:
      return true;
    case ELocale.en_AU:
    case ELocale.es_MX:
    case ELocale.pt_BR:
    case ELocale.en_UK:
    case ELocale.cs_CZ:
    case ELocale.sk_SK:
    case ELocale.ja_JP:
    case ELocale.de_DE:
    case ELocale.fr_FR:
      return false;
  }
};

export const getCountryCode = (customerLocale: ELocale): string => {
  switch (customerLocale) {
    case ELocale.en_AU:
      return 'AU';
    case ELocale.en_US:
      return 'US';
    case ELocale.es_US:
      return 'US';
    case ELocale.es_MX:
      return 'MX';
    case ELocale.en_UK:
      return 'GB';
    case ELocale.cs_CZ:
      return 'CZ';
    case ELocale.sk_SK:
      return 'SK';
    case ELocale.pt_BR:
      return 'BR';
    case ELocale.ja_JP:
      return 'JP';
    case ELocale.de_DE:
      return 'DE';
    case ELocale.fr_FR:
      return 'FR';
    default:
      return 'US';
  }
};

export const getAddressLabel = (
  locale: string,
): { address1: string; address2: string; city: string; post_code: string } => {
  switch (locale) {
    case ELocale.fr_FR:
    case ELocale.de_DE:
      return {
        address1: 'Street Name',
        address2: 'House Number',
        city: 'City',
        post_code: 'Zip Code',
      };
    case ELocale.en_AU:
      return {
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        city: 'Suburb',
        post_code: 'Postcode',
      };
    case ELocale.en_UK:
      return {
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        city: 'City/Town',
        post_code: 'Postcode',
      };
    case ELocale.ja_JP:
    case ELocale.sk_SK:
    case ELocale.cs_CZ:
    case ELocale.es_US:
    case ELocale.pt_BR:
    case ELocale.es_MX:
    case ELocale.en_US:
    default:
      return {
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        city: 'City',
        post_code: 'Zip Code',
      };
  }
};
