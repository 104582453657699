import React, { BaseSyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useLogin } from '../../recoil';
import { genAcceptInvite } from '../../services/epSubsUserService';
import style from './EpSubsLogin.module.scss';
import { putNoty } from 'src/utilities/epSubsNoty';
import { DEBUGGING } from 'src/config';

type Props = {
  inviteToken: string;
};

export interface IEpSubsAcceptInvitePost {
  first_name: string;
  last_name: string;
  token: string;
  password: string;
}

const EpSubsInviteAccept = ({ inviteToken }: Props): JSX.Element => {
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const login = useLogin();
  const history = useHistory();

  function validateForm() {
    return password.length > 0;
  }

  const handleSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault();

    try {
      const resp = await genAcceptInvite({
        first_name: firstName,
        last_name: lastName,
        token: inviteToken,
        password: password,
      });

      const successMsg = 'Password set successfully! Logging you in...';
      putNoty({ type: 'success', text: successMsg });

      const email = resp.email;
      await login({
        user: email,
        password: password,
      });

      // go to homepage
      history.push('/');
    } catch (e) {
      DEBUGGING && console.log('accept invite error: ', e);
      setErrorMessage((e as Error).message);
    }
  };

  return (
    <div className="Login">
      <h1 className={style.login_header}>Welcome to Conductor</h1>
      <Form noValidate onSubmit={handleSubmit} id={style.login_form} autoComplete={'off'}>
        <Form.Group controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
            }}
            required={true}
          />
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
            }}
            required={true}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Please set an account password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
          />
        </Form.Group>
        <Button block type="submit" disabled={!validateForm()}>
          Create Account
        </Button>
        {errorMessage && <div className={style.error}>{errorMessage}</div>}
      </Form>
    </div>
  );
};

export default EpSubsInviteAccept;
