import { atom, atomFamily, GetRecoilValue, selector, selectorFamily } from 'recoil';
import { DEBUGGING } from 'src/config';
import {
  IEpSubsDBNotification_eventValue,
  IEpSubsDBNotification_from_emailValue,
  IEpSubsNotificationEvent,
  IEpSubsCompositeNotificationValue,
  IEpSubsDBCompositeNotificationEventCategoryValue,
  IEpSubsNotificationApiValue,
  IEpSubsCompositeNotificationValueInList,
  IEpSubsDBNotificationEventApiValue,
  IEpSubsDBCompositeNotificationEventValue,
  IEpSubsDBNotificationEventCategoryApiValue,
} from 'src/models';
import {
  genAllNotificationEvents,
  genAllNotifications,
  genEventData,
  genNotificationData,
  genNotificationFromEmails,
} from 'src/services';

// *********************************************** //
//          Notification List Page States          //
// *********************************************** //
export const rowNotificationEventsRequestIDAtom = atom<number>({
  key: 'rowNotificationEventsRequestIDAtom',
  default: 0,
});

const genCompositeNotifications = async (
  get: GetRecoilValue,
  notifications: IEpSubsNotificationApiValue[],
): Promise<IEpSubsCompositeNotificationValueInList[]> => {
  DEBUGGING &&
    console.log(
      'Check compositeNotificationEventsSelector map 3: notifications',
      notifications,
    );
  return await Promise.all(
    notifications.map(async notification => {
      const detailApiEnabled = get(notificationDetailEnabledAtomFamily(notification.id));
      if (!detailApiEnabled) return { notification };
      DEBUGGING &&
        console.log(
          'Check compositeNotificationEventsSelector map 3: notification id',
          notification.id,
          detailApiEnabled,
        );
      return get(notificationDetailSelectorFamily(notification.id));
    }),
  );
};

const genCompositeEvents = async (
  get: GetRecoilValue,
  events: IEpSubsDBNotificationEventApiValue[],
): Promise<IEpSubsDBCompositeNotificationEventValue[]> => {
  DEBUGGING &&
    console.log('Check compositeNotificationEventsSelector map 2: events', events);
  return await Promise.all(
    events.map(async event => {
      if (!event.notifications) return { ...event, notifications: [] };
      const notifications = await genCompositeNotifications(get, event.notifications);
      return { ...event, notifications };
    }),
  );
};

const genCompositeEventCategories = async (
  get: GetRecoilValue,
  eventCategories: IEpSubsDBNotificationEventCategoryApiValue[],
): Promise<IEpSubsDBCompositeNotificationEventCategoryValue[]> => {
  DEBUGGING &&
    console.log(
      'Check compositeNotificationEventsSelector map 1: allNotificationEventCategories',
      eventCategories,
    );
  return await Promise.all(
    eventCategories.map(async eventCategory => {
      const events = await genCompositeEvents(get, eventCategory.events ?? []);
      return { ...eventCategory, events };
    }),
  );
};

/**
 * All raw NotificationEventCategory Data
 * The Notification Data come from the genAllNotifications.
 * Separating this api call out from compositeNotificationEventsSelector optimizes the data refreshing.
 */
export const rawNotificationEventsSelector = selector<
  IEpSubsDBNotificationEventCategoryApiValue[]
>({
  key: 'rawNotificationEventsSelector',
  get: async ({ get }) => {
    get(rowNotificationEventsRequestIDAtom);
    const allNotificationEventCategories = await genAllNotifications();
    DEBUGGING &&
      console.log(
        'Recoil, rawNotificationEventsSelector, raw api result',
        allNotificationEventCategories,
      );
    return allNotificationEventCategories;
  },
});

/**
 * All composite NotificationEventCategory Data
 * The Notification Data could be either from the genAllNotifications or genNotificationData apiService
 * Depends on the notificationDetailEnabledAtomFamily
 */
export const compositeNotificationEventsSelector = selector<
  IEpSubsDBCompositeNotificationEventCategoryValue[]
>({
  key: 'compositeNotificationEventsSelector',
  get: async ({ get }) => {
    get(rowNotificationEventsRequestIDAtom);
    const allNotificationEventCategories = get(rawNotificationEventsSelector);
    const compositeAllNotificationEventCategories = await genCompositeEventCategories(
      get,
      allNotificationEventCategories,
    );
    DEBUGGING &&
      console.log(
        'Recoil, compositeNotificationEventsSelector, Check Notification List before map',
        allNotificationEventCategories,
        compositeAllNotificationEventCategories,
      );
    return compositeAllNotificationEventCategories;
  },
});

// *********************************************** //
//          Edit Notification Page States          //
// *********************************************** //
export const notificationDetailEnabledAtomFamily = atomFamily<boolean, number>({
  key: 'notificationDetailEnabledAtomFamily',
  default: false,
});

export const notificationDetailRequestIDAtomFamily = atomFamily<number, number>({
  key: 'notificationDetailRequestIDAtomFamily',
  default: 0,
});

export const notificationDetailSelectorFamily = selectorFamily<
  IEpSubsCompositeNotificationValue,
  number
>({
  key: 'notificationDetailSelectorFamily',
  get:
    notificationId =>
    async ({ get }) => {
      const requestId = get(notificationDetailRequestIDAtomFamily(notificationId));
      DEBUGGING &&
        console.log(
          'Recoil, notificationDetailSelectorFamily, Check Notification Detail request Id',
          notificationId,
          requestId,
        );
      return await genNotificationData(notificationId);
    },
});

export const fromEmailListSelector = selector<IEpSubsDBNotification_from_emailValue[]>({
  key: 'fromEmailListSelector',
  get: async () => {
    return await genNotificationFromEmails();
  },
});

// *********************************************** //
//     Add Notification for given Event States     //
// *********************************************** //
export const allNotificationEventsSelector = selector<IEpSubsDBNotification_eventValue[]>(
  {
    key: 'allNotificationEventsSelector',
    get: async ({}) => {
      return await genAllNotificationEvents();
    },
  },
);

export const notificationEventSelectorFamily = selectorFamily<
  IEpSubsNotificationEvent,
  number
>({
  key: 'notificationEventSelectorFamily',
  get:
    (eventId: number) =>
    async ({}) => {
      return await genEventData(eventId);
    },
});
