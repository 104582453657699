/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware, Store } from '@reduxjs/toolkit';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import { createReducer } from './createReducer';

export function configureAppStore(): Store {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      // Set serializableCheck to false since we want to keep Map in redux store state,
      // since Map is not fully serializable. As a result, Map in redux store state
      // cannot be properly tracked by redux dev-tool. (Ref: https://shorturl.at/foMVZ)
      ...getDefaultMiddleware({ serializableCheck: false }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  sagaMiddleware.run(rootSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./createReducer', () => {
      forceReducerReload(store);
    });
  }

  return store;
}
