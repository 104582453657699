import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { TLocationRecord } from '../components/tables/EpFullFeaturedTable';
import {
  latestProductSearchParamAtom,
  latestPlanSearchParamAtom,
  latestCustomerSearchParamAtom,
  latestSubscriptionSearchParamAtom,
  latestInvoiceSearchParamAtom,
  latestAddonSearchParamAtom,
  latestCouponSearchParamAtom,
  latestTransactionSearchParamAtom,
  useHasPermission,
  latestEmailLogSearchParamAtom,
  latestUserSearchParamAtom,
  latestOfferSearchParamAtom,
  userInfoSelector,
  useLoadOptionalRecoilSelector,
} from '../recoil';
import './EpSubsTopBar.scss';
import { IEpSubsProfile, UiPermission } from 'src/models';
import { latestGatewaySearchParamAtom } from 'src/recoil/gateway/gatewayStates';
import { latestAdminBulkSearchParamAtom } from 'src/recoil/bulkOperation/adminBulkOperationStates';
import { latestWebhookSearchParamAtom } from 'src/recoil/webhook/webhookOperationStates';
import { latestPluginSearchParamAtom } from 'src/recoil/pluginConductor/pluginOperationStates';
import { simpleToolTip } from 'src/components/simpleTooltip/simpleTooltip';
import EpSubsUserInfoModal from 'src/components/modals/EpSubsUserInfoModal';
import { EpIcon, IconFactory } from 'src/assets';

interface MenuLinkProps {
  label: string;
  to: string;
  matchPaths?: string | string[];
  exact?: boolean;
  id?: string;
  className?: string;
  iconName?: keyof typeof IconFactory;
  height?: string;
}

const MenuLink = (props: MenuLinkProps): JSX.Element => {
  const { id, label, to, exact = false, className = '', height } = props;
  const matchPaths = props.matchPaths ?? to;

  const match = useRouteMatch({
    path: matchPaths,
    exact,
  });

  return (
    <Link
      id={id}
      to={to}
      className={`${match ? 'active' : ''} ${className} top_bar_no_dropdown`}
    >
      {props.iconName && (
        <EpIcon
          name={props.iconName}
          style={{ ...{ height: `${height}`, verticalAlign: 'text-bottom' } }}
        />
      )}
      {label}
    </Link>
  );
};

const NavDropDownMenuItem = (props: MenuLinkProps): JSX.Element => {
  const { id, className = '', to, label, matchPaths, iconName, height } = props;
  const history = useHistory();
  return (
    <NavDropdown.Item className={`mx-0`} onClick={() => history.push(to)}>
      <MenuLink
        id={id}
        className={`${className} mx-0`}
        to={to}
        label={label}
        matchPaths={matchPaths}
        iconName={iconName}
        height={height}
      />
    </NavDropdown.Item>
  );
};

export interface NavDropDownMenuHeadProps {
  label: string;
  matchPaths?: string[];
  exact?: boolean;
  id?: string;
  className?: string;
  iconName?: keyof typeof IconFactory;
  iconHeight?: string;
}

export const NavDropDownMenuHead = (
  props: PropsWithChildren<NavDropDownMenuHeadProps>,
): JSX.Element => {
  const { id = '', exact = false, label, className = '', iconName, iconHeight } = props;
  const dropDownItems = React.Children.toArray(props.children);
  const matchPaths = (props.matchPaths ?? []).concat(
    dropDownItems.map((dropDownItem: any) => dropDownItem.props.matchPaths),
  );
  const match = useRouteMatch({
    path: matchPaths,
    exact,
  });

  return (
    <NavDropdown
      title={
        <>
          {iconName && (
            <EpIcon
              name={iconName}
              style={{ height: iconHeight, verticalAlign: 'top' }}
            />
          )}
          {label}
        </>
      }
      id={id}
      active={!!match}
      className={`${className} top_bar`}
    >
      {props.children}
    </NavDropdown>
  );
};

const EpSubsTopBar = (): JSX.Element => {
  const [profile, setProfile] = useState<IEpSubsProfile>({
    first_name: '',
    last_name: '',
    email: '',
  });
  const userInfoLoadable = useLoadOptionalRecoilSelector(userInfoSelector);
  useEffect(() => {
    if (userInfoLoadable != null) {
      setProfile(userInfoLoadable);
    }
  }, [userInfoLoadable]);

  const [infoModal, setInfoModal] = useState<boolean>(false);

  const productSearchLoc: TLocationRecord = useRecoilValue(latestProductSearchParamAtom);
  const planSearchLoc: TLocationRecord = useRecoilValue(latestPlanSearchParamAtom);
  const offerSearchLoc: TLocationRecord = useRecoilValue(latestOfferSearchParamAtom);
  const addonSearchLoc: TLocationRecord = useRecoilValue(latestAddonSearchParamAtom);
  const couponSearchLoc: TLocationRecord = useRecoilValue(latestCouponSearchParamAtom);
  const gatewaySearchLoc: TLocationRecord = useRecoilValue(latestGatewaySearchParamAtom);
  const adminBulkSearchLoc: TLocationRecord = useRecoilValue(
    latestAdminBulkSearchParamAtom,
  );
  const webhookSearchLoc: TLocationRecord = useRecoilValue(latestWebhookSearchParamAtom);
  const pluginSearchLoc: TLocationRecord = useRecoilValue(latestPluginSearchParamAtom);
  const customerSearchLoc: TLocationRecord = useRecoilValue(
    latestCustomerSearchParamAtom,
  );
  const subscriptionSearchLoc: TLocationRecord = useRecoilValue(
    latestSubscriptionSearchParamAtom,
  );
  const invoiceSearchLoc: TLocationRecord = useRecoilValue(latestInvoiceSearchParamAtom);
  const transactionSearchLoc: TLocationRecord = useRecoilValue(
    latestTransactionSearchParamAtom,
  );
  const emailLogSearchLoc: TLocationRecord = useRecoilValue(
    latestEmailLogSearchParamAtom,
  );
  const userSearchLoc: TLocationRecord = useRecoilValue(latestUserSearchParamAtom);

  const closeInfoModal = () => {
    setInfoModal(false);
  };

  return (
    <>
      <Navbar expand="md" className={'epsubs_top_nav_bar'}>
        <Navbar.Brand>
          <Image
            style={{ height: '40px' }}
            src="https://cdn.epoch.cloud/assets/static_assets/EET_Logo_Stacked_Small_Transparent.png"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            id="TopBar"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: 'auto',
            }}
          >
            <div style={{ padding: '8px' }}>
              {
                <MenuLink
                  id="home"
                  to={'/'}
                  label={'Home'}
                  exact={true}
                  iconName="home"
                  height="20px"
                />
              }
            </div>
            <div style={{ padding: '8px' }}>
              {useHasPermission(UiPermission['cust.view']) && (
                <MenuLink
                  id="customers"
                  to={'/customers/search' + customerSearchLoc.search}
                  label={'Customers'}
                  matchPaths={'/customers/'}
                  iconName="user"
                  height="20px"
                />
              )}
            </div>
            <div style={{ padding: '8px' }}>
              {useHasPermission(UiPermission['sub.view']) && (
                <MenuLink
                  id="subscriptions"
                  to={'/subscriptions/search' + subscriptionSearchLoc.search}
                  label={'Subscriptions'}
                  matchPaths={'/subscriptions/'}
                  iconName="subscribe"
                  height="19px"
                />
              )}
            </div>
            <div style={{ padding: '8px' }}>
              {useHasPermission(UiPermission['inv.view']) && (
                <MenuLink
                  id="invoices"
                  to={'/invoices/search' + invoiceSearchLoc.search}
                  label={'Invoices'}
                  matchPaths={'/invoices/'}
                  iconName="invoice"
                  height="19px"
                />
              )}
            </div>

            {(useHasPermission(UiPermission['product.view']) ||
              useHasPermission(UiPermission['plan.view']) ||
              useHasPermission(UiPermission['offer.view']) ||
              useHasPermission(UiPermission['coupon.view']) ||
              useHasPermission(UiPermission['addon.view'])) && (
              <>
                <NavDropDownMenuHead
                  label={'Product'}
                  id="product-dropdown"
                  iconName="product"
                  iconHeight="24px"
                >
                  {useHasPermission(UiPermission['product.view']) && (
                    <NavDropDownMenuItem
                      id="products"
                      to={'/products/search' + productSearchLoc.search}
                      label={'Products'}
                      matchPaths={'/products/'}
                    />
                  )}
                  {useHasPermission(UiPermission['plan.view']) && (
                    <NavDropDownMenuItem
                      id="plans"
                      to={'/plans/search' + planSearchLoc.search}
                      label={'Plans'}
                      matchPaths={'/plans/'}
                    />
                  )}
                  {useHasPermission(UiPermission['offer.view']) && (
                    <NavDropDownMenuItem
                      id="offers"
                      to={'/offers/search' + offerSearchLoc.search}
                      label={'Offers'}
                      matchPaths={'/offers/'}
                    />
                  )}
                  {useHasPermission(UiPermission['addon.view']) && (
                    <NavDropDownMenuItem
                      id="addons"
                      to={'/addons/search' + addonSearchLoc.search}
                      label={'Addons'}
                      matchPaths={'/addons/'}
                    />
                  )}
                  {useHasPermission(UiPermission['coupon.view']) && (
                    <NavDropDownMenuItem
                      id="coupons"
                      to={'/coupons/search' + couponSearchLoc.search}
                      label={'Coupons'}
                      matchPaths={'/coupons/'}
                    />
                  )}
                </NavDropDownMenuHead>
              </>
            )}

            {(useHasPermission(UiPermission['tran.view']) ||
              useHasPermission(UiPermission['email.view'])) && (
              <NavDropDownMenuHead
                label="Logs"
                id="logs-dropdown"
                iconName="logs"
                iconHeight="22px"
              >
                {useHasPermission(UiPermission['tran.view']) && (
                  <NavDropDownMenuItem
                    id="transactions"
                    to={'/transactions/search' + transactionSearchLoc.search}
                    label={'Transactions'}
                    matchPaths={'/transactions/'}
                  />
                )}
                {useHasPermission(UiPermission['email.view']) && (
                  <NavDropDownMenuItem
                    id="email-logs"
                    to={'/email-logs/search' + emailLogSearchLoc.search}
                    label={'Email Logs'}
                    matchPaths={'/email-logs/'}
                  />
                )}
              </NavDropDownMenuHead>
            )}

            {/* {useHasPermission([Permission.full_access]) && (
            <MenuLink
              id="reports"
              to={'/reports/'}
              label={'Reports'}
              matchPaths={'/reports/'}
            />
          )} */}
            {useHasPermission(UiPermission['ff.view']) && (
              <NavDropDownMenuHead
                label="Fulfillment Centers"
                id="centers-dropdown"
                iconName="centers"
                iconHeight="20px"
              >
                <NavDropDownMenuItem
                  id="fulfillment-centers"
                  to={'/fulfillment-centers'}
                  label={'Fulfillment Centers'}
                  matchPaths={'/fulfillment-centers'}
                />

                <NavDropDownMenuItem
                  id="delivery-methods"
                  to={'/fulfillment-delivery-methods'}
                  label={'Fulfillment Delivery Methods'}
                  matchPaths={'/fulfillment-delivery-methods'}
                />

                <NavDropDownMenuItem
                  id="fulfillment-deliveries"
                  to={'/fulfillment-deliveries'}
                  label={'Fulfillment Deliveries'}
                  matchPaths={'/fulfillment-deliveries'}
                />
              </NavDropDownMenuHead>
            )}

            {(useHasPermission(UiPermission['admin.setting']) ||
              useHasPermission(UiPermission['admin.user']) ||
              useHasPermission(UiPermission['notification.view'])) && (
              <NavDropDownMenuHead
                label="Settings"
                id="settings-dropdown"
                iconName="setting"
                iconHeight="24px"
              >
                {useHasPermission(UiPermission['admin.setting']) && (
                  <NavDropDownMenuItem
                    id="configure"
                    to={'/configure/'}
                    label={'Configure'}
                    matchPaths={'/configure/'}
                  />
                )}
                {useHasPermission(UiPermission['admin.setting']) && (
                  <NavDropDownMenuItem
                    id="system-admin"
                    to={'/system-admin/'}
                    label={'System Admin'}
                    matchPaths={'/system-admin/'}
                  />
                )}
                {useHasPermission(UiPermission['admin.user']) && (
                  <NavDropDownMenuItem
                    id="user-management"
                    to={'/user-management/search' + userSearchLoc.search}
                    label={'User Management'}
                    matchPaths={'/user-management/'}
                  />
                )}
                {useHasPermission(UiPermission['notification.view']) && (
                  <NavDropDownMenuItem
                    id="notifications"
                    to={'/notifications/'}
                    label={'Notifications'}
                    matchPaths={'/notifications/'}
                  />
                )}
                {useHasPermission(UiPermission['gateway.view']) && (
                  <NavDropDownMenuItem
                    id="gateways"
                    to={'/gateways/search' + gatewaySearchLoc.search}
                    label={'Gateways'}
                    matchPaths={'/gateways/search'}
                  />
                )}
                {useHasPermission(UiPermission['plugin.view']) && (
                  <NavDropDownMenuItem
                    id="plugIn"
                    to={'/plugin/search' + pluginSearchLoc.search}
                    label={'Plug In'}
                    matchPaths={'/plugin'}
                  />
                )}
              </NavDropDownMenuHead>
            )}
            {(useHasPermission(UiPermission['bulkop.view']) ||
              useHasPermission(UiPermission['admin.bulkop']) ||
              useHasPermission(UiPermission['delivery.export']) ||
              useHasPermission(UiPermission['wh.view'])) && (
              <NavDropDownMenuHead
                label="Operations"
                id="settings-dropdown"
                iconName="operations"
                iconHeight="24px"
              >
                {useHasPermission(UiPermission['bulkop.view']) && (
                  <NavDropDownMenuItem
                    id="bulk-operation"
                    to={'/bulk-operation'}
                    label={'Bulk Operation'}
                    matchPaths={'/bulk-operation'}
                  />
                )}
                {useHasPermission(UiPermission['admin.bulkop']) && (
                  <NavDropDownMenuItem
                    id="operations"
                    to={'/admin-bulk-operations/search' + adminBulkSearchLoc.search}
                    label={'Bulk Management'}
                    matchPaths={'/admin-bulk-operations/'}
                  />
                )}
                {useHasPermission(UiPermission['delivery.export']) && (
                  <NavDropDownMenuItem
                    id="delivery"
                    to={'/delivery/'}
                    label={'Delivery List'}
                    matchPaths={'/delivery/'}
                  />
                )}
                {useHasPermission(UiPermission['wh.view']) && (
                  <NavDropDownMenuItem
                    id="webhook"
                    to={'/webhooks/search' + webhookSearchLoc.search}
                    label={'Webhook'}
                    matchPaths={'/webhooks'}
                  />
                )}
              </NavDropDownMenuHead>
            )}
            {/* {useHasPermission(UiPermission['admin.setting']) && (
            <MenuLink id='emails'
              to={'/emails/create-email/'}
              label={'Emails'}
              matchPaths={'/emails/'}
            />
          )} */}
            {/* <MenuLink id='help' to={'/help/'} label={'Help'} matchPaths={'/help/'} /> */}
          </Nav>

          {simpleToolTip(
            <Button
              variant="primary"
              style={{ borderRadius: '30px' }}
              onClick={() => {
                setInfoModal(true);
              }}
            >
              {Array.from(`${profile.first_name}`)[0]?.toUpperCase() ?? 'N'}
            </Button>,
            `Conductor Account ${profile.first_name}`,
          )}
        </Navbar.Collapse>
      </Navbar>
      <EpSubsUserInfoModal
        modalShow={infoModal}
        profile={profile}
        onClose={closeInfoModal}
      />
    </>
  );
};

export default EpSubsTopBar;
