import { TLanguageResource } from '../texts';

export const ja_JP: TLanguageResource = {
  portal: {
    logo: {
      logo: 'RADIANT LIFE',
      title: '支払い方法',
      subTitle: '初期設定により主要アカウントに請求されます',
    },
    payments: {
      header: '末尾の数字',
      primaryContent: 'これはお客様の主な支払方法です.',
      backupContent: 'これはあなたの別のバックアップ用の支払い方法です.',
    },
    update: {
      header: '支払い方法の変更',
      cardNumber: 'クレジットカード番号',
      cvv: 'セキュリティコード',
      expirationDate: '有効期限',
      postalCode: '郵便番号',
      confirm: '確認',
    },
    add: {
      header: '新しい支払い方法を追加する',
      subTitle1: 'Stripe',
      subTitle2: 'Paypal Account',
      subTitle3: 'Paypal Express',
      subTitle4: 'Sepa Debit',
      cardNumber: 'クレジットカード番号',
      cvv: 'セキュリティコード',
      expirationDate: '有効期限',
      postalCode: '郵便番号',
      first_name: '名前',
      last_name: '苗字',
      email: 'Eメール',
      iban: 'IBAN',
      confirm: '確認',
    },
    buttons: {
      add: '新しい支払い方法を追加する',
      edit: '編集する',
      remove: '削除',
      primary: 'デフォルトの支払い方法として設定',
    },
    isPrimary: {
      name: 'primary',
    },
    primaryCard: {
      header: 'デフォルトの支払い方法として設定',
      content: 'この支払い方法を主な支払い方法として設定しますか？',
      cancel: 'キャンセル',
      confirm: 'はい',
    },
    removeCard: {
      header: '削除',
      content: 'この支払い方法を削除しますか?',
      cancel: 'キャンセル',
      confirm: 'はい',
    },
    paypalCheckout: {
      name: 'PayPalで支払う',
    },
    create: {
      success: 'の新しい支払い方法が正常に追加されました.',
      failure: '支払いの作成に失敗しました.',
    },
    edit: {
      success: '支払い方法の変更が完了しました.',
      failure: '支払いの更新に失敗しました.',
    },
    delete: {
      success: '支払いの削除が完了しました.',
      failure: '支払いが削除できませんでした.',
    },
    error: {
      braintree: {
        failure: 'ブレインツリーの初期化に失敗しました.',
        failurePayment: 'ブレインツリーから支払い方法の取得に失敗しました.',
      },
      stripe: {
        failureInitialize: 'ストライプのの初期化に失敗しました',
      },
      paypal: {
        failure:
          '予期しないブレインツリー／ペイパルの支払インスタンス・ペイロードが発生しました',
      },
      token: {
        failure: 'サーバ側でトークン化に失敗しました。カードが有効か確認してください?',
        somethingWrong: '何かがうまくいかなかった!',
      },
      postalCode: {
        frontLength: '郵便番号は',
        endLength: '文字以内にする必要があります.',
        failure: '郵便番号が無効です.',
      },
      gateway: {
        failure: 'ゲートウェイ・オプションの選択に失敗しました.',
      },
      paymentMethod: {
        failure: 'クレジットカード以外の支払い方法は取り扱いできません!',
      },
      link: {
        failure: 'お客様のリンクは無効です.',
      },
      unknown: '不明なゲートウェイエラー',
    },
    netWork: 'トークン化の最中にネットワークエラーが発生しました.',
    reminder: {
      someField: '一部のフィールドが無効です.',
      allField: '全てのフィールドが空です！フォームに入力してください.',
      contact: '再生成するためにカスタマーサービスに連絡してください.',
    },
    sepa: {
      header: 'Select a Country',
    },
    default: {
      make_default: 'デフォルトの支払い方法として設定',
      apply_all: 'すべてのサブスクリプションに適用',
    },
    link: 'エポックタイムズジャパンのニュースサイト',
  },
};
