import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { EpIcon } from 'src/assets';

export const EpSubsError = (): JSX.Element => {
  const history = useHistory();
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <EpIcon name={'warning'} style={{ ...{ height: '100px', marginTop: '30px' } }} />
      <div style={{ marginTop: '30px' }}>
        <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
          Oops!!!! Connection Lost
        </div>
        <div style={{ marginTop: '20px' }}>
          Reading Error with Database. Please Check your Database Connection.
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button
          variant="primary"
          onClick={() => {
            history.push('/');
          }}
        >
          Reload
        </Button>
      </div>
    </div>
  );
};
