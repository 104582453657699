import React, { useCallback, useEffect, useState } from 'react';
import Braintree, { Client, HostedFields } from 'braintree-web';
import { putNoty } from '../../../utilities/epSubsNoty';
import { DEBUGGING } from 'src/config';

type Props = {
  options: { authorization: string };
  // preselectVaultedPaymentMethod: boolean;
  onInstance?: (instance: HostedFields) => void;
  className?: string;
  children?: JSX.Element;
};

const styles = {
  ':focus': {
    color: '#9e2146',
  },
  '.valid': {
    color: '#424770',
  },
  '.invalid': {
    color: '#9e2146',
  },
  input: {
    'font-size': '20px',
    'font-family': 'sans-serif',
    color: '#3A3A3A',
    '::placeholder': {
      color: 'blue',
    },
  },
  '::-webkit-input-placeholder': {
    /* Chrome/Opera/Safari */ color: '#D3D3D3',
  },
  '::-moz-placeholder': {
    /* Firefox 19+ */ color: '#D3D3D3',
  },
  ':-ms-input-placeholder': {
    /* IE 10+ */ color: '#D3D3D3',
  },
  ':-moz-placeholder': {
    /* Firefox 18- */ color: '#D3D3D3',
  },

  // // Styling a specific field
  // '.number': {
  //   'font-family': 'san ',
  // },
};

const fields = {
  number: {
    selector: '#card-number',
    // placeholder: '4111 1111 1111 1111',
    prefill: DEBUGGING ? '4111 1111 1111 1111' : '',
  },
  cvv: {
    selector: '#cvv',
    placeholder: '***',
    prefill: DEBUGGING ? '123' : '',
  },
  expirationDate: {
    selector: '#expiration-date',
    placeholder: 'MM/YYYY',
    prefill: DEBUGGING ? '12/2027' : '',
  },
  postalCode: {
    selector: '#postal-code',
    // placeholder: '*****[-****]',
    prefill: DEBUGGING ? '11111' : '',
  },
};

const BraintreeWebHostedFields = ({
  options = { authorization: '' },
  // preselectVaultedPaymentMethod = true,
  onInstance,
  className,
  children,
}: Props): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clientInstance, setClientInstance] = useState<Client>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<HostedFields>();
  const createHostedFields = useCallback(
    async (client: Client) => {
      await Braintree.hostedFields.create(
        { client, styles, fields },
        (createErr, hostedFieldsInstance: HostedFields) => {
          console.log('Create Hosted Fields Callback: ', styles, fields, onInstance);
          if (createErr) {
            console.error(createErr);
            // putNoty({ type: 'error', text: 'Error creating braintree fields' });
          } else {
            setHostedFieldsInstance(hostedFieldsInstance);
            if (onInstance) {
              onInstance(hostedFieldsInstance);
            }
          }
        },
      );
    },
    [styles, fields, onInstance],
  );

  const createClient = useCallback(async () => {
    await Braintree.client.create(
      { authorization: options.authorization },
      (createErr, clientInstance) => {
        if (createErr) {
          console.error(createErr);
          putNoty({ type: 'error', text: 'Error loading Braintree client.' });
          return;
        }
        setClientInstance(clientInstance);
        createHostedFields(clientInstance);
      },
    );
  }, [options.authorization, createHostedFields]);

  useEffect(() => {
    createClient();
    // Clean it out at component Will Un-mount
    return () => {
      // hostedFieldsInstance &&
      //   hostedFieldsInstance.teardown(() => {
      //     console.log('Braintree Hosted Fields instance tear down complete.');
      //   });
      // clientInstance &&
      //   clientInstance.teardown(() => {
      //     console.log('Braintree Client instance tear down complete.');
      //   });
    };
  }, [createClient]);

  return <div className={className}>{children}</div>;
};

export default BraintreeWebHostedFields;
