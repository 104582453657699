import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { HIDE_CREATE_NEW_BUTTON_IN_LIST } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { FILTER_SOURCE, ADDON_STATUS } from 'src/js/constants/subs';
import { UiPermission } from 'src/models';
import { useHasPermission } from 'src/recoil';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import EpSubsListNav from '../../components/navBars/EpSubsListNav/EpSubsListNav';

const ADDON_STATUS_FILTER_GROUP_NAME = 'addon_status';
export const ADDON_STATUS_FILTER_GROUP_FIELD = 'status';
const noFilter = {
  label: 'All Addons',
  value: '',
  groupField: ADDON_STATUS_FILTER_GROUP_FIELD,
};

export const DEFAULT_ADDON_SEARCH_OPTION = {
  [ADDON_STATUS_FILTER_GROUP_FIELD]: [ADDON_STATUS.ACTIVE],
};

const EpSubsAddonNav = (routerProps: RouteComponentProps): JSX.Element => {
  const history = useHistory();
  const allowAddonCreatePermission = useHasPermission(UiPermission['addon.create']);
  const createBtnText = '+ Create New Addon';

  const onCreateBtnClick = allowAddonCreatePermission
    ? () => {
        history.push('/addons/create-addon');
      }
    : HIDE_CREATE_NEW_BUTTON_IN_LIST;

  return (
    <EpSubsListNav
      key="addon"
      {...{
        routerProps,
        createBtnText,
        onCreateBtnClick,
        history,
        source: FILTER_SOURCE.ADDON,
        allSimplyFilterOptions: {
          [ADDON_STATUS_FILTER_GROUP_FIELD]: [noFilter].concat(
            Object.values(ADDON_STATUS).map(status => {
              return {
                label: toInitialUpperCase(status),
                value: status,
                groupName: ADDON_STATUS_FILTER_GROUP_NAME,
                groupField: ADDON_STATUS_FILTER_GROUP_FIELD,
              };
            }),
          ),
        },
        defaultSimplyFilterOption: DEFAULT_ADDON_SEARCH_OPTION,
      }}
    />
  );
};

export default EpSubsAddonNav;
