import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Navbar, Spinner } from 'react-bootstrap';
import style from './index.module.scss';
import { isNumeric } from '../../../utilities/stringUtility';
import { TAscendingType } from '../../../services/epSubsServiceUtils';
import * as H from 'history';
import Image from 'react-bootstrap/Image';
import exportBtn from '../../../assets/exportBtn.png';
import SearchBar from './SearchBar/SearchBar';
import SimpleSearchBar from './SearchBar/SimpleSearchBar';
import Pagination, { TPageData } from './Pagination/Pagination';
import EpSimpleTable, { ListState, TKeyParsingResult } from './EpSimpleTable';
import { ESimpleSearchFields, TTabOptionItem } from 'src/js/constants/subs';
import { DEBUGGING } from 'src/config';

type TRowSchemaKeysString<TRowSchemaKeys> = TRowSchemaKeys extends string
  ? TRowSchemaKeys
  : string;

export type IFetchingParamBase<
  TRowSchemaKeys,
  TFieldKeys extends string | typeof ESimpleSearchFields.ALL = ESimpleSearchFields,
> = {
  status?: string;
  perPage: number;
  order: TAscendingType;
  sort: TRowSchemaKeysString<TRowSchemaKeys> | '';
  filter: string;
  query: string;
  simpleQuery: string;
  simpleQueryFieldKey: TFieldKeys | '';
  filterId: number;
  useFilteredSearch: boolean;
  useSavedFilter: boolean;
};

export interface IFetchingParamOffset<
  TRowSchemaKeys,
  TFieldKeys extends string | typeof ESimpleSearchFields.ALL = ESimpleSearchFields,
> extends IFetchingParamBase<TRowSchemaKeys, TFieldKeys> {
  offset: number;
}

export interface IFetchingParamPageId<
  TRowSchemaKeys,
  TFieldKeys extends string | typeof ESimpleSearchFields.ALL = ESimpleSearchFields,
> extends IFetchingParamBase<TRowSchemaKeys, TFieldKeys> {
  page: number;
}

export type TLocationRecord = {
  search: string;
  ready?: boolean;
};

/**
 * @param search The search parameter string in the router URL
 * @returns Return the parsed object.
 * - The url encode will be automatically done once by URLSearchParams.
 * - And this function encode them again to keep the URL encode/decode logic invariant before and after this function.
 * - The encoded fields include [query, simpleQuey, filter].
 * - - Please apply **decodeURIComponent** to them if you need.
 * - - If you need to pass them for url, please leave them as they are returned by this function.
 */
export function getParamsFromSearch<
  TRowSchemaKeys,
  TFieldKeys extends string | typeof ESimpleSearchFields.ALL = ESimpleSearchFields,
>(search: H.Search): IFetchingParamPageId<TRowSchemaKeys, TFieldKeys> {
  const params = new URLSearchParams(search);
  const status = params.get('status');
  const order = (params.get('order') ?? 'DESC') as TAscendingType;

  const pageParam = params.get('page') ?? '';
  const page = isNumeric(pageParam) ? Number.parseInt(pageParam, 10) : 1;

  const perPageParam = params.get('perPage') ?? '';
  const perPage = isNumeric(perPageParam) ? Number.parseInt(perPageParam, 10) : 10;

  const sort = (params.get('sort') ?? '') as TRowSchemaKeysString<TRowSchemaKeys> | '';
  const filter = params.get('filter') ?? '';
  const query = params.get('query') ?? '';
  const simpleQuery = params.get('simpleQuery') ?? '';
  const simpleQueryFieldKey = (params.get('simpleQueryFieldKey') as TFieldKeys) ?? '';

  // const useFilteredSearchParam = params.get('useFilteredSearch') ?? '';
  // const useFilteredSearch = useFilteredSearchParam === 'true' ? true : false;
  const useFilteredSearch = true;
  const useSavedFilterParam = params.get('useSavedFilter') ?? '';
  const useSavedFilter = useSavedFilterParam === 'true' ? true : false;

  const filterIdParam = params.get('filterId') ?? '';
  const filterId = isNumeric(filterIdParam) ? Number.parseInt(filterIdParam, 10) : -1;
  return {
    status: status ?? '',
    order,
    page,
    perPage,
    sort,
    filter: encodeURIComponent(filter),
    query: encodeURIComponent(query),
    simpleQuery: encodeURIComponent(simpleQuery),
    simpleQueryFieldKey,
    filterId,
    useFilteredSearch,
    useSavedFilter,
  };
}
type Props<
  TRowSchema,
  TRowSchemaKeys,
  TFieldKeys extends string | typeof ESimpleSearchFields.ALL = ESimpleSearchFields,
> = {
  // fetchData: (param: IFetchingParamOffset<TRowSchemaKeys>) => any;
  listState: ListState<TRowSchema>;
  searchByOptions?: Array<TRowSchemaKeys>;
  enableSimpleSearch?: boolean;
  isLoading: boolean;
  simpleSearchPlaceHolder?: string;
  enableSimpleSearchByField?: boolean;
  simpleQueryDefaultFieldKey?: TFieldKeys | typeof ESimpleSearchFields.ALL;
  simpleQueryFieldOptions?: TTabOptionItem<string>[];
  enableColumnSort?: boolean;
  onExportClick?: () => void;
  onChangeSearchParam?: (loc: TLocationRecord) => void;
  _key: keyof TRowSchema | ((record?: TRowSchema) => TKeyParsingResult);
  children?: any;
};

function EpFullFeaturedTable<
  TRowSchema,
  TRowSchemaKeys,
  TFieldKeys extends string | typeof ESimpleSearchFields.ALL = ESimpleSearchFields,
>({
  // fetchData,
  listState,
  searchByOptions,
  enableSimpleSearch,
  isLoading,
  simpleSearchPlaceHolder = undefined,
  enableSimpleSearchByField = false,
  simpleQueryDefaultFieldKey = ESimpleSearchFields.ALL,
  simpleQueryFieldOptions = [],
  enableColumnSort = true,
  onExportClick,
  onChangeSearchParam,
  _key,
  children,
}: Props<TRowSchema, TRowSchemaKeys, TFieldKeys>): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  // Handle Router parameters:
  const getParams = (): IFetchingParamPageId<TRowSchemaKeys, TFieldKeys> => {
    return getParamsFromSearch<TRowSchemaKeys, TFieldKeys>(location.search);
  };

  const changeParam = ({
    status,
    order,
    page,
    perPage,
    sort,
    filter,
    query,
    simpleQuery,
    simpleQueryFieldKey,
    filterId,
    useFilteredSearch,
  }: IFetchingParamPageId<
    TRowSchemaKeys,
    TFieldKeys | typeof ESimpleSearchFields.ALL
  >) => {
    const newLocationRecord = {
      search: [
        `?status=${status}`,
        `order=${order}`,
        `page=${page}`,
        `perPage=${perPage}`,
        `sort=${sort ?? ''}`,
        `filter=${filter ?? ''}`,
        `query=${query ?? ''}`,
        `simpleQuery=${simpleQuery ?? ''}`,
        simpleQueryFieldKey ? `simpleQueryFieldKey=${simpleQueryFieldKey}` : 'all',
        `filterId=${filterId}`,
        `useFilteredSearch=${useFilteredSearch}`,
      ]
        .filter(item => !!item)
        .join('&'),
    };
    history.push(newLocationRecord);
    onChangeSearchParam && onChangeSearchParam(newLocationRecord);
    DEBUGGING &&
      console.log(
        'Search Param changed in FullFeaturedTable: History Pushed',
        newLocationRecord,
      );
  };

  // Pagination Module
  const onPageChanged = (data: TPageData) => {
    const currentPage = data.currentPage;
    const pageLimit = data.pageLimit ?? 10;
    const {
      status,
      order,
      sort,
      filter,
      query,
      simpleQuery,
      simpleQueryFieldKey,
      filterId,
      useFilteredSearch,
      useSavedFilter,
    } = getParams();

    changeParam({
      status,
      order,
      page: currentPage,
      perPage: pageLimit,
      sort,
      filter,
      query,
      simpleQuery,
      simpleQueryFieldKey,
      filterId,
      useFilteredSearch,
      useSavedFilter,
    });
  };

  const paginationModule = (
    <Pagination<TRowSchemaKeys>
      variant="full"
      totalRecords={listState.total}
      onPageChanged={onPageChanged}
      pageLimit={getParams().perPage}
    />
  );

  const topPaginationModule = (
    <Pagination<TRowSchemaKeys>
      variant="right"
      totalRecords={listState.total}
      onPageChanged={onPageChanged}
      pageLimit={getParams().perPage}
    />
  );

  // Search Module
  const onSearchBtnClicked = (value: any) => {
    const { option, input } = value;
    const {
      status,
      order,
      perPage,
      sort,
      simpleQuery,
      simpleQueryFieldKey,
      filterId,
      useFilteredSearch,
      useSavedFilter,
    } = getParams();
    changeParam({
      status,
      order,
      page: 1,
      perPage,
      sort,
      filter: option,
      query: encodeURIComponent(input),
      simpleQuery,
      simpleQueryFieldKey,
      filterId,
      useFilteredSearch,
      useSavedFilter,
    });
  };

  const searchModule = searchByOptions && (
    <SearchBar onSearch={onSearchBtnClicked} options={searchByOptions} />
  );

  // The ChargeBee default search Module -- SimpleSearch Module
  const onSimpleSearchBtnClicked = (
    value: { input: string; field?: TFieldKeys | typeof ESimpleSearchFields.ALL },
    event?: React.BaseSyntheticEvent,
  ) => {
    event && event.preventDefault();
    const simpleQuery = value.input;
    const simpleQueryFieldKey = value.field ?? ESimpleSearchFields.ALL;
    const { status, order, perPage, sort, filter, query, filterId, useSavedFilter } =
      getParams();
    changeParam({
      status,
      order,
      page: 1,
      perPage,
      sort,
      filter,
      query,
      simpleQuery: encodeURIComponent(simpleQuery),
      simpleQueryFieldKey,
      filterId,
      // Always Combine simple search with filter, this will not make a difference for not filterEnabled search lists
      useFilteredSearch: true,
      useSavedFilter,
    });
    return false;
  };

  const simpleSearchModule = (enableSimpleSearch ?? false) && (
    <SimpleSearchBar<TFieldKeys>
      onSearch={onSimpleSearchBtnClicked}
      simpleQuery={decodeURIComponent(getParams().simpleQuery)}
      placeHolder={simpleSearchPlaceHolder}
      enableSimpleSearchByField={enableSimpleSearchByField}
      simpleQueryFieldKey={
        getParams().simpleQueryFieldKey !== ''
          ? (getParams().simpleQueryFieldKey as
              | TFieldKeys
              | typeof ESimpleSearchFields.ALL)
          : simpleQueryDefaultFieldKey
      }
      simpleQueryDefaultFieldKey={simpleQueryDefaultFieldKey}
      simpleQueryFieldOptions={simpleQueryFieldOptions}
    />
  );

  const { filterId } = getParams();
  DEBUGGING && console.log('check if has filter id:', filterId);
  // Export Module:
  const exportModule = onExportClick && (
    <div>
      <span>
        <Button
          id="export"
          onClick={onExportClick}
          variant="outline-success"
          disabled={isLoading}
          style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
        >
          <div className={style.flex_row}>
            <div>
              <Image src={exportBtn} width="19" height="21" alt="" />
            </div>
            {isLoading ? (
              <span className={style.icon_text}>
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{ width: '20px', height: '20px' }}
                />
              </span>
            ) : (
              <div className={style.icon_text}>Export</div>
            )}
          </div>
        </Button>
      </span>
    </div>
  );

  // Table Module
  const onSort = (
    e: React.MouseEvent,
    source: TRowSchemaKeysString<TRowSchemaKeys> | '',
  ) => {
    e.preventDefault();
    const {
      status,
      order,
      page,
      perPage,
      sort,
      query,
      filter,
      simpleQuery,
      simpleQueryFieldKey,
      filterId,
      useFilteredSearch,
      useSavedFilter,
    } = getParams();

    let newOrder = order;
    if (source === sort) {
      newOrder = order === 'ASC' ? 'DESC' : 'ASC';
    }
    changeParam({
      status: status,
      order: newOrder,
      page,
      perPage,
      sort: source,
      filter,
      query,
      simpleQuery,
      simpleQueryFieldKey,
      filterId,
      useFilteredSearch,
      useSavedFilter,
    });
  };

  const tableModule = (
    <EpSimpleTable<TRowSchema, TRowSchemaKeys>
      listState={listState}
      _key={_key}
      enableColumnSort={enableColumnSort}
      onSort={onSort}
    >
      {children}
    </EpSimpleTable>
  );

  return (
    <div>
      {listState.loading ?? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: '50px', height: '50px' }}
          />
        </div>
      )}
      {listState.err ?? <div>{listState.err}</div>}
      <div>
        {searchModule}
        {simpleSearchModule}
        <Navbar
          id="list-nav"
          className={style.bg_grey}
          variant="dark"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <span>{exportModule}</span>
          <span>{topPaginationModule}</span>
        </Navbar>

        {tableModule}
        {paginationModule}
      </div>
    </div>
  );
}

export default EpFullFeaturedTable;
