import React, { BaseSyntheticEvent, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { EpSubsBraintreeFormSection } from 'src/components/formComponents';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm';
import { TTokenizeCallback } from 'src/containers/subscriptions/subscriptionOperations/EpSubsCreateSubscription';
import { languageOptions } from 'src/models/i18n';

export const EpSubsPaymentBrainTreeCardForm = ({
  gatewayAccountId,
  onSubmit,
  onBraintreeInstance,
}: {
  gatewayAccountId: number;
  onSubmit: (
    gateway: number,
    data: TNewPaymentFormData,
    setIsLoading: (isLoading: boolean) => void,
    event?: BaseSyntheticEvent<any>,
  ) => Promise<boolean>;
  onBraintreeInstance: (tokenize: TTokenizeCallback) => void;
  // defaultPaymentData?: TNewPaymentFormData;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, register } = useForm<TNewPaymentFormData>({
    mode: 'onChange',
  });
  return (
    <>
      {gatewayAccountId && (
        <Form
          onSubmit={handleSubmit(
            async (formData: TNewPaymentFormData | never, event?: BaseSyntheticEvent) => {
              await onSubmit(gatewayAccountId, formData, setIsLoading, event);
            },
          )}
          style={{ width: '100%', height: 'auto' }}
        >
          <EpSubsBraintreeFormSection
            gatewayAccountId={gatewayAccountId}
            onInstanceEnding={onBraintreeInstance}
            vaultCard={false}
            make_primary={true}
            // defaultPaymentData={defaultPaymentData}
          />
          <Row style={{ marginLeft: '50px', marginTop: '20px' }}>
            <Form.Check
              type="checkbox"
              name="make_primary"
              ref={register()}
              defaultChecked={true}
            />
            <p>{languageOptions().portal.default.make_default}</p>
          </Row>
          <Row style={{ marginLeft: '50px' }}>
            <Form.Check
              type="checkbox"
              name="apply_to_all_subs"
              ref={register()}
              defaultChecked={true}
            />
            <p>{languageOptions().portal.default.apply_all}</p>
          </Row>
          <Button
            variant="primary"
            type="submit"
            style={{ float: 'right', marginRight: '10px', marginTop: '20px' }}
          >
            {languageOptions().portal.add.confirm}
          </Button>
          <EpSubsSpinnerModal isLoading={isLoading} />
        </Form>
      )}
    </>
  );
};
