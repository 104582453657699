import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { configAxios } from './services/epSubsServiceUtils';
import './index.css';
import { configureAppStore } from './store/configureStore';
import App from './App';
import { ErrorBoundary } from './ErrorBoundary';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}

configAxios();

const ConnectedApp = ({ Component }: Props) => (
  <ErrorBoundary>
    <Provider store={store}>
      <React.StrictMode>
        <RecoilRoot>
          <Component />
        </RecoilRoot>
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>
);

const renderApp = (Component: typeof App) => {
  render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

renderApp(App);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
