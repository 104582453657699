export const White = '#FFFFFF';
export const Backgrounds = '#FAFAFA';
export const Title = '#111111';

export const Primary_Blue = '#006ECE';
export const Secondary_Blue = '#4a90e2';
export const Hover_Primary = '#238bdb';
export const Hover_Secondary = '#EBEBF0';

export const Click = '#0054a8';

export const Brand_Green = '#208A3D';
