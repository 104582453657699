import React, { Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import EpSubsTopBar from './EpSubsTopBar';
// import EpSubsBody from './EpSubsBody';
import { lazy } from 'react';
import EpSubsLogin from './login/EpSubsLogin';
import EpSubsInviteAccept from './login/EpSubsInviteAccept';
import { jwtTokenAtom } from '../recoil';
import EpSubsPortalBody, { PORTAL_ROOT } from './EpSubsPortalBody';
import { COMPANY_NAME } from 'src/config';
import EpSubsResetPassword from './login/EpSubsResetPassword';
import EpSubsStripe3ds from './3dSecure/EpSubsStripe3ds';
import { EpSubsError } from 'src/components/errorPage/EpSubsError';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';

const EpSubsBody = lazy(() => import('./EpSubsBody'));

const EpSubsMainContainer = (): JSX.Element => {
  const token = useRecoilValue(jwtTokenAtom);

  const location = useLocation();
  const path = location.pathname;
  let inviteToken = '',
    resetToken = '';

  if (path && path.includes('/users/accept/')) {
    inviteToken = path.split('/users/accept/')[1];
  } else if (path && path.includes('/users/reset-password/')) {
    resetToken = path.split('/users/reset-password/')[1];
  }

  let viewToRender,
    basicPageTitle,
    displayTitleHere = true;

  if (inviteToken) {
    viewToRender = <EpSubsInviteAccept {...{ inviteToken: inviteToken }} />;
    basicPageTitle = 'Login';
  } else if (resetToken) {
    //render reset password component
    const email = resetToken.split('/email/')[1];
    const token = resetToken.split('/email/')[0];
    viewToRender = <EpSubsResetPassword email={email} resetToken={token} />;
    basicPageTitle = 'Reset Password';
  } else if (path && path.includes('customers/threeds-transfer')) {
    viewToRender = <EpSubsStripe3ds />;
    basicPageTitle = '3D Secure - Stripe';
  } else if (path && path.includes(PORTAL_ROOT)) {
    viewToRender = <EpSubsPortalBody />;
    basicPageTitle = 'Portal';
  } else if (!token) {
    viewToRender = (
      <Suspense fallback={<EpSubsSpinnerModal isLoading={true} />}>
        <EpSubsLogin />
      </Suspense>
    );
    basicPageTitle = 'Login';
  } else if (path && path.includes('/error')) {
    viewToRender = <EpSubsError />;
    basicPageTitle = 'Error';
  } else {
    displayTitleHere = false;
    viewToRender = (
      <Switch>
        <Route
          path={'*'}
          render={() => (
            <div id="main-container" style={{ width: '100%' }}>
              <div id="top-bar">
                <EpSubsTopBar />
              </div>
              <div id="body-container">
                <Suspense fallback={<EpSubsSpinnerModal isLoading={true} />}>
                  <EpSubsBody />
                </Suspense>
              </div>
            </div>
          )}
        />
      </Switch>
    );
  }
  return (
    <>
      {viewToRender}
      {displayTitleHere && <title>{`${basicPageTitle} | ${COMPANY_NAME}`}</title>}
    </>
  );
};

export default EpSubsMainContainer;
