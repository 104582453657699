import { TDropdownItem } from 'src/components/dropdownSelect/ESGeneralDropdownInput';
import { INewPureAddressData } from 'src/components/formComponents';
import {
  IEpSubsDBCredit_note_allocationValue,
  IEpSubsDBCustomerPost,
  IEpSubsDBCustomerValue,
  IEpSubsDBCustomer_addressPost,
  IEpSubsDBCustomer_addressValue,
  IEpSubsDBCustomer_payment_methodPost,
  IEpSubsDBCustomer_payment_methodValue,
  IEpSubsDBElastic_ui_paramsValue,
  IEpSubsDBEmail_logValue,
  IEpSubsDBInvoicePost,
  IEpSubsDBInvoiceValue,
  IEpSubsDBInvoice_itemValue,
  IEpSubsDBInvoice_item_coupon_appliedPost,
  IEpSubsDBCouponPost,
  IEpSubsDBCouponValue,
  IEpSubsDBNotificationValue,
  IEpSubsDBNotification_eventValue,
  IEpSubsDBPlanPost,
  IEpSubsDBPlanValue,
  IEpSubsDBSubscriptionPost,
  IEpSubsDBSubscriptionValue,
  IEpSubsDBSubscription_marketing_trackingPost,
  IEpSubsDBSubscription_marketing_trackingValue,
  IEpSubsDBUserValue,
  IEpSubsDBCredit_noteValue,
  IEpSubsDBCredit_notePost,
  IEpSubsDBTransactionPost,
  IEpSubsDBTransactionValue,
  IEpSubsDBUserPost,
  IEpSubsDBProductValue,
  IEpSubsDBOfferPost,
  IEpSubsDBOfferValue,
  IEpSubsDBGateway_accountValue,
  IEpSubsDBGateway_accountPost,
  IEpSubsDBProductPost,
  IEpSubsDBFulfillment_centerPost,
  IEpSubsDBFulfillment_centerValue,
  IEpSubsDBExcess_paymentsValue,
  IEpSubsDBInvoice_pdfValue,
  // TEpSubsDBStats_dailyValueKeys,
} from './_db_generated_/epSubsDBTypes';

export type DateNumber = number;
export type TSimpleValue = string | number | boolean;

// *********************************************** //
//              Gateway Types                      //
// *********************************************** //

export enum GATEWAY_TYPE {
  Braintree = 'braintree',
  Stripe = 'stripe',
  PaypalExpress = 'paypal_express',
  None = 'none',
}

export const GATEWAYS = {
  None: 0,
  Braintree: 1,
  Stripe: 2,
  PayPalExpress: 3,
};

export const GATEWAY_MAP = {
  0: 'None',
  1: 'Braintree',
  2: 'Stripe',
  3: 'PayPalExpress',
};

export const GATEWAY_TYPES: TDropdownItem<string>[] = [
  { label: 'Braintree', value: '1' },
  { label: 'Stripe', value: '2' },
  { label: 'PayPalExpress', value: '3' },
];

export type IEpSubsDBGateway_accountApiValue = Pick<
  IEpSubsDBGateway_accountValue,
  'id' | 'name' | 'client_id' | 'is_default' | 'payment_method_types'
> & {
  type: string;
  url: string;
  version: string;
  api_key: string;
  minimal_charge: number;
  ext_options: string;
  enabled: boolean;
};

export type IEpSubsDBGateway_accountApiPost = Pick<
  IEpSubsDBGateway_accountPost,
  'id' | 'name' | 'client_id' | 'is_default' | 'payment_method_types'
> & {
  type?: string;
  url?: string;
  version?: string;
  api_key?: string;
  minimal_charge?: number;
  ext_options?: string;
  enabled?: boolean;
};

// *********************************************** //
//                  Plan Types                     //
// *********************************************** //

export interface IEpSubsCompositeDBPlanValue extends IEpSubsDBPlanValue {
  // product might be missing when product_id = 0
  product?: IEpSubsDBProductValue;
}

// *********************************************** //
//              Subscription Types                 //
// *********************************************** //

export interface IEpSubsSubscriptionTimeline {
  action: string;
  utc_time: string;
}

export interface IEpSubsAddonApiPost {
  id: string;
  quantity: number;
  unit_price?: number;
  amount?: number;
}
export interface IEpSubsAddonApiValue extends IEpSubsAddonApiPost {
  quantity: number;
  unit_price: number;
  amount: number;
  charge_once: boolean;
  last_charge_at: string;
}
export interface IEpSubsSubscriptionDelivery {
  delivery_method?: string;
  responsible_office?: string;
}
export interface IEpSubsSubscriptionAdvanceInvoiceItem {
  invoice_id: number;
  invoice_from: string;
  invoice_to: string;
}
export interface IEpSubsCompositeDBSubscriptionPost {
  addons?: IEpSubsAddonApiPost[];
  event_based_addons?: IEpSubsAddonApiPost[];
  coupon_ids?: string[];
  customer?: IEpSubsDBCustomerPost;
  giftee?: IEpSubsDBCustomerPost | null;
  billing?: IEpSubsDBCustomer_addressPost | { id: string } | null;
  shipping?: IEpSubsDBCustomer_addressPost | { id: string } | null;
  shipping_to_billing?: boolean;
  cancel_updated_at?: number;
  subscription: IEpSubsDBSubscriptionPost;
  subscription_delivery?: IEpSubsSubscriptionDelivery;
  payment?: IEpSubsCustomizedDBCustomer_payment_methodPost | null;
  plan?: IEpSubsDBPlanPost | null;
  next_plan?: IEpSubsDBPlanPost | null;
  marketing_tracking?: IEpSubsDBSubscription_marketing_trackingPost | null;
  timeline?: IEpSubsSubscriptionTimeline[] | null;
  total_amount_due?: number | null;
  total_invoices_due?: number | null;
  gifter_message?: string;
  page_src?: string;
  charge_future_subs_now?: boolean;
}

export type IEpSubsCompositeDBSubscriptionApiPost = Omit<
  IEpSubsCompositeDBSubscriptionPost,
  'plan' | 'next_plan'
> & {
  plan: { id: string; quantity: number } | null;
  next_plan: { id: string; quantity: number } | null;
  offer_id?: string;
  delivery_method_id?: number;
  phase_coupons?: {
    coupon_id: string;
    start_dt?: number;
    end_dt?: number;
  }[];
  gateway_params?: {
    gateway_customer_id?: string;
  };
};

export interface IEpSubsCompositeDBSubscriptionValueInList
  extends IEpSubsCompositeDBSubscriptionPost {
  customer?: IEpSubsDBCustomerValue;
  billing?: IEpSubsDBCustomer_addressValue;
  shipping?: IEpSubsDBCustomer_addressValue;
  shipping_to_billing?: boolean;
  subscription: IEpSubsDBSubscriptionValue & { tags: string };
  payment?: IEpSubsCustomizedDBCustomer_payment_methodValue | null;
  plan?: IEpSubsCompositeDBPlanValue | null;
  next_plan?: IEpSubsCompositeDBPlanValue | null;
  marketing_tracking?: IEpSubsDBSubscription_marketing_trackingValue | null;
  timeline?: IEpSubsSubscriptionTimeline[] | null;
  total_amount_due?: number | null;
  total_invoices_due?: number | null;
  next_plan_start_date?: string | null;
  coupons?: IEpSubsDBCompositeCouponValue[];
  phase_coupons?: IEpSubsDBCompositeCouponValue[];
  addons?: IEpSubsAddonApiValue[];
  event_based_addons?: IEpSubsAddonApiValue[];
  advance_invoices?: IEpSubsSubscriptionAdvanceInvoiceItem[];
  next_billing?: TEpSubsSubscriptionNextBillingPreview;
}

export interface IEpSubsCompositeDBSubscriptionValueInCustomer
  extends IEpSubsCompositeDBSubscriptionValueInList {
  shipping_to_billing: boolean;
  plan: IEpSubsCompositeDBPlanValue;
  subscription_delivery: IEpSubsSubscriptionDelivery;
  offers?: {
    offer_digital_id: number;
    offer_id: number;
    offer_name: string;
    subscription_id: number;
    created_at: string;
    status: string;
  }[];
  subscription_items: {
    amount: number;
    billing_cycles: number;
    charge_on_event: string;
    charge_on_option: string;
    charge_once: boolean;
    description: string;
    entity_key: string;
    entity_type: string;
    id: number;
    last_charged_at: string;
    quantity: number;
    status: string;
    subscription_id: number;
    unit_price: number;
  }[];
}
export interface IEpSubsCompositeDBSubscriptionValue
  extends IEpSubsCompositeDBSubscriptionValueInCustomer {
  customer: IEpSubsDBCustomerValue & { tags: string; state_code: string };
  timeline: IEpSubsSubscriptionTimeline[];
  cancel_updated_at: number;
  total_amount_due: number;
  total_invoices_due: number;
  pause_delivery_date?: string;
  resume_delivery_date?: string;
}

export interface IEpSubsPlanChangePostData {
  plan?: {
    id: string;
    quantity: number;
    billing_cycles?: number | null;
  };
  offer_id?: string;
  change_apply_time: 'I' | 'E' | 'D';
  credit_option_for_current_charges: string;
  addons: IEpSubsAddonApiPost[];
  event_based_addons: IEpSubsAddonApiPost[];
  change_apply_date: number;
  phase_coupons: {
    coupon_id: string;
    start_dt?: number;
    end_dt?: number;
  }[];
}

export interface IEpSubsReactivePostData {
  plan: {
    id: string;
    quantity: number;
    billing_cycles?: number | null;
  };
  addons: IEpSubsAddonApiPost[];
  event_based_addons: IEpSubsAddonApiPost[];
  phase_coupons: {
    coupon_id: string;
    start_dt?: number;
    end_dt?: number;
  }[];
  shipping?: INewAddressFormData;
}

export type TEpSubsCompositeDBSubscriptionValueKeys =
  keyof IEpSubsCompositeDBSubscriptionValue;

type TEpSubsSubscriptionPreviewInvoice_itemApiValue = Exclude<
  IEpSubsDBInvoice_itemValue,
  'id' | 'invoice_id' | 'customer_id'
> & {
  entity_id: number; // id depends on the entity_type
  entity_key: string;
  recurring?: boolean;
  applied_item_coupons: {
    amount: number;
    coupon_id: number;
    description: string;
    entity_id: number;
    entity_type: string;
    invoice_item_id: number;
  }[];
};

export enum ESubscriptionChangeApiAmendType {
  // The type comes from API
  NO_CHANGE = 'no_change',
  ADDED = 'added',
  DELETED = 'deleted',
  //Below are defined by FE
  CHANGED = 'changed',
  CHANGED_FROM = 'from',
  CHANGED_TO = 'to',
}

export type TSubscriptionImmediateChangeType =
  | 'Subtotal'
  | 'Promotional Credits'
  | 'Total'
  | 'Credits Applied'
  | 'Amount Due';
export interface IEpSubsSubscriptionPlanChangePreview {
  // summaries: string[];
  Currency: {
    code: string;
    zero_decimal: boolean;
  };
  summaries: {
    item:
      | 'status' // For Create Subs only
      | 'invoice'
      | 'activation'
      | 'refundable_credit'
      | 'promotional_credit'
      | 'gift_credit'
      | 'adjustment_credit'
      | 'excess_payments'
      | 'subsequent_invoice';
    value: number;
    apply?: boolean;
    date?: number;
  }[];
  credit_note?: string;
  immediate_charges: {
    items?: TEpSubsSubscriptionPreviewInvoice_itemApiValue[]; // If we allow only coupon change, this could be null
    applied_coupons?: {
      amount: number;
      coupon_id: number;
      description: string;
      entity_id: number;
      entity_type: string;
      invoice_item_id: number;
    }[];
    subtotal: number;
    promotional_credits: number;
    credit_applied: number;
    tax: number;
    total: number;
    applied_excess_payments: number;
    applied_giftcard: number;
    amount_due: number;
    tax_included: boolean;
  };
  renewal_charges?: {
    items: TEpSubsSubscriptionPreviewInvoice_itemApiValue[]; // If we allow only coupon change, this could be null
    applied_coupons?: {
      amount: number;
      coupon_id: number;
      description: string;
      entity_id: number;
      entity_type: string;
      invoice_item_id: number;
    }[];
    subtotal: number;
    tax: number;
    total: number;
    amount_due: number;
    credit_applied: number;
    tax_included: boolean;
  };
  subscription_changes?: {
    field: string;
    old_value: string;
    new_value: string;
  }[];
  recurring_addons_changes?: {
    item_id: string;
    amend_type: ESubscriptionChangeApiAmendType;
  }[];
  addons_changes?: { item_id: string; amend_type: ESubscriptionChangeApiAmendType }[];
  coupons_changes?: { item_id: string; amend_type: ESubscriptionChangeApiAmendType }[];
}
export type IEpSubsCreateSubscriptionPreview = IEpSubsSubscriptionPlanChangePreview;

export interface IEpSubsSubscriptionRenewPreview {
  Currency: {
    code: string;
    zero_decimal: boolean;
  };
  title: string;
  next_billing_at: DateNumber;
  tax: number;
  subtotal: number;
  total: number;
  promotional_credits: number | undefined;
  credits: number | undefined;
  amount_due: number;
  applied_coupons: IEpSubsCompositeDBInvoiceValue['applied_coupons'];
  line_items: {
    date_from: number;
    date_to: number;
    unit_price: number;
    quantity: number;
    amount: number;
    description: string;
    entity_type: string;
    entity_key: string;
    entity_id: number;
    applied_item_coupons: {
      amount: number;
      coupon_id: number;
      description: string;
      entity_id: number;
      entity_type: string;
      invoice_item_id: number;
    }[];
  }[];
  tax_included: boolean;
}

export type TEpSubsSubscriptionNextBillingPreview = Pick<
  IEpSubsDBInvoiceValue,
  'total'
> & {
  Currency: {
    Code: string;
    Sign: string;
    ZeroDecimal: boolean;
  };
  amount_due: number | null;
  subtotal: number | null;
  tax: number | null;
  applied_coupons?: IEpSubsCompositeDBInvoiceValue['applied_coupons'];
  items: ({
    applied_item_coupons: {
      amount: number;
      coupon_id: number;
      description: string;
      entity_id: number;
      entity_type: string;
      invoice_item_id: number;
    }[];
  } & (Omit<
    IEpSubsDBInvoice_itemValue,
    'customer_id' | 'subscription_id' | 'invoice_id' | 'id'
  > & {
    id?: number;
  }))[];
  tax_included: boolean;
};
// *********************************************** //
//               Customer Types                    //
// *********************************************** //

export interface IEpSubsCompositeDBCustomerPost {
  customer: IEpSubsDBCustomerPost;
  payment?: IEpSubsCustomer_payment_methodApiPost | null;
  subscriptions?: IEpSubsCompositeDBSubscriptionPost[] | null;
  net_payment?: number | null;
  total_amount_due?: number | null;
  total_invoices_due?: number | null;
}

export interface IEpSubsCustomer_payment_methodValue {
  card_type: string;
  customer_id: number;
  expiration_month: string;
  expiration_year: string;
  gateway: number;
  id: number;
  is_primary: boolean;
  last4_digits: string;
  payment_source_id: string;
  payment_type: string;
  status: string;
  zip: string;
  pm_id: string;
  created_at: string;
}
export interface IEpSubsCompositeDBCustomerValueInList
  extends IEpSubsCompositeDBCustomerPost {
  customer: IEpSubsDBCustomerValue & { tags: string; state_code: string };
  payment_methods: IEpSubsCustomer_payment_methodValue[] | null;
  subscriptions?: IEpSubsCompositeDBSubscriptionValueInCustomer[] | null;
  custom_fields?: IEpSubsCustomFieldType[];
}

export type IEpSubsCustomFieldType = {
  entity: string;
  type: string;
  code: string;
  name?: string;
  value: string;
};

export interface IEpSubsCompositeDBCustomerValue
  extends IEpSubsCompositeDBCustomerValueInList {
  subscriptions: IEpSubsCompositeDBSubscriptionValueInCustomer[] | null;
  net_payment: number;
  payment_methods: IEpSubsCustomer_payment_methodValue[];
  total_amount_due: number;
  total_invoices_due: number;
}

export type TEpSubsCompositeDBCustomerValueKeys = keyof IEpSubsCompositeDBCustomerValue;

// *********************************************** //
//                Invoice Types                    //
// *********************************************** //

export interface IEpSubsCompositeDBInvoicePost {
  invoice: IEpSubsDBInvoicePost;
  customer?: IEpSubsDBCustomerPost | null;
  plan?: IEpSubsDBPlanPost | null;
  subscription?: IEpSubsDBSubscriptionPost | null;
}

export interface IEpSubsCompositeDBInvoiceValueInList
  extends IEpSubsCompositeDBInvoicePost {
  invoice: IEpSubsDBInvoiceValue & { tags: string };
  customer?: IEpSubsDBCustomerValue | null;
  plan?: IEpSubsCompositeDBPlanValue | null;
  subscription?: IEpSubsDBSubscriptionValue | null;
  payments?: IEpSubsSimplePaymentApiValue[];
}

export type TEpSubsDBInvoice_item_coupon_appliedApiValue = Required<
  Pick<
    IEpSubsDBInvoice_item_coupon_appliedPost,
    'entity_type' | 'entity_id' | 'description'
  >
> & {
  amount: number; // amount_allocated as amount
  coupon_id: number; // coupon_id change from string to number
};

export interface IEpSubsSimplePaymentApiValue {
  transaction_id: number;
  amount: number;
  allocated_amount: number;
  excess_amount: number;
  payment_method: string;
  card_type: string;
  last4_digits: string;
  reference_number: string;
  transaction_type: string;
  transaction_date: string;
  status: string;
  updated_at: string;
}

export interface IEpSubsSimpleRefundApiValue {
  transaction_id: number;
  amount: number;
  allocated_amount: number;
  excess_amount: number;
  payment_method: string;
  card_type: string;
  last4_digits: string;
  reference_number: string;
  transaction_type: string;
  transaction_date: string;
  status: string;
  updated_at: string;
}
export interface IEpSubsCompositeDBInvoiceValue
  extends IEpSubsCompositeDBInvoiceValueInList {
  customer: IEpSubsDBCustomerValue;
  plan: IEpSubsCompositeDBPlanValue;
  subscription?: IEpSubsDBSubscriptionValue;
  items: {
    item: IEpSubsDBInvoice_itemValue;
    applied_item_coupons: {
      amount: number;
      coupon_id: number;
      description: string;
      entity_id: number;
      entity_type: string;
      invoice_item_id: number;
    }[];
  }[];
  discounts?: {
    amount: number;
    description: string;
    entity_type: string;
  }[];
  applied_coupons?: Omit<
    TEpSubsDBInvoice_item_coupon_appliedApiValue,
    'entity_type' | 'entity_id'
  >[];
  applied_credits?: IEpSubsDBCredit_note_allocationValue[];
  issued_credit_notes?: IEpSubsDBCredit_noteValue[];
  payments?: IEpSubsSimplePaymentApiValue[];
  pending_refunds?: IEpSubsSimpleRefundApiValue[];
  adjustment_credit_notes?: IEpSubsDBCredit_noteValue[];
  applied_excess_payments?: number;
  issued_excess_payments?: IEpSubsDBExcess_paymentsValue[];
  applied_giftcard?: number;
  pdf_versions?: IEpSubsDBInvoice_pdfValue[];
  tax_included: boolean;
}
// *********************************************** //
//                 CreditNote Types                //
// *********************************************** //
export interface IEpSubsCompositeDBCredit_notePost {
  credit_note: IEpSubsDBCredit_notePost;
  customer?: IEpSubsDBCustomerPost;
}

export interface IEpSubsCompositeDBCredit_noteValueInList
  extends IEpSubsCompositeDBCredit_notePost {
  credit_note: IEpSubsDBCredit_noteValue;
  customer?: IEpSubsDBCustomerValue;
}

export interface IEpSubsDBCredit_note_refundSectionValue {
  transaction_id: number;
  amount: number;
  allocated_amount: number;
  excess_amount: number;
  payment_method: string;
  card_type: string;
  last4_digits: string;
  reference_number: string;
  transaction_type: string;
  transaction_date: string;
  status: string;
  updated_at: string;
}

export interface IEpSubsCompositeDBCredit_noteValue
  extends IEpSubsCompositeDBCredit_noteValueInList {
  customer: IEpSubsDBCustomerValue;
  allocations?: IEpSubsDBCredit_note_allocationValue[];
  refunds?: IEpSubsDBCredit_note_refundSectionValue[];
}

// *********************************************** //
//                 CreditNote Types                //
// *********************************************** //
export interface IEpSubsCompositeDBCredit_notePost {
  credit_note: IEpSubsDBCredit_notePost;
  customer?: IEpSubsDBCustomerPost;
}

export interface IEpSubsCompositeDBCredit_noteValueInList
  extends IEpSubsCompositeDBCredit_notePost {
  credit_note: IEpSubsDBCredit_noteValue;
  customer?: IEpSubsDBCustomerValue;
}

export interface IEpSubsCompositeDBCredit_noteValue
  extends IEpSubsCompositeDBCredit_noteValueInList {
  customer: IEpSubsDBCustomerValue;
  allocations?: IEpSubsDBCredit_note_allocationValue[];
  refunds?: IEpSubsSimplePaymentApiValue[];
}

// *********************************************** //
//                 Coupon Types                    //
// *********************************************** //

export interface IEpSubsCompositeDBCouponPost {
  coupon: IEpSubsDBCouponPost;
  applicable_plans: string[] | null;
  applicable_addons: string[] | null;
}

export interface IEpSubsCompositeDBCouponValueInList
  extends IEpSubsCompositeDBCouponPost {
  coupon: IEpSubsDBCouponValue;
}

export interface IEpSubsCompositeDBCouponValue
  extends IEpSubsCompositeDBCouponValueInList {}

// *********************************************** //
//                 Offer Types                     //
// *********************************************** //

export type IEpSubsCreateOrUpdateOfferPost = {
  content: {
    plan: {
      id: string;
      quantity: number;
      billing_cycles: number | null;
      unit_price?: number;
    };
    next_plan?: {
      id: string | null;
      quantity: number;
      billing_cycles: number | null;
      unit_price?: number;
    };
    addons?: IEpSubsAddonApiPost[];
    event_based_addons?: IEpSubsAddonApiPost[];
    phase_coupons: {
      coupon_id: string;
      start_dt?: number;
      end_dt?: number;
      delayed_start?: number;
      delayed_start_unit?: string;
      duration?: number;
      duration_unit?: string;
    }[];
    // Fields below might be read only in the future,
    // but for now need manual input and verified by Offer preparer in the marketing team
    first_billing_amount: number;
    next_billing_amount?: number;
  };
};

export type IEpSubsCreateOrUpdateOfferValue = {
  content: Omit<
    IEpSubsCreateOrUpdateOfferPost['content'],
    'addons' | 'event_based_addons'
  > & {
    addons?: IEpSubsAddonApiValue[];
    event_based_addons?: IEpSubsAddonApiValue[];
  };
};

export type IEpSubsCompositeDBOfferPost = Omit<IEpSubsDBOfferPost, 'content'> &
  IEpSubsCreateOrUpdateOfferPost;

export type IEpSubsCompositeDBOfferValue = Omit<IEpSubsDBOfferValue, 'content'> &
  IEpSubsCreateOrUpdateOfferValue & {
    offer_detail?: {
      phase_coupons?: {
        coupon: IEpSubsDBCouponValue;
        delayed_start: number;
        delayed_start_unit: string;
        duration: number;
        duration_unit: string;
      }[];
      plan: IEpSubsCompositeDBPlanValue;
      next_plan?: IEpSubsCompositeDBPlanValue;
    };
  };

// *********************************************** //
//                 Transaction Types               //
// *********************************************** //
export type IEpSubsCustomer_payment_methodApiPost = IEpSubsDBCustomer_payment_methodPost;

export type IEpSubsCustomer_payment_methodApiValue =
  IEpSubsDBCustomer_payment_methodValue;

// *********************************************** //
//                 Transaction Types               //
// *********************************************** //

export interface IEpSubsTransactionActionData {
  entity: string;
  entity_id: number;
  date: string;
  type: string;
  amount: number;
}

export interface IEpSubsTransactionPaymentData
  extends Pick<
    IEpSubsCustomer_payment_methodApiValue,
    | 'card_type'
    | 'last4_digits'
    | 'expiration_year'
    | 'expiration_month'
    | 'zip'
    | 'email'
  > {
  payment_method: string;
  gateway: string;
  id_at_gateway: string;
  status: string;
  reference_number: string;
  error: string;
}

export interface IEpSubsCompositeDBTransactionPost {
  transaction: IEpSubsDBTransactionPost;
  payment_method?: IEpSubsCustomer_payment_methodApiPost;
  customer?: IEpSubsDBCustomerPost;
}

export interface IEpSubsCompositeDBTransactionValueInList
  extends IEpSubsCompositeDBTransactionPost {
  transaction: IEpSubsDBTransactionValue & {
    subscription_key?: string;
    customer_key?: string;
  };
  payment_method?: IEpSubsCustomer_payment_methodApiValue;
  customer?: IEpSubsDBCustomerValue;
  payment?: IEpSubsTransactionPaymentData;
}

export interface IEpSubsCompositeDBTransactionValue
  extends IEpSubsCompositeDBTransactionValueInList {
  transaction: IEpSubsDBTransactionValue & {
    subscription_key: string;
    customer_key: string;
  };
  action: IEpSubsTransactionActionData;
  payment_method: IEpSubsCustomer_payment_methodApiValue;
  customer: IEpSubsDBCustomerValue;
  payment: IEpSubsTransactionPaymentData;
}

// *********************************************** //
//                  Address Types                  //
// *********************************************** //

export type TEpSubsAddressDuplication = Omit<
  IEpSubsDBCustomer_addressValue,
  keyof INewPureAddressData | 'company' | 'validation_status' | 'id'
> & { subscription_id: number; plan_id: string; start_date: string };

export type TAddressUsageType = 'billing' | 'shipping';
export type TEpSubsAddressUsage = {
  subscription_id: number;
  address_type: TAddressUsageType;
};

export type INewAddressFormData = Omit<
  IEpSubsDBCustomer_addressValue,
  'id' | 'customer_id' | 'created_at' | 'updated_at'
> & {
  customer_id?: number;
  subscription_id?: number;
};

export type TEpSubsSubscriptionAddressUpdateData = {
  address: INewAddressFormData;
  apply_to: TEpSubsAddressUsage[];
};

// *********************************************** //
//                Payment Types                    //
// *********************************************** //
export interface IEpSubsCustomizedDBCustomer_payment_methodPost
  extends IEpSubsCustomer_payment_methodApiPost {
  make_primary?: boolean;
}

export interface IEpSubsCustomizedDBCustomer_payment_methodValue
  extends IEpSubsCustomer_payment_methodApiValue {
  make_primary?: boolean;
}

// *********************************************** //
//                Metrics for Revenue Story        //
// *********************************************** //

export interface IEpSubsAllMetricsData {
  id: number;
  name: string;
  description: string;
  category: string;
  table_type: string;
  kpi_type: string;
  chart_type: string;
}

// *********************************************** //
//                Stats_Daily Types                //
// *********************************************** //

export type TEpSubsDailyStatsSingleMetricData = { day: string; value: number }[];
export type TEpSubsDailyStatsCompositeMetricData = {
  [key in number]: TEpSubsDailyStatsSingleMetricData;
};

// *********************************************** //
//                User Management Types            //
// *********************************************** //

export interface IEpSubsUserInviteValue {
  status: string;
  id: number;
  email: string;
}
export interface IEpSubsUserResetPasswordValue {
  message: string;
  data: any;
}
export interface IEpSubsUserRoles extends IEpSubsDBUserValue {
  roles: string;
  role_ids: string;
  status: string;
  created_at: string;
}

export type IEpSubsUserRolesKeys = keyof IEpSubsUserRoles;

// *********************************************** //
//                Filter Search Types              //
// *********************************************** //
export interface TFilterEntry {
  field: string;
  operator: string;
  value?: string | number;
  value2?: string | number;
  values_set?: string[];
}

export interface TFilterSearchSort {
  field: string;
  asc: boolean;
}

export interface TFilterData {
  filters: TFilterEntry[];
  sort_field?: TFilterSearchSort; // Default to *.created_at desc
  page_index?: number; // Default to 0
  per_page?: number; // Default to 10
  keyword: string;
  element: string;
}

export type TEpSubsDBElastic_ui_paramsApiValue = Omit<
  IEpSubsDBElastic_ui_paramsValue,
  'id' | 'all_values'
> & { all_operators: string[]; all_values: null | string[] };
export interface IFilterFieldsUiParameters {
  customer: TEpSubsDBElastic_ui_paramsApiValue[];
  subscriptions: TEpSubsDBElastic_ui_paramsApiValue[];
  plan: TEpSubsDBElastic_ui_paramsApiValue[];
  coupon: TEpSubsDBElastic_ui_paramsApiValue[];
  transaction: TEpSubsDBElastic_ui_paramsApiValue[];
  shipping_address: TEpSubsDBElastic_ui_paramsApiValue[];
}

// *********************************************** //
//            Filter Operation Types               //
// *********************************************** //
export type TEpSubsSaveFilterRequestData = {
  id?: number;
  filter_name?: string;
  filters: string;
  // 0 is private; 1 is public;
  access_level: number;
};

// *********************************************** //
//           Email Notification Types              //
// *********************************************** //
/**
 * Notification Interfaces (Bottom Level)
 * - DB Data with custom field
 */
export interface IEpSubsNotificationApiValue extends IEpSubsDBNotificationValue {
  is_draft: boolean;
}
/**
 * Notification Interfaces (Bottom Level)
 * - Composite Data with optional extra object
 */
export interface IEpSubsCompositeNotificationValueInList {
  notification: IEpSubsNotificationApiValue;
  event?: IEpSubsDBNotificationEventApiValue;
  filters?: IEpSubsDBElastic_saved_filtersValue[];
}
/**
 * Notification Interfaces (Bottom Level)
 * - Composite Data with guaranteed extra object
 */
export interface IEpSubsCompositeNotificationValue
  extends IEpSubsCompositeNotificationValueInList {
  event: IEpSubsDBNotificationEventApiValue;
  filters: IEpSubsDBElastic_saved_filtersValue[];
}

export enum NotificationType {
  email = 'email',
  sms = 'sms',
}
/**
 * Event Interfaces (Mid Level)
 * - Api Data
 */
export interface IEpSubsDBNotificationEventApiValue {
  id: number;
  name: string;
  segment_entity: string;
  available_variables: string;
  notifications: IEpSubsNotificationApiValue[] | null;
  notification_type: NotificationType;
}

/**
 * Event Interfaces (Mid Level)
 * - Composite Data with optional extra object for notification entries
 */
export interface IEpSubsDBCompositeNotificationEventValue {
  id: number;
  name: string;
  segment_entity: string;
  available_variables: string;
  notifications: IEpSubsCompositeNotificationValueInList[] | null;
  notification_type: NotificationType;
}

/**
 * Event Category Interfaces (Top Level)
 * - Api Data
 */
export interface IEpSubsDBNotificationEventCategoryApiValue {
  id: number;
  name: string;
  description: string;
  events: IEpSubsDBNotificationEventApiValue[] | null;
}
/**
 * Event Category Interfaces (Top Level)
 * - Composite Data with optional extra object for notification entries
 */
export interface IEpSubsDBCompositeNotificationEventCategoryValue {
  id: number;
  name: string;
  description: string;
  events: IEpSubsDBCompositeNotificationEventValue[];
}

// *********************************************** //
//               Admin Bulk Operation Types        //
// *********************************************** //
export interface IEpSubsDBAdminBulkOperationValue {
  id: number;
  operation_type: string;
  name: string;
  code: string;
  description: string;
  sample_file_content: string;
  display_order: number;
}

export type TEpSubsDBAdminBulkValueKeys = keyof IEpSubsDBAdminBulkOperationValue;

export type TEpSubsDBAdminBulkListData = Array<IEpSubsDBAdminBulkOperationValue>;

export interface IEpSubsDBAdminBulkPost {
  operation_type: string;
  name: string;
  code: string;
  description: string;
  sample_file_content: string;
  display_order: number;
}
// *********************************************** //
//                Bulk Operation Types             //
// *********************************************** //
export interface IEpSubsDBBulkOperationValue {
  id: number;
  operation_type: string;
  name: string;
  code: string;
  description: string;
  sample_file_content: string;
  display_order: number;
}

export interface IEpSubsDBBulkOperationLogValue {
  Id: number;
  BulkOpId: number;
  File: string;
  Records: string;
  ValidationError: string;
  Validated: boolean;
  ProcessError: string;
  Completed: boolean;
}

export type IEpSubsDBBulkOperationPostValue = Pick<
  IEpSubsDBBulkOperationLogValue,
  'BulkOpId' | 'File'
>;

// *********************************************** //
//                Email Log Types                  //
// *********************************************** //
export interface IEpSubsDBEmail_logApiValue extends IEpSubsDBEmail_logValue {
  subject: string;
  body: string;
  from_email: string;
}

// *********************************************** //
//                Delivery Types                   //
// *********************************************** //
export interface IEpSubsDBDeliveryValue {
  id: number;
  name: string;
  display_name: string;
  center_id: number;
  ref_delivery_method_id: number;
}
// *********************************************** //
//                Kpi Reports                      //
// *********************************************** //
// export interface IEpSubsKpiReports {
//   metric_id: number;
//   view_period: string;
//   comparison: string;
// }

export interface IEpSubsNotificationEvent {
  event: IEpSubsDBNotification_eventValue;
  filters: IEpSubsDBElastic_saved_filtersValue[];
}

export interface IEpSubsChangePasswordPut {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface IEpSubsChangeNamePut {
  first_name: string;
  last_name: string;
}

export interface IEpSubsProfile extends IEpSubsDBUserPost {
  first_name: string;
  last_name: string;
  email: string;
}

export interface IEpSubsMelissaAddress {
  address: {
    address1: string;
    address2: string;
    city: string;
    company: string;
    state: string;
    suite: string;
    zip: string;
  };
  deliverable: boolean;
  changed: boolean;
  rawResult: string;
  results: { code: string; message: string; description: string }[];
}

// *********************************************** //
//                Product Type                     //
// *********************************************** //

export type IEpSubsDBProductCompositeValue = IEpSubsDBProductValue & {
  display_order: number;
  gateway_list: string;
};

export type IEpSubsDBProductCompositePost = IEpSubsDBProductPost & {
  display_order: number;
  gateway_list: string;
};

// *********************************************** //
//                Fulfillment Center Type          //
// *********************************************** //

export type IEpSubsDBFulfillmentCenterCompositeValue =
  IEpSubsDBFulfillment_centerValue & {
    active: boolean;
    contact_fax: string;
  };

export type IEpSubsDBFulfillmentCenterCompositePost = IEpSubsDBFulfillment_centerPost & {
  active: boolean;
  contact_fax: string;
};

export type TEpSubsDBFulfillment_centerCompositeValueKeys =
  keyof IEpSubsDBFulfillmentCenterCompositeValue;

export type TEpSubsDBFulfillment_centerListCompositeData =
  Array<IEpSubsDBFulfillmentCenterCompositeValue>;

export type IEdSubsDBFulfillmentDeliveryMethodsValue = {
  id: number;
  ref_delivery_method_id: number;
  display_name: string;
  center_id: number;
  is_default: boolean;
  create_by: number;
  create_at: string;
};

export type IEpSubsDeliveryMethodValue = {
  id: number;
  locale: string;
  code: string;
  name: string;
  restriction: string;
};
// *********************************************** //
//                   Webhooks Type                 //
// *********************************************** //

export interface IEpSubsDBWebhookValue {
  id: number;
  url: string;
  description: string;
  event_types: {
    id: number;
    code: string;
    resource: string;
    event_name: string;
  }[];
  basic_auth: boolean;
  auth_user: string;
  auth_password: string;
  active: boolean;
  deleted: boolean;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

export interface IEpSubsDBWebhookPost {
  url: string;
  description: string;
  event_types: string;
  basic_auth: boolean;
  auth_user: string;
  auth_password: string;
}

export type TEpSubsDBWebhookValueKeys = keyof IEpSubsDBWebhookValue;

export type TEpSubsDBWebhookListData = Array<IEpSubsDBWebhookValue>;

export interface TEpSubsDBWebhookTypeValue {
  id: number;
  code: string;
  resource: string;
  event_name: string;
}

export interface IEpSubsDBSubscription_addressValue {
  id: number;
  subscription_id: number;
  customer_id: number;
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state_code: string;
  zip: string;
  country: string;
  start_at: string;
  end_at: string;
  validation_status: boolean;
  created_at: string;
}

export type IEpSubsDBCompositeCouponValue = IEpSubsDBCouponValue & {
  is_usable: boolean;
  start_dt: string;
  end_dt: string;
  sub_coupon_id: number;
};

export type INewTempAddressFormData = Omit<
  IEpSubsDBCustomer_addressValue,
  'id' | 'customer_id' | 'created_at' | 'updated_at' | 'start_at' | 'end_at'
> & {
  customer_id?: number;
  subscription_id?: number;
  start_at?: number;
  end_at?: number;
};

// *********************************************** //
//                   Tag List Type                 //
// *********************************************** //

export type IEpSubsTagsListValue = {
  id: number;
  type: string;
  tag_id: string;
  name: string;
};

export type IEpSubsTagsPost = {
  tag_name: string;
  tag_id: string;
};

// *********************************************** //
//                   Webhook Log Type              //
// *********************************************** //

export type IEpSubsWebhookLogValue = {
  id: number;
  event_id: number;
  event_type_code: string;
  webhook_id: number;
  status: string;
  last_err: {
    String: string;
    Valid: boolean;
  };
  last_called: string;
  tried_times: number;
  next_retry: {
    Time: string;
    Valid: boolean;
  };
  created_at: string;
};

export type TEpSubsDBWebhookLogsValueKeys = keyof IEpSubsWebhookLogValue;

export type TEpSubsDBWebhookLogListData = Array<IEpSubsWebhookLogValue>;

// *********************************************** //
//                    Plugin Type                  //
// *********************************************** //

// post Elastic_saved_filters
export interface IEpSubsDBElastic_saved_filtersPost {
  id?: number | null;
  group_name?: string | null;
  filter_name?: string | null;
  filters?: string | null;
  created_at?: string | null;
  created_by?: string | null;
  updated_at?: string | null;
  updated_by?: string | null;
  access_level?: number | null;
}

// get Elastic_saved_filters
export interface IEpSubsDBElastic_saved_filtersValue
  extends IEpSubsDBElastic_saved_filtersPost {
  id: number;
  group_name: string;
  filter_name: string;
  filters: string;
  created_at: string;
  created_by: string;
  access_level: number;
}

export type TEpSubsDBElastic_saved_filtersValueKeys =
  keyof IEpSubsDBElastic_saved_filtersValue;

export type TEpSubsDBElastic_saved_filtersListData =
  Array<IEpSubsDBElastic_saved_filtersValue>;

// post Setting
export interface IEpSubsDBSettingPost {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  group_id?: number | null;
  value_type?: string | null;
  value?: string | null;
  required?: boolean | null;
  choices?: string | null;
  locked?: boolean | null;
  lockable?: boolean | null;
  configurable?: boolean | null;
  display_order?: number | null;
  updated_at?: string | null;
  updated_by?: number | null;
}

// get Setting
export interface IEpSubsDBSettingValue extends IEpSubsDBSettingPost {
  id: number;
  code: string;
  name: string;
  description: string;
  group_id: number;
  value_type: string;
  value: string;
  required: boolean;
  locked: boolean;
  lockable: boolean;
  configurable: boolean;
  display_order: number;
}

export type TEpSubsDBSettingValueKeys = keyof IEpSubsDBSettingValue;

export type TEpSubsDBSettingListData = Array<IEpSubsDBSettingValue>;

// post Setting_group
export interface IEpSubsDBSetting_groupPost {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  preview?: string | null;
  enabled?: boolean | null;
}

// get Setting_group
export interface IEpSubsDBSetting_groupValue extends IEpSubsDBSetting_groupPost {
  id: number;
  code: string;
  name: string;
  description: string;
}

export type TEpSubsDBSetting_groupValueKeys = keyof IEpSubsDBSetting_groupValue;

export type TEpSubsDBSetting_groupListData = Array<IEpSubsDBSetting_groupValue>;
