import { selector, selectorFamily, waitForAll } from 'recoil';
import * as H from 'history';
import {
  IEpSubsDBAddonValue,
  TEpSubsDBAddonValueKeys,
  IEpSubsDBAddon_activityValue,
  IEpSubsDBAddon_commentValue,
  UiPermission,
} from '../../models';
import {
  genAddonData,
  genAddonListData,
  genActiveAddonListData,
  genAddonActivityData,
  genAddonActivityDetail,
  genAddonCommentData,
  genRecurringAddonListData,
  genAllAddonData,
} from '../../services/epSubsAddonService';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
} from '../../components/tables/EpFullFeaturedTable';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { CURRENT_ID_ATOM_DEFAULT_VALUE, hasPermissionSelectors } from '..';
import { reduce } from 'lodash';
import { atom, atomFamily } from 'recoil';
import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';
import {
  ADDON_STATUS_FILTER_GROUP_FIELD,
  DEFAULT_ADDON_SEARCH_OPTION,
} from 'src/containers/addons/EpSubsAddonNav';
import { getSimpleFilterData } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//             Addon List Page States              //
// *********************************************** //
export const DEFAULT_ADDON_SEARCH_PARAMETER = `?filter=${JSON.stringify(
  DEFAULT_ADDON_SEARCH_OPTION,
)}`;
/**
 * An atom that stores the latest addon search param
 */
export const latestAddonSearchParamAtom = atom<TLocationRecord>({
  key: 'latestAddonSearchParamAtom',
  default: { search: DEFAULT_ADDON_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for addon list
 */
export const addonSearchRequestIDAtom = atom<number>({
  key: 'addonSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for addon list based on current searchParam
 */
export const addonBasicListSelector = selector<ListState<IEpSubsDBAddonValue>>({
  key: 'addonIdListSelector',
  get: async ({ get }) => {
    const requestID = get(addonSearchRequestIDAtom); // Add request ID as a dependency
    const addonSearchParam = get(latestAddonSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, addonBasicListSelector addonSearchParam',
        addonSearchParam,
        'requestID',
        requestID,
      );

    // fetch addons
    if (addonSearchParam.ready && addonSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBAddonValueKeys>(
        addonSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const filter = decodeURIComponent(routeParams.filter);
      const offset = (page - 1) * perPage;
      const filterData = getSimpleFilterData(filter);

      try {
        const response = await genAddonListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'created_at', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          filterData[ADDON_STATUS_FILTER_GROUP_FIELD][0],
        );

        DEBUGGING && console.log('recoil, addonBasicListSelector response', response);

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An selector that stores the addon detail list based on current searchParam
 */
export const addonCompleteListSelector = selector<ListState<IEpSubsDBAddonValue>>({
  key: 'addonCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(addonBasicListSelector);

    if (!idListState.err) {
      const addonIds = idListState.data.map(addonData => addonData.id);
      const addonDetailList = get(
        waitForAll(addonIds.map(addonId => addonSelectors(addonId))),
      );
      DEBUGGING && console.log('recoil, addonCompleteListSelector', addonDetailList);

      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: addonDetailList ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from addonBasicListSelector
      return idListState as ListState<IEpSubsDBAddonValue>;
    }
  },
});

/**
 * An atomFamily that stores the refresh Request ID for each addon
 */
export const addonRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'addonRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores addon details for each addon
 */
export const addonSelectors = selectorFamily<IEpSubsDBAddonValue, number>({
  key: 'addonSelectors',
  get:
    addonId =>
    async ({ get }) => {
      get(addonRefreshRequestIDAtoms(addonId)); // Add request ID as a dependency
      const addonData = await genAddonData(addonId);
      DEBUGGING && console.log('recoil, addonSelectors addonData:', addonData);
      return addonData;
    },
});

// *********************************************** //
//           Addon Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected addon id
 */
export const currentAddonIdAtom = atom<number | null>({
  key: 'currentAddonIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected addon details
 */
export const currentAddonSelector = selector<IEpSubsDBAddonValue | undefined>({
  key: 'currentAddonSelector',
  get: ({ get }) => {
    const id = get(currentAddonIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(addonSelectors(id));
    }
  },
});

/**
 * An atom that stores the currently selected addon Comments query parameters
 */
export const currentAddonCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentAddonCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected addon Comments
 */
export const currentAddonCommentsSelector = selector<IEpSubsDBAddon_commentValue[]>({
  key: 'currentAddonCommentsSelector',
  get: async ({ get }) => {
    const id = get(currentAddonIdAtom);
    const params = get(currentAddonCommentsSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['addon.view_comment']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genAddonCommentData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An atom that stores the currently selected addon Activities query parameters
 */
export const currentAddonActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentAddonActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected addon Activities
 */
export const currentAddonActivitiesSelector = selector<IEpSubsDBAddon_activityValue[]>({
  key: 'currentAddonActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentAddonIdAtom);
    const params = get(currentAddonActivitiesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['addon.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genAddonActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores Addon Activity Detail for each activity
 */
export const addonActivityDetailSelectors = selectorFamily<
  IEpSubsDBAddon_activityValue,
  number
>({
  key: 'addonActivityDetailSelectors',
  get:
    actLogId =>
    async ({}) => {
      const addonActivityData = await genAddonActivityDetail(actLogId);
      DEBUGGING &&
        console.log('recoil, addonSelectors addonActivityData:', addonActivityData);
      return addonActivityData;
    },
});

// *********************************************** //
//          Addon Dropdown Data Selectors           //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for active addon list
 */
export const activeAddonsRequestIDAtom = atom<number>({
  key: 'activeAddonsRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the addon detail list
 */
export const activeAddonListStateSelector = selector<ListState<IEpSubsDBAddonValue>>({
  key: 'activeAddonListStateSelector',
  get: async ({ get }) => {
    get(activeAddonsRequestIDAtom);
    const response = await genActiveAddonListData();

    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: response ?? [],
      total: response.length,
    };
  },
});

/**
 * An selector that stores the map from addon_id to addon_basic details
 */
export const allAddonDataMapSelector = selector<Map<string, IEpSubsDBAddonValue>>({
  key: 'allAddonDataMapSelector',
  get: async ({ get }) => {
    // To be replaced with a all addon list API
    const addonList = get(activeAddonListStateSelector).data ?? [];
    return reduce<IEpSubsDBAddonValue, Map<string, IEpSubsDBAddonValue>>(
      addonList,
      (addonIdMap, addonRecord) => {
        addonIdMap.set(addonRecord.addon_id, addonRecord);
        return addonIdMap;
      },
      new Map(),
    );
  },
});

/**
 * An atom that stores that stores the refresh Request ID for active addon list
 */
// export const allNonRecurringAddonRequestIDAtom = atom<number>({
//   key: 'allNonRecurringAddonRequestIDAtom',
//   default: 0,
// });

/**
 * Get the list of non recurring add ons
 */
export const allRecurringAddonSelectors = selectorFamily<IEpSubsDBAddonValue[], boolean>({
  key: 'allRecurringAddonSelectors',
  get:
    isRecurring =>
    async ({}) => {
      return genRecurringAddonListData(isRecurring);
    },
});

/**
 * Get the all addons
 */

export interface IEpSubsActiveAddonsValue {
  id: number;
  addon_id: string;
  name: string;
  invoice_name: string;
  description: string;
  pricing_model: string;
  charge_type: string;
  charge_once: boolean;
  price: number;
  currency_code: string;
  period: number;
  period_totaldays: number;
  included_in_mrr: boolean;
  show_description_in_invoice: boolean;
  shippable: boolean;
  status: string;
  created_at: string;
}
export const allAddonSelector = selector<IEpSubsActiveAddonsValue[]>({
  key: 'allAddonSelectors',
  get: async ({}) => {
    return genAllAddonData();
  },
});
