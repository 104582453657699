import axios from 'axios';
import { hostUrl } from 'src/config';
import {
  IEpSubsDBNotificationPost,
  IEpSubsDBNotification_eventValue,
  IEpSubsDBNotificationEventCategoryApiValue,
  IEpSubsCompositeNotificationValue,
  IEpSubsDBNotification_from_emailValue,
  IEpSubsNotificationEvent,
} from 'src/models';

/******************************
 * GET ALL NOTIFICATION EVENT *
 ******************************/
export const genAllNotificationEvents = async (): Promise<
  IEpSubsDBNotification_eventValue[]
> => {
  return axios.get(`${hostUrl}/notification-events`).then(response => {
    return response.data ?? [];
  });
};

/*************************
 * GET ONE EVENT *
 *************************/
export const genEventData = async (
  eventId: number,
): Promise<IEpSubsNotificationEvent> => {
  return axios.get(`${hostUrl}/notification-events/${eventId}`).then(response => {
    return response.data;
  });
};
/*************************
 * GET ALL NOTIFICATIONS *
 *************************/
export const genAllNotifications = async (): Promise<
  IEpSubsDBNotificationEventCategoryApiValue[]
> => {
  return axios.get(`${hostUrl}/notifications`).then(response => {
    return response.data ?? [];
  });
};

/*************************
 * GET ONE NOTIFICATIONS *
 *************************/
export const genNotificationData = async (
  notificationId: number,
): Promise<IEpSubsCompositeNotificationValue> => {
  return axios.get(`${hostUrl}/notifications/${notificationId}`).then(response => {
    return response.data;
  });
};

/*****************************
 * CREATE A NEW NOTIFICATION *
 *****************************/
export const genCreateNotification = async (
  data: IEpSubsDBNotificationPost,
): Promise<string> => {
  return axios.post(`${hostUrl}/notifications`, data).then(response => {
    return response.data.data;
  });
};

/*******************************************
 * UPDATE NOTIFICATION, SAVE DRAFT VERSION *
 *******************************************/
export const genUpdateNotification = async (
  id: number,
  data: IEpSubsDBNotificationPost,
): Promise<string> => {
  return axios.patch(`${hostUrl}/notifications/${id}`, data).then(response => {
    return response.data.data;
  });
};

/****************************************
 * PUBLISH DRAFT VERSION EMAIL TEMPLATE *
 ****************************************/
export const genPublishDraftVersionEmailTemplate = async (
  id: number,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/notifications/${id}/publish`).then(() => {
    return true;
  });
};

/***********************************************
 * DISCARD DRAFT VERSION NOTIFICATION TEMPLATE *
 ***********************************************/
export const genDiscardDraftVersionTemplate = async (id: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/notifications/${id}/discard-draft`).then(() => {
    return true;
  });
};

/****************************************
 * DELETE NOTIFICATION                  *
 ****************************************/
export const genDeleteNotification = async (id: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/notifications/${id}`).then(response => {
    return response.data.data;
  });
};

/****************************************
 *  CLONE NOTIFICATION                  *
 ****************************************/
export const genCloneNotification = async (id: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/notifications/${id}/clone`).then(response => {
    return response.data.data;
  });
};

/*******************************
 * ENABLE/DISABLE NOTIFICATION *
 *******************************/
export const genToggleNotification = async (id: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/notifications/${id}/toggle`).then(() => {
    return true;
  });
};

/******************
 * GET FROM EMAIL *
 ******************/
export const genNotificationFromEmails = async (): Promise<
  IEpSubsDBNotification_from_emailValue[]
> => {
  return axios.get(`${hostUrl}/notifications/from-emails`).then(response => {
    return response.data ?? [];
  });
};
