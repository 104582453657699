import { Button, Form, Modal, Row, Col, Card } from 'react-bootstrap';
import React, { BaseSyntheticEvent, useLayoutEffect, useState } from 'react';
import {
  INewAddressFormData,
  TAddressUsageType,
  TEpSubsAddressUsage,
  TEpSubsSubscriptionAddressUpdateData,
} from '../../models';
import { EpSubsAddressCardBody } from './EpSubsAddressCard';
import style from './EpSubsUpdateAddressUsageModal.module.scss';
import { useForm } from 'react-hook-form';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import { DEBUGGING } from 'src/config';
import { putNoty } from 'src/utilities/epSubsNoty';
import { Link } from 'react-router-dom';

type Props = {
  modalShow: boolean;
  formTitle?: string;
  prevAddressData: INewAddressFormData;
  updatedAddressData?: INewAddressFormData;
  addressUsageData?: TEpSubsAddressUsage[];
  isShipping?: boolean;
  subscriptionId?: number;
  onSubmit: (
    formData: Pick<TEpSubsSubscriptionAddressUpdateData, 'apply_to'>,
    event?: BaseSyntheticEvent,
  ) => Promise<boolean>;
  onCancel: () => void;
};

export const EpSubsUpdateAddressUsageModal = ({
  modalShow,
  formTitle = 'Preview Address Update',
  prevAddressData,
  updatedAddressData,
  addressUsageData,
  isShipping,
  subscriptionId,
  onSubmit,
  onCancel,
}: Props): JSX.Element => {
  const addressType: TAddressUsageType | undefined =
    isShipping == undefined ? undefined : isShipping ? 'shipping' : 'billing';
  const [selectedUsages, setSelectedUsages] = useState<Set<string>>(new Set());
  // Initial Value for Editable Fields
  const { handleSubmit } = useForm<
    Pick<TEpSubsSubscriptionAddressUpdateData, 'apply_to'>
  >({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const isUsageSelected = (usageItem: TEpSubsAddressUsage): boolean => {
    return selectedUsages.has(JSON.stringify(usageItem));
  };

  const handleUsageSelection = (usageItem: TEpSubsAddressUsage) => {
    const usageItemStr = JSON.stringify(usageItem);
    const newSet = new Set(selectedUsages);
    if (selectedUsages.has(usageItemStr)) {
      newSet.delete(usageItemStr);
      setSelectedUsages(newSet);
    } else {
      newSet.add(usageItemStr);
      setSelectedUsages(newSet);
    }
  };
  DEBUGGING &&
    console.log('Check EpSubsUpdateAddressUsageModal Status: ', selectedUsages);

  useLayoutEffect(() => {
    setSelectedUsages(
      new Set([
        JSON.stringify({
          subscription_id: subscriptionId,
          address_type: addressType,
        } as TEpSubsAddressUsage),
      ]),
    );
  }, [isShipping, subscriptionId]);

  return (
    <>
      <Modal show={modalShow} onHide={onCancel} size="lg" centered>
        <Modal.Body id="contained-modal-title-vcenter">
          <h5 className="subheader">{formTitle}</h5>
          <Row className="m-3">
            <Col>
              <Card className="px-3 pt-3 h-100 w-100">
                <Card.Header>
                  <strong>Previous Address</strong>
                </Card.Header>
                <Card.Body>{EpSubsAddressCardBody(prevAddressData, false)}</Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="px-3 pt-3 h-100 w-100">
                <Card.Header>
                  <strong>Updated Address</strong>
                </Card.Header>
                <Card.Body>
                  {EpSubsAddressCardBody(updatedAddressData ?? null, false)}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Form
            className={`${style.form} px-5`}
            onSubmit={handleSubmit(async (_data, event) => {
              const parsedSelectedUsages = Array.from(selectedUsages).map(item => {
                try {
                  return JSON.parse(item);
                } catch (e) {
                  const message =
                    `Unexpectedly failed to parse ${item}! ` + (e as Error).message;
                  putNoty({ type: 'error', text: message });
                }
              });
              await onSubmit(
                { apply_to: parsedSelectedUsages as TEpSubsAddressUsage[] },
                event,
              );
              setSelectedUsages(new Set());
            })}
            autoComplete={'off'}
          >
            <h5 className="subheader">Select Address Usage for update:</h5>
            <Card className="px-3 py-1 mb-3">
              {addressUsageData == null ? (
                <Form.Text>There usage Api failed!</Form.Text>
              ) : addressUsageData.length === 0 ? (
                <Form.Text>There is no usage returned yet, check Database!</Form.Text>
              ) : (
                addressUsageData.map((usageItem, index) => {
                  const isTargetUsage =
                    addressType === usageItem.address_type &&
                    subscriptionId === usageItem.subscription_id;
                  return (
                    <Form.Group
                      className="my-1 d-flex flex-row"
                      style={isTargetUsage ? { fontWeight: 'bold' } : {}}
                      key={index}
                      controlId={`${usageItem.subscription_id} ${usageItem.address_type}`}
                    >
                      <Form.Check
                        type="checkbox"
                        checked={isUsageSelected(usageItem)}
                        onChange={() => handleUsageSelection(usageItem)}
                        label={
                          `Subscription-${usageItem.subscription_id}:` +
                          ` ${toInitialUpperCase(usageItem.address_type)} Address`
                        }
                      />
                      <Link
                        className="pl-3"
                        to={`/subscriptions/${usageItem.subscription_id}/detail`}
                        target="_blank" // Open a new tab after click
                      >
                        View Detail
                      </Link>
                    </Form.Group>
                  );
                })
              )}
            </Card>
            <div style={{ textAlign: 'right' }}>
              <Button
                className={`${style.form_button}`}
                variant="primary"
                type="submit"
                id="address_form_save"
              >
                Confirm Update
              </Button>
              <Button
                className={`${style.form_button}`}
                onClick={() => onCancel()}
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
