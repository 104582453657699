import { useRecoilCallback } from 'recoil';
import { TFilterSource } from 'src/js/constants/subs';
import { savedFilterListRequestIdFamily } from '..';

// *********************************************** //
//               Saved Filter Hooks                //
// *********************************************** //

export const useRefreshSavedFilterList = (): ((source: TFilterSource) => void) => {
  return useRecoilCallback(
    ({ set }) =>
      async source => {
        // refresh invoice list
        set(savedFilterListRequestIdFamily(source), requestID => requestID + 1);
      },
    [],
  );
};
