import axios from 'axios';
import {
  TEpSubsDBResponseOk,
  IEpSubsDBOfferValue,
  IEpSubsCompositeDBOfferPost,
  TEpSubsDBOfferValueKeys,
  IEpSubsDBOffer_activityValue,
} from '../models';
import { hostUrl } from '../config.js';
import {
  EMPTY_EXPORT_ERROR,
  getSortSQLStrFromSortingItems,
  TSortingItem,
} from './epSubsServiceUtils';
import FileDownload from 'js-file-download';
import { unixtimeWithoutTime } from 'src/components/formComponents';

export const genOfferListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBOfferValueKeys>> = [],
  simpleQuery = '',
  // status = '',
  // productType = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBOfferValue>> => {
  const sortSQLStr = getSortSQLStrFromSortingItems<TEpSubsDBOfferValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/offers?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
          // `status=${status}`,
          // `product_type=${productType}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

export const genOfferData = async (id: number): Promise<IEpSubsDBOfferValue> => {
  return axios.get(`${hostUrl}/offers/${id}`).then(response => {
    return response.data;
  });
};

export const genAllOfferData = async (
  productID: number,
  excludeTrialPlans?: boolean,
): Promise<IEpSubsDBOfferValue[]> => {
  return axios
    .get(
      `${hostUrl}/offers?simple=1&product=${productID}&exclude_trial_plan=${
        excludeTrialPlans ? 1 : 0
      }`,
    )
    .then(response => {
      return response.data.data;
    });
};

export const genAllOfferList = async (): Promise<IEpSubsDBOfferValue[]> => {
  return axios.get(`${hostUrl}/offers?simple=1`).then(response => {
    return response.data.data;
  });
};

export const genOfferDetailData = async (
  offerStringID: string,
): Promise<
  IEpSubsDBOfferValue & {
    offer_detail: {
      plan: {
        center_id: number;
        product: {
          id: number;
          shippable: boolean;
        };
      };
    };
  }
> => {
  return axios.get(`${hostUrl}/offers/offer-id/${offerStringID}`).then(response => {
    return response.data;
  });
};

export const genCreateOffer = async (
  data: IEpSubsCompositeDBOfferPost,
): Promise<string> => {
  return axios.post(`${hostUrl}/offers`, data).then(response => {
    return response.data.data;
  });
};

export const genCloneOffer = async (
  data: IEpSubsCompositeDBOfferPost,
): Promise<string> => {
  return axios.post(`${hostUrl}/offers`, data).then(response => {
    return response.data.data;
  });
};

export const genEditOffer = async (
  id: number,
  data: IEpSubsCompositeDBOfferPost,
): Promise<boolean> => {
  return axios.put(`${hostUrl}/offers/${id}`, data).then(() => {
    return true;
  });
};

export const genExportOffer = async (): Promise<string> => {
  return axios.get(`${hostUrl}/offers/export`).then(response => {
    const payloadFileStr = response.data;
    if (Object.keys(payloadFileStr).length === 0) throw new Error(EMPTY_EXPORT_ERROR);
    const fileName = `OFFERS-${unixtimeWithoutTime(Date.now())}.csv`;
    FileDownload(payloadFileStr, fileName);
    return fileName;
  });
};

export const genOfferActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBOffer_activityValue[]> => {
  return axios.get(`${hostUrl}/offers/${id}/actlogs?limit=${limit}`).then(response => {
    return response.data.data;
  });
};

export const genOfferActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBOffer_activityValue> => {
  return axios.get(`${hostUrl}/offers/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const reactivateOffer = async (id: number): Promise<any> => {
  return axios.post(`${hostUrl}/offers/${id}/toggle`).then(response => {
    return response.data;
  });
};
