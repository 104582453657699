import React, { useEffect, useRef, useState } from 'react';
import ReactSelect, {
  OptionsType,
  StylesConfig,
  createFilter,
  ValueContainerProps,
  components,
} from 'react-select';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import {
  EDropdownItemType,
  getGroupedOptions,
  TFlatDropdownItem,
  TGroupDropdownItem,
  TGroupedOptions,
} from './ESGeneralDropdownInput';
import { TUseFormSetValue } from '../formComponents';
import { FilterGroupNameFlag } from '../flags/EpSubsFlags';
import { Row } from 'react-bootstrap';
import { DEBUGGING } from 'src/config';

export interface IMultipleSelectProps {
  name?: string;
  control?: Control<Record<string, any>>;
  controlId?: string;
  register?: any;
  setValue?: TUseFormSetValue<any>;
  placeholder?: string;
  allOptions: (TFlatDropdownItem<string> | TGroupDropdownItem<string>)[];
  defaultValue?: string[];
  required?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  validate?: () => boolean;
  onBlur?: () => void;
  onChange?: (valueList?: string[]) => void;
  displayOptionGroup?: boolean;
  filterAvailableOptions?: (
    options: (TFlatDropdownItem<string> | TGroupDropdownItem<string>)[],
  ) => (TFlatDropdownItem<string> | TGroupDropdownItem<string>)[];
  selectId?: string;
}

type IsMulti = true;
type TValueContainerChildren = {
  props: { data: SelectOption };
}[][];

type SelectOption = {
  label: string;
  value: string;
  groupName?: string;
  groupField?: string;
};

/**
 * General component for multiple select, implemented by ReactSelect and useForm Control
 * @param onChange Define side effect after choosing an option
 * or Changing the multi-select option list.
 * @param onBlur Define side effect after click outside the multi-select components
 * or, most commonly, Confirm changing the multi-select option list [This is tricky without form]
 * or, sometimes, Triggering validation for the selected options.
 * (Be careful, it is called before the finish of internal states update)
 * - Below are REQUIRED parameters when used with useForm hook
 * @param control Determine whether to use useForm hook or not
 * @param setValue Used for updating the useForm hook controlled value in form input states
 * @param name Used to identify the field name in useForm states
 * @param key The default react parameter. Tip: We should not set defaultValue as the key if it were the current Value of the form components in useForm.
 * If set, the UI would not work properly and the value cannot be changed.
 */
export const EpSubsMultipleSelect = ({
  name = '',
  control,
  register,
  setValue,
  placeholder = 'Select...',
  allOptions,
  defaultValue = [],
  required,
  disabled,
  isClearable,
  validate,
  onBlur,
  onChange,
  displayOptionGroup = false,
  filterAvailableOptions,
  selectId,
}: IMultipleSelectProps): JSX.Element => {
  const selectRef = useRef<ReactSelect<
    SelectOption,
    IsMulti,
    TGroupedOptions<SelectOption>
  > | null>(null);
  const [selectedOptionValues, setSelectedOptionValues] =
    useState<string[]>(defaultValue);

  // Sync selectedOptionValues with the value in useForm hook
  const formValue = control?.getValues(name) as string[];
  useEffect(() => {
    formValue != null && setSelectedOptionValues(formValue);
  }, [formValue]);

  const customStyles: StylesConfig<
    SelectOption,
    IsMulti,
    TGroupedOptions<SelectOption>
  > = {
    option: provided => ({
      ...provided,
      wordWrap: 'break-word',
      paddingLeft: '1rem',
    }),
  };

  // Used to style grouped dropdown.
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    // fontWeight: 'bold',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    // textAlign: 'center',
  };

  const formatGroupLabel = (data: TGroupedOptions<SelectOption>) => (
    <div style={groupStyles}>
      <span>{data.groupName}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  // if there is no options, then return dummy Select,
  // only render real Select if there is options
  // otherwise cannot show defaultValue correctly
  if (allOptions.length === 0) return <ReactSelect options={[]} />;

  // convert options
  let groupedSelectOptions: TGroupedOptions<SelectOption>[] | undefined;
  if (displayOptionGroup && allOptions[0].type === EDropdownItemType.GROUPED) {
    groupedSelectOptions = getGroupedOptions<TGroupDropdownItem<string>, SelectOption>(
      (!!filterAvailableOptions
        ? filterAvailableOptions(allOptions as TGroupDropdownItem<string>[])
        : allOptions) as TGroupDropdownItem<string>[],
      optionItem => {
        return {
          value: String(optionItem.value),
          label: optionItem.label,
          groupName: optionItem.groupName,
        };
      },
    ) as TGroupedOptions<SelectOption>[];
  }

  // Used to customize the layout of selected options
  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<SelectOption, IsMulti, TGroupedOptions<SelectOption>>) => {
    return (
      <components.ValueContainer {...props}>
        <Row className={`ml-1`} style={{ height: '2rem' }} tabIndex={0}>
          {FilterGroupNameFlag(
            !!children && !!(children as TValueContainerChildren)[0][0]?.props?.data
              ? (children as TValueContainerChildren)[0][0].props.data.groupField ?? ''
              : null,
            { margin: '0.3rem 0rem', marginRight: '0.3rem' },
          )}
          <div className="font-weight-bold d-flex flex-row">{children}</div>
        </Row>
      </components.ValueContainer>
    );
  };

  let rules = {};
  if (required) {
    rules = { required: true };
  }
  if (validate) {
    rules = { validate };
  }

  const handleChange = (
    selectedOptions: OptionsType<SelectOption> | null,
    // action: ActionMeta<SelectOption>,
  ) => {
    const selectedOptionValues = Array.isArray(selectedOptions)
      ? selectedOptions.map(option => option.value)
      : [];
    DEBUGGING && console.log('Check MultiSelect Change: ', selectedOptionValues);

    // For component use
    setSelectedOptionValues(selectedOptionValues);
    // For useForm submit
    setValue && setValue(name, selectedOptionValues);
    // For customized props
    onChange && onChange(selectedOptionValues);
    onBlur && onBlur(); //
  };

  DEBUGGING &&
    console.log(
      'MultiSelect status check:',
      'selectedOptionValues',
      selectedOptionValues,
      'Default value:',
      defaultValue,
    );

  return control ? (
    <Controller
      name={name}
      control={control}
      ref={register && register()}
      rules={rules}
      required={required}
      defaultValue={defaultValue}
      onFocus={() => {
        if (selectRef.current !== null) selectRef.current.focus();
      }}
      render={(renderProps: ControllerRenderProps<Record<string, any>>) => {
        return (
          <ReactSelect
            id={`${selectId}`}
            placeholder={placeholder}
            isMulti
            autoBlur
            isDisabled={disabled}
            isClearable={isClearable}
            ref={ref => (selectRef.current = ref)}
            styles={customStyles}
            formatGroupLabel={formatGroupLabel}
            options={
              groupedSelectOptions ??
              (filterAvailableOptions ? filterAvailableOptions(allOptions) : allOptions)
            }
            // for typeahead filter, only search 'label' field, don't search 'value' field
            filterOption={createFilter({
              matchFrom: 'any',
              stringify: option => `${option.label}`,
            })}
            value={allOptions.filter(option =>
              selectedOptionValues.includes(option.value),
            )}
            onChange={options => {
              handleChange(options);
            }}
            blurInputOnSelect
            backspaceRemovesValue
            // ** Specific Props for form controlled component: ** //
            // components={{ ValueContainer }}
            onBlur={() => {
              renderProps.onBlur();
              onBlur && onBlur();
            }}
          />
        );
      }}
    />
  ) : (
    <ReactSelect
      id={selectId}
      placeholder={placeholder}
      isMulti
      autoBlur
      isDisabled={disabled}
      isClearable={isClearable}
      ref={ref => (selectRef.current = ref)}
      styles={customStyles}
      formatGroupLabel={formatGroupLabel}
      options={
        groupedSelectOptions ??
        (filterAvailableOptions ? filterAvailableOptions(allOptions) : allOptions)
      }
      // for typeahead filter, only search 'label' field, don't search 'value' field
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: option => `${option.label}`,
      })}
      value={allOptions.filter(option => selectedOptionValues.includes(option.value))}
      onChange={options => {
        handleChange(options);
      }}
      blurInputOnSelect
      backspaceRemovesValue
      // ** Specific Props for non form controlled component: ** //
      components={{ ValueContainer }}
      onBlur={() => {
        onBlur && onBlur();
      }}
    />
  );
};
