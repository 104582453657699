import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from 'src/components/tables/EpFullFeaturedTable';
import * as H from 'history';
import { offsetToPageId } from 'src/components/tables/EpFullFeaturedTable/Pagination/Pagination';
import {
  IEpSubsDBAdminBulkOperationValue,
  TEpSubsDBAdminBulkValueKeys,
} from 'src/models';
import { arrayToMap } from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import { CURRENT_ID_ATOM_DEFAULT_VALUE } from '../recoilConstants';
import {
  genAdminBulkData,
  genAdminBulkListData,
  getAllAdminBulkList,
} from 'src/services/epSubsBulkOperationService';
import { DEBUGGING } from 'src/config';

/**
 * An atom that stores the fresh Request ID for AdminBulk list
 */
export const adminBulkListRequestIDAtom = atom<number>({
  key: 'adminBulkListRequestIDAtom',
  default: 0,
});

/**
 * An atomFamily that stores the refresh Request ID for each adminBulk
 */
export const adminBulkRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'adminBulkRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores adminBulk details for each adminBulk
 */
export const adminBulkSelectors = selectorFamily<
  IEpSubsDBAdminBulkOperationValue,
  number
>({
  key: 'adminBulkSelectors',
  get:
    adminBulkId =>
    async ({ get }) => {
      get(adminBulkRefreshRequestIDAtoms(adminBulkId)); // Add request ID as a dependency
      const adminBulkData = await genAdminBulkData(adminBulkId);
      DEBUGGING &&
        console.log('recoil, adminBulkSelectors adminBulkData:', adminBulkData);
      return adminBulkData;
    },
});

/**
 * An selector that stores AdminBulk list
 */
export const adminBulkSelector = selector<IEpSubsDBAdminBulkOperationValue[]>({
  key: 'adminBulkListSelector',
  get: async ({ get }) => {
    get(adminBulkListRequestIDAtom);
    const adminBulkList = await getAllAdminBulkList();
    return adminBulkList;
  },
});

/**
 * An selector that stores a map from adminBulk.id to adminBulk Object
 */
export const adminBulkMapSelector = selector<
  Map<number, IEpSubsDBAdminBulkOperationValue>
>({
  key: 'adminBulkMapSelector',
  get: async ({ get }) => {
    const adminBulkList = get(adminBulkSelector);
    return arrayToMap(adminBulkList, adminBulk => adminBulk.id);
  },
});

/**
 * An selector that stores the default AdminBulk
 */
export const defaultAdminBulkSelector = selector<IEpSubsDBAdminBulkOperationValue>({
  key: 'defaultAdminBulkSelector',
  get: async ({ get }) => {
    const adminBulkList = get(adminBulkSelector);
    if (adminBulkList.length === 0) {
      const message = 'No default adminBulk set! Please set on backend.';
      putNoty({ type: 'error', text: message });
      throw new Error(message);
    }
    return adminBulkList[0];
  },
});

// *********************************************** //
//             AdminBulk List Page States            //
// *********************************************** //
export const DEFAULT_ADMIN_BULK_SEARCH_PARAMETER = '';

/**
 * An atom that stores the latest adminBulk search param
 */
export const latestAdminBulkSearchParamAtom = atom<TLocationRecord>({
  key: 'latestAdminBulkSearchParamAtom',
  default: { search: DEFAULT_ADMIN_BULK_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for adminBulk list
 */
export const adminBulkSearchRequestIDAtom = atom<number>({
  key: 'adminBulkSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for adminBulk list
 */
export const adminBulkListSelector = selector<
  ListState<IEpSubsDBAdminBulkOperationValue>
>({
  key: 'adminBulkIdListSelector',
  get: async ({ get }) => {
    const requestID = get(adminBulkSearchRequestIDAtom); // Add request ID as a dependency
    const adminBulkSearchParam = get(latestAdminBulkSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, adminBulkBasicListSelector adminBulkSearchParam',
        adminBulkSearchParam,
        'requestID',
        requestID,
      );

    // fetch adminBulks
    if (adminBulkSearchParam.ready && adminBulkSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBAdminBulkValueKeys>(
        adminBulkSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const offset = (page - 1) * perPage;
      try {
        const response = await genAdminBulkListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'name', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
        );

        DEBUGGING && console.log('recoil, adminBulkBasicListSelector response', response);
        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

// *********************************************** //
//           AdminBulk Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected adminBulk id
 */
export const currentAdminBulkIdAtom = atom<number | null>({
  key: 'currentAdminBulkIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected adminBulk details
 */
export const currentAdminBulkSelector = selector<
  IEpSubsDBAdminBulkOperationValue | undefined
>({
  key: 'currentAdminBulkSelector',
  get: ({ get }) => {
    const id = get(currentAdminBulkIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(adminBulkSelectors(id));
    }
  },
});
