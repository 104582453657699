import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './components/Button';
import {
  IEpSubsCustomer_payment_response,
  genDeletePaymentForCustomer,
  genSetPrimaryPaymentForCustomer,
} from './services/epSubsPortalPaymentsService';
import { putNoty } from '../../utilities/epSubsNoty';
import { paymentListSelector, useRefreshPaymentList } from '../../recoil';
import EpSubsPortalEditPaymentModal from './EpSubsPortalEditPaymentModal';
import { White } from './components/colors';
import Modal from './components/Modal';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { languageOptions } from 'src/models/i18n';
import EpToolTip from 'src/components/toolTip/EpToolTip';
import { EpIcon, IconFactory } from 'src/assets';
import { useRecoilValueLoadable } from 'recoil';

type Props = {
  id: number;
  token: string;
  card_type?: string;
  expiration_month?: string;
  expiration_year?: string;
  is_primary?: boolean;
  last4_digits?: string | null;
  payment_type?: string;
  collapsed?: boolean;
  children?: JSX.Element;
  email: string;
  first_name: string;
  last_name: string;
  gateway_account: number;
};

const StyledLi = styled.li`
  box-shadow: 0px 4px 12px 0 #00000033;
  border-radius: 8px;
  background-color: ${White};
  margin: 5px 0 5px 0;
  width: 100%;
`;

const CollapseLabel = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  color: #333333;
  font-family: inherit;
  font-size: 14px;
  text-align: left;
  vertical-align: top;
  text-transform: none;
  font-weight: 400;
  letter-spacing: -0.22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  line-height: 20px;
`;

const PrimaryLabel = styled.div`
  font-size: 10px;
  border-radius: 4px;
  margin: 0 2px 0 2px;
  padding: 0 2px 0 2px;
  background-color: #0093ff;
  color: #ffffff;
  line-height: 15px;
  font-weight: 600;
`;

const EpSubsPaymentRow = ({
  id,
  token,
  card_type,
  payment_type,
  expiration_month,
  expiration_year,
  is_primary,
  last4_digits,
  email,
  collapsed = true,
  gateway_account,
}: Props): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showPrimaryConfirmModal, setShowPrimaryConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const refreshPaymentList = useRefreshPaymentList();
  let epIcon: keyof typeof IconFactory;
  switch (card_type || payment_type) {
    case 'Visa':
    case 'visa':
      epIcon = 'visa' as keyof typeof IconFactory;
      break;
    case 'American Express':
    case 'Amex':
      epIcon = 'amex' as keyof typeof IconFactory;
      break;
    case 'Discover':
    case 'discover':
      epIcon = 'discover' as keyof typeof IconFactory;
      break;
    case 'Mastercard':
    case 'mastercard':
      epIcon = 'master' as keyof typeof IconFactory;
      break;
    case 'PayPalAccount':
    case 'PayPalExpress':
      epIcon = 'paypal' as keyof typeof IconFactory;
      break;
    case 'Sepa_Debit':
    case 'sepa_debit':
      epIcon = 'sepaDebit' as keyof typeof IconFactory;
      break;
    case 'au_becs_debit':
      epIcon = 'becs' as keyof typeof IconFactory;
      break;
    case 'Bacs_Debit':
    case 'bacs_debit':
      epIcon = 'bacs' as keyof typeof IconFactory;
      break;
    default:
      epIcon = 'creditCard' as keyof typeof IconFactory;
  }

  const [payments, setPayments] = useState<IEpSubsCustomer_payment_response>();
  const paymentListLoadable = useRecoilValueLoadable(paymentListSelector);
  useEffect(() => {
    switch (paymentListLoadable.state) {
      case 'loading':
        setIsLoading(true);
        break;
      case 'hasValue':
        if (paymentListLoadable.contents) setPayments(paymentListLoadable.contents);
        setIsLoading(false);
        break;
      case 'hasError':
        setIsLoading(false);
    }
  }, [paymentListLoadable.contents]);

  return (
    <StyledLi>
      <CollapseLabel onClick={() => setIsCollapsed(!isCollapsed)}>
        {payment_type && ['PayPalAccount', 'PayPalExpress'].includes(payment_type) ? (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '0px' }}>
              <EpIcon name={epIcon} style={{ height: '15px', marginLeft: '0px' }} />
              <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {languageOptions().portal.paypalCheckout.name}
              </div>
              {is_primary && (
                <PrimaryLabel>{languageOptions().portal.isPrimary.name}</PrimaryLabel>
              )}
            </div>
            <div style={{ fontSize: '10px', textAlign: 'left', marginLeft: '24px' }}>
              {email}
            </div>
          </div>
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EpIcon
                name={epIcon}
                style={{
                  height:
                    payment_type &&
                    ['Sepa_Debit', 'au_becs_debit', 'Bacs_Debit'].includes(payment_type)
                      ? '15px'
                      : '30px',
                  marginLeft: '0px',
                }}
              />
              {languageOptions().portal.payments.header} {last4_digits}
              {is_primary && (
                <PrimaryLabel>{languageOptions().portal.isPrimary.name}</PrimaryLabel>
              )}
            </div>
          </>
        )}
        <div style={{ fontSize: '12px' }}>
          <div style={{ display: 'flex' }}>
            {expiration_month &&
              expiration_year &&
              expirationDateChecker(expiration_month, expiration_year, false)}
            {isCollapsed && <EpIcon name={'thickDownArrow'} style={{ height: '10px' }} />}
            {!isCollapsed && <EpIcon name={'thickUpArrow'} style={{ height: '10px' }} />}
          </div>
        </div>
      </CollapseLabel>
      {!isCollapsed && (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 16px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontSize: '12px',
                color: '#666',
                marginRight: '5px',
                textAlign: 'right',
              }}
            >
              <EpIcon
                name="infoIcon"
                style={{ height: '11px', marginLeft: '0px', marginRight: '3px' }}
              />
              {is_primary
                ? `${languageOptions().portal.payments.primaryContent}`
                : `${languageOptions().portal.payments.backupContent}`}
            </div>
            {!is_primary && (
              <div>
                <Button
                  btnType="secondary"
                  onClick={() => {
                    setShowPrimaryConfirmModal(true);
                  }}
                >
                  {languageOptions().portal.buttons.primary}
                </Button>
              </div>
            )}
            <>
              <Modal
                visible={showPrimaryConfirmModal}
                cancelText={`${languageOptions().portal.primaryCard.cancel}`}
                onCancel={() => {
                  setShowPrimaryConfirmModal(false);
                }}
                onOk={async () => {
                  try {
                    setIsLoading(true);
                    await genSetPrimaryPaymentForCustomer(token, id);
                    putNoty({
                      type: 'success',
                      text: `${languageOptions().portal.edit.success}`,
                    });
                  } catch (e) {
                  } finally {
                    refreshPaymentList();
                    setShowPrimaryConfirmModal(false);
                    setIsLoading(false);
                  }
                }}
              >
                <>
                  <div
                    style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '30px' }}
                  >
                    {languageOptions().portal.primaryCard.header}
                  </div>
                  <div>{languageOptions().portal.primaryCard.content}</div>
                </>
              </Modal>
            </>
          </div>
          <span
            style={{
              marginTop: '25px',
              display: 'flex',
              justifyContent: is_primary ? 'flex-end' : 'space-between',
            }}
          >
            {!is_primary && (
              <Button
                btnType="secondary"
                onClick={() => {
                  setShowRemoveModal(true);
                }}
              >
                {languageOptions().portal.buttons.remove}
              </Button>
            )}
            <Modal
              visible={showRemoveModal}
              cancelText={`${languageOptions().portal.removeCard.cancel}`}
              onCancel={() => {
                setShowRemoveModal(false);
              }}
              onOk={async () => {
                try {
                  setIsLoading(true);
                  await genDeletePaymentForCustomer(token, id);
                  refreshPaymentList();
                  putNoty({
                    type: 'success',
                    text: `${languageOptions().portal.delete.success}`,
                  });
                } catch (e) {
                  const message =
                    `${languageOptions().portal.delete.failure}. \n` +
                    (e as Error).message;
                  putNoty({ type: 'error', text: message });
                } finally {
                  setShowRemoveModal(false);
                  setIsLoading(false);
                }
              }}
            >
              <>
                <div
                  style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '30px' }}
                >
                  {languageOptions().portal.removeCard.header}
                </div>
                <div>{languageOptions().portal.removeCard.content}</div>
              </>
            </Modal>
            <EpSubsPortalEditPaymentModal
              token={token}
              id={id}
              originalGatewayAccountId={gateway_account}
              paymentType={payment_type}
              customerID={payments?.customer_id as number}
            />
          </span>
        </div>
      )}
      <EpSubsSpinnerModal isLoading={isLoading} />
    </StyledLi>
  );
};

export default EpSubsPaymentRow;

export const expirationDateChecker = (
  cardMonth: string,
  cardYear: string,
  onlyWarning?: boolean,
) => {
  const dateYear = Number(new Date().getFullYear());
  const dateMonth = Number(new Date().getMonth());
  // parseInt(String(new Date().getMonth));

  if (Number(cardYear) < dateYear) {
    return (
      <>
        {!onlyWarning && (
          <span style={{ color: 'red' }}>
            {cardMonth}/{cardYear}
          </span>
        )}
        <span>
          <EpToolTip placement="top" tooltip={'expired'}>
            <EpIcon name={'paymentWarning'} />
          </EpToolTip>
        </span>
      </>
    );
  } else if (Number(cardYear) === dateYear && Number(cardMonth) < dateMonth) {
    return (
      <>
        {!onlyWarning && (
          <span style={{ color: 'red' }}>
            {cardMonth}/{cardYear}
          </span>
        )}
        <span>
          <EpToolTip placement="top" tooltip={'expired'}>
            <EpIcon name={'paymentWarning'} />
          </EpToolTip>
        </span>
      </>
    );
  } else if (Number(cardYear) >= dateYear) {
    return (
      <>
        {!onlyWarning && (
          <span>
            {cardMonth} / {cardYear}
          </span>
        )}
      </>
    );
  }
};
