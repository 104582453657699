import { useEffect } from 'react';
/**
 * Loading external script at run time and call the callback after loading success.
 * @dependingArray Default to be empty, will rerun the callback if it is changed.
 */
const useScript = ({
  scriptUrl,
  scriptId,
  callback,
}: {
  scriptUrl: string;
  scriptId: string;
  callback?: () => void;
}) => {
  useEffect(() => {
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.id = scriptId;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) {
          callback();
        }
      };
    }

    if (existingScript && callback) {
      callback();
    }

    return () => {
      if (existingScript && callback) {
        existingScript.remove();
      }
    };
  }, []);
};

export default useScript;
