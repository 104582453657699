import { TLanguageResource } from '../texts';

export const de_DE: TLanguageResource = {
  portal: {
    logo: {
      logo: 'RADIANT LIFE',
      title: 'Zahlungsmethoden',
      subTitle:
        'Ihr nächster Rechnungsbetrag wird von der Standardzahlungsmethode abgebucht.',
    },
    payments: {
      header: 'ending in',
      primaryContent: 'Das ist Ihre Standardzahlungsmethode.',
      backupContent: 'Das ist Ihre sekundäre Zahlungsmethode.',
    },
    update: {
      header: 'Update Payment Method',
      cardNumber: 'Kartennummer',
      cvv: 'Kartenprüfnummer',
      expirationDate: 'Ablaufdatum',
      postalCode: 'Postleitzahl',
      confirm: 'Bestätigen',
    },
    add: {
      header: 'Neue Zahlungsmethode hinzufügen',
      subTitle1: 'Stripe',
      subTitle2: 'Paypal Account',
      subTitle3: 'Paypal Express',
      subTitle4: 'Sepa Debit',
      cardNumber: 'Kartennummer',
      cvv: 'Kartenprüfnummer',
      expirationDate: 'Ablaufdatum',
      postalCode: 'Postleitzahl',
      first_name: 'Vorname',
      last_name: 'Familienname',
      email: 'E-Mail-Adresse',
      iban: 'IBAN',
      confirm: 'Bestätigen',
    },
    buttons: {
      add: 'Neue Zahlungsmethode hinzufügen',
      edit: 'Bearbeiten',
      remove: 'Entfernen',
      primary: 'Als Standard Zahlungsmethode festlegen',
    },
    isPrimary: {
      name: 'Standard',
    },
    primaryCard: {
      header: 'Als Standard Zahlungsmethode festlegen',
      content:
        'Sind Sie sicher, dass Sie diese Zahlungsmethode als Ihre Standard  Zahlungsmethode festlegen möchten?',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    removeCard: {
      header: 'Entfernen',
      content: 'Sind Sie sicher, dass Sie diese Zahlungsmethode entfernen möchten?',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
    },
    paypalCheckout: {
      name: 'PayPal Checkout',
    },
    create: {
      success: 'Neue Zahlungsmethode erfolgreich hinzugefügt.',
      failure: 'Die Zahlungsmethode konnte nicht hinzugefügt werden.',
    },
    edit: {
      success: 'Zahlungsmethode erfolgreich aktualisiert.',
      failure: 'Die Zahlung konnte nicht aktualisiert werden.',
    },
    delete: {
      success: 'Zahlung erfolgreich gelöscht.',
      failure: 'Die Zahlungsmethode konnte nicht gelöscht werden.',
    },
    error: {
      braintree: {
        failure: 'Braintree konnte nicht initialisiert werden.',
        failurePayment:
          'Die Zahlungsmethoden von Braintree konnten nicht abgerufen werden.',
      },
      stripe: {
        failureInitialize: 'Stripe konnte nicht initialisiert werden.',
      },
      paypal: {
        failure: 'Paypal Payment Intent von Braintree konnte nicht abgerufen werden.',
      },
      token: {
        failure:
          'Die Tokenisierung ist serverseitig fehlgeschlagen. Ist die Karte gültig?',
        somethingWrong: 'Etwas Schlimmes ist passiert!',
      },
      postalCode: {
        frontLength: 'Es sollte nicht mehr als',
        endLength: 'Zeichen in der Postleitzahl sein',
        failure: 'Ungültige Postleitzahl',
      },
      gateway: {
        failure: 'Es konnte keine Gateway-Option ausgewählt werden.',
      },
      paymentMethod: {
        failure: 'Andere Zahlungsmethoden als Karte können nicht verarbeitet werden!',
      },
      link: {
        failure: 'Der Link ist nicht mehr gültig.',
      },
      unknown: 'Unbekannte Gateway',
    },
    netWork: 'Bei der Tokenisierung ist ein Netzwerkfehler aufgetreten.',
    reminder: {
      someField: 'Einige Felder sind ungültig.',
      allField: 'Alle Felder sind leer! Bitte füllen Sie das Formular aus.',
      contact:
        'Bitte kontaktieren Sie unseren Kundenservice, um einen neuen Link zu erhalten.',
    },
    sepa: {
      header: 'Bitte Land auswählen',
      error:
        'Bitte nutzen Sie außerhalb Deutschlands die Zahlungsmethode "Kreditkarte" für Ihre Transaktion.',
    },
    default: {
      make_default: 'Als Standard Zahlungsmethode festlegen',
      apply_all: 'Für alle Abonnements anwenden',
    },
    link: 'Zurück zur EPOCH TIMES',
    address: {
      address: 'Adresse',
      postal_code: 'Postleitzahl',
      city: 'Stadt',
    },
  },
};
