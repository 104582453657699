import React, { BaseSyntheticEvent, useState } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { Button, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { EpSubsStripeFormSection } from 'src/components/formComponents';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm';
import { languageOptions } from 'src/models/i18n';
import * as stripeJs from '@stripe/stripe-js';

export const EpSubsPaymentStripeCardForm = ({
  gatewayAccountId,
  onSubmit,
  onStripeInstance,
}: {
  gatewayAccountId: number;
  onSubmit: (
    gateway: number,
    data: TNewPaymentFormData,
    setIsLoading: (isLoading: boolean) => void,
    event?: BaseSyntheticEvent<any>,
  ) => Promise<boolean>;
  onStripeInstance: (stripe: Stripe, stripeElements?: stripeJs.StripeElements) => void;
  // defaultPaymentData?: TNewPaymentFormData;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stripe, setStripe] = useState<Stripe>();
  const { register, handleSubmit, errors } = useForm<TNewPaymentFormData>({
    mode: 'onChange',
  });

  return (
    <Form
      onSubmit={handleSubmit(
        (formData: TNewPaymentFormData, event?: BaseSyntheticEvent) => {
          onSubmit(gatewayAccountId, formData, setIsLoading, event);
        },
      )}
      style={{ width: '100%', height: 'auto' }}
    >
      <EpSubsStripeFormSection
        gatewayAccountId={gatewayAccountId}
        register={register}
        setStripe={setStripe}
        onInstanceEnding={onStripeInstance}
        errors={errors}
      />
      <Row style={{ marginLeft: '10px', marginTop: '20px' }}>
        <Form.Check
          type="checkbox"
          name="make_primary"
          ref={register()}
          defaultChecked={true}
        />
        <p>{languageOptions().portal.default.make_default}</p>
      </Row>
      <Row style={{ marginLeft: '10px' }}>
        <Form.Check
          type="checkbox"
          name="apply_to_all_subs"
          ref={register()}
          defaultChecked={true}
        />
        <p>{languageOptions().portal.default.apply_all}</p>
      </Row>

      <Button
        variant="primary"
        type="submit"
        disabled={!stripe}
        style={{ float: 'right', marginRight: '10px', marginTop: '40px' }}
      >
        {languageOptions().portal.add.confirm}
      </Button>
      <EpSubsSpinnerModal isLoading={isLoading} />
    </Form>
  );
};
