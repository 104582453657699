import axios from 'axios';
import { TFilterSource, ESimpleSearchFields } from 'src/js/constants/subs.js';
import {
  IEpSubsDBCustomerValue,
  IEpSubsDBElastic_saved_filtersValue,
  IEpSubsDBInvoiceValue,
  IEpSubsDBSubscriptionValue,
  IFilterFieldsUiParameters,
  TEpSubsDBResponseOk,
  TFilterData,
  TEpSubsSaveFilterRequestData,
} from 'src/models/index.js';
import { hostUrl } from '../config.js';
import { toInitialUpperCase } from '../utilities/stringUtility';
import { getSortSQLStrFromFilterSearchSort } from './epSubsServiceUtils';
import { unixtimeWithoutTime } from 'src/components/formComponents/ESFormDateInput';
import { putNoty } from 'src/utilities/epSubsNoty';

// *********************************************** //
//               Filter UI Services                //
// *********************************************** //
export const genFilterUiParameters = async (): Promise<IFilterFieldsUiParameters> => {
  return axios.get(`${hostUrl}/elastic/layout`).then(response => {
    return response.data.data;
  });
};

export const genSavedFilterList = async (
  source: TFilterSource,
): Promise<IEpSubsDBElastic_saved_filtersValue[]> => {
  return axios.get(`${hostUrl}/${source}s/filters`).then(response => {
    return response.data;
  });
};

// *********************************************** //
//             Filtered Search Services            //
// *********************************************** //
export async function genFilteredSearchData<
  TRecord extends
    | IEpSubsDBCustomerValue
    | IEpSubsDBSubscriptionValue
    | IEpSubsDBInvoiceValue,
>(source: TFilterSource, filterData: TFilterData): Promise<TEpSubsDBResponseOk<TRecord>> {
  return axios
    .post(`${hostUrl}/${source}s/filtered-search`, {
      filters: filterData.filters.map(val => {
        return {
          field: val.field,
          operator: val.operator,
          value: val.value,
          value2: val.value2,
          values_set: val.values_set,
        };
      }),
      sort_field: filterData.sort_field,
      page_index: filterData.page_index,
      per_page: filterData.per_page,
      keyword: filterData.keyword,
      element: filterData.element,
    })
    .then(response => {
      return response.data;
    });
}

export async function genSavedFilteredSearchData<
  TRecord extends
    | IEpSubsDBCustomerValue
    | IEpSubsDBSubscriptionValue
    | IEpSubsDBInvoiceValue,
>(
  source: TFilterSource,
  filterId: number,
  filterMetaData: Omit<TFilterData, 'filters'>,
): Promise<TEpSubsDBResponseOk<TRecord>> {
  return axios
    .post(`${hostUrl}/${source}s/filtered-search/${filterId}`, filterMetaData)
    .then(response => {
      return response.data;
    });
}

// *********************************************** //
//             Filtered Export Services            //
// *********************************************** //
const DETAIL_EXPORT_LIST = ['subscription'];
export function genExportFilteredSearchData(
  source: TFilterSource,
  filterData: Omit<TFilterData, 'page_index' | 'per_page'>,
): Promise<string> {
  const sort = getSortSQLStrFromFilterSearchSort(filterData.sort_field);
  const apiActionSuffix = DETAIL_EXPORT_LIST.includes(source) ? '-detail' : '';
  putNoty({ type: 'success', text: 'File is downloading' });
  return axios
    .post(
      `${hostUrl}/${source}s/filtered-export${apiActionSuffix}?sort=${sort}`,
      {
        filters: filterData.filters,
        element: filterData.element,
        keyword: filterData.keyword,
      },
      {
        responseType: 'blob',
      },
    )
    .then(blob => {
      const fileName = [
        `${toInitialUpperCase(source)}s`,
        `${unixtimeWithoutTime(Date.now())}.csv`,
      ].join('-');
      const url = window.URL.createObjectURL(blob.data);
      const anchorElement = document.createElement('a');
      anchorElement.href = url;
      anchorElement.download = `${fileName}`;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      window.URL.revokeObjectURL(url);
      return fileName;
    });
}

export async function genExportSavedFilteredSearchData({
  source,
  filterId,
  filterMetaData,
  keyword,
  element,
}: {
  source: TFilterSource;
  filterId: number;
  // This is the special choice by BE,
  // not in the same style as the other use cases of filterMetaData
  filterMetaData: Pick<TFilterData, 'sort_field'>;
  keyword: string;
  element: ESimpleSearchFields;
}): Promise<string> {
  const sort = getSortSQLStrFromFilterSearchSort(filterMetaData.sort_field);
  const apiActionSuffix = DETAIL_EXPORT_LIST.includes(source) ? '-detail' : '';
  return axios
    .get(
      `${hostUrl}/${source}s/filtered-export${apiActionSuffix}?` +
        [
          `id=${filterId}`,
          `sort=${sort}`,
          `keyword=${keyword}`,
          `element=${element}`,
        ].join('&'),
      {
        responseType: 'blob',
      },
    )
    .then(blob => {
      const fileName = [
        `${toInitialUpperCase(source)}s`,
        `${unixtimeWithoutTime(Date.now())}.csv`,
      ].join('-');
      const url = window.URL.createObjectURL(blob.data);
      const anchorElement = document.createElement('a');
      anchorElement.href = url;
      anchorElement.download = `${fileName}`;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      window.URL.revokeObjectURL(url);
      return fileName;
    });
}

// *********************************************** //
//           Filter Management Services            //
// *********************************************** //
export type TSaveFilterFailedResponse = {
  Internal: boolean;
  Code: string;
  Data: null;
  Message: string;
};
export async function genSaveFilter(
  source: TFilterSource,
  data: TEpSubsSaveFilterRequestData,
  confirm?: boolean,
): Promise<number | TSaveFilterFailedResponse> {
  const newData = {
    ...data,
    group_name: source,
  };
  return axios
    .post(`${hostUrl}/${source}s/save-filter${confirm ? '?confirm=1' : ''}`, newData)
    .then(response => {
      return response.data;
    });
}

export async function genRemoveFilter(filterId: number): Promise<boolean> {
  return axios.post(`${hostUrl}/elastic/remove-filter/${filterId}`).then(() => {
    return true;
  });
}
