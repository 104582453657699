import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import { IEpSubsCompositeDBSubscriptionValue } from '../../models/';

export const FETCH_SUBSCRIPTION_DATA = 'FETCH_SUBSCRIPTION_DATA';
export const FETCH_SUBSCRIPTION_DATA_SUCCESS = 'FETCH_SUBSCRIPTION_DATA_SUCCESS';
export const FETCH_SUBSCRIPTION_DATA_FAILURE = 'FETCH_SUBSCRIPTION_DATA_FAILURE';
export const SAVE_SUBSCRIPTION_DATA_SUCCESS = 'SAVE_SUBSCRIPTION_DATA_SUCCESS';
export const SAVE_LATEST_SUBSCRIPTION_SEARCH_PARAM =
  'SAVE_LATEST_SUBSCRIPTION_SEARCH_PARAM';
export const ARCHIVE_SUBSCRIPTION = 'ARCHIVE_SUBSCRIPTION';
export const ARCHIVE_SUBSCRIPTION_FAILURE = 'ARCHIVE_SUBSCRIPTION_FAILURE';

export type IFetchSubscriptionDataAction = {
  type: typeof FETCH_SUBSCRIPTION_DATA;
  id: number;
  refresh?: boolean;
};

export type IFetchSubscriptionDataSuccessAction = {
  type: typeof FETCH_SUBSCRIPTION_DATA_SUCCESS;
  id: number;
  subscriptionData: IEpSubsCompositeDBSubscriptionValue;
};

export type ISaveLatestSubscriptionSearchParamAction = {
  type: typeof SAVE_LATEST_SUBSCRIPTION_SEARCH_PARAM;
  loc: TLocationRecord;
};

export type IArchiveSubscriptionAction = {
  type: typeof ARCHIVE_SUBSCRIPTION;
  id: number;
  archive?: boolean;
};

export type epSubsSubscriptionActionTypes =
  | IFetchSubscriptionDataAction
  | IFetchSubscriptionDataSuccessAction
  | ISaveLatestSubscriptionSearchParamAction;
