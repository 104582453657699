/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers, Reducer } from '@reduxjs/toolkit';

import { InjectedReducersType } from '../types/injector-typings';
import epSubsPlanReducer from './plan/epSubsPlanReducer';
import epSubsCustomerReducer from './customer/epSubsCustomerReducer';
import epSubsSubscriptionReducer from './subscription/epSubsSubscriptionReducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(): Reducer {
  const injectedReducers: InjectedReducersType = {
    plans: epSubsPlanReducer,
    customers: epSubsCustomerReducer,
    subscriptions: epSubsSubscriptionReducer,
  };

  return combineReducers(injectedReducers);
}
