import { put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga/macro';
import { SagaIterator } from '@redux-saga/core';
import { IEpSubsCompositeDBPlanValue } from '../../models/';
import {
  epSubsPlanActionTypes,
  FETCH_PLAN_DATA,
  FETCH_PLAN_DATA_SUCCESS,
  FETCH_PLAN_DATA_FAILURE,
  IFetchPlanDataAction,
  ARCHIVE_PLAN,
  IArchivePlanAction,
  ARCHIVE_PLAN_FAILURE,
} from './epSubsPlanTypes';
import {
  genArchivePlan,
  genPlanData,
  // genSamplePlanData
} from '../../services/epSubsPlanService';
import { RootState, TPlanDataMap } from '../rootState';
import { putNoty } from '../../utilities/epSubsNoty';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//                watchFetchPlan Saga              //
// *********************************************** //

export function fetchPlanDataSuccess(
  id: number,
  planData: IEpSubsCompositeDBPlanValue,
): epSubsPlanActionTypes {
  return {
    type: FETCH_PLAN_DATA_SUCCESS,
    id,
    planData,
  };
}

export const getPlanList = (state: RootState): TPlanDataMap => state.plans.planList;

function* getPlanAsync(action: IFetchPlanDataAction): SagaIterator {
  const planList = yield* select(getPlanList);
  const planData = planList.get(action.id);
  DEBUGGING &&
    console.log('Saga: FetchPlanById: ', planData, action.id, action.refresh, planList);
  if (planData === null || planData === undefined || action.refresh) {
    try {
      DEBUGGING &&
        console.log('Saga: ReFetchPlanById: ', action.id, action.refresh, planList);
      // *** Use genSamplePlanData for offline test, use genPlanData for db server *** //
      const planData = yield* call(genPlanData, action.id);
      if (planData !== null) {
        yield put(fetchPlanDataSuccess(action.id, planData));
      }
    } catch (error) {
      // Dispatch a fetch failure action. What to do?
      yield put({ type: FETCH_PLAN_DATA_FAILURE, error });
    }
  }
}

export function* watchFetchPlan(): SagaIterator {
  // Need to use takeEvery instead of takeLatest, otherwise the fetch process
  // are always interrupted by later fetch action and causing an unnecessary delay
  yield takeEvery(FETCH_PLAN_DATA, getPlanAsync);
}

// // *********************************************** //
// //          watchSetCurrentPlan Saga               //
// // *********************************************** //

// export function setCurrentPlanSuccess(id: number): epSubsPlanActionTypes {
//   return {
//     type: SET_CURRENT_PLAN_SUCCESS,
//     id
//   };
// }

// function* setCurrentPlanAsync(action: ISetCurrentPlanAction): SagaIterator {
//   try{
//     yield* call(getPlanAsync, {type: FETCH_PLAN_DATA, id: action.id})
//     yield put(setCurrentPlanSuccess(action.id))
//   } catch(error){
//     // Dispatch a set failure action. What to do? Retry?
//     yield put({ type: SET_CURRENT_PLAN_FAILURE, error })
//   }
// }

// export function* watchSetCurrentPlan(): SagaIterator {
//   yield takeLatest(SET_CURRENT_PLAN, setCurrentPlanAsync);
// }

// *********************************************** //
//            watchArchivePlan Saga                //
// *********************************************** //

function* archivePlanAsync(action: IArchivePlanAction): SagaIterator {
  try {
    const archiveSuccess = yield* call(genArchivePlan, action.id, action.archive);
    if (archiveSuccess) {
      // Update the plan detail in redux store
      yield put({ type: FETCH_PLAN_DATA, id: action.id, refresh: true });
      const message = `Successfully ${
        action.archive ? 'archived' : 'activated'
      } plan with id ${action.id}.`;
      yield* call(putNoty, { type: 'success', text: message });
    }
  } catch (error) {
    // Dispatch a archive failure action. What to do? Retry?
    yield put({ type: ARCHIVE_PLAN_FAILURE, error });
    yield* call(putNoty, { type: 'error', text: (error as Error).message });
  }
}

export function* watchArchivePlan(): SagaIterator {
  yield takeEvery(ARCHIVE_PLAN, archivePlanAsync);
}
