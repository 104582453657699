import { useRecoilCallback } from 'recoil';
import {
  currentOfferActivitiesSearchParamAtom,
  // currentAddonActivitiesSearchParamAtom,
  // currentAddonCommentsSearchParamAtom,
  offerRefreshRequestIDAtoms,
  offerSearchRequestIDAtom,
} from '..';
import { DEFAULT_ACTIVITY_COUNT } from 'src/js/constants/subs';

// *********************************************** //
//                Offer List Page Hooks             //
// *********************************************** //

export const useRefreshOfferList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh offer list
        set(offerSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshOffer = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        // refresh offer
        set(offerRefreshRequestIDAtoms(id), requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshCurrentOfferActivities = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ACTIVITY_COUNT) => {
        set(currentOfferActivitiesSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};
