import * as H from 'history';

import {
  TEpSubsDBCustomerValueKeys,
  TEpSubsDBEmail_logValueKeys,
  TEpSubsDBInvoiceValueKeys,
  TEpSubsDBPlanValueKeys,
  TEpSubsDBSubscriptionValueKeys,
  TFilterEntry,
} from 'src/models';

import {
  getParamsFromSearch,
  TLocationRecord,
} from 'src/components/tables/EpFullFeaturedTable';

import { RouteComponentProps } from 'react-router-dom';
import { DEBUGGING } from 'src/config';
import { TSimpleFilterSelectOption } from 'src/components/tables/EpFullFeaturedTable/Filters/EpSubsSimpleFilterSelector';
import { TFilterSelectOption } from 'src/components/tables/EpFullFeaturedTable/Filters/EpSubsSavedFilterSelector';
import { putNoty } from 'src/utilities/epSubsNoty';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import { compareJsonData } from 'src/utilities/commonUtils';
import { FilterAccessLevel } from 'src/js/constants/subs';
import { SetterOrUpdater } from 'recoil';

export const UNTITLED_FILTER_TITLE = 'Untitled Customized Filter';

// *********************************************** //
//                Core Filter Util                 //
// *********************************************** //
/**
 * @returns the parsed the filterData from JSON string of given filterOption
 */
export const getFilterData = (filterOption: TFilterSelectOption): TFilterEntry[] => {
  const filterJsonData = filterOption.filterJson;
  if (!filterJsonData) {
    const message =
      'Failed to get FilterData due to missing filterJsonData in option object!';
    putNoty({ type: 'error', text: message });
    return [];
  }
  try {
    const parsedFilterData = JSON.parse(filterJsonData);
    return parsedFilterData;
  } catch (e) {
    const message = `Failed to parse filterData from filterJson ${filterJsonData}. ${
      (e as Error).message
    }`;
    putNoty({ type: 'error', text: message });
    return [];
  }
};

/**
 * @returns true if they are equivalent
 */
export const compareFilterJson = (
  filterJson1: string,
  filterJson2: string,
  caseSensitive = true,
): boolean => {
  if (filterJson1 === '' || filterJson2 === '') return false;
  try {
    const parsedJson1 = JSON.parse(filterJson1);
    const parsedJson2 = JSON.parse(filterJson2);
    return compareJsonData<TFilterEntry[]>(parsedJson1, parsedJson2, caseSensitive);
  } catch (e) {
    const message = `Failed to compare the json string ${filterJson1} vs ${filterJson2}!\n ${
      (e as Error).message
    }`;
    putNoty({ type: 'error', text: message });
    return false;
  }
};

/**
 * @param filterJsonStr stringified filter Json
 * @returns The Default search param with given filterJsonStr
 */
export const getDefaultFilterSearchParam = (filterJsonStr?: string): string => {
  return [
    `?filter=${encodeURIComponent(filterJsonStr ?? '')}`,
    `simpleQuery=`,
    `simpleQueryFieldKey=all`,
    `filterId=0`,
    `useFilteredSearch=true`,
    `useSavedFilter=false`,
  ].join('&');
};

// *********************************************** //
//                Simple Filter Util               //
// *********************************************** //
/**
 * @returns the parsed simpleFilterData from JSON string of given filterStr from routeParams
 */
export const getSimpleFilterData = <TFilterData extends { [key: string]: string[] }>(
  filterJsonData: string,
): TFilterData => {
  if (filterJsonData === '' || !filterJsonData) {
    // const message =
    //   'Failed to get FilterData due to missing filterJsonData in option object!';
    // putNoty({ type: 'error', text: message });
    return {} as TFilterData;
  }
  try {
    const parsedFilterData = JSON.parse(filterJsonData);
    if (!(parsedFilterData instanceof Object) || parsedFilterData instanceof Array) {
      throw new Error('The parsed data is not a string key object.');
    }
    return parsedFilterData;
  } catch (e) {
    const message =
      `Failed to parse simpleFilterData from filterStr ${filterJsonData}. ` +
      `${(e as Error).message}`;
    putNoty({ type: 'error', text: message });
    return {} as TFilterData;
  }
};

/**
 * Initial Status and URL control for simple filter supported search Lists. Need to check
 * - 1 If the search parameter in routerProps is empty, redirect to url with the default filters
 * - 2 Check whether the filter matches any provided filters.
 *   - If match, setSourceSearchParam to call API
 *   - If does not match, redirect to url with the corresponding default filter .
 */
export const getVerifiedSimpleFilterOptionAndUpdateData = ({
  routerProps,
  allSimplyFilterOptions,
  defaultSimplyFilterOption,
  history,
  setSourceSearchParam,
}: {
  routerProps: RouteComponentProps;
  allSimplyFilterOptions: { [key: string]: TSimpleFilterSelectOption[] };
  defaultSimplyFilterOption: { [key: string]: string[] };
  history: H.History<unknown>;
  setSourceSearchParam: SetterOrUpdater<TLocationRecord>;
}): { [key: string]: string[] } => {
  const routeParams = getParamsFromSearch<
    TEpSubsDBPlanValueKeys | TEpSubsDBEmail_logValueKeys
  >(routerProps.location.search as H.Search);
  const { order, sort, page, perPage, simpleQuery } = routeParams;
  const filter = decodeURIComponent(routeParams.filter);

  // Step 1: Handle default behavior for empty search path
  if (routerProps.location.search === '') {
    const loc = {
      search: `?filter=${encodeURIComponent(
        JSON.stringify(defaultSimplyFilterOption),
      )}&simpleQuery=&simpleQueryFieldKey=all`,
      ready: true,
    };
    history.push(loc);
    setSourceSearchParam(curLoc => {
      return { ...curLoc, ...loc };
    });
    return defaultSimplyFilterOption;
  }

  // Step 2: Check if the search path has a match with options for each filterKey
  let pathChangeNeeded = false;
  const filterData = getSimpleFilterData(filter);
  const filterMatchedOptions: [string, string[]][] = [];
  DEBUGGING &&
    console.log('Simple Filter, Check matched simple filter: input', filter, filterData);
  Object.keys(allSimplyFilterOptions).map(filterKey => {
    filterMatchedOptions.push([
      filterKey,
      (() => {
        const filterValueList = filterData[filterKey];

        // For multi-selection filter with list value, if list is empty,
        // no need to do further match to recognize values.
        if (filterValueList.length === 0) {
          return [];
        }

        // Try to do match to recognize values
        const matchedOptions = allSimplyFilterOptions[filterKey].filter(option => {
          const filterValuesMatchedWithOption = filterValueList.filter(
            value => option.value.toLowerCase() === value.toLowerCase(),
          );
          return filterValuesMatchedWithOption.length > 0;
        });
        if (matchedOptions.length === 1) return [matchedOptions[0].value];
        else if (matchedOptions.length > 1) {
          // Merge the multi-selected options together
          return matchedOptions.map(option => option.value);
        }
        // PathChange Needed for noMatch search path of at least one filterKey
        pathChangeNeeded = true;

        // Return default filter value if the value cannot be recognized.
        return defaultSimplyFilterOption[filterKey];
      })(),
    ]);
  });
  const finalFilterData = Object.fromEntries(new Map(filterMatchedOptions));

  DEBUGGING &&
    console.log(
      'Simple Filter, Check matched simple filter: optionMatchCheck',
      pathChangeNeeded,
      filterMatchedOptions,
      filter,
    );

  // Step 3: Update sourceSearchParam and do pathChange if needed and return the final Filter Data
  const loc = {
    search: [
      `?filter=${encodeURIComponent(JSON.stringify(finalFilterData))}`,
      `order=${sort !== '' ? order : ''}`,
      `page=${page}`,
      `perPage=${perPage}`,
      `sort=${sort}`,
      `simpleQuery=${simpleQuery}`,
    ].join('&'),
    ready: !pathChangeNeeded,
  };
  pathChangeNeeded && history.push(loc);
  setSourceSearchParam(curLoc => {
    return { ...curLoc, ...loc };
  });

  return finalFilterData;
};

// *********************************************** //
//                General Filter Util              //
// *********************************************** //
/**
 * - 1 If the search parameter in routerProps is empty, redirect to url with default filter
 *   - Need to be matched with basicFilterEnabledSearchListSelectorsFamily
 * - 2 setSourceSearchParam to trigger selector to call API
 * - 3 Check if the filter match any saved filters.
 *   - If match, add the corresponding filterId as the default;
 *   - If does not match, set filterId = 0 and it is a customized filter.
 */
export const getVerifiedFilterOptionAndUpdateData = ({
  routerProps,
  history,
  setSourceSearchParam,
  noFilter,
  savedFilterOptions,
  onRenderingEditedFilter,
}: {
  routerProps: RouteComponentProps;
  history: H.History;
  setSourceSearchParam: SetterOrUpdater<TLocationRecord>;
  noFilter: TFilterSelectOption;
  savedFilterOptions: TFilterSelectOption[];
  onRenderingEditedFilter: () => void;
}): TFilterSelectOption => {
  // Step 0: Handle default behavior for empty search path
  if (routerProps.location.search === '') {
    const loc = {
      search: getDefaultFilterSearchParam(noFilter.filterJson),
      ready: false,
    };
    history.push(loc);
    setSourceSearchParam(curLoc => {
      return { ...curLoc, ...loc };
    });
    return noFilter;
  }

  // Step 1: Read url and update sourceSearchParam
  const loc = { search: routerProps.location.search, ready: true };
  setSourceSearchParam(curLoc => {
    return { ...curLoc, ...loc };
  });
  const searchParam = getParamsFromSearch<
    | TEpSubsDBCustomerValueKeys
    | TEpSubsDBSubscriptionValueKeys
    | TEpSubsDBInvoiceValueKeys
  >(routerProps.location.search as H.Search);
  const { filterId, useFilteredSearch } = searchParam;
  const filter = decodeURIComponent(searchParam.filter);
  if (filter === '' || !useFilteredSearch) return noFilter;

  // Step 2: Find matched filter by filterJson
  const filterMatchedOptions = savedFilterOptions
    .filter(option => compareFilterJson(option.filterJson ?? '', filter, false))
    .filter(val => parseInt(val.value) == filterId);
  DEBUGGING &&
    console.log(
      'Filter, Check matched filter: optionMatchCheck',
      filterMatchedOptions,
      filterId,
      useFilteredSearch,
      filter,
      savedFilterOptions,
    );
  if (filterMatchedOptions.length > 0) {
    if (filterMatchedOptions.length > 1) {
      // Check if Id also match if there is more than one matched
      const idMatchOption = filterMatchedOptions.filter(
        option => parseInt(option.value) === filterId,
      );
      if (idMatchOption.length > 0) return idMatchOption[0];
    }
    // Return the first filterMatchedOption if there is only one matched result or no id match
    //if not matched it will return[]
    return filterMatchedOptions[0];
  }

  // Step 3: If there is no filter match, check if there is a idMatch
  // to determine what filter title to display
  const idMatchOption = savedFilterOptions.filter(
    option => parseInt(option.value) === filterId,
  );
  if (idMatchOption.length > 0) {
    const matchedFilterId = parseInt(idMatchOption[0].value);
    if (idMatchOption.length > 1) {
      putNoty({
        type: 'error',
        text: `Unexpected multiple filterId=${matchedFilterId} match!!!`,
      });
    }
    if (matchedFilterId > 0) {
      onRenderingEditedFilter();
      DEBUGGING && console.log('Filter, Check matched filter: ', matchedFilterId, filter);
      return {
        groupName: idMatchOption[0].groupName,
        label: `${toInitialUpperCase(idMatchOption[0].label)}`,
        value: `${matchedFilterId}`,
        filterJson: filter,
        accessLevel: idMatchOption[0].accessLevel,
        created_by: idMatchOption[0].created_by,
      };
    }
  }

  // Step 4: Return the customized filter value if no match with savedFilters at all
  onRenderingEditedFilter();
  DEBUGGING && console.log('Filter, Check matched filter:', filter);
  return {
    label: UNTITLED_FILTER_TITLE,
    value: '0',
    filterJson: filter,
    accessLevel: parseInt(FilterAccessLevel.PUBLIC),
  };
};

/**
 * @returns The savedFilterOption that matched the filterId of the url one.
 * This is used as an reference to check if we have make an non-trivial change
 * to the filter
 */
export const getIdMatchedSavedFilterOption = ({
  routerProps,
  noFilter,
  savedFilterOptions,
}: {
  routerProps: RouteComponentProps;
  noFilter: TFilterSelectOption;
  savedFilterOptions: TFilterSelectOption[];
}): TFilterSelectOption | null => {
  const { filterId, filter } = getParamsFromSearch<
    | TEpSubsDBCustomerValueKeys
    | TEpSubsDBSubscriptionValueKeys
    | TEpSubsDBInvoiceValueKeys
  >(routerProps.location.search as H.Search);
  if (filter === '' || filterId < 0) return noFilter;

  const idMatchOption = savedFilterOptions.filter(
    option => parseInt(option.value) === filterId,
  );
  if (idMatchOption.length > 0) {
    if (idMatchOption.length > 1) {
      const matchedFilterId = parseInt(idMatchOption[0].value);
      putNoty({
        type: 'error',
        text: `Unexpected multiple filterId=${matchedFilterId} match!!!`,
      });
    }
    return idMatchOption[0];
  }
  putNoty({
    type: 'error',
    text: `Unexpected wrong filterId=${filterId} in url!!!`,
  });
  return null;
};

export const getConfirmRemoveFilterModalContext = (
  filterIdToRemove: number,
  savedFilterOptions: TFilterSelectOption[],
): string => {
  const optionToRemove = savedFilterOptions.find(
    option => option.value === `${filterIdToRemove}`,
  );
  if (optionToRemove) {
    return `Are you sure you want to remove filter ${optionToRemove?.value} (${optionToRemove.label})?`;
  }
  return 'The filter to be removed does not exist! Please refresh you page and try again.';
};

export const HIDE_CREATE_NEW_BUTTON_IN_LIST = undefined;
export const HIDE_EXPORT_BUTTON = undefined;
