import { selector, selectorFamily, waitForAll } from 'recoil';
import * as H from 'history';
import {
  IEpSubsDBProductCompositeValue,
  IEpSubsDBProductValue,
  TEpSubsDBProductValueKeys,
} from '../../models';
import {
  genProductData,
  genProductListData,
  genAllProductListData,
} from '../../services/epSubsProductService';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
} from '../../components/tables/EpFullFeaturedTable';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { CURRENT_ID_ATOM_DEFAULT_VALUE } from '..';
import { reduce } from 'lodash';
import { atom, atomFamily } from 'recoil';
import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//             Product List Page States               //
// *********************************************** //
export const DEFAULT_PRODUCT_SEARCH_PARAMETER = '';

/**
 * An atom that stores the latest product search param
 */
export const latestProductSearchParamAtom = atom<TLocationRecord>({
  key: 'latestProductSearchParamAtom',
  default: { search: DEFAULT_PRODUCT_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for product list
 */
export const productSearchRequestIDAtom = atom<number>({
  key: 'productSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for product list based on current searchParam
 */
export const productBasicListSelector = selector<ListState<IEpSubsDBProductValue>>({
  key: 'productIdListSelector',
  get: async ({ get }) => {
    const requestID = get(productSearchRequestIDAtom); // Add request ID as a dependency
    const productSearchParam = get(latestProductSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, productBasicListSelector productSearchParam',
        productSearchParam,
        'requestID',
        requestID,
      );

    // fetch products
    if (productSearchParam.ready && productSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBProductValueKeys>(
        productSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const offset = (page - 1) * perPage;
      // const filter = decodeURIComponent(routeParams.filter);
      // const filterData = getSimpleFilterData(filter);
      try {
        const response = await genProductListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'created_at', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          // filterData[PRODUCT_STATUS_FILTER_GROUP_FIELD][0],
          // filterData[PRODUCT_FILTER_GROUP_FIELD][0],
        );

        DEBUGGING && console.log('recoil, productBasicListSelector response', response);

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An selector that stores the product detail list based on current searchParam
 */
export const productCompleteListSelector = selector<ListState<IEpSubsDBProductValue>>({
  key: 'productCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(productBasicListSelector);

    if (!idListState.err) {
      const productIds = idListState.data.map(productData => productData.id);
      const productDetailList = get(
        waitForAll(productIds.map(productId => productSelectors(productId))),
      );
      DEBUGGING && console.log('recoil, productCompleteListSelector', productDetailList);

      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: productDetailList ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from productBasicListSelector
      return idListState as ListState<IEpSubsDBProductValue>;
    }
  },
});

/**
 * An atomFamily that stores the refresh Request ID for each product
 */
export const productRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'productRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores product details for each product
 */
export const productSelectors = selectorFamily<IEpSubsDBProductCompositeValue, number>({
  key: 'productSelectors',
  get:
    productId =>
    async ({ get }) => {
      get(productRefreshRequestIDAtoms(productId)); // Add request ID as a dependency
      const productData = await genProductData(productId);
      return productData;
    },
});

// *********************************************** //
//           Product Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected product id
 */
export const currentProductIdAtom = atom<number | null>({
  key: 'currentProductIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected product details
 */
export const currentProductSelector = selector<
  IEpSubsDBProductCompositeValue | undefined
>({
  key: 'currentProductSelector',
  get: ({ get }) => {
    const id = get(currentProductIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(productSelectors(id));
    }
  },
});

/**
 * An atom that stores that stores the refresh Request ID for active product list
 */
export const allProductsRequestIDAtom = atom<number>({
  key: 'allProductsRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the product detail list
 */
export const allProductListSelector = selector<ListState<IEpSubsDBProductCompositeValue>>(
  {
    key: 'allProductListSelector',
    get: async ({ get }) => {
      get(allProductsRequestIDAtom);
      const response = await genAllProductListData();
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: response ?? [],
        total: response.length,
      };
    },
  },
);

/**
 * An selector that stores the map from product_id to product_basic details
 */
export const allProductDataMapSelector = selector<
  Map<string, IEpSubsDBProductCompositeValue>
>({
  key: 'allProductDataMapSelector',
  get: async ({ get }) => {
    // To be replaced with a all product list API
    const productList = get(allProductListSelector).data ?? [];
    return reduce<
      IEpSubsDBProductCompositeValue,
      Map<string, IEpSubsDBProductCompositeValue>
    >(
      productList,
      (productIdMap, productRecord) => {
        productIdMap.set(String(productRecord.id), productRecord);
        return productIdMap;
      },
      new Map(),
    );
  },
});
