import { selector, selectorFamily } from 'recoil';
import {
  getSettingsGroupList,
  getSettingsRules,
} from '../../services/epSubsSettingsService';
import { IEpSubsDBSetting_groupValue, IEpSubsDBSettingValue } from '../../models';
import {
  settingsGroupListRequestIDAtom,
  settingsGroupRequestIDAtoms,
} from './settingsAtoms';

/**
 * An selector that stores settings groups list
 */
export const settingsGroupListSelector = selector<IEpSubsDBSetting_groupValue[]>({
  key: 'settingsGroupListSelector',
  get: async ({ get }) => {
    get(settingsGroupListRequestIDAtom);
    const groupList = await getSettingsGroupList();
    return groupList;
  },
});

/**
 * An selectorFamily that stores group detail for each settings group
 */
export const settingsGroupDetailSelectors = selectorFamily<
  IEpSubsDBSetting_groupValue | undefined,
  string
>({
  key: 'settingsGroupDetailSelectors',
  get:
    groupCode =>
    async ({ get }) => {
      // Add request ID as a dependency
      const groups = get(settingsGroupListSelector);

      const groupDetail = groups.find(group => group.code === groupCode);

      return groupDetail;
    },
});

/**
 * An selectorFamily that stores settings rules for each settings group
 */
export const settingsRuleListSelectors = selectorFamily<IEpSubsDBSettingValue[], string>({
  key: 'settingsRuleListSelectors',
  get:
    groupCode =>
    async ({ get }) => {
      // Add request ID as a dependency
      get(settingsGroupRequestIDAtoms(groupCode));

      const settingsRules = await getSettingsRules(groupCode);
      return settingsRules;
    },
});

export type TSettingsRuleMap = { [code: string]: { value: string; locked: boolean } };
/**
 * An selectorFamily that stores settings rules for each settings for a given settings group
 */
export const settingRuleMapSelectors = selectorFamily<TSettingsRuleMap, string>({
  key: 'settingRuleSelectors',
  get:
    groupCode =>
    async ({ get }) => {
      // Add request ID as a dependency
      const settingsRules = get(settingsRuleListSelectors(groupCode));
      const rulesObj: TSettingsRuleMap = {};
      settingsRules.map(rule => {
        rulesObj[rule.code] = {
          value: rule.value,
          locked: rule.locked,
        };
      });
      return rulesObj;
    },
});
