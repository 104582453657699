import { put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga/macro';
import { SagaIterator } from '@redux-saga/core';
import { IEpSubsCompositeDBCustomerValue } from '../../models/';
import {
  epSubsCustomerActionTypes,
  FETCH_CUSTOMER_DATA,
  FETCH_CUSTOMER_DATA_SUCCESS,
  FETCH_CUSTOMER_DATA_FAILURE,
  IFetchCustomerDataAction,
  // ARCHIVE_CUSTOMER,
  // IArchiveCustomerAction,
  // ARCHIVE_CUSTOMER_FAILURE,
} from './epSubsCustomerTypes';
import {
  // genArchiveCustomer,
  genCustomerData,
  // genSampleCustomerData
} from '../../services/epSubsCustomerService';
import { RootState, TCustomerDataMap } from '../rootState';
import { DEBUGGING } from 'src/config';
// import { putNoty } from '../../utilities/epSubsNoty';

// *********************************************** //
//                watchFetchCustomer Saga              //
// *********************************************** //

export function fetchCustomerDataSuccess(
  id: number,
  customerData: IEpSubsCompositeDBCustomerValue,
): epSubsCustomerActionTypes {
  return {
    type: FETCH_CUSTOMER_DATA_SUCCESS,
    id,
    customerData,
  };
}

export const getCustomerList = (state: RootState): TCustomerDataMap =>
  state.customers.customerList;

function* getCustomerAsync(action: IFetchCustomerDataAction): SagaIterator {
  const customerList = yield* select(getCustomerList);
  const customerData = customerList.get(action.id);
  DEBUGGING &&
    console.log(
      'Saga: FetchCustomerById: ',
      customerData,
      action.id,
      action.refresh,
      customerList,
    );
  if (customerData === null || customerData === undefined || action.refresh) {
    try {
      DEBUGGING &&
        console.log(
          'Saga: ReFetchCustomerById: ',
          action.id,
          action.refresh,
          customerList,
        );
      // *** Use genSampleCustomerData for offline test, use genCustomerData for db server *** //
      const customerData = yield* call(genCustomerData, action.id);
      if (customerData !== null) {
        yield put(fetchCustomerDataSuccess(action.id, customerData));
      }
    } catch (error) {
      // Dispatch a fetch failure action. What to do?
      yield put({ type: FETCH_CUSTOMER_DATA_FAILURE, error });
    }
  }
}

export function* watchFetchCustomer(): SagaIterator {
  // Need to use takeEvery instead of takeLatest, otherwise the fetch process
  // are always interrupted by later fetch action and causing an unnecessary delay
  yield takeEvery(FETCH_CUSTOMER_DATA, getCustomerAsync);
}

// // *********************************************** //
// //          watchSetCurrentCustomer Saga               //
// // *********************************************** //

// export function setCurrentCustomerSuccess(id: number): epSubsCustomerActionTypes {
//   return {
//     type: SET_CURRENT_CUSTOMER_SUCCESS,
//     id
//   };
// }

// function* setCurrentCustomerAsync(action: ISetCurrentCustomerAction): SagaIterator {
//   try{
//     yield* call(getCustomerAsync, {type: FETCH_CUSTOMER_DATA, id: action.id})
//     yield put(setCurrentCustomerSuccess(action.id))
//   } catch(error){
//     // Dispatch a set failure action. What to do? Retry?
//     yield put({ type: SET_CURRENT_CUSTOMER_FAILURE, error })
//   }
// }

// export function* watchSetCurrentCustomer(): SagaIterator {
//   yield takeLatest(SET_CURRENT_CUSTOMER, setCurrentCustomerAsync);
// }

// // *********************************************** //
// //            watchArchiveCustomer Saga                //
// // *********************************************** //

// function* archiveCustomerAsync(action: IArchiveCustomerAction): SagaIterator {
//   try {
//     const archiveSuccess = yield* call(genArchiveCustomer, action.id, action.archive);
//     if (archiveSuccess) {
//       // Update the customer detail in redux store
//       yield put({ type: FETCH_CUSTOMER_DATA, id: action.id, refresh: true });
//       const message = `Successfully ${
//         action.archive ? 'archived' : 'activated'
//       } customer with id ${action.id}.`;
//       yield* call(putNoty, { type: 'success', text: message });
//     }
//   } catch (error) {
//     // Dispatch a archive failure action. What to do? Retry?
//     yield put({ type: ARCHIVE_CUSTOMER_FAILURE, error });
//     yield* call(putNoty, { type: 'error', text: (error as Error).message });
//   }
// }

// export function* watchArchiveCustomer(): SagaIterator {
//   yield takeEvery(ARCHIVE_CUSTOMER, archiveCustomerAsync);
// }
