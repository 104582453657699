export const correctEmailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const correctKeyRegex = new RegExp(/^[A-Za-z0-9][[A-Za-z0-9\-_]*[A-Za-z0-9]$/i);
export const correctKeyRequirements = [
  '1.Only numbers,letters, , "-", "_" are allowed in the key.\n',
  '2.At least two characters are required.\n',
].join('\n');

export const ATRegex = new RegExp(/^[A,T]{2}/);
export const DERegex = new RegExp(/^[D,E]{2}/);
