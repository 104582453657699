import axios from 'axios';
import { sortBy } from 'lodash';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBRoleValue,
  IEpSubsDBRolePost,
  IEpSubsDBPermissionValue,
} from '../models';

export const getRoleList = async (): Promise<IEpSubsDBRoleValue[]> => {
  return axios.get(`${hostUrl}/admin/roles`).then(response => {
    return sortBy(response.data, 'display_order');
  });
};

export const createRole = async (data: IEpSubsDBRolePost): Promise<boolean> => {
  return axios.post(`${hostUrl}/admin/roles`, data).then(() => {
    return true;
  });
};

export const getRolePermissions = async (
  roleId: number,
): Promise<IEpSubsDBPermissionValue[]> => {
  return axios.get(`${hostUrl}/admin/roles/${roleId}/permissions`).then(response => {
    return sortBy(response.data, 'id');
  });
};

export const updateRolePermissions = async (
  roleId: number,
  permissionIdList: number[],
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/admin/roles/${roleId}/permissions`, permissionIdList)
    .then(() => {
      return true;
    });
};

export const getPermissionList = async (): Promise<IEpSubsDBPermissionValue[]> => {
  return axios.get(`${hostUrl}/admin/permissions`).then(response => {
    return sortBy(response.data, 'id');
  });
};
