import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { TEpSubsAddressDuplication } from 'src/models/epSubsCompositeDBTypes';
import { Link } from 'react-router-dom';
import EpSimpleTable, {
  Col as EpCol,
  ListState,
} from '../tables/EpFullFeaturedTable/EpSimpleTable';
import style from './EpSubsAddressCard.module.scss';
import { useRecoilValueLoadable } from 'recoil';
import { allPlanDataMapSelector } from 'src/recoil';
import { IEpSubsCompositeDBPlanValue } from 'src/models';
import { getPlanSelectionLabel } from '../plans/EpSubsPlanSelect/EpSubsPlanSelect';
import { convertToLocalTime } from 'src/models/i18n';
type Props = {
  modalShow: boolean;
  dupeAddresses: TEpSubsAddressDuplication[];
  onClose: () => void;
};

type IRowSchema = TEpSubsAddressDuplication;

export const EpSubsAddressDuplicationModal = ({
  modalShow,
  dupeAddresses,
  onClose,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [planDataMap, setPlanDataMap] = useState<
    Map<string, IEpSubsCompositeDBPlanValue>
  >(new Map());
  const planDataMapLoadable = useRecoilValueLoadable(allPlanDataMapSelector);

  useEffect(() => {
    // console.log('2.0 customerLoadable.state:', customerLoadable.state);
    switch (planDataMapLoadable.state) {
      case 'loading':
        // only show loading once, do not show loading UI when refresh the list
        break;
      case 'hasValue':
        setIsLoading(false);
        setPlanDataMap(planDataMapLoadable.contents);
        break;
      case 'hasError':
        setIsLoading(false);
    }
  }, [planDataMapLoadable.contents]);

  // Table data
  const listState: ListState<IRowSchema> = {
    loading: false,
    err: '',
    data: dupeAddresses,
    total: dupeAddresses.length,
  };

  // Column key names from the IRowSchema
  const columnsToRender: (keyof IRowSchema)[] = [
    'customer_id',
    'subscription_id',
    'plan_id',
    'created_at',
  ];

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20%',
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        style={{ width: '50px', height: '50px' }}
      />
    </div>
  ) : (
    <>
      <Modal show={modalShow} onHide={onClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{'Customer Address Duplication'}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="contained-modal-title-vcenter">
          <div id={'loaded_list_table'} className={style.epsubs_table}>
            <EpSimpleTable<IRowSchema, keyof IRowSchema>
              listState={listState}
              _key={(record?: IRowSchema) => {
                return { keyName: 'id', keyValue: record?.customer_id };
              }}
            >
              {columnsToRender.map(column_id => {
                switch (column_id) {
                  case 'customer_id':
                    return (
                      <EpCol
                        className={style.col_status}
                        key="customer_id"
                        source={(record?: IRowSchema) => {
                          return {
                            keyName: 'customer_id',
                            keyValue: record?.customer_id,
                          };
                        }}
                        label={'Customer'}
                        render={(customer_id: number, record: IRowSchema) => (
                          <div>
                            <Link
                              to={`/customers/${customer_id}/detail`}
                              target="_blank" // Open a new tab after click
                            >{`${record.first_name} ${record.last_name}`}</Link>
                          </div>
                        )}
                      />
                    );
                  case 'subscription_id':
                    return (
                      <EpCol
                        className={`${style.col_period}`}
                        source={(record?: IRowSchema) => {
                          return {
                            keyName: 'subscription_id',
                            keyValue: record?.subscription_id,
                          };
                        }}
                        key="subscription_id"
                        label={'Subscription'}
                        render={(subscription_id: string, record: IRowSchema) => (
                          <div>
                            <Link
                              to={`/subscriptions/${subscription_id}/detail`}
                              target="_blank" // Open a new tab after click
                            >{`${record.subscription_id}`}</Link>
                          </div>
                        )}
                      />
                    );
                  case 'plan_id':
                    return (
                      <EpCol
                        className={`${style.col_status}`}
                        source={(record?: IRowSchema) => {
                          return {
                            keyName: 'plan_id',
                            keyValue: record?.plan_id,
                          };
                        }}
                        key="plan_id"
                        label={'Plan'}
                        render={(plan_id: string, record: IRowSchema) => {
                          const planData = planDataMap.get(record.plan_id);
                          return (
                            <div>
                              <Link
                                to={`/plans/${planData?.id}/detail`}
                                target="_blank" // Open a new tab after click
                              >{`${
                                planData ? getPlanSelectionLabel(planData) : ''
                              }`}</Link>
                            </div>
                          );
                        }}
                      />
                    );
                  case 'created_at':
                    return (
                      <EpCol
                        className={`${style.col_status}`}
                        source={(record?: IRowSchema) => {
                          return {
                            keyName: 'created_at',
                            keyValue: record?.created_at,
                          };
                        }}
                        key="created_at"
                        label={'Create At'}
                        render={(record: IRowSchema) => (
                          <div>{convertToLocalTime(record.created_at)}</div>
                        )}
                      />
                    );
                  default:
                    return (
                      <EpCol
                        source={(record?: IRowSchema) => {
                          return {
                            keyName: column_id,
                            keyValue: record && record[column_id],
                          };
                        }}
                        key={column_id}
                        label={column_id}
                      />
                    );
                }
              })}
            </EpSimpleTable>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EpSubsAddressDuplicationModal;
