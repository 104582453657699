import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import {
  cleanUpInTheEndOfSubmit,
  disableButton,
  reEnableButton,
} from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import { TTokenizeCallback } from '../subscriptions/subscriptionOperations/EpSubsCreateSubscription';
import { genAddPaymentForCustomer } from './services/epSubsPortalPaymentsService';
import './styles.scss';
import { useRefreshPaymentList } from '../../recoil';
import { Col, Modal, Row } from 'react-bootstrap';
import {
  EpSubsPortalAddPaymentForm,
  TPortalPaymentMethod,
} from './EpSubsPortalAddPaymentForm';
import { GATEWAYS } from 'src/models';
import { Stripe } from '@stripe/stripe-js';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm';
import { languageOptions } from 'src/models/i18n';
import {
  genPaypalBillingAgreementID,
  genPaypalExpressCheckoutDetail,
} from '../payPalExpress/paypalExpressService';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { DEBUGGING } from 'src/config';
import { GatewayListDataType } from './EpSubsUpdatePaymentWrapper';
import { genClientSecretForPortal } from 'src/services';
import * as stripeJs from '@stripe/stripe-js';

export type TSepaPaymentFormData = {
  iban: string;
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  country: string;
  line1: string;
  postal_code: string;
  make_primary: boolean;
  apply_to_all_subs: boolean;
};

interface Props {
  defaultGatewayAccountId: number;
  productGatewayList?: GatewayListDataType[];
  token: string;
  hasPayments: boolean;
  customerId: number;
}

const getAllowedPaymentMethods = ({
  productGatewayList,
}: {
  productGatewayList?: GatewayListDataType[];
}): TPortalPaymentMethod[] => {
  const allPaymentTypes = ['creditCard'] as any;
  productGatewayList?.map(val => {
    if (
      val.type == GATEWAYS.PayPalExpress &&
      val.payment_method_types.some(val => val.code == 'paypal_express')
    ) {
      allPaymentTypes.push('payPalExpress');
    }
    if (
      val.type == GATEWAYS.Braintree &&
      val.payment_method_types.some(val => val.code == 'paypal')
    ) {
      allPaymentTypes.push('payPalAccount');
    }
  });
  DEBUGGING && console.log('all payment types:', allPaymentTypes);
  return allPaymentTypes as TPortalPaymentMethod[];
};

const EpSubsPortalAddPaymentModal = ({
  defaultGatewayAccountId,
  productGatewayList,
  token,
  hasPayments,
  customerId,
}: Props) => {
  const [tokenize, setTokenize] = useState<TTokenizeCallback>();
  const [addPaymentModalShow, setAddPaymentModalShow] = useState(false);
  const refreshPaymentList = useRefreshPaymentList();
  const [stripe, setStripe] = useState<Stripe>();
  const [stripeElements, setStripeElements] = useState<stripeJs.StripeElements>();
  const params = new URLSearchParams(window.location.search);
  const [baid, setBAID] = useState<any>();
  const [payPalLoad, setpayPalLoad] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    payPalExpressOnSubmit();
  }, []);
  const currentUrl = window.location.href;
  const payPalExpressOnSubmit = async () => {
    let payPalInfoDetail;
    let BAID;
    if (params.get('token') !== null) {
      BAID = await genPaypalBillingAgreementID(params.get('token') as string, token);
      payPalInfoDetail = await genPaypalExpressCheckoutDetail(
        params.get('token') as string,
        token,
      );
    }

    DEBUGGING && console.log('productGatewayList:', productGatewayList);
    DEBUGGING &&
      console.log(
        'local storage checkout box for paypal express:',
        localStorage.getItem('payPalExpressCheckbox'),
      );
    let paypalExpressData;
    setBAID(BAID);
    setpayPalLoad(payPalInfoDetail);
    if (payPalInfoDetail !== undefined) {
      const payload = JSON.parse(payPalInfoDetail);
      if (payload.ACK[0] === 'Success') {
        setIsLoading(true);
        if (productGatewayList) {
          const payPalExpressID = [] as any;
          productGatewayList.map(val => {
            if (val.type == GATEWAYS.PayPalExpress) {
              payPalExpressID.push(val.id);
            }
          });

          paypalExpressData = {
            nonce: BAID,
            email: payload.EMAIL[0],
            first_name: payload.FIRSTNAME[0],
            last_name: payload.LASTNAME[0],
            payment_type: 'PayPalExpress',
            make_primary:
              localStorage.getItem('payPalExpressCheckbox') == 'false' ? false : true,
            apply_to_all_subs:
              localStorage.getItem('payPalExpressApplyAllSubsCheckbox') == 'false'
                ? false
                : true,
            gateway: payPalExpressID[0],
          };

          const newPaymentMethodId = await genAddPaymentForCustomer(
            token,
            paypalExpressData,
          );
          const message = `Id = ${newPaymentMethodId} ${
            languageOptions().portal.create.success
          }`;
          putNoty({ type: 'success', text: message });
        }
        setIsLoading(false);
      }
      urlRemoveParams();
      localStorage.removeItem('payPalExpressCheckbox');
      localStorage.removeItem('payPalExpressApplyAllSubsCheckbox');
    }
  };

  DEBUGGING && console.log('baid:', baid, payPalLoad);
  const urlRemoveParams = () => {
    if (window.location.href.includes('&token'))
      window.location.href = window.location.href.split('&')[0];
  };
  const onStripeInstance = (stripe: Stripe, stripeElements?: stripeJs.StripeElements) => {
    setStripe(() => stripe);
    setStripeElements(() => stripeElements);
  };

  const onSubmitNewPaymentMethod = async (
    gatewayAccountId: number,
    formData: TNewPaymentFormData,
    setIsLoading: (isLoading: boolean) => void,
    event?: BaseSyntheticEvent<any>,
  ) => {
    event && disableButton(event);
    // The method will be set by the paymentFormSection in gateway API callback
    let data;
    const gatewayPaymentTypeHasStripe = productGatewayList?.map(val => {
      if (
        val.type == GATEWAYS.Stripe &&
        val.payment_method_types.some(val => val.code == 'card')
      ) {
        return true;
      } else {
        return false;
      }
    });

    switch (gatewayPaymentTypeHasStripe?.includes(true)) {
      case false:
        // The method will be set by the paymentFormSection in gateway API callback
        if (!tokenize) {
          putNoty({
            type: 'error',
            text: `${languageOptions().portal.error.braintree.failure}`,
          });
          return cleanUpInTheEndOfSubmit(event);
        }
        setIsLoading(true);
        const paymentFields = event && (await tokenize(event, gatewayAccountId));
        if (!paymentFields) {
          const message = `${languageOptions().portal.error.braintree.failurePayment}`;
          putNoty({ type: 'error', text: message });
          setIsLoading(false);
          return cleanUpInTheEndOfSubmit(event);
        }
        data = {
          ...paymentFields,
          make_primary: formData.make_primary,
          apply_to_all_subs: formData.apply_to_all_subs,
          gateway: gatewayAccountId,
        };
        break;
      case true:
        if (!(stripe && stripeElements)) {
          putNoty({
            type: 'error',
            text: `${languageOptions().portal.error.stripe.failureInitialize}`,
          });
          return cleanUpInTheEndOfSubmit(event);
        }
        setIsLoading(true);
        const { error: submitError } = await stripeElements.submit();

        const clientSecretData = await genClientSecretForPortal(token, {
          gateway_account_id: gatewayAccountId,
          customer: {
            id: customerId,
          },
        });

        const { setupIntent, error } = await stripe.confirmSetup({
          elements: stripeElements,
          clientSecret: clientSecretData.client_secret,
          confirmParams: {
            return_url: `${window.location.href}`,
          },
          redirect: 'if_required',
        });

        if (error || !setupIntent || submitError) {
          const message = `${error?.message} ${
            error?.decline_code ? `(${error?.decline_code})` : ''
          }`;
          error && putNoty({ type: 'error', text: message });
          submitError && putNoty({ type: 'error', text: `${submitError?.message}` });
          setIsLoading(false);
          return cleanUpInTheEndOfSubmit(event);
        }
        if (!setupIntent.id) {
          const message = `${languageOptions().portal.error.paymentMethod}`;
          putNoty({ type: 'error', text: message });
          setIsLoading(false);
          return cleanUpInTheEndOfSubmit(event);
        }
        data = {
          nonce: setupIntent.id,
          gateway: gatewayAccountId,
          make_primary: formData.make_primary,
          apply_to_all_subs: formData.apply_to_all_subs,
          use_payment_element: true,
        };
        break;
      default:
        putNoty({ type: 'error', text: `${languageOptions().portal.error.gateway}` });
        return cleanUpInTheEndOfSubmit(event);
    }
    try {
      setIsLoading(true);
      const paymentsResp = await genAddPaymentForCustomer(token, data, currentUrl);
      if (typeof paymentsResp == 'number') {
        const newPaymentMethodId = paymentsResp;
        setIsLoading(false);
        const message = `Id = ${newPaymentMethodId} ${
          languageOptions().portal.create.success
        }`;
        putNoty({ type: 'success', text: message });
      } else {
        setIsLoading(false);
        window.location.replace(`${paymentsResp.url}`);
      }
      setIsLoading(false);
      event && reEnableButton(event);
      setAddPaymentModalShow(false);
    } catch (e) {
      event && reEnableButton(event);
      setIsLoading(false);
      // putNoty({ type: 'error', text: (e as Error).message });
    } finally {
      refreshPaymentList();
    }
    return cleanUpInTheEndOfSubmit(event);
  };

  const headerName = `${languageOptions().portal.add.header}`;

  const onBraintreeInstance = (tokenize: TTokenizeCallback) => {
    setTokenize(() => tokenize);
  };

  const onClose = () => {
    setAddPaymentModalShow(false);
  };

  return (
    <>
      <Col md={12} className="px-3 mx-3">
        {!hasPayments ? (
          <EpSubsPortalAddPaymentForm
            defaultGatewayAccountId={defaultGatewayAccountId}
            onSubmit={onSubmitNewPaymentMethod}
            onBraintreeInstance={onBraintreeInstance}
            onStripeInstance={onStripeInstance}
            allowedPaymentMethods={getAllowedPaymentMethods({
              productGatewayList,
            })}
            productGatewayList={productGatewayList}
          />
        ) : (
          <Row className="justify-content-center">
            <button
              className="update-payment-add-new-btn"
              onClick={() => {
                setAddPaymentModalShow(true);
              }}
            >
              {languageOptions().portal.buttons.add}
            </button>
          </Row>
        )}
        <Modal
          show={addPaymentModalShow}
          onHide={onClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          contentClassName="px-5 pb-5"
          centered
        >
          <Modal.Header
            closeButton
            className="mt-3 mb-3"
            style={{ borderBottom: 'none' }}
          >
            <Modal.Title id="contained-modal-title-vcenter">{headerName}</Modal.Title>
          </Modal.Header>
          <EpSubsPortalAddPaymentForm
            defaultGatewayAccountId={defaultGatewayAccountId}
            onClosePaypalForm={onClose}
            onSubmit={onSubmitNewPaymentMethod}
            onBraintreeInstance={onBraintreeInstance}
            onStripeInstance={onStripeInstance}
            allowedPaymentMethods={getAllowedPaymentMethods({
              productGatewayList,
            })}
            productGatewayList={productGatewayList}
            // defaultPaymentData={defaultPaymentData}
          />
        </Modal>
      </Col>
      <EpSubsSpinnerModal isLoading={isLoading} />
    </>
  );
};
export default EpSubsPortalAddPaymentModal;
