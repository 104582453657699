import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EpSubsPaymentRow from './EpSubsPaymentRow';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { currentPaymentTokenAtom, paymentListSelector } from '../../recoil';
import EpSubsPortalAddPaymentModal from './EpSubsPortalAddPaymentModal';
import { IEpSubsCustomer_payment_response } from './services/epSubsPortalPaymentsService';
import { DEBUGGING, hostUrl, webUrl } from 'src/config';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';
import { languageOptions, logoDimension } from 'src/models/i18n';
import { genGatewayListDataFromProductKey } from 'src/services';
import { Spinner } from 'react-bootstrap';

interface ParamType {
  token: string;
}

export interface GatewayListDataType {
  id: number;
  name: string;
  payment_method_types: {
    code: string;
    id: number;
    name: string;
  }[];
  type: number;
}

const EpSubsPortalUpdatePaymentWrapper = () => {
  const [payments, setPayments] = useState<IEpSubsCustomer_payment_response>();
  const token = useParams<ParamType>().token;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tokenValid, setTokenValid] = useState<boolean>();
  const params = new URLSearchParams(window.location.search);
  const product_key = params.get('prd') as string;
  const [gatewayListData, setGatewayListData] = useState<GatewayListDataType[]>([]);
  const setCurrentPaymentToken = useSetRecoilState(currentPaymentTokenAtom);
  const getProductData = async () => {
    if (product_key != null) {
      const response = await genGatewayListDataFromProductKey(product_key);
      setGatewayListData(response);
    }
  };

  useEffect(() => {
    getProductData();
    setCurrentPaymentToken(token);
  }, []);
  DEBUGGING && console.log('product data:', gatewayListData);
  const paymentListLoadable = useRecoilValueLoadable(paymentListSelector);
  useEffect(() => {
    switch (paymentListLoadable.state) {
      case 'loading':
        setIsLoading(true);
        break;
      case 'hasValue':
        if (paymentListLoadable.contents) setPayments(paymentListLoadable.contents);
        setIsLoading(false);
        setTokenValid(true);
        break;
      case 'hasError':
        setIsLoading(false);
        setTokenValid(false);
    }
  }, [paymentListLoadable.contents]);
  DEBUGGING &&
    console.log(
      'Portal: Check paymentsList Loading status & its gateway: ',
      payments,
      payments?.gateway,
    );

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20%',
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        style={{ width: '50px', height: '50px' }}
      />
    </div>
  ) : (
    <div className="update-payment-container">
      <div className="update-payment-body">
        <div className="update-payment-body-header">
          {payments?.logo && (
            <div
              className="p-0"
              style={{
                width: `${logoDimension().width}`,
                height: `${logoDimension().height}`,
              }}
            >
              <img src={`${hostUrl}${payments.logo}`} alt="" />
            </div>
          )}
          {tokenValid && (
            <div
              className="update-payment-body-header-text"
              style={{ marginBottom: '30px' }}
            >
              {languageOptions().portal.logo.title}
            </div>
          )}
        </div>
        {tokenValid ? (
          !payments ? (
            <EpSubsSpinnerModal isLoading={true} />
          ) : (
            <>
              {payments.payment_methods.length > 0 && (
                <>
                  <div className="update-payment-body-warning-banner">
                    {languageOptions().portal.logo.subTitle}
                  </div>
                  <ul className="update-payment-body-list-ul">
                    {payments.payment_methods.map((payment, key) => (
                      <EpSubsPaymentRow key={key} token={token} {...payment} />
                    ))}
                  </ul>
                </>
              )}
              <EpSubsPortalAddPaymentModal
                defaultGatewayAccountId={payments.gateway_account}
                productGatewayList={gatewayListData}
                token={token}
                customerId={payments.customer_id}
                hasPayments={
                  !!payments.payment_methods && payments.payment_methods.length > 0
                }
              />
            </>
          )
        ) : (
          <p style={{ padding: '20px', textAlign: 'center', fontSize: '20px' }}>
            {languageOptions().portal.error.link.failure +
              ' ' +
              languageOptions().portal.reminder.contact}
          </p>
        )}

        <a
          style={{
            marginTop: '60px',
            color: '#4a90e2',
            cursor: 'pointer',
            fontFamily: 'Roboto',
          }}
          onClick={() => {
            window.open(`${webUrl}`);
          }}
          className="link_to"
        >
          {languageOptions().portal.link}
        </a>
      </div>
    </div>
  );
};

export default EpSubsPortalUpdatePaymentWrapper;
