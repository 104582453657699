import { useRecoilCallback } from 'recoil';
import { createRole, updateRolePermissions } from '../../services';
import { roleListRequestIDAtom, roleRefreshRequestIDAtoms } from './roleAtoms';
import { IEpSubsDBRolePost } from '../../models';
import { putNoty } from '../../utilities/epSubsNoty';

export const useCreateRole = (): ((data: IEpSubsDBRolePost) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (data: IEpSubsDBRolePost) => {
        try {
          const updateSuccess = await createRole(data);
          if (updateSuccess) {
            const message = `Successfully created Role.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh API Key list
        set(roleListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useUpdateRolePermissions = (): ((
  roleId: number,
  permissionIdList: number[],
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (roleId: number, permissionIdList: number[]) => {
        try {
          const updateSuccess = await updateRolePermissions(roleId, permissionIdList);
          if (updateSuccess) {
            const message = `Successfully updated Role Permissions.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log('error', error);
        }

        // refresh Role list
        set(roleRefreshRequestIDAtoms(roleId), requestID => requestID + 1);
      },
    [],
  );
};
