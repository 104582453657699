import * as React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { ESimpleSearchFields, TTabOptionItem } from 'src/js/constants/subs';
// import { ESGeneralRadioInput } from 'src/components/radioSelect/ESGeneralRadioInput';
import { Col, Row } from 'react-bootstrap';
import EpSubsHorizontalTabNav from 'src/components/tabs/EpSubsHorizontalTabNav';
import { DEBUGGING } from 'src/config';

interface Props<TFieldKeys extends string | typeof ESimpleSearchFields.ALL> {
  onSearch: (
    result: {
      input: string;
      field?: TFieldKeys | typeof ESimpleSearchFields.ALL;
    },
    event?: React.BaseSyntheticEvent,
  ) => void;
  // simple query string
  simpleQuery?: string;
  placeHolder?: string;
  // simple query key
  enableSimpleSearchByField?: boolean;
  simpleQueryFieldKey?: TFieldKeys | typeof ESimpleSearchFields.ALL;
  simpleQueryDefaultFieldKey?: TFieldKeys | typeof ESimpleSearchFields.ALL;
  simpleQueryFieldOptions?: TTabOptionItem<string>[];
}

function SimpleSearchBar<TFieldKeys extends string = ESimpleSearchFields>(
  props: Props<TFieldKeys>,
): JSX.Element {
  const {
    placeHolder = 'Search by ID / Name',
    enableSimpleSearchByField = false,
    simpleQueryFieldKey,
    simpleQueryDefaultFieldKey = ESimpleSearchFields.ALL as TFieldKeys,
    simpleQueryFieldOptions,
  } = props;
  const [isValid, setIsValid] = useState(true);
  const [userInput, setUserInput] = useState(props.simpleQuery ?? '');
  const [chosenField, setChosenField] = useState<
    TFieldKeys | typeof ESimpleSearchFields.ALL
  >();

  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    setUserInput(e.target.value);
    setIsValid(() => String(e.target.value).length <= 200);
  };
  const handleInputFieldKeyChange = (
    field?: TFieldKeys | string,
    event?: React.BaseSyntheticEvent,
  ) => {
    field && setChosenField(field as TFieldKeys);
    field && props.onSearch({ input: userInput, field: field as TFieldKeys }, event);
  };

  const result = { input: userInput, field: chosenField };
  DEBUGGING && console.log('user input value:', userInput);
  const urlString = window.location.search;
  const urlParams = new URLSearchParams(urlString);
  DEBUGGING && console.log('checking on the url params:', urlParams.get('filter'));
  return (
    <Form onSubmit={e => props.onSearch(result, e)}>
      <InputGroup>
        <Col className="pl-0">
          <Row className="mb-3 pl-0 mr-auto flex">
            {/* Dropdown Ui of the SimpleSearchField */}
            {/* {enableSimpleSearchByField && (
              <ESGeneralDropdownInput
                className={'mr-3 rounded'}
                style={{ width: '120px' }}
                controlId="#simpleSearchInputFieldDropdown"
                placeholder={'Select the simple search field'}
                defaultValue={simpleQueryFieldKey ?? simpleQueryDefaultFieldKey}
                options={simpleQueryFieldOptions ?? []}
                onChange={handleInputFieldKeyChange}
                renderProps={{ value: chosenField }}
              />
            )} */}
            <Col style={{ maxWidth: '450px' }}>
              <Row className="ml-0">
                <FormControl
                  id="Ep_simpleSearchInput"
                  placeholder={placeHolder}
                  aria-label="Placeholder"
                  aria-describedby="basic-addon2"
                  className={'mr-3 rounded'}
                  value={userInput}
                  onChange={handleInputChange}
                />
              </Row>
              <Row className="ml-0" style={{ color: 'red' }}>
                {isValid ? '' : 'Cannot be longer than 40 characters.'}
              </Row>
            </Col>
            <Col>
              <Button
                id="Ep_search"
                variant="primary"
                style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
                onClick={e => {
                  if (
                    (userInput == '' &&
                      window.location.toString().includes('customers')) ||
                    (userInput == '' &&
                      window.location.toString().includes('subscriptions')) ||
                    (userInput == '' && window.location.toString().includes('invoices'))
                  ) {
                    const allFilterData = localStorage.getItem('filterData');
                    DEBUGGING &&
                      console.log('check allFilterData values:', allFilterData);
                    const url = new URL(`${window.location}`);
                    const search_params = url.searchParams;
                    search_params.set('filter', `${allFilterData}`);
                    search_params.set('page', `1`);
                    search_params.set('perPage', `10`);
                    search_params.delete('simpleQuery');
                    search_params.delete('simpleQueryFieldKey');
                    search_params.delete('filterId');
                    search_params.delete('useFilteredSearch');
                    search_params.delete('useSavedFilter');
                    url.search = search_params.toString();
                    const new_url = url.toString();
                    window.location.replace(new_url);
                    DEBUGGING && console.log('all filter data:', allFilterData);
                  }
                  props.onSearch(result, e);
                }}
                disabled={!isValid}
              >
                Search
              </Button>
            </Col>
          </Row>
          {/* Radio Group Ui of the SimpleSearchField */}
          {/* {enableSimpleSearchByField && (
            <ESGeneralRadioInput
              radioClassName={'mr-3 rounded'}
              controlId="#simpleSearchInputFieldRadioGroup"
              defaultValue={simpleQueryFieldKey ?? simpleQueryDefaultFieldKey}
              options={simpleQueryFieldOptions ?? []}
              onChange={handleInputFieldKeyChange}
              renderProps={{ value: chosenField }}
            />
          )} */}
          {enableSimpleSearchByField && (
            <EpSubsHorizontalTabNav<TFieldKeys | typeof ESimpleSearchFields.ALL>
              tabs={simpleQueryFieldOptions ?? []}
              defaultKey={simpleQueryFieldKey ?? simpleQueryDefaultFieldKey}
              onSelect={handleInputFieldKeyChange}
              controlId="#simpleSearchInputFieldTabGroup"
              disabled={!isValid}
            />
          )}
        </Col>
      </InputGroup>
    </Form>
  );
}

export default SimpleSearchBar;
