import { selector } from 'recoil';
import { getApikeyList } from '../../services';
import { apikeyListRequestIDAtom } from './apikeyAtoms';
import { IEpSubsDBApikeyValue } from '../../models';

/**
 * An selector that stores API Key list
 */
export const apikeyListSelector = selector<IEpSubsDBApikeyValue[]>({
  key: 'apikeyListSelector',
  get: async ({ get }) => {
    get(apikeyListRequestIDAtom);
    const apikeyList = await getApikeyList();
    return apikeyList;
  },
});
