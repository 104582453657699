import { useRecoilCallback } from 'recoil';
import { emailLogRefreshRequestIDAtoms, emailLogSearchRequestIDAtom } from '..';

// *********************************************** //
//                EmailLog List Page Hooks             //
// *********************************************** //

export const useRefreshEmailLogList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh emailLog list
        set(emailLogSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshEmailLog = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        // refresh emailLog
        set(emailLogRefreshRequestIDAtoms(id), requestID => requestID + 1);
      },
    [],
  );
};
