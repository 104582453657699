import { useEffect, useState } from 'react';
import { RecoilValueReadOnly, useRecoilValueLoadable } from 'recoil';
import { DEBUGGING } from 'src/config';

/**
 * Load a loadable recoil selector value that is not critical for the render component
 * @param selector Recoil selector
 * @param initialValue Initial value of the loaded value before loading is completed, default to null
 */
export const useLoadOptionalRecoilSelector = <TData, TInit = TData | null>(
  selector: RecoilValueReadOnly<TData>,
  initialValue?: TInit,
): TData | TInit => {
  const [data, setData] = useState<TData | TInit>((initialValue ?? null) as TInit);
  const dataLoadable = useRecoilValueLoadable<TData>(selector);

  useEffect(() => {
    if (dataLoadable.state === 'hasValue') {
      setData(dataLoadable.contents);
    }
  }, [dataLoadable.contents]);
  DEBUGGING && console.log(`useLoadOptionalRecoilSelector ${selector.key}`, data);

  return data;
};
