import axios from 'axios';
import { sortBy } from 'lodash';
import { hostUrl } from '../config.js';
import { IEpSubsDBApikeyValue, IEpSubsDBApikeyPost } from '../models';

export const getApikeyList = async (): Promise<IEpSubsDBApikeyValue[]> => {
  return axios.get(`${hostUrl}/apikeys`).then(response => {
    return sortBy(response.data, 'created_at').reverse();
  });
};

export const createApikey = async (data: IEpSubsDBApikeyPost): Promise<boolean> => {
  return axios.post(`${hostUrl}/apikeys`, data).then(() => {
    return true;
  });
};

export const deleteApikey = async (apikeyId: number): Promise<boolean> => {
  return axios.delete(`${hostUrl}/apikeys/${apikeyId}`).then(() => {
    return true;
  });
};

export const deactivateApikey = async (apikeyId: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/apikeys/${apikeyId}/deactivate`, {}).then(() => {
    return true;
  });
};

export const reactivateApikey = async (apikeyId: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/apikeys/${apikeyId}/reactivate`, {}).then(() => {
    return true;
  });
};
