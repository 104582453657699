import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { FILTER_SOURCE, EMAIL_LOG_STATUS } from 'src/js/constants/subs';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
import EpSubsListNav from '../../components/navBars/EpSubsListNav/EpSubsListNav';

const EMAIL_LOG_STATUS_FILTER_GROUP_NAME = 'email_sent_status';
export const EMAIL_LOG_STATUS_FILTER_GROUP_FIELD = 'status';

export const DEFAULT_EMAIL_LOG_SEARCH_OPTION = {
  [EMAIL_LOG_STATUS_FILTER_GROUP_FIELD]: [
    EMAIL_LOG_STATUS.BOUNCED,
    EMAIL_LOG_STATUS.DROPPED,
  ],
};

const EpSubsEmailLogNav = (routerProps: RouteComponentProps): JSX.Element => {
  const history = useHistory();

  return (
    <EpSubsListNav
      key="emailLog"
      {...{
        routerProps,
        history,
        source: FILTER_SOURCE.EMAIL_LOG,
        allSimplyFilterOptions: {
          [EMAIL_LOG_STATUS_FILTER_GROUP_FIELD]: Object.values(EMAIL_LOG_STATUS).map(
            status => {
              return {
                label: toInitialUpperCase(status),
                value: status,
                groupName: EMAIL_LOG_STATUS_FILTER_GROUP_NAME,
                groupField: EMAIL_LOG_STATUS_FILTER_GROUP_FIELD,
              };
            },
          ),
        },
        defaultSimplyFilterOption: DEFAULT_EMAIL_LOG_SEARCH_OPTION,
      }}
    />
  );
};

export default EpSubsEmailLogNav;
