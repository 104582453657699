import { useRecoilCallback } from 'recoil';
import {
  currentCreditNoteCommentsSearchParamAtom,
  creditNoteRefreshRequestIDAtoms,
  creditNoteSearchRequestIDAtom,
  currentCreditNoteActivitySearchParamAtom,
  currentDetailPageInvoiceTabRefreshRequestIDAtoms,
} from '..';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';

// *********************************************** //
//           CreditNote List Page Hooks          //
// *********************************************** //

export const useRefreshCreditNoteList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh creditNote list
        set(creditNoteSearchRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

/**
 * Refresh CreditNote and its activity log.
 * - If associatedCustomerId or associatedSubscriptionId provided,
 * will refresh the invoice tab there as well
 */
export const useRefreshCreditNote = (): ((props: {
  id: number;
  associatedCustomerId?: number;
  associatedSubscriptionId?: number;
}) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async ({
        id,
        associatedCustomerId = null,
        associatedSubscriptionId = null,
      }): Promise<void> => {
        // refresh creditNote
        set(creditNoteRefreshRequestIDAtoms(id), requestID => requestID + 1);

        // refresh Activity log
        set(currentCreditNoteActivitySearchParamAtom, prevParam => {
          return {
            limit: prevParam.limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });

        // refresh memoized customer detail page invoice tab if associated id is given
        if (associatedCustomerId) {
          set(
            currentDetailPageInvoiceTabRefreshRequestIDAtoms('customers'),
            requestID => requestID + 1,
          );
        }

        // refresh memoized subscription detail page invoice tab if associated id is given
        if (associatedSubscriptionId) {
          set(
            currentDetailPageInvoiceTabRefreshRequestIDAtoms('subscriptions'),
            requestID => requestID + 1,
          );
        }
      },
    [],
  );
};

// *********************************************** //
//           CreditNote Detail Page Hooks             //
// *********************************************** //

export const useRefreshCurrentCreditNoteActivity = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_ACTIVITY_COUNT) => {
        set(currentCreditNoteActivitySearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};

export const useRefreshCurrentCreditNoteComments = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      (limit: number = DEFAULT_COMMENT_COUNT) => {
        set(currentCreditNoteCommentsSearchParamAtom, prevParam => {
          return {
            limit: limit,
            requestId: prevParam.requestId + 1, // for refresh query
          };
        });
      },
    [],
  );
};
