import React, { useCallback, useEffect, useState } from 'react';
import { DeepMap, FieldError, UnpackNestedValue } from 'react-hook-form';
import {
  ELocale,
  IEpSubsDBCustomer_addressValue,
  INewAddressFormData,
  TEpSubsAddressDuplication,
} from '../../models';
import {
  ESFormSimpleDropdownField,
  ESFormSimpleField,
  TUseFormSetValue,
} from '../formComponents/EpSubsFormComponents';
import useScript from '../../utilities/useScript';
import { DEBUGGING, LOCALE, gmapsApiKey } from '../../config.js';
import EpSubsVerifyAddressModal, {
  TAddressVerificationChoice,
} from './EpSubsVerifyAddressModal';
import style from './EpSubsAddressCard.module.scss';
import { useRecoilValueLoadable, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  currentInputAddressAtoms,
  currentUspsAddressSelectors,
  TAddressSectionSource,
} from 'src/recoil';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { isObjectDataSame } from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import {
  genAllCountryRuleData,
  genCheckDuplicatedAddress,
  genCountryAddressRuleData,
  genCountryStateData,
} from 'src/services';
import EpSubsAddressDuplicationModal from './EpSubsAddressDuplicationModal';
import { EpIcon } from 'src/assets';
import EpSubsFormInvisibleInput from '../formComponents/EpSubsFormInvisibleInput';
import {
  getAddressLabel,
  getCountryCode,
  getEnableAddressVerification,
  getRequiredZipDigits,
  languageOptions,
} from 'src/models/i18n';

export type IPureAddressData = Omit<
  INewAddressFormData,
  | 'first_name'
  | 'last_name'
  | 'company'
  | 'validation_status'
  | 'customer_id'
  | 'subscription_id'
  | 'start_at'
  | 'end_at'
>;
export type INewPureAddressData = Required<IPureAddressData>;
export type IExistingAddressFormData = Pick<IEpSubsDBCustomer_addressValue, 'id'>;
export type IAddressFormData = IExistingAddressFormData | INewAddressFormData;
export type IRawUSPSAddress = Omit<INewAddressFormData, 'country'> & {
  country?: string | null;
};

export type ICountryRuleData = {
  address1?: number;
  address2?: number;
  city?: number;
  company?: number;
  country?: number;
  state_code?: number;
  zip?: number;
  zip_format?: string;
};

export type ICountryStateData = {
  id: number;
  code: string;
  name: string;
  country: string;
}[];

export type IAllCountryRuleData = {
  id: number;
  code: string;
  name: string;
  phone: number;
  symbol: string;
  continent_code: {
    String: string;
    Valid: boolean;
  };
  currency: {
    String: string;
    Valid: true;
  };
  address_rule_json: {
    String: string;
    Valid: boolean;
  };
  active: number;
  created_at: string;
};

export const DEFAULT_COUNTRY = 'US';
const ADDRESS_SECTION_SOURCES: TAddressSectionSource[] = ['shipping', 'billing'];

const verifyAddressSectionSource = (source: string): TAddressSectionSource | '' => {
  return (
    ADDRESS_SECTION_SOURCES.filter(
      sourceItem => sourceItem === source || source.match(sourceItem),
    )[0] ?? ''
  );
};

// AddressFormSection Interface
export interface IAddressFormSectionProps {
  defaultAddressData: INewAddressFormData;
  namePath?: string;
  errors: DeepMap<INewAddressFormData, FieldError>;
  register: any;
  control: any;
  setValue: TUseFormSetValue<any>;
  watch: UnpackNestedValue<INewAddressFormData>;
  validationStatus?: boolean;
  customerLocale: ELocale;
}

// Google Interface
export interface IGoogleSuggestedAddress {
  street_number: TAddressComponentType;
  route: TAddressComponentType;
  sublocality_level_1: TAddressComponentType;
  locality: TAddressComponentType;
  administrative_area_level_1: TAddressComponentType;
  postal_code: TAddressComponentType;
}

type TAddressComponentType = keyof Pick<
  google.maps.GeocoderAddressComponent,
  'long_name' | 'short_name'
>;

type TGoogleUspsVerificationResult = 'failure' | 'success' | 'not_exact_match';

const compareGoogleUspsAddress = (
  googleAddress: IPureAddressData,
  uspsAddress?: IPureAddressData | Error | null,
): TGoogleUspsVerificationResult => {
  DEBUGGING &&
    console.log(
      'Check Address Verification: ',
      'USPS: ',
      uspsAddress,
      'Google',
      googleAddress,
    );
  if (!uspsAddress || uspsAddress instanceof Error) {
    return 'failure';
  } else {
    const match = isObjectDataSame<IPureAddressData>(googleAddress, uspsAddress);
    if (match) {
      return 'success';
    } else {
      return 'not_exact_match';
    }
  }
};

const isValidGoogleAddress = (googleAddress: IPureAddressData): boolean => {
  const { address1, state_code, city } = googleAddress;
  return address1 !== '' && state_code !== '' && city !== '';
};

const formatUspsAddress = (rawUspsAddress: IRawUSPSAddress): IPureAddressData => {
  const address2 = rawUspsAddress.address2 ? rawUspsAddress.address2 : '';
  return { ...rawUspsAddress, country: DEFAULT_COUNTRY, address2 };
};

const addressDuplicationChecking = async ({
  addressData,
  onDupeFound,
  onNoDupeFound,
}: {
  addressData: IPureAddressData;
  onDupeFound: (data: TEpSubsAddressDuplication[]) => void;
  onNoDupeFound: () => void;
}) => {
  try {
    const response = await genCheckDuplicatedAddress(addressData);
    let message: string;
    switch (response.type) {
      case 'no_dupe':
        onNoDupeFound();
        message = 'No other subscriptions are using this address as shipping address';
        putNoty({ type: 'success', text: message });
        break;
      case 'dupe':
        onDupeFound(response.data);
        message = 'This address has been used as shipping address, please check!';
        putNoty({ type: 'error', text: message });
        break;
    }
  } catch (e) {
    putNoty({ type: 'error', text: (e as Error).message });
  }
  return false;
};

const setChosenAddress = ({
  addressChoice,
  verifiedAddress,
  setValue,
  namePath,
  setShowAddressModal,
}: {
  addressChoice: TAddressVerificationChoice;
  verifiedAddress?: IPureAddressData | Error | null;
  setValue: TUseFormSetValue<any>;
  namePath: string;
  setShowAddressModal: React.Dispatch<React.SetStateAction<boolean>>;
}): void => {
  if (
    addressChoice === 'verified' &&
    verifiedAddress &&
    !(verifiedAddress instanceof Error)
  ) {
    setValue(
      `${namePath}address1`,
      `${verifiedAddress.address1} ${verifiedAddress.address2 ?? ''}`.trim(),
    );
    setValue(`${namePath}address2`, '');
    setValue(`${namePath}city`, verifiedAddress.city);
    setValue(`${namePath}state_code`, verifiedAddress.state_code);
    setValue(`${namePath}zip`, verifiedAddress.zip);
    setValue(`${namePath}validation_status`, true);
  } else {
    setValue(`${namePath}validation_status`, false);
  }

  setShowAddressModal(false);
};
/**
 *
 * @summary Address Verification process:
 * 1. After inputting the address, a google API driven type ahead will show up;
 * 2. After select the google auto-filled address, the USPS address verification API will be called for it.
 * 3. Case A: If the google auto-filled address is the same as the usps verified address,
 *    the address is verified,
 * 4. Case B: If they did not match exactly or the usps API failed to get an effective address,
 *    the user needs to choose either the google auto-filled or usps verified address.
 *    If we choose the google version, the address will be left unverified and unblock the user from submission.
 * 5. If user edit the address again, the user will have to verify address
 *    using the verify address button at the bottom of the address section.
 */
export const EpSubsAddressFormSubSection = ({
  defaultAddressData,
  namePath = '',
  errors,
  register,
  control,
  watch,
  setValue,
  validationStatus,
}: IAddressFormSectionProps): JSX.Element => {
  const MAX_POSTAL_CODE_LENGTH = getRequiredZipDigits();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const setCurrentInputAddressAtom = useSetRecoilState(
    currentInputAddressAtoms(verifyAddressSectionSource(namePath)),
  );
  const resetCurrentInputAddressAtom = useResetRecoilState(
    currentInputAddressAtoms(verifyAddressSectionSource(namePath)),
  );
  const country = watch ? watch.country : getCountryCode(LOCALE as ELocale);
  const [currentGoogleAddress, setCurrentGoogleAddress] = useState<INewPureAddressData>();
  const [isUspsApiLoading, setIsUspsApiLoading] = useState(false);
  const [verificationProcessSuccess, setVerificationProcessSuccess] = useState(
    defaultAddressData.validation_status,
  );
  const [uspsAddress, setUspsAddress] = useState<
    IPureAddressData | Error | undefined | null
  >();
  const [hasDupeAddress, setHasDupeAddress] = useState(false);
  const [duplicationShow, setDuplicationShow] = useState(false);

  const [dupeAddresses, setDupeAddresses] = useState<TEpSubsAddressDuplication[]>();
  DEBUGGING && console.log(dupeAddresses, 'dupeAddresses');
  DEBUGGING &&
    console.log(
      'Country in address form: ',
      country,
      watch?.country,
      defaultAddressData.country,
    );
  const [errorMessage, setErrorMessage] = useState<string>('Please fill in all fields');
  const [addressRule, setAddressRule] = useState<ICountryRuleData>();
  const [countryState, setCountrySate] = useState<ICountryStateData>();
  const [allCountryOptions, setAllCountryOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >();
  useEffect(() => {
    setValue(`${namePath}validation_status`, validationStatus ?? false);
  }, [namePath, validationStatus, setValue]);

  useEffect(() => {
    const getAllCountryCode = async () => {
      const allCountryData = await genAllCountryRuleData();
      const countryOptions = Object.keys(allCountryData).map(key => {
        const country = allCountryData[key as any];
        return {
          value: `${country.code}`,
          label: String(country.name),
        };
      });
      setAllCountryOptions(countryOptions);
    };
    getAllCountryCode();
  }, []);

  useEffect(() => {
    const getSelectedCountryRuleData = async () => {
      const selectedCountryData = await genCountryAddressRuleData(country);
      setAddressRule(selectedCountryData);
    };
    getSelectedCountryRuleData();
  }, [country]);

  useEffect(() => {
    const getSelectedStateRuleData = async () => {
      try {
        const selectedCountryStateData = await genCountryStateData(country);
        setCountrySate(selectedCountryStateData);
      } catch (error) {
        console.log('error', error);
      }
    };
    getSelectedStateRuleData();
  }, [country]);

  DEBUGGING && console.log('country states list:', countryState);

  const stateOptions = countryState?.map(val => {
    return {
      value: val.code,
      label: val.name,
    };
  });
  DEBUGGING && console.log('selected address rule data:', addressRule);
  const resetValidationProcess = useCallback(() => {
    setHasDupeAddress(false);
    setVerificationProcessSuccess(false);
    setValue(`${namePath}validation_status`, false);
  }, [namePath, setValue]);

  const uspsSuggestedAddressLoadable = useRecoilValueLoadable(
    currentUspsAddressSelectors(verifyAddressSectionSource(namePath)),
  );
  useEffect(() => {
    switch (uspsSuggestedAddressLoadable.state) {
      case 'hasValue':
        // It returns null if the country is not US or there is not input yet
        if (uspsSuggestedAddressLoadable.contents !== null) {
          const formattedUspsAddress = formatUspsAddress(
            uspsSuggestedAddressLoadable.contents as IRawUSPSAddress,
          );
          setUspsAddress(formattedUspsAddress);
          setIsUspsApiLoading(false);
        }
        break;
      case 'hasError':
        setUspsAddress(uspsSuggestedAddressLoadable.contents);
        setIsUspsApiLoading(false);
        break;
      case 'loading':
        setUspsAddress(null);
        break;
    }
  }, [uspsSuggestedAddressLoadable.contents]);

  const initGMaps = async () => {
    const input = document.getElementById(
      `${namePath}formGridAddress1`,
    ) as HTMLInputElement;
    const options = {
      componentRestrictions: { country: DEFAULT_COUNTRY },
      types: ['address'],
    };
    if (window.google) {
      if (LOCALE === ELocale.en_US) {
        const geoAutocomplete = new window.google.maps.places.Autocomplete(
          input,
          options,
        );
        google.maps.event.addListener(
          input,
          'keydown',
          function (event: React.KeyboardEvent<HTMLInputElement>) {
            if (event.keyCode === 13) {
              event.preventDefault();
            }
          },
        );
        geoAutocomplete.addListener('place_changed', () => {
          const selectedPlace = geoAutocomplete.getPlace();
          const componentForm: IGoogleSuggestedAddress = {
            street_number: 'short_name',
            route: 'short_name',
            sublocality_level_1: 'short_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            postal_code: 'short_name',
          };
          // Get each component of the address from the place details
          // and fill the corresponding field on the form.
          const selectedSuggest = new Map<keyof IGoogleSuggestedAddress, string>();
          const addrComponents = selectedPlace?.address_components;

          if (addrComponents) {
            // Step 1: Convert google addrComponents to Address Component Map
            addrComponents.map(addressComponent => {
              const addressType = addressComponent
                .types[0] as keyof IGoogleSuggestedAddress;
              if (componentForm[addressType]) {
                selectedSuggest.set(
                  addressType,
                  addressComponent[componentForm[addressType]],
                );
              }
            });
            // Step 3: Combining part of the input fields with google address Map into the Address Object
            const address1 = [
              selectedSuggest.get('street_number'),
              selectedSuggest.get('route'),
            ]
              .filter(item => !!item)
              .join(' ');

            const city =
              (selectedSuggest.get('locality')
                ? selectedSuggest.get('locality')
                : selectedSuggest.get('sublocality_level_1')) ?? '';
            const state_code = selectedSuggest.get('administrative_area_level_1') ?? '';
            const zip = selectedSuggest.get('postal_code') ?? '';
            const address2Element = document.getElementById(
              `${namePath}formGridAddress2`,
            ) as HTMLInputElement;
            const countryElement = document.getElementById(
              `${namePath}formGridCountry`,
            ) as HTMLInputElement;
            const address2 = address2Element.value;
            const country = !countryElement.value
              ? DEFAULT_COUNTRY
              : countryElement.value;
            const googleAddress = { address1, address2, city, state_code, zip, country };

            // Step 4: Trigger USPS API to verify
            if (isValidGoogleAddress(googleAddress)) {
              // Must put setIsUspsApiLoading here before setCurrentGoogleAddress,
              // since setCurrentGoogleAddress with immediately trigger useEffect
              // that depends on currentGoogleAddress.
              setIsUspsApiLoading(true);
              setCurrentGoogleAddress(googleAddress);
              // Recoil will call USPS API
              resetCurrentInputAddressAtom();
              setCurrentInputAddressAtom({
                address: googleAddress,
              });
              // Set valid Google Address to form input fields
              setValue(`${namePath}address1`, `${address1} ${address2 ?? ''}`.trim());
              setValue(`${namePath}address2`, '');
              setValue(`${namePath}city`, city);
              setValue(`${namePath}country`, country);
              setValue(`${namePath}state_code`, state_code);
              setValue(`${namePath}zip`, zip);
              DEBUGGING &&
                console.log(
                  'Check Address Subsection, set Google Address: ',
                  'watch',
                  watch,
                  'googleAddress',
                  googleAddress,
                  'uspsAddress',
                  uspsAddress,
                  uspsSuggestedAddressLoadable.contents,
                  zip,
                );
            }
          }
        });
      }
    }
  };

  useScript({
    scriptUrl: `https://maps.googleapis.com/maps/api/js?key=${gmapsApiKey}&libraries=places`,
    scriptId: 'places_url',
    callback: initGMaps,
  });

  useEffect(() => {
    if (currentGoogleAddress && uspsAddress && !isUspsApiLoading) {
      switch (compareGoogleUspsAddress(currentGoogleAddress, uspsAddress)) {
        case 'failure':
          setVerificationProcessSuccess(false);
          setValue(`${namePath}validation_status`, false);
          break;
        case 'success':
          setVerificationProcessSuccess(true);
          setValue(`${namePath}validation_status`, true);
          addressDuplicationChecking({
            addressData: currentGoogleAddress,
            onDupeFound: (data: any[]) => {
              setHasDupeAddress(true);
              setDupeAddresses(data);
            },
            onNoDupeFound: () => {
              // Set the status of showing the extraStatusElement to be false
              setHasDupeAddress(false);
              setDupeAddresses([]);
            },
          });
          break;
        case 'not_exact_match':
          setVerificationProcessSuccess(true);
          setShowAddressModal(true);
          break;
      }
    }
  }, [
    uspsAddress,
    currentGoogleAddress,
    isUspsApiLoading,
    uspsSuggestedAddressLoadable.contents,
  ]);

  DEBUGGING &&
    console.log(
      'Check Address Subsection, render: ',
      'watch',
      watch,
      'currentGoogleAddress',
      currentGoogleAddress,
      'uspsAddress',
      uspsAddress,
      uspsSuggestedAddressLoadable.contents,
    );
  return (
    // -1 is not show section, 0 is show section.
    <>
      <ESFormSimpleField
        label={'First Name'}
        controlId={`${namePath}formGridFirstName`}
        placeholder={'First Name'}
        name={namePath + 'first_name'}
        required={true}
        register={register}
        defaultValue={defaultAddressData.first_name}
        errorField={errors.first_name}
      />
      <ESFormSimpleField
        label={'Last Name'}
        controlId={`${namePath}formGridLastName`}
        placeholder={'Last Name'}
        name={namePath + 'last_name'}
        required={true}
        register={register}
        defaultValue={defaultAddressData.last_name}
        errorField={errors.last_name}
      />
      {addressRule?.company !== -1 && (
        <ESFormSimpleField
          label={'Company Name'}
          controlId={`${namePath}formGridCompanyName`}
          placeholder={'Company Name'}
          name={namePath + 'company'}
          register={register}
          defaultValue={defaultAddressData.company}
          errorField={errors.company}
        />
      )}

      <ESFormSimpleField
        label={getAddressLabel(LOCALE).address1}
        controlId={`${namePath}formGridAddress1`}
        placeholder={getAddressLabel(LOCALE).address1}
        name={namePath + 'address1'}
        required={true}
        register={register}
        defaultValue={defaultAddressData.address1}
        errorField={errors.address1}
        onChange={() => resetValidationProcess()}
        isLoading={isUspsApiLoading}
        validate={() => {
          if (LOCALE == ELocale.ja_JP || LOCALE == ELocale.de_DE) {
            return true;
          } else {
            return !!watch?.address1
              ? watch.address1?.trim().split(' ').length >= 2
              : false;
          }
        }}
        extraStatusElement={
          hasDupeAddress ? (
            <>
              <Button
                variant="primary"
                size="sm"
                style={{ margin: '0.25rem' }}
                onClick={() => setDuplicationShow(true)}
              >
                Click for More
              </Button>

              <EpSubsAddressDuplicationModal
                dupeAddresses={dupeAddresses ?? []}
                modalShow={duplicationShow}
                onClose={() => setDuplicationShow(false)}
              />
            </>
          ) : (
            <></>
          )
        }
        maxLength={150}
      />

      {addressRule?.address2 !== -1 && (
        <ESFormSimpleField
          label={getAddressLabel(LOCALE).address2}
          controlId={`${namePath}formGridAddress2`}
          placeholder={getAddressLabel(LOCALE).address2}
          name={namePath + 'address2'}
          register={register}
          defaultValue={defaultAddressData.address2}
          errorField={errors.address2}
          onChange={() => resetValidationProcess()}
          maxLength={100}
        />
      )}
      {addressRule?.city !== -1 && (
        <ESFormSimpleField
          label={getAddressLabel(LOCALE).city}
          controlId={`${namePath}formGridCity`}
          placeholder={getAddressLabel(LOCALE).city}
          name={namePath + 'city'}
          required={true}
          register={register}
          defaultValue={defaultAddressData.city}
          errorField={errors.city}
          onChange={() => resetValidationProcess()}
        />
      )}
      {addressRule?.state_code !== -1 && (
        <ESFormSimpleDropdownField
          label={'State'}
          controlId={`${namePath}formGridState`}
          placeholder={'State'}
          name={namePath + 'state_code'}
          required={true}
          register={register}
          control={control}
          options={stateOptions ?? []}
          onChange={() => resetValidationProcess()}
        />
      )}
      {addressRule?.zip !== -1 && (
        <Form.Group>
          <Row>
            <Col md={4}>
              <p
                style={{
                  textAlign: 'right',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  paddingTop: '5px',
                }}
              >
                {`${getAddressLabel(LOCALE).post_code}*`}
              </p>
            </Col>
            <Col md={4}>
              <Form.Control
                id={`${namePath}formGridZip`}
                placeholder={getAddressLabel(LOCALE).post_code}
                name={namePath + 'zip'}
                required={!!(LOCALE != ELocale.ja_JP)}
                ref={register({
                  maxLength: {
                    value: MAX_POSTAL_CODE_LENGTH,
                    message: `${
                      languageOptions().portal.error.postalCode.frontLength
                    } ${MAX_POSTAL_CODE_LENGTH} ${
                      languageOptions().portal.error.postalCode.endLength
                    }`,
                  },
                  pattern: {
                    value: new RegExp(addressRule?.zip_format ?? ''),
                    message: 'Invalid postal code',
                  },
                  required: true,
                })}
                defaultValue={defaultAddressData.zip}
                onChange={() => resetValidationProcess()}
              />
              {errors?.zip && <div style={{ color: 'red' }}>{errors?.zip.message}</div>}
            </Col>
          </Row>
        </Form.Group>
      )}

      <ESFormSimpleDropdownField
        label={'Country'}
        controlId={`${namePath}formGridCountry`}
        placeholder={'Country'}
        name={namePath + 'country'}
        required={true}
        register={register}
        control={control}
        defaultValue={defaultAddressData.country ?? country}
        options={allCountryOptions ?? []}
        onChange={() => resetValidationProcess()}
      />

      {getEnableAddressVerification() && (
        <EpSubsFormInvisibleInput
          className={`d-flex flex-row ${style.validation_status}`}
          name={`${namePath}validation_status`}
          checked={watch && !!watch.validation_status}
          control={control}
          register={register}
          validate={() => {
            if (!!uspsAddress && !verificationProcessSuccess) {
              putNoty({ type: 'error', text: 'Please manually validate address first!' });
              setShowAddressModal(true);
            }
            // return true if address Validation Process Success
            return verificationProcessSuccess;
          }}
          onClick={() => {
            if (!watch?.address1) {
              setShowAddressModal(() => false);
              setErrorMessage('Please fill in all fields');
            } else {
              setShowAddressModal(() => true);
              resetCurrentInputAddressAtom();
              setCurrentInputAddressAtom(() => {
                return { address: watch };
              });
            }
          }}
        >
          <Col md={4} className="text-right">
            <p id="Ep_verify_address" className={style.action_text}>
              Verify Address
            </p>
          </Col>
          <Col md={6}>
            <div className="d-flex flex-row">
              {!watch?.address1 ? (
                <div className={style.error}>{errorMessage}</div>
              ) : (
                <div className={verificationProcessSuccess ? style.success : style.error}>
                  <p tabIndex={0} className={style.validation_text_container}>
                    <EpIcon
                      className={style.validation}
                      name={
                        verificationProcessSuccess
                          ? watch.validation_status
                            ? 'check'
                            : 'warning'
                          : 'error'
                      }
                      style={{ height: '16px', marginLeft: '0px' }}
                      tabIndex={0}
                    />
                    {verificationProcessSuccess && watch.validation_status
                      ? 'Address verified.'
                      : 'Address not verified!'}
                  </p>
                </div>
              )}
            </div>
          </Col>
        </EpSubsFormInvisibleInput>
      )}
      <EpSubsVerifyAddressModal
        modalShow={showAddressModal}
        currentAddressData={watch}
        verifiedAddress={uspsAddress}
        onClose={() => setShowAddressModal(false)}
        onConfirm={(addressChoice: TAddressVerificationChoice) => {
          addressDuplicationChecking({
            addressData:
              addressChoice === 'verified' &&
              uspsAddress &&
              !(uspsAddress instanceof Error)
                ? uspsAddress
                : watch,
            onDupeFound: (data: any[]) => {
              // Set the status of showing the extraStatusElement to be true
              setHasDupeAddress(true);
              setDupeAddresses(data);
            },
            onNoDupeFound: () => {
              // Set the status of showing the extraStatusElement to be false
              setHasDupeAddress(false);
              setDupeAddresses([]);
            },
          });
          setChosenAddress({
            addressChoice,
            verifiedAddress: uspsAddress,
            setValue,
            namePath,
            setShowAddressModal,
          });
          setVerificationProcessSuccess(true);
        }}
        source={namePath}
      />
    </>
  );
};
