import axios from 'axios';
import { hostUrl } from '../../../config';
import { TNewPaymentFormData } from '../../../components/subscriptions/EpSubsSubscriptionForm';
import { TPaymentPortalGateWayType } from '../components/paymentForms';
import { putNoty } from 'src/utilities/epSubsNoty';

export interface IEpSubsCustomer_payment_response {
  gateway: TPaymentPortalGateWayType;
  logo: string;
  gateway_account: number;
  customer_id: number;
  payment_methods: {
    id: number;
    customer_id: number;
    payment_type: string;
    last4_digits: string;
    card_type: string;
    expiration_year: string;
    expiration_month: string;
    is_primary: boolean;
    email: string;
    first_name: string;
    last_name: string;
    gateway_account: number;
  }[];
}

export const genPaymentListForCustomer = async (
  token: string,
  productId: string,
): Promise<IEpSubsCustomer_payment_response> => {
  return axios
    .get(`${hostUrl}/customers/payment-methods/${token}?prd=${productId}`)
    .then(response => {
      const response_data = response.data.data;
      return {
        ...response_data,
        payment_methods: response_data.payment_methods ?? [],
      };
    });
};

export const genAddPaymentForCustomer = async (
  token: string,
  newPaymentData: TNewPaymentFormData,
  currentUrl?: string,
): Promise<number | { url?: string; returnUrl?: string }> => {
  return axios
    .post(
      `${hostUrl}/customers/payment-methods/${token}?src=${currentUrl}`,
      newPaymentData,
    )
    .then(response => {
      if (response.data.code != undefined) {
        const message = `${response.data.message}`;
        putNoty({ type: 'error', text: message });
        const { url, return_url } = JSON.parse(response.data.data);
        return { url, returnUrl: return_url };
      } else {
        const newSubscriptionId = response.data.data;
        return newSubscriptionId;
      }
    });
};

export const genUpdatePaymentForCustomer = async (
  token: string,
  id: number,
  newPaymentData: TNewPaymentFormData,
  currentUrl?: string,
): Promise<number | { url?: string; returnUrl?: string }> => {
  return axios
    .patch(
      `${hostUrl}/customers/payment-methods/${token}/${id}?src=${currentUrl}`,
      newPaymentData,
    )
    .then(response => {
      if (response.data.code != undefined) {
        const message = `${response.data.message}`;
        putNoty({ type: 'error', text: message });
        const { url, return_url } = JSON.parse(response.data.data);
        return { url, returnUrl: return_url };
      } else {
        const newCustomerPaymentId = response.data.data.id;
        return newCustomerPaymentId;
      }
    });
};

export const genAddPayPalPaymentForCustomer = async (
  token: string,
  newPaymentData: TNewPaymentFormData,
): Promise<number> => {
  return axios
    .post(`${hostUrl}/customers/payment-methods/${token}`, newPaymentData)
    .then(response => {
      return response.data.data;
    });
};

export const genDeletePaymentForCustomer = async (
  token: string,
  id: number,
): Promise<boolean> => {
  return axios
    .delete(`${hostUrl}/customers/payment-methods/${token}/${id}`)
    .then(() => true);
};

export const genSetPrimaryPaymentForCustomer = async (
  token: string,
  id: number,
): Promise<boolean> => {
  return axios
    .patch(`${hostUrl}/customers/payment-methods/${token}/${id}/make-primary`)
    .then(() => true);
};
