import { ELocale } from 'src/models/i18n/Locale';
import RecoilEnv from 'recoil';
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
export const hostUrl =
  process.env.REACT_APP_API_HOST || 'https://devw.subconductor.com/api/v1';
export const gmapsApiKey =
  process.env.REACT_APP_GMAPS_API || 'AIzaSyBgWKKuNk4mzxydfgtyn0asmjZToKFEgrc';
export const COMPANY_NAME = 'Conductor';
export const DEBUGGING = process.env.REACT_APP_DEBUGGING === 'true'; // set true for debug (will use default value in the form)
export const LOCALE = process.env.REACT_APP_LOCALE || ELocale.en_US;
export const TIMEZONE = process.env.REACT_APP_TIMEZONE;
export const UI_VERSION = process.env.REACT_APP_UI_VERSION || 'v1';
export const addressVerificationMethod =
  process.env.REACT_APP_ADDRESS_VERIFICATION_METHOD || 'melissa';
export const isLocalHost = process.env.REACT_APP_API_ENV;
//When paypal express use for test use this
export const paypalTestUrl =
  'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=';
//When paypal express go live use this
export const paypalLiveUrl =
  'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=';
export const webUrl = process.env.REACT_APP_WEBSITE_URL;
