import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from 'src/components/tables/EpFullFeaturedTable';
import * as H from 'history';
import { offsetToPageId } from 'src/components/tables/EpFullFeaturedTable/Pagination/Pagination';
import {
  GATEWAYS,
  IEpSubsDBGateway_accountApiValue,
  IEpSubsDBPayment_method_typeValue,
  TEpSubsDBGateway_accountValueKeys,
} from 'src/models';
import {
  genGatewayData,
  genGatewayListData,
  getAllGatewayList,
  getAllGateways,
  getGatewaysPaymentMethodTypes,
} from 'src/services/epSubsGatewaysService';
import { arrayToMap } from 'src/utilities/commonUtils';
import { putNoty } from 'src/utilities/epSubsNoty';
import { CURRENT_ID_ATOM_DEFAULT_VALUE } from '../recoilConstants';
import { DEBUGGING } from 'src/config';

/**
 * An atom that stores the fresh Request ID for Gateway list
 */
export const gatewayListRequestIDAtom = atom<number>({
  key: 'gatewayListRequestIDAtom',
  default: 0,
});

/**
 * An atom that stores the fresh Request ID for Gateway list
 */
export const gatewaysPortalListRequestIDAtom = atom<number>({
  key: 'gatewaysPortalListRequestIDAtom',
  default: 0,
});
/**
 * An atom that stores the fresh Request ID for Gateway Payment Method Types
 */
export const gatewayPaymentMethodTypesRequestIDAtom = atom<number>({
  key: 'gatewayPaymentMethodTypesRequestIDAtom',
  default: 0,
});

/**
 * An atomFamily that stores the refresh Request ID for each gateway
 */
export const gatewayRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'gatewayRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores gateway details for each gateway
 */
export const gatewaySelectors = selectorFamily<IEpSubsDBGateway_accountApiValue, number>({
  key: 'gatewaySelectors',
  get:
    gatewayId =>
    async ({ get }) => {
      get(gatewayRefreshRequestIDAtoms(gatewayId)); // Add request ID as a dependency
      const gatewayData = await genGatewayData(gatewayId);
      DEBUGGING && console.log('recoil, gatewaySelectors gatewayData:', gatewayData);
      return gatewayData;
    },
});

/**
 * An selector that stores Gateway list
 */
export const gatewaysPortalListSelector = selector<gatewaysOnPortalType[]>({
  key: 'gatewaysPortalListSelector',
  get: async ({ get }) => {
    get(gatewaysPortalListRequestIDAtom);
    const gatewayList = await getAllGatewayList();
    return gatewayList;
  },
});

/**
 * An selector that stores All Gateways
 */
export const allGatewaySelector = selector<IEpSubsDBGateway_accountApiValue[]>({
  key: 'allGatewaySelector',
  get: async ({ get }) => {
    get(gatewayListRequestIDAtom);
    const allGateway = await getAllGateways();
    return allGateway;
  },
});

/**
 * An selector that stores all gateway payment method types
 */
export const gatewayPaymentMethodTypesSelector = selector<
  IEpSubsDBPayment_method_typeValue[]
>({
  key: 'gatewayPaymentMethodTypesSelector',
  get: async () => {
    const gatewaysPaymentMethodTypes = await getGatewaysPaymentMethodTypes();
    return gatewaysPaymentMethodTypes;
  },
});

/**
 * An selector that stores a map from payment type id to payment types Object
 */
export const gatewayPaymentMethodTypesMapSelector = selector<
  Map<number, IEpSubsDBPayment_method_typeValue>
>({
  key: 'gatewayPaymentMethodTypesMapSelector',
  get: async ({ get }) => {
    const gatewayPaymentMethodTypes = get(gatewayPaymentMethodTypesSelector);
    return arrayToMap(
      gatewayPaymentMethodTypes,
      gatewayPaymentMethodType => gatewayPaymentMethodType.id,
    );
  },
});

/**
 * An selector that stores a map from gateway.id to gateway Object
 */
export const gatewayMapSelector = selector<Map<number, IEpSubsDBGateway_accountApiValue>>(
  {
    key: 'gatewayMapSelector',
    get: async ({ get }) => {
      const gatewayList = get(allGatewaySelector);
      if (gatewayList.length === 0) {
        const message = 'No default gateway set! Please set on backend.';
        putNoty({ type: 'error', text: message });
        throw new Error(message);
      }
      return arrayToMap(gatewayList, gateway => gateway.id);
    },
  },
);

/**
 * An selector that stores the default Gateway
 */
export const defaultGatewaySelector = selector<IEpSubsDBGateway_accountApiValue>({
  key: 'defaultGatewaySelector',
  get: async ({ get }) => {
    const gatewayList = get(allGatewaySelector);
    if (gatewayList.length === 0) {
      const message = 'No default gateway set! Please set on backend.';
      putNoty({ type: 'error', text: message });
      throw new Error(message);
    }
    return gatewayList[0];
  },
});

/**
 * An selector that stores the default Braintree Gateway for Paypal
 * @returns If exist, return the gateway object. If not, return null.
 */
export const defaultBraintreeGatewaySelector = selector<gatewaysOnPortalType | null>({
  key: 'defaultBraintreeGatewaySelector',
  get: async ({ get }) => {
    const gatewayList = get(gatewaysPortalListSelector);
    const defaultGateways = gatewayList.filter(
      gateway => gateway.type === GATEWAYS.Braintree,
    );
    if (defaultGateways.length === 0) {
      // const message = 'No default gateway set! Please set on backend.';
      // putNoty({ type: 'error', text: message });
      return null;
    }
    return defaultGateways[0];
  },
});

// *********************************************** //
//             Gateway List Page States            //
// *********************************************** //
export const DEFAULT_GATEWAY_SEARCH_PARAMETER = '';

/**
 * An atom that stores the latest gateway search param
 */
export const latestGatewaySearchParamAtom = atom<TLocationRecord>({
  key: 'latestGatewaySearchParamAtom',
  default: { search: DEFAULT_GATEWAY_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for gateway list
 */
export const gatewaySearchRequestIDAtom = atom<number>({
  key: 'gatewaySearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for gateway list
 */
export const gatewayListSelector = selector<ListState<IEpSubsDBGateway_accountApiValue>>({
  key: 'gatewayIdListSelector',
  get: async ({ get }) => {
    const requestID = get(gatewaySearchRequestIDAtom); // Add request ID as a dependency
    const gatewaySearchParam = get(latestGatewaySearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, gatewayBasicListSelector gatewaySearchParam',
        gatewaySearchParam,
        'requestID',
        requestID,
      );

    // fetch gateways
    if (gatewaySearchParam.ready && gatewaySearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBGateway_accountValueKeys>(
        gatewaySearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const offset = (page - 1) * perPage;
      // const filter = decodeURIComponent(routeParams.filter);
      // const filterData = getSimpleFilterData(filter);
      try {
        const response = await genGatewayListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'name', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          // filterData[PRODUCT_FILTER_GROUP_FIELD][0],
        );

        DEBUGGING && console.log('recoil, gatewayBasicListSelector response', response);
        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

// *********************************************** //
//           Gateway Detail Page Selectors            //
// *********************************************** //

/**
 * An atom that stores the currently selected gateway id
 */
export const currentGatewayIdAtom = atom<number | null>({
  key: 'currentGatewayIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected gateway details
 */
export const currentGatewaySelector = selector<
  IEpSubsDBGateway_accountApiValue | undefined
>({
  key: 'currentGatewaySelector',
  get: ({ get }) => {
    const id = get(currentGatewayIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(gatewaySelectors(id));
    }
  },
});

/**
 * An selector that stores a map from gateway.id and type, this is only for portal gage
 */

export type gatewaysOnPortalType = {
  id: number;
  type: number;
  client_id: string;
  is_default: boolean;
};
export const gatewaysPortalMapSelector = selector<Map<number, gatewaysOnPortalType>>({
  key: 'gatewaysPortalMapSelector',
  get: async ({ get }) => {
    const gatewaysPortalList = get(gatewaysPortalListSelector);
    return arrayToMap(gatewaysPortalList, gateway => gateway.id);
  },
});
