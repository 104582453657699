import React, { useEffect, useCallback } from 'react';
import { TNewPaymentFormData } from 'src/components/subscriptions/EpSubsSubscriptionForm';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { DeepMap, FieldError } from 'react-hook-form';
import { putNoty } from 'src/utilities/epSubsNoty';
import { gatewaysPortalMapSelector } from 'src/recoil/gateway/gatewayStates';
import { useLoadOptionalRecoilSelector } from 'src/recoil';
import { DEBUGGING } from 'src/config';
import * as stripeJs from '@stripe/stripe-js';
import { getCurrencyCode } from 'src/models/i18n';

export interface IAddPaymentFormData extends TNewPaymentFormData {
  id: number;
}

export type stripeProps = {
  namePath?: string;
  gatewayAccountId: number;
  setStripe: (stripe: Stripe) => void;
  onInstanceEnding: (stripe: Stripe, stripeElements?: stripeJs.StripeElements) => void;
  register: any;
  errors?: DeepMap<IAddPaymentFormData, FieldError>;
};

export const EpSubsStripeFormSection = ({
  namePath = '',
  gatewayAccountId,
  register,
  setStripe,
  onInstanceEnding,
  errors,
}: stripeProps) => {
  const gatewayMap = useLoadOptionalRecoilSelector(gatewaysPortalMapSelector);
  const client_id = gatewayMap?.get(gatewayAccountId)?.client_id;
  const [stripePromise, setStripePromise] =
    React.useState<PromiseLike<Stripe | null> | null>(null);

  const options = {
    mode: 'setup',
    currency: `${getCurrencyCode().toLowerCase()}`,
    captureMethod: 'automatic',
  };

  useEffect(() => {
    try {
      if (client_id) {
        setStripePromise(loadStripe(client_id));
      }
    } catch (error) {
      putNoty({ type: 'error', text: (error as Error).message });
    }
  }, [client_id]);

  return !stripePromise ? (
    <></>
  ) : (
    <div>
      <Elements
        stripe={stripePromise}
        options={options as stripeJs.StripeElementsOptions}
      >
        <EpSubsStripeHostedFields
          {...{
            gatewayAccountId,
            namePath,
            register,
            setStripe,
            onInstanceEnding,
            errors,
          }}
        />
      </Elements>
    </div>
  );
};

export const EpSubsStripeHostedFields = ({
  setStripe,
  onInstanceEnding,
  errors,
}: stripeProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const initiateStripe = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');
    setStripe(stripe);
    onInstanceEnding(stripe, elements as stripeJs.StripeElements);
  }, [stripe, elements]);

  useEffect(() => {
    initiateStripe();
  }, [initiateStripe]);
  DEBUGGING && console.log('Stripe zip error:', errors);

  return <div id="payment-element" style={{ margin: '10px' }}></div>;
};
