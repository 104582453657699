import { atom, useRecoilCallback } from 'recoil';
import { DEFAULT_ACTIVITY_COUNT, DEFAULT_COMMENT_COUNT } from '../../js/constants/subs';

import { selector, selectorFamily } from 'recoil';
import {
  IEpSubsDBInvoice_activityValue,
  IEpSubsDBInvoice_commentValue,
  IEpSubsCompositeDBInvoiceValue,
  UiPermission,
  IEpSubsTagsListValue,
} from '../../models/';
import {
  genInvoiceActivityData,
  genInvoiceActivityDetail,
  genInvoiceCommentData,
  getInvoiceTags,
} from '../../services/';
import { invoiceSelectors } from './invoiceListPageStates';
import { CURRENT_ID_ATOM_DEFAULT_VALUE, hasPermissionSelectors } from '..';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//         Current Invoice Detail States           //
// *********************************************** //

/**
 * An atom that stores the currently selected invoice id
 */
export const currentInvoiceIdAtom = atom<number | null>({
  key: 'currentInvoiceIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected invoice details
 */
export const currentInvoiceSelector = selector<
  IEpSubsCompositeDBInvoiceValue | undefined
>({
  key: 'currentInvoiceSelector',
  get: ({ get }) => {
    const id = get(currentInvoiceIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(invoiceSelectors(id));
    }
  },
});

// *********************************************** //
//        Current Invoice Comments States          //
// *********************************************** //

/**
 * An atom that stores the currently selected invoice Comments query parameters
 */
export const currentInvoiceCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentInvoiceCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected invoice Comments
 */
export const currentInvoiceCommentsSelector = selector<IEpSubsDBInvoice_commentValue[]>({
  key: 'currentInvoiceCommentsSelector',
  get: async ({ get }) => {
    const id = get(currentInvoiceIdAtom);
    const params = get(currentInvoiceCommentsSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['inv.view_comment']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genInvoiceCommentData(id, params.limit);
    } else {
      return [];
    }
  },
});

// *********************************************** //
//      Current Invoice Activities States          //
// *********************************************** //

/**
 * An atom that stores the currently selected invoice Activities query parameters
 */
export const currentInvoiceActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentInvoiceActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected invoice Activities
 */
export const currentInvoiceActivitiesSelector = selector<
  IEpSubsDBInvoice_activityValue[]
>({
  key: 'currentInvoiceActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentInvoiceIdAtom);
    const params = get(currentInvoiceActivitiesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['inv.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genInvoiceActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores Invoice Activity Detail for each activity
 */
export const invoiceActivityDetailSelectors = selectorFamily<
  IEpSubsDBInvoice_activityValue,
  number
>({
  key: 'invoiceActivityDetailSelectors',
  get: actLogId => async () => {
    const invoiceActivityData = await genInvoiceActivityDetail(actLogId);
    DEBUGGING &&
      console.log('recoil, invoiceSelectors invoiceActivityData:', invoiceActivityData);
    return invoiceActivityData;
  },
});

// *********************************************** //
//          Invoice Tags Selector                //
// *********************************************** //

export const invoiceTagsRequestIDAtom = atom<number>({
  key: 'invoiceTagsRequestIDAtom',
  default: 0,
});

export const useRefreshInvoiceTags = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(invoiceTagsRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const invoiceTagsSelector = selector<IEpSubsTagsListValue[]>({
  key: 'invoiceTagsSelector',
  get: async () => {
    const tagsList = await getInvoiceTags();
    return tagsList;
  },
});
