import { selector, selectorFamily, waitForAll } from 'recoil';
import * as H from 'history';
import { IEpSubsDBEmail_logApiValue } from '../../models';
import { genEmailLogData, genEmailLogListData } from '../../services/epSubsEmailService';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
} from '../../components/tables/EpFullFeaturedTable';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { atom, atomFamily } from 'recoil';
import { TLocationRecord } from '../../components/tables/EpFullFeaturedTable';
import {
  DEFAULT_EMAIL_LOG_SEARCH_OPTION,
  EMAIL_LOG_STATUS_FILTER_GROUP_FIELD,
} from 'src/containers/emailLog/EpSubsEmailLogNav';
import { getSimpleFilterData } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//             EmailLog List Page States               //
// *********************************************** //
export const DEFAULT_EMAIL_LOG_SEARCH_PARAMETER = `?filter=${JSON.stringify(
  DEFAULT_EMAIL_LOG_SEARCH_OPTION,
)}`;
// export const DEFAULT_EMAIL_LOG_SEARCH_PARAMETER = `?order=&page=1&perPage=10&sort=&filter=${EMAIL_LOG_STATUS.BOUNCED}&simpleQuery=`;
/**
 * An atom that stores the latest emailLog search param
 */
export const latestEmailLogSearchParamAtom = atom<TLocationRecord>({
  key: 'latestEmailLogSearchParamAtom',
  default: { search: DEFAULT_EMAIL_LOG_SEARCH_PARAMETER, ready: false },
});

/**
 * An atom that stores that stores the refresh Request ID for emailLog list
 */
export const emailLogSearchRequestIDAtom = atom<number>({
  key: 'emailLogSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for emailLog list based on current searchParam
 */
export const emailLogBasicListSelector = selector<ListState<IEpSubsDBEmail_logApiValue>>({
  key: 'emailLogIdListSelector',
  get: async ({ get }) => {
    const requestID = get(emailLogSearchRequestIDAtom); // Add request ID as a dependency
    const emailLogSearchParam = get(latestEmailLogSearchParamAtom);
    DEBUGGING &&
      console.log(
        'recoil, emailLogBasicListSelector emailLogSearchParam',
        emailLogSearchParam,
        'requestID',
        requestID,
      );

    // fetch emailLogs
    if (emailLogSearchParam.ready && emailLogSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<keyof IEpSubsDBEmail_logApiValue>(
        emailLogSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const filter = decodeURIComponent(routeParams.filter);
      const offset = (page - 1) * perPage;
      const filterData = getSimpleFilterData(filter);
      try {
        const response = await genEmailLogListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'created_at', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          simpleQuery,
          filterData[EMAIL_LOG_STATUS_FILTER_GROUP_FIELD],
        );

        DEBUGGING && console.log('recoil, emailLogBasicListSelector response', response);

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data: response.data ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An selector that stores the emailLog detail list based on current searchParam
 */
export const emailLogCompleteListSelector = selector<
  ListState<IEpSubsDBEmail_logApiValue>
>({
  key: 'emailLogCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(emailLogBasicListSelector);

    if (!idListState.err) {
      const emailLogIds = idListState.data.map(emailLogData => emailLogData.id);
      const emailLogDetailList = get(
        waitForAll(emailLogIds.map(emailLogId => emailLogSelectors(emailLogId))),
      );
      DEBUGGING &&
        console.log('recoil, emailLogCompleteListSelector', emailLogDetailList);

      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: emailLogDetailList ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from emailLogBasicListSelector
      return idListState as ListState<IEpSubsDBEmail_logApiValue>;
    }
  },
});

/**
 * An atomFamily that stores the refresh Request ID for each emailLog
 */
export const emailLogRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'emailLogRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores emailLog details for each emailLog
 */
export const emailLogSelectors = selectorFamily<IEpSubsDBEmail_logApiValue, number>({
  key: 'emailLogSelectors',
  get:
    emailLogId =>
    async ({ get }) => {
      get(emailLogRefreshRequestIDAtoms(emailLogId)); // Add request ID as a dependency
      const emailLogData = await genEmailLogData(emailLogId);
      DEBUGGING && console.log('recoil, emailLogSelectors emailLogData:', emailLogData);
      return emailLogData;
    },
});
