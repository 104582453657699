// import React from 'react';
import * as H from 'history';
import { GATEWAY_MAP, IEpSubsDBProductValue } from 'src/models';
import { notEmpty } from 'src/utilities/commonUtils';
import { toInitialUpperCase } from 'src/utilities/stringUtility';
// import { PRODUCT_STATUS, TProductStatus } from 'src/js/constants/subs';

export const getProductTypeInGroupTitle = (
  productData?: IEpSubsDBProductValue,
): string => {
  return productData
    ? [
        productData.digital ? 'Digital' : null,
        productData.shippable ? 'Shippable' : null,
        toInitialUpperCase(
          GATEWAY_MAP[productData.default_gateway as keyof typeof GATEWAY_MAP],
        ),
        productData.default_currency,
      ]
        .filter(notEmpty)
        .join(', ')
    : 'Type Data Not Available';
};

export const onEditProductBtnClick = (history: H.History, id: number): void => {
  history.push(`/products/edit-product/${id}/0`);
};

// export const archiveBtnText = (status: TProductStatus | string): string => {
//   return status === PRODUCT_STATUS.ACTIVE ? 'Archive Product' : 'Unarchive Product';
// };

interface ICloneButtonProps {
  id: number;
  history: H.History;
}

export const onCloneProductBtnClick = async ({
  id,
  history,
}: ICloneButtonProps): Promise<void> => {
  const isClone = 1;
  history.push(`/products/edit-product/${id}/${isClone}`);
};
