import React from 'react';
import ReactSelect, { createFilter, StylesConfig } from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

import { groupBy } from 'src/utilities/commonUtils';

export enum EDropdownItemType {
  FLAT = 'flat',
  GROUPED = 'grouped',
}

export type TFlatDropdownItem<TValue = string> = {
  // To be compatible with previous code without type field
  type?: EDropdownItemType.FLAT | undefined;
  value: TValue;
  label: string;
  groupName?: string;
  groupField?: string;
  icon?: JSX.Element;
};
export type TGroupDropdownItem<TValue = string> = {
  type: EDropdownItemType.GROUPED;
  value: TValue;
  label: string;
  groupName: string;
  groupField?: string;
  icon?: JSX.Element;
};
export type TDropdownItem<TValue = string> =
  | TFlatDropdownItem<TValue>
  | TGroupDropdownItem<TValue>;

export type TGroupedOptions<TOption> = {
  type: EDropdownItemType.GROUPED;
  groupName: string;
  options: TOption[];
};
type OptionValue = string;
export type IESGeneralDropdownInputProps<TValue extends string> = {
  className?: string;
  style?: { [key: string]: string | number };
  placeholder: string;
  controlId?: string;
  defaultValue?: string | null;
  options: (TFlatDropdownItem<TValue> | TGroupDropdownItem<TValue>)[];
  disabled?: boolean;
  variant?: 'main' | 'side';
  onChange?: (value?: OptionValue) => void;
  displayOptionGroup?: boolean;
  // Used only when called from controlled form
  selectDefaultValue?: SelectOption | null;
  onSetRef?: (
    ref: ReactSelect<SelectOption, IsMulti, TGroupedOptions<SelectOption>> | null,
  ) => void;
  // selectRef?: React.MutableRefObject<ReactSelect<
  //   SelectOption,
  //   IsMulti,
  //   TGroupedOptions<SelectOption>
  // > | null>;
};

type TControlledRenderProps = {
  renderProps: {
    onChange?: (value: string) => void;
    onBlur?: () => void;
    value: string;
  };
};

type SelectOption = {
  label: string;
  value: string;
  groupName?: string;
};
type IsMulti = false;

export function ESGeneralDropdownInput<TValue extends string>(
  props: IESGeneralDropdownInputProps<TValue> & TControlledRenderProps,
): JSX.Element {
  const {
    className = '',
    style = {},
    disabled = false,
    placeholder,
    controlId = '',
    defaultValue,
    options,
    displayOptionGroup = false,
    renderProps,
    onSetRef,
    // selectRef = useRef<ReactSelect<
    //   SelectOption,
    //   IsMulti,
    //   TGroupedOptions<SelectOption>
    // > | null>(null),
  } = props;
  let { selectDefaultValue } = props;
  const customStyles: StylesConfig<
    SelectOption,
    IsMulti,
    TGroupedOptions<SelectOption>
  > = {
    option: provided => ({
      ...provided,
      wordWrap: 'break-word',
      paddingLeft: '1rem',
    }),
    container: provided => ({
      ...provided,
      ...style,
    }),
  };

  // Used to style grouped dropdown.
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    // fontWeight: 'bold',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    // textAlign: 'center',
  };

  const formatGroupLabel = (data: TGroupedOptions<SelectOption>) => (
    <div style={groupStyles}>
      <span>{data.groupName}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  // if there is no options, then return dummy Select,
  // only render real Select if there is options
  // otherwise cannot show defaultValue correctly
  if (options.length === 0) return <ReactSelect options={[]} />;

  // convert options
  let groupedSelectOptions: TGroupedOptions<SelectOption>[] | undefined;
  if (displayOptionGroup && options[0].type === EDropdownItemType.GROUPED) {
    groupedSelectOptions = getGroupedOptions<TGroupDropdownItem<string>, SelectOption>(
      options as TGroupDropdownItem<string>[],
      optionItem => {
        return {
          value: String(optionItem.value),
          label: optionItem.label,
          groupName: optionItem.groupName,
        };
      },
    ) as TGroupedOptions<SelectOption>[];
  }
  const convertedFlatSelectOptions = options.map(option => {
    return {
      type: option.type,
      value: String(option.value),
      label: option.label,
      groupName: option.groupName,
    };
  });

  if (!selectDefaultValue) {
    // If not called from form components, need to check if existed in options
    selectDefaultValue = convertedFlatSelectOptions.find(
      option => option.value === defaultValue,
    );

    if (!selectDefaultValue) {
      // if no match in option found, (undefined),
      selectDefaultValue = null;
    }
  }
  return (
    // render={({ onChange, onBlur, value }) => {

    <ReactSelect
      className={className}
      id={controlId}
      inputId={controlId}
      isDisabled={disabled}
      // The ref enables explorer to focus on the dropdown
      // if there is validation error for this field when submit form
      ref={onSetRef}
      styles={customStyles}
      formatGroupLabel={formatGroupLabel}
      options={groupedSelectOptions ?? convertedFlatSelectOptions}
      // for typeahead filter, only search 'label' field, don't search 'value' field
      filterOption={createFilter({
        matchFrom: 'any',
        stringify: option => `${option.label}`,
      })}
      placeholder={placeholder}
      defaultValue={selectDefaultValue}
      value={convertedFlatSelectOptions.find(
        option => option.value === renderProps.value,
      )}
      onChange={option => {
        option && renderProps.onChange && renderProps.onChange(option.value);
        props.onChange && option && props.onChange(option.value);
      }}
      onBlur={renderProps.onBlur}
    />
  );
}

export function getGroupedOptions<
  TOriginalOption extends { groupName: string },
  TTargetOption = TOriginalOption,
>(
  flattenedOptions: TOriginalOption[],
  optionConverter?: (oriOption: TOriginalOption) => TTargetOption,
): TGroupedOptions<TTargetOption | TOriginalOption>[] {
  const groupedMap = groupBy<TOriginalOption, string>(
    flattenedOptions,
    optionItem => optionItem.groupName,
  );
  return Array.from(groupedMap, ([groupName, optionItems]) => ({
    type: EDropdownItemType.GROUPED,
    groupName,
    options: !!optionConverter ? optionItems.map(optionConverter) : optionItems,
  }));
}
