import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IPureAddressData } from 'src/components/formComponents';
import { INewAddressFormData } from '../../models';
import { Col, ModalFooter, Row } from 'react-bootstrap';
import { EpSubsAddressCardBody } from './EpSubsAddressCard';
import style from './EpSubsVerifyAddressModal.module.scss';
import { camelCase } from 'lodash';
export interface IAddressVerifyFormData {
  address1: string;
  address2: string;
  zip: string;
}

export type TAddressVerificationChoice = 'verified' | 'input';
type TAddressVerificationCase = 'success' | 'failure' | 'no_input';

const getVerificationCase = (
  currentAddressData: INewAddressFormData,
  verifiedAddress?: IPureAddressData | Error | null,
): TAddressVerificationCase => {
  const { address1, city, zip, state_code, country } = currentAddressData;
  if (
    address1 === '' ||
    city === '' ||
    zip === '' ||
    state_code === '' ||
    country === ''
  ) {
    return 'no_input';
  } else if (!verifiedAddress || verifiedAddress instanceof Error) {
    return 'failure';
  }
  return 'success';
};

type Props = {
  modalShow: boolean;
  currentAddressData: INewAddressFormData;
  verifiedAddress?: IPureAddressData | Error | null;
  onClose: () => void;
  onConfirm: (addressChoice: TAddressVerificationChoice) => void;
  source?: string;
};

const EpSubsVerifyAddressModal = ({
  modalShow,
  currentAddressData,
  verifiedAddress,
  onClose,
  onConfirm,
  source = '',
}: Props): JSX.Element => {
  const [addressChoice, setAddressChoice] = useState<TAddressVerificationChoice>('input');
  const [verificationProcessSuccess, setVerificationProcessSuccess] = useState(false);
  const [smallModalShow, setSmallModalShow] = useState(false);
  return (
    <>
      <Modal
        show={modalShow}
        onHide={onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Comparing ${camelCase(source)} Addresses and Select One`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="contained-modal-title-vcenter">
          <Row>
            <Col md={6} className="pl-5 pr-4">
              <Button
                variant="light"
                className={`p-5 h-100 w-100 ${
                  addressChoice === 'input' ? style.address_button : ''
                }`}
                id="Ep_input_address_btn"
                onClick={() => {
                  setAddressChoice('input');
                  // setValidationStatus(false);
                  switch (getVerificationCase(currentAddressData, verifiedAddress)) {
                    case 'no_input':
                      setVerificationProcessSuccess(false);
                      break;
                    case 'failure':
                      setVerificationProcessSuccess(true);
                      break;
                    case 'success':
                      setVerificationProcessSuccess(true);
                      setSmallModalShow(true);
                  }
                }}
              >
                <p className="font-weight-bold">Current:</p>
                {currentAddressData
                  ? EpSubsAddressCardBody(currentAddressData, true)
                  : 'Please input address first before we can verify it.'}
              </Button>
            </Col>
            <Col md={6} className="pr-5 pl-4">
              <Button
                variant="light"
                className={`p-5 h-100 w-100 ${
                  addressChoice === 'verified' ? style.address_button : ''
                }`}
                id="Ep_usps_address_btn"
                onClick={() => {
                  setAddressChoice('verified');
                  switch (getVerificationCase(currentAddressData, verifiedAddress)) {
                    case 'no_input':
                      setVerificationProcessSuccess(false);
                      break;
                    case 'failure':
                      setVerificationProcessSuccess(false);
                      break;
                    case 'success':
                      setVerificationProcessSuccess(true);
                  }
                }}
              >
                <p className="font-weight-bold">Verified:</p>
                {verifiedAddress
                  ? EpSubsAddressCardBody(verifiedAddress, true)
                  : 'Failed to get verified Address.'}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onClose()}>
            Cancel and Revise
          </Button>
          <Button
            id="Ep_address_confirm"
            type="button"
            disabled={!verificationProcessSuccess}
            onClick={() => {
              onConfirm(addressChoice);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={smallModalShow}
        onHide={onClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={() => setSmallModalShow(false)}>
          <Modal.Title id="contained-modal-title-vcenter">Confirm Address</Modal.Title>
        </Modal.Header>
        <Modal.Body id="contained-modal-title-vcenter">
          <p>Are you sure that you want to use this address:</p>
          {currentAddressData
            ? EpSubsAddressCardBody(currentAddressData, true)
            : 'Please input address first before we can verify it.'}
        </Modal.Body>
        <ModalFooter>
          <Button variant="light" onClick={() => setSmallModalShow(false)}>
            Cancel
          </Button>
          <Button
            id="Ep_address_save"
            variant="primary"
            onClick={() => {
              onConfirm(addressChoice);
              setSmallModalShow(false);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EpSubsVerifyAddressModal;
