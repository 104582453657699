import {
  DEFAULT_ACTIVITY_COUNT,
  DEFAULT_ADDRESS_COUNT,
  DEFAULT_COMMENT_COUNT,
} from '../../js/constants/subs';

import { atom, atomFamily, selector, selectorFamily, useRecoilCallback } from 'recoil';
import {
  IEpSubsDBCustomer_activityValue,
  IEpSubsDBCustomer_commentValue,
  IEpSubsCompositeDBCustomerValue,
  IEpSubsDBCustomer_addressValue,
  IEpSubsCustomer_payment_methodApiValue,
  TEpSubsDBResponseOk,
  UiPermission,
  IEpSubsDBGift_cardValue,
  IEpSubsTagsListValue,
} from '../../models/';
import {
  genCustomerActivityData,
  genCustomerActivityDetail,
  genCustomerCommentData,
  genCustomerPaymentMethods,
  getAllGiftCardsList,
  getCustomerTags,
} from '../../services/';
import {
  genAddressListForCustomer,
  genMelissaVerifyCustomerAddress,
  genUSPSVerifyCustomerAddress,
} from '../../services/epSubsAddressService';
import { customerSelectors } from './customerListPageStates';
import { IPureAddressData } from 'src/components/formComponents';
import { CURRENT_ID_ATOM_DEFAULT_VALUE } from '../recoilConstants';
import { addressVerificationMethod, DEBUGGING } from 'src/config';
import { putNoty } from 'src/utilities/epSubsNoty';
import { hasPermissionSelectors } from '..';
import { TLocationRecord } from 'src/components/tables/EpFullFeaturedTable';

// *********************************************** //
//       Current Customer Detail States            //
// *********************************************** //

/**
 * An atom that stores the currently selected customer id
 */
export const currentCustomerIdAtom = atom<number | null>({
  key: 'currentCustomerIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores the currently selected customer details
 */
export const currentCustomerSelector = selector<
  IEpSubsCompositeDBCustomerValue | undefined
>({
  key: 'currentCustomerSelector',
  get: ({ get }) => {
    const id = get(currentCustomerIdAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return get(customerSelectors(id));
    }
  },
});

// *********************************************** //
//       Current Customer Payment States            //
// *********************************************** //

/**
 * An atom that stores the currently selected subscription Comments query parameters
 */
export const currentCustomerPaymentMethodsRequestIDAtom = atom<number>({
  key: 'currentCustomerPaymentMethodsRequestIDAtom',
  default: 0,
});

/**
 * A selector that gets the currently selected customer payment methods
 */
export const currentCustomerPaymentMethodsSelector = selector<
  IEpSubsCustomer_payment_methodApiValue[]
>({
  key: 'currentCustomerPaymentMethodsSelector',
  get: async ({ get }) => {
    const id = get(currentCustomerIdAtom);
    const requestId = get(currentCustomerPaymentMethodsRequestIDAtom);

    if (id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      const paymentMethods = await genCustomerPaymentMethods(id);
      DEBUGGING &&
        console.log(
          'recoil, currentCustomerPaymentMethodsSelector: ',
          `requestId: ${requestId}`,
          `customerId: ${id}`,
          `paymentMethods: [${paymentMethods.map(payment => payment.id)}]`,
          paymentMethods,
        );
      return paymentMethods;
    } else {
      return [];
    }
  },
});

// *********************************************** //
//       Current Customer Addresses States         //
// *********************************************** //

/**
 * An atom that stores the currently selected customer Addresses query parameters
 */
export const currentCustomerAddressesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentCustomerAddressesSearchParamAtom',
  default: {
    limit: DEFAULT_ADDRESS_COUNT,
    requestId: 0, // for query refresh
  },
});

export const DATA_NOT_READY = -Infinity;
/**
 * An selector that stores the currently selected customer Addresses
 */
export const currentCustomerAddressesSelector = selector<
  Pick<TEpSubsDBResponseOk<IEpSubsDBCustomer_addressValue>, 'data' | 'total'>
>({
  key: 'currentCustomerAddressesSelector',
  get: async ({ get }) => {
    const id = get(currentCustomerIdAtom);
    get(currentCustomerAddressesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['cust.view']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genAddressListForCustomer(id);
    } else {
      return { data: [], total: DATA_NOT_READY };
    }
  },
});

// *********************************************** //
//       Current Input Address States              //
// *********************************************** //
export type TAddressSectionSource = 'billing' | 'shipping';
export const currentInputAddressAtoms = atomFamily<
  { address?: IPureAddressData | null },
  TAddressSectionSource | ''
>({
  key: 'currentInputAddressAtoms',
  default: { address: undefined },
});

export const ALLOWED_COUNTRY_FOR_ADDRESS_VERIFICATION = ['US'];
export const currentUspsAddressSelectors = selectorFamily<
  IPureAddressData | Error | null,
  TAddressSectionSource | ''
>({
  key: 'currentUspsAddressSelectors',
  get:
    source =>
    async ({ get }) => {
      const inputAddress = get(currentInputAddressAtoms(source)).address;
      if (
        inputAddress
        // &&
        // ALLOWED_COUNTRY_FOR_ADDRESS_VERIFICATION.find(
        //   value => value === inputAddress.country,
        // )
      ) {
        DEBUGGING &&
          console.log(
            'Selector input Address Ver Check: ',
            inputAddress.address1,
            'Address 2: ',
            inputAddress.address2,
            'State: ',
            inputAddress.state_code,
          );
        try {
          switch (addressVerificationMethod) {
            case 'usps':
              const uspsAddress = await genUSPSVerifyCustomerAddress(inputAddress);
              return {
                address1: uspsAddress.address1,
                address2: uspsAddress.address2,
                city: uspsAddress.city,
                state_code: uspsAddress.state_code,
                zip: uspsAddress.zip,
                country: uspsAddress.country,
              };
            case 'melissa':
            default:
              const melissaAddress = await genMelissaVerifyCustomerAddress(
                inputAddress.address1,
                inputAddress.address2,
                inputAddress.state_code ?? '',
                inputAddress.zip,
              );
              return {
                address1: melissaAddress.address1,
                address2: melissaAddress.suite,
                city: melissaAddress.city,
                state_code: melissaAddress.state,
                zip: melissaAddress.zip,
                country: inputAddress.country,
              };
          }
        } catch (error) {
          // throw new Error(INVALID_ADDRESS);
          putNoty({ type: 'error', text: (error as Error).message });
          return Promise.reject<IEpSubsDBCustomer_addressValue>(error);
        }
      } else {
        return null;
      }
    },
});

// *********************************************** //
//       Current Customer Comments States          //
// *********************************************** //

/**
 * An atom that stores the currently selected customer Comments query parameters
 */
export const currentCustomerCommentsSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentCustomerCommentsSearchParamAtom',
  default: {
    limit: DEFAULT_COMMENT_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected customer Comments
 */
export const currentCustomerCommentsSelector = selector<IEpSubsDBCustomer_commentValue[]>(
  {
    key: 'currentCustomerCommentsSelector',
    get: async ({ get }) => {
      const id = get(currentCustomerIdAtom);
      const params = get(currentCustomerCommentsSearchParamAtom);
      const hasPermission = get(
        hasPermissionSelectors(UiPermission['cust.view_comment']),
      );
      if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
        return await genCustomerCommentData(id, params.limit);
      } else {
        return [];
      }
    },
  },
);

// *********************************************** //
//       Current Customer Activities States        //
// *********************************************** //

/**
 * An atom that stores the currently selected customer Activities query parameters
 */
export const currentCustomerActivitiesSearchParamAtom = atom<{
  limit: number;
  requestId: number;
}>({
  key: 'currentCustomerActivitiesSearchParamAtom',
  default: {
    limit: DEFAULT_ACTIVITY_COUNT,
    requestId: 0, // for query refresh
  },
});

/**
 * An selector that stores the currently selected customer Activities
 */
export const currentCustomerActivitiesSelector = selector<
  IEpSubsDBCustomer_activityValue[]
>({
  key: 'currentCustomerActivitiesSelector',
  get: async ({ get }) => {
    const id = get(currentCustomerIdAtom);
    const params = get(currentCustomerActivitiesSearchParamAtom);
    const hasPermission = get(hasPermissionSelectors(UiPermission['cust.view_log']));
    if (hasPermission && id !== CURRENT_ID_ATOM_DEFAULT_VALUE) {
      return await genCustomerActivityData(id, params.limit);
    } else {
      return [];
    }
  },
});

/**
 * An selectorFamily that stores Customer Activity Detail for each activity
 */
export const customerActivityDetailSelectors = selectorFamily<
  IEpSubsDBCustomer_activityValue,
  number
>({
  key: 'customerActivityDetailSelectors',
  get: actLogId => async () => {
    const customerActivityData = await genCustomerActivityDetail(actLogId);
    DEBUGGING &&
      console.log(
        'recoil, customerSelectors customerActivityData:',
        customerActivityData,
      );
    return customerActivityData;
  },
});

/**
 * An atom that make phone type to be requited if the phone number entered
 */
export const customerPhoneNumberEnteredAtom = atom<boolean>({
  key: 'customerPhoneNumberEnteredAtom',
  default: false,
});

// *********************************************** //
//          Customer Gift Cards  Selectors         //
// *********************************************** //

export const giftCardsListRequestIDAtom = atom<number>({
  key: 'giftCardsListRequestIDAtom',
  default: 0,
});

export const currentGiftCardIdAtom = atom<number | null>({
  key: 'currentGiftCardIdAtom',
  default: CURRENT_ID_ATOM_DEFAULT_VALUE,
});

/**
 * An selector that stores customer gift cards list
 */
export const giftCardsSelectors = selectorFamily<IEpSubsDBGift_cardValue[], number>({
  key: 'giftCardsSelectors',
  get: customerId => async () => {
    const giftCardsData = await getAllGiftCardsList(customerId);
    DEBUGGING && console.log('recoil, get customer gift cards Data:', giftCardsData);
    return giftCardsData;
  },
});

// ************************************************ //
//  Gift Card List Latest Search Parameter States  //
// ************************************************ //
export const DEFAULT_GIFT_CARD_SEARCH_PARAMETER = `?sort=gift-cards&order=DESC`;
/**
 * An atom that stores the latest gift card search param
 */
export const latestGiftCardsSearchParamAtom = atom<TLocationRecord>({
  key: 'latestGiftCardsSearchParamAtom',
  default: { search: DEFAULT_GIFT_CARD_SEARCH_PARAMETER, ready: false },
});

// *********************************************** //
//                Gift Cards List Page Hooks        //
// *********************************************** //

export const useRefreshGiftCardsList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh gift cards list
        set(giftCardsListRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

// *********************************************** //
//          Customer Tags Selector                //
// *********************************************** //

export const customerTagsRequestIDAtom = atom<number>({
  key: 'customerTagsRequestIDAtom',
  default: 0,
});

export const useRefreshCustomerTags = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        set(customerTagsRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const customerTagsSelector = selector<IEpSubsTagsListValue[]>({
  key: 'customerTagsSelector',
  get: async () => {
    const tagsList = await getCustomerTags();
    return tagsList;
  },
});
